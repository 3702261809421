import { BigDecimal } from '../../utils/bigDecimal'

import type { TokenBalanceData } from './balances.types'

export const sumBalancesDataForToken = (balances: TokenBalanceData[]) => {
  const tokenSums: Map<string, string> = new Map()

  balances.forEach((balance) => {
    const { tokenAddress, amount } = balance
    if (tokenSums.has(tokenAddress))
      tokenSums.set(
        tokenAddress,
        BigDecimal.fromString(tokenSums.get(tokenAddress) as string)
          .add(BigDecimal.fromString(amount))
          .toFixed(18)
      )
    else tokenSums.set(tokenAddress, amount)
  })

  return [...tokenSums].map(([tokenAddress, amount]) => ({ tokenAddress, amount }))
}
