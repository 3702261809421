import type { Language } from '../src/types/common'

// ======================= Lasy Tlenowe ======================= //
import LasyTlenoweBase from './lasy-tlenowe/base.json'
import LasyTlenowePLContent from './lasy-tlenowe/content-pl.json'
import LasyTlenoweENContent from './lasy-tlenowe/content-en.json'
import LasyTlenoweProjectToken from './lasy-tlenowe/project-token.json'
import LasyTlenowePhases from './lasy-tlenowe/phases.json'
import LasyTlenoweTranches from './lasy-tlenowe/tranches.json'
import LasyTlenoweRewardERC20 from './lasy-tlenowe/reward-tokens.json'
import LasyTlenoweSubgraphFallbackData from './lasy-tlenowe/subgraphFallback.json'
// ======================= Vivo ======================= //
import VivoBase from './vivo/base.json'
import VivoPLContent from './vivo/content-pl.json'
import VivoENContent from './vivo/content-en.json'
import VivoProjectToken from './vivo/project-token.json'
import VivoPhases from './vivo/phases.json'
import VivoTranches from './vivo/tranches.json'
import VivoRewardERC20 from './vivo/reward-tokens.json'
import VivoSubgraphFallbackData from './vivo/subgraphFallback.json'
// ======================= IFO ======================= //
// import IfoPLContent from './ifo/content-pl.json'
// import IfoBase from './ifo/base.json'
// import IfoENContent from './ifo/content-en.json'
// import IfoProjectToken from './ifo/project-token.json'
// import IfoPhases from './ifo/phases.json'
// import IfoTranches from './ifo/tranches.json'
// ======================= MusTank ======================= //
import MusTankBase from './mustank/base.json'
import MusTankPLContent from './mustank/content-pl.json'
import MusTankENContent from './mustank/content-en.json'
import MusTankProjectToken from './mustank/project-token.json'
import MusTankPhases from './mustank/phases.json'
import MusTankTranches from './mustank/tranches.json'
import MusTankRewardERC20 from './mustank/reward-tokens.json'
// ======================= Copernic GO ======================= //
import CopernicGoPLContent from './copernic-go/content-pl.json'
import CopernicGoBase from './copernic-go/base.json'
import CopernicGoENContent from './copernic-go/content-en.json'
import CopernicGoProjectToken from './copernic-go/project-token.json'
import CopernicGoPhases from './copernic-go/phases.json'
import CopernicGoTranches from './copernic-go/tranches.json'
import CopernicGoSubgraphFallbackData from './copernic-go/subgraphFallback.json'
// ======================= Copernic ======================= //
import CopernicPLContent from './copernic/content-pl.json'
import CopernicBase from './copernic/base.json'
import CopernicENContent from './copernic/content-en.json'
import CopernicProjectToken from './copernic/project-token.json'
import CopernicPhases from './copernic/phases.json'
import CopernicTranches from './copernic/tranches.json'
import CopernicSubgraphFallbackData from './copernic/subgraphFallback.json'
// ======================= Toscanizzazione 2 ======================= //
import Toscanizzazione2PLContent from './toscanizzazione-2/content-pl.json'
import Toscanizzazione2Base from './toscanizzazione-2/base.json'
import Toscanizzazione2ENContent from './toscanizzazione-2/content-en.json'
import Toscanizzazione2ProjectToken from './toscanizzazione-2/project-token.json'
import Toscanizzazione2Phases from './toscanizzazione-2/phases.json'
import Toscanizzazione2Tranches from './toscanizzazione-2/tranches.json'
import Toscanizzazione2SubgraphFallbackData from './toscanizzazione-2/subgraphFallback.json'
// ======================= Motorizzazione ======================= //
import MotorizzazionePLContent from './motorizzazione/content-pl.json'
import MotorizzazioneBase from './motorizzazione/base.json'
import MotorizzazioneENContent from './motorizzazione/content-en.json'
import MotorizzazioneProjectToken from './motorizzazione/project-token.json'
import MotorizzazionePhases from './motorizzazione/phases.json'
import MotorizzazioneTranches from './motorizzazione/tranches.json'
import MotorizzazioneSubgraphFallbackData from './motorizzazione/subgraphFallback.json'
// ======================= HUB ONE ======================= //
import HubOnePLContent from './hub-one/content-pl.json'
import HubOneBase from './hub-one/base.json'
import HubOneENContent from './hub-one/content-en.json'
import HubOneProjectToken from './hub-one/project-token.json'
import HubOnePhases from './hub-one/phases.json'
import HubOneTranches from './hub-one/tranches.json'
import HubOneSubgraphFallbackData from './hub-one/subgraphFallback.json'
// ======================= Pasieka Jurajska ======================= //
import PasiekaJurajskaPLContent from './pasieka-jurajska/content-pl.json'
import PasiekaJurajskaBase from './pasieka-jurajska/base.json'
import PasiekaJurajskaENContent from './pasieka-jurajska/content-en.json'
import PasiekaJurajskaProjectToken from './pasieka-jurajska/project-token.json'
import PasiekaJurajskaPhases from './pasieka-jurajska/phases.json'
import PasiekaJurajskaTranches from './pasieka-jurajska/tranches.json'
import PasiekaJurajskaSubgraphFallbackData from './pasieka-jurajska/subgraphFallback.json'
// ======================= Closed projects ======================= //
import ClosedProjectNBase from './closed-projects/base.json'
import MosaicoAssetsPLContent from './mosaico-assets/content-pl.json'
import MosaicoAssetsENContent from './mosaico-assets/content-en.json'

const ProjectBase = [
  LasyTlenoweBase,
  VivoBase,
  MusTankBase,
  CopernicGoBase,
  CopernicBase,
  Toscanizzazione2Base,
  MotorizzazioneBase,
  HubOneBase,
  PasiekaJurajskaBase,
]

const ProjectPLContent = [
  LasyTlenowePLContent,
  VivoPLContent,
  MusTankPLContent,
  CopernicGoPLContent,
  CopernicPLContent,
  Toscanizzazione2PLContent,
  MotorizzazionePLContent,
  HubOnePLContent,
  PasiekaJurajskaPLContent,
]
const ProjectENContent = [
  LasyTlenoweENContent,
  VivoENContent,
  MusTankENContent,
  CopernicGoENContent,
  CopernicENContent,
  Toscanizzazione2ENContent,
  MotorizzazioneENContent,
  HubOneENContent,
  PasiekaJurajskaENContent,
]

const ProjectTokens = [
  LasyTlenoweProjectToken,
  VivoProjectToken,
  MusTankProjectToken,
  CopernicGoProjectToken,
  CopernicProjectToken,
  Toscanizzazione2ProjectToken,
  MotorizzazioneProjectToken,
  HubOneProjectToken,
  PasiekaJurajskaProjectToken,
]
const RewardTokens = [LasyTlenoweRewardERC20, VivoRewardERC20, MusTankRewardERC20]

const Phases = [
  LasyTlenowePhases,
  VivoPhases,
  MusTankPhases,
  CopernicGoPhases,
  CopernicPhases,
  Toscanizzazione2Phases,
  MotorizzazionePhases,
  HubOnePhases,
  PasiekaJurajskaPhases,
]
const Tranches = [
  LasyTlenoweTranches,
  VivoTranches,
  MusTankTranches,
  CopernicGoTranches,
  CopernicTranches,
  Toscanizzazione2Tranches,
  MotorizzazioneTranches,
  HubOneTranches,
  PasiekaJurajskaTranches,
]

const SubgraphFallback = [
  VivoSubgraphFallbackData,
  LasyTlenoweSubgraphFallbackData,
  CopernicGoSubgraphFallbackData,
  CopernicSubgraphFallbackData,
  Toscanizzazione2SubgraphFallbackData,
  MotorizzazioneSubgraphFallbackData,
  HubOneSubgraphFallbackData,
  PasiekaJurajskaSubgraphFallbackData,
]

export const AllProjectBaseQueryResponse = () => {
  return ProjectBase
}

export const AllClosedProjectBaseQueryResponse = () => {
  return ClosedProjectNBase
}

export const GetAllMosaicoAssetsContentByLanguage = (language: Language) => {
  if (language === 'pl') return MosaicoAssetsPLContent
  return MosaicoAssetsENContent
}

export const OneProjectBaseQueryResponse = (slug: string) => AllProjectBaseQueryResponse().find((project) => project.slug === slug)

const GetProjectsContentByLanguage = (language: Language) => {
  if (language === 'pl') return ProjectPLContent
  return ProjectENContent
}

export const GetAllProjectsSlug = () => ProjectBase.map((project) => project.slug)

// ======================= Project content ======================= //

export const GetProjectPageData = (language: Language, id: number) =>
  GetProjectsContentByLanguage(language).find((project) => project.projectId === id)

export const GetProjectDescriptionsData = (language: Language) =>
  GetProjectsContentByLanguage(language).map((project) => {
    return {
      shortDescription: project.shortDescription,
      projectId: project.projectId,
      longDescription: project.longDescription,
    }
  })

export const GetProjectRewardsDescriptionsData = (language: Language) =>
  GetProjectsContentByLanguage(language).flatMap((project) => {
    return project.rewardsDescriptions
  })

// ======================= Project tokens ======================= //

export const ProjectTokensBackendQueryResponse = () => ProjectTokens.map((token) => token)
export const ProjectTokensBackendQueryResponseByAddress = (address: string) =>
  ProjectTokensBackendQueryResponse().find((token) => token.address === address)

// ======================= Phases ======================= //

export const PhasesBackendAllQueryResponse = () => Phases.flat()

export const PhasesBackendQueryResponseByAddress = (address: string) =>
  PhasesBackendAllQueryResponse().filter((phase) => phase.fundraise.address === address)

// ======================= Tranches ======================= //

export const TranchesBackendAllQueryResponse = () => Tranches.flat()

export const TranchesBackendQueryResponseByAddress = (address: string) =>
  TranchesBackendAllQueryResponse().filter((tranche) => tranche.token.address === address)

// ======================= Reward tokens ======================= //

export const RewardTokensBackendQueryResponse = () => RewardTokens.flat()

export const RewardTokensBackendQueryResponseByAddress = (address: string) => RewardTokens.flat().find((token) => token.address === address)

// ======================= Subgraph Fallback Data ======================= //

// Fundraises

export const SubgraphFundraisesQueryResponse = () => SubgraphFallback.map((fallback) => fallback.fundraise)

export const SubgraphFundraiseQueryResponseByAddress = (address: string) =>
  SubgraphFundraisesQueryResponse().find((fundraise) => fundraise.address === address)

export const SubgraphFundraiseQueryResponseByAdresses = (addresses: string[]) =>
  SubgraphFundraisesQueryResponse().filter((fundraise) => addresses.includes(fundraise.address))

// Phases (Fundraises)

export const SubgraphPhasesQueryResponse = () => SubgraphFallback.flatMap((fallback) => fallback.phases)

export const SubgraphPhasesQueryResponseByAddress = (address: string) =>
  SubgraphPhasesQueryResponse().filter((phase) => phase.fundraise.address === address)

export const SubgraphPhasesQueryResponseByAdresses = (addresses: string[]) =>
  SubgraphPhasesQueryResponse().filter((phase) => addresses.includes(phase.fundraise.address))

// Project Tokens

export const SubgraphProjectTokensQueryResponse = () => SubgraphFallback.map((fallback) => fallback.projectToken)

export const SubgraphProjectTokenQueryResponseByAddress = (address: string) =>
  SubgraphProjectTokensQueryResponse().find((token) => token.address === address)

export const SubgraphProjectTokenQueryResponseByAdresses = (addresses: string[]) =>
  SubgraphProjectTokensQueryResponse().filter((token) => addresses.includes(token.address))

// Tranches (Project tokens)

export const SubgraphTranchesQueryResponse = () => SubgraphFallback.flatMap((fallback) => fallback.tranches)

export const SubgraphTranchesQueryResponseByAddress = (address: string) =>
  SubgraphTranchesQueryResponse().filter((tranche) => tranche.token.address === address)

export const SubgraphTranchesQueryResponseByAdresses = (addresses: string[]) =>
  SubgraphTranchesQueryResponse().filter((tranche) => addresses.includes(tranche.token.address))
