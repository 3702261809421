import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type {
  FetchProjectPageDataPayload,
  FetchProjectPageDataSuccessPayload,
  ProjectContent,
  ProjectDescriptionsData,
  ProjectPageData,
  RewardDescriptionData,
} from './projectContent.types'
import { ProjectContentState } from './projectContent.state'
import { isProjectDescriptionsData, isProjectPageData, isProjectRewardsDescriptionsData } from './projectContent.const'

export const projectPageContentAdapter = createEntityAdapter<ProjectPageData>({
  selectId: (projectContent) => projectContent.projectId,
})

export const projectDescriptionsAdapter = createEntityAdapter<ProjectDescriptionsData>({
  selectId: (projectContent) => projectContent.projectId,
})

export const projectRewardsDescriptionsAdapter = createEntityAdapter<RewardDescriptionData>({
  selectId: (projectContent) => projectContent.address,
})

export const initialProjectContentSliceState: ProjectContent = {
  ...new ProjectContentState(),
  rewardsDescriptions: projectRewardsDescriptionsAdapter.getInitialState(),
  projectPage: projectPageContentAdapter.getInitialState(),
  descriptions: projectDescriptionsAdapter.getInitialState(),
}

const hydrate = createAction<{ [StoreKeys.ProjectContent]: ProjectContent }>(HYDRATE)

export const projectContentSlice = createSlice({
  reducers: {
    fetchProjectRewardsDescriptionsSuccess: (state, { payload }: PayloadAction<RewardDescriptionData[]>) => {
      state.fetchProjectRewardsDescriptionsStatus = RequestStatus.Succeeded
      projectRewardsDescriptionsAdapter.upsertMany(state.rewardsDescriptions, payload)
    },
    fetchProjectRewardsDescriptionsError: (state, { payload }: PayloadAction<RequestError>) => {
      state.fetchProjectRewardsDescriptionsStatus = RequestStatus.Failed
      state.error = payload
    },
    fetchProjectRewardsDescriptions: (state, _: PayloadAction<{ projectId: number }>) => {
      state.fetchProjectRewardsDescriptionsStatus = RequestStatus.Loading
    },
    fetchProjectPageDataSuccess: (state, { payload }: PayloadAction<FetchProjectPageDataSuccessPayload>) => {
      state.fetchProjectPageDataStatus = RequestStatus.Succeeded
      projectPageContentAdapter.upsertOne(state.projectPage, payload)
    },
    fetchProjectPageDataError: (state, { payload }: PayloadAction<RequestError>) => {
      state.fetchProjectPageDataStatus = RequestStatus.Failed
      state.error = payload
    },
    fetchProjectPageData: (state, _: PayloadAction<FetchProjectPageDataPayload>) => {
      state.fetchProjectPageDataStatus = RequestStatus.Loading
    },
    fetchProjectDescriptionsSuccess: (state, { payload }: PayloadAction<ProjectDescriptionsData[]>) => {
      state.fetchProjectDescriptionsStatus = RequestStatus.Succeeded
      projectDescriptionsAdapter.upsertMany(state.descriptions, payload)
    },
    fetchProjectDescriptionsError: (state, { payload }: PayloadAction<RequestError>) => {
      state.fetchProjectDescriptionsStatus = RequestStatus.Failed
      state.error = payload
    },
    fetchProjectDescriptions: (state) => {
      state.fetchProjectDescriptionsStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.ProjectContent,
  initialState: initialProjectContentSliceState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      if (payload[StoreKeys.ProjectContent]) {
        const { projectPage, descriptions, rewardsDescriptions, ...statuses } = payload[StoreKeys.ProjectContent]

        const rewardsDescriptionsData = Object.values(rewardsDescriptions.entities).filter(isProjectRewardsDescriptionsData)
        const projectPageData = Object.values(projectPage.entities).filter(isProjectPageData)
        const descriptionsData = Object.values(descriptions.entities).filter(isProjectDescriptionsData)

        state = {
          ...state,
          ...statuses,
          rewardsDescriptions: projectRewardsDescriptionsAdapter.upsertMany(state.rewardsDescriptions, rewardsDescriptionsData),
          projectPage: projectPageContentAdapter.upsertMany(state.projectPage, projectPageData),
          descriptions: projectDescriptionsAdapter.upsertMany(state.descriptions, descriptionsData),
        }
      }
    }),
})

export const projectPageContentSelectors = projectPageContentAdapter.getSelectors()
export const projectDescriptionsSelectors = projectDescriptionsAdapter.getSelectors()
export const projectRewardsDescriptionsSelectors = projectRewardsDescriptionsAdapter.getSelectors()

export const projectContentActions = projectContentSlice.actions
export const projectContentReducer = projectContentSlice.reducer
export type ProjectContentActions = ActionsType<typeof projectContentActions>
