import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { projectContentActions } from './projectContent.slice'
import { fetchProjectPageDataSaga } from './sagas/fetchProjectPageData/fetchProjectPageData.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(projectContentActions.fetchProjectPageData, fetchProjectPageDataSaga),
    takeLatest(projectContentActions.fetchProjectDescriptions, fetchProjectPageDataSaga),
  ])
}

export function* projectContentSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
