import { gql } from './gql'

export const ALL_PROJECTS_QUERY = gql(`
  query GetAllProjects($languageCode: String!, $deviceType: String!) {
    projects(languageCode: $languageCode, deviceType: $deviceType) {
      id
      description
      tags
      logoSrc
      contractAddress
      mainImageSrc
    }
  }
`)
export const SAVE_WALLET_MUTATION = gql(`
  mutation SaveWallet($walletType: String!, $ethereumAddress: String!){
    saveWallet(walletType: $walletType, ethereumAddress: $ethereumAddress){
      walletType,
      ethereumAddress
    }
  }
`)

export const GET_USER = gql(`
  query GetUser {
    user {
      id,
      email,
      basicKycStatus,
      advancedKycStatus,
      localization,
      marketingConsents,
      monthlyInvestments,
      isMigrated,
      wallets {
        walletType,
        ethereumAddress
      }
    }
  }
`)

export const UPDATE_USER = gql(`
  mutation UpdateUser($localization: String, $marketingConsents: Boolean){
    updateUser(localization: $localization, marketingConsents: $marketingConsents) {
      localization
      marketingConsents
    }
  }
`)

export const FETCH_APPLICANT_DATA = gql(`
  query GetApplicantData {
    applicantData {
      firstName,
      lastName,
      email,
      dateOfBirth,
      phone,
      country,
      postCode,
      town,
      street,
      streetNumber
    }
  }
`)

export const GET_USER_INVESTMENTS = gql(`
  query getUserInvestments {
    investments {
      id
      type
      status
      transactionStatus
      transactionHash
      fundraiseAddress
      amountIn
      amountOut
      userId
      walletAddress
      createdAt
      updatedAt
    }
  }
`)

export const UPDATE_INVESTMENT = gql(`
  mutation updateInvestment($id: Float!, $transactionStatus: String!, $transactionHash: String) {
    updateInvestment(id: $id, transactionStatus: $transactionStatus, transactionHash: $transactionHash) {
      id
      type
      status
      transactionStatus
      transactionHash
      amountIn
      amountOut
      walletAddress
      fundraiseAddress
      createdAt
      updatedAt
    }
  }
`)

export const CREATE_INVESTMENT = gql(`
  mutation createInvestment($type: String!, $amountIn: String!, $amountOut: String!, $walletAddress: String!, $fundraiseAddress: String!) {
    createInvestment(type: $type, amountIn: $amountIn, amountOut: $amountOut, walletAddress: $walletAddress, fundraiseAddress: $fundraiseAddress) {
      id
      type
      status
      transactionStatus
      transactionHash
      fundraiseAddress
      amountIn
      amountOut
      walletAddress
      createdAt
      updatedAt
    }
  }
`)
