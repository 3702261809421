import type { Components, Theme } from '@mui/material'

export const getAccordion = (): Components<Theme> => ({
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          minHeight: '84px',
        },
      },
      content: {
        '&.Mui-expanded': {
          margin: '12px 0',
        },
      },
    },
  },
})
