import type { Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'
import type { CustomTypographyVariants } from '../typography'

export const getInput = (palette: CustomPalette, __: CustomTypographyVariants): Components<Theme> => ({
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        letterSpacing: '0.14px',
      },
      notchedOutline: {
        borderRadius: '8px',
        border: `1px solid ${palette._stroke[400]}`,
      },
      input: {
        padding: '10px 16px',
        lineHeight: '170%',
        fontSize: '14px',
      },
    },
  },
})
