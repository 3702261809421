import { Close } from '@mui/icons-material'
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Stack, DialogActions } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'

import { Button } from '../../core-components/buttons/Button'
import { getIsVisited, saveIsVisited } from '../../store/session/session.utils'

export const NewUserDialog = () => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(!getIsVisited())

  const handleClose = useCallback(() => {
    setIsOpen(false)
    saveIsVisited()
  }, [])

  return (
    <Dialog maxWidth="md" open={isOpen} onClose={handleClose}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle sx={{ p: 6, m: 0 }}>{t('welcome-to-new-platform')}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            mr: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent sx={{ maxWidth: '480px' }} dividers>
        <Typography variant="bodySm">{t('recreate-account')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          {t('understand')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
