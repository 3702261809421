import type { SubgraphRewardTokenERC20QueryResult } from '../../types/subgraphQueryTypes'
import type { BackendRewardTokenERC20QueryResult } from '../../types/backendQueryTypes'
import { BigDecimal } from '../../utils/bigDecimal'
import { DEFAULT_INTERNAL_TOKEN_DECIMALS } from '../../../config/decimals'

import type { RewardTokenERC20Backend, RewardTokenERC20Subgraph } from './rewardTokenERC20.types'
import { RewardTokenType } from './rewardTokenERC20.const'

export const mapSubgraphRewardTokenERC20DataToRewardTokenERC20Data = (
  subgraphRewardTokenERC20Data: SubgraphRewardTokenERC20QueryResult
): RewardTokenERC20Subgraph => ({
  totalSupply: BigDecimal.fromString(subgraphRewardTokenERC20Data.totalSupply, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(
    DEFAULT_INTERNAL_TOKEN_DECIMALS
  ),
  symbol: subgraphRewardTokenERC20Data.symbol,
  stakeTokenAddress: subgraphRewardTokenERC20Data.stakeToken?.address ?? null,
  rewardTokenType: RewardTokenType.ERC20,
  projectTokenAddress: subgraphRewardTokenERC20Data.projectToken?.address ?? null,
  name: subgraphRewardTokenERC20Data.name,
  id: subgraphRewardTokenERC20Data.id,
  decimals: Number(subgraphRewardTokenERC20Data.decimals),
  dailyReward: BigDecimal.fromString(subgraphRewardTokenERC20Data.dailyReward, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(
    DEFAULT_INTERNAL_TOKEN_DECIMALS
  ),
  address: subgraphRewardTokenERC20Data.address,
})

export const mapBackendRewardTokenERC20DataToRewardTokenERC20Data = (
  backendRewardTokenERC20Data: BackendRewardTokenERC20QueryResult
): RewardTokenERC20Backend => ({
  ...backendRewardTokenERC20Data,
  rewardPriceInUSD: backendRewardTokenERC20Data.rewardPriceInUSD ?? '0',
  projectTokenAddress: backendRewardTokenERC20Data.projectTokenAddress ?? null,
})
