import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import { FundraiseState } from './fundraise.state'
import type { Fundraise, FundraiseData, InvestPayload, SwapFundTokensPayload } from './fundraise.types'
import { isFundraise } from './fundraise.const'

export const fundraiseAdapter = createEntityAdapter<FundraiseData>({
  sortComparer: (a, b) => a.address.localeCompare(b.address),
  selectId: (fundraise) => fundraise.address,
})

export const fundraiseInitialState: Fundraise = fundraiseAdapter.getInitialState({
  ...new FundraiseState(),
})

const hydrate = createAction<{ [StoreKeys.Fundraise]: Fundraise }>(HYDRATE)

export const fundraiseSlice = createSlice({
  reducers: {
    swapFundTokensSuccess(state) {
      state.swapFundTokensStatus = RequestStatus.Succeeded
    },
    swapFundTokensFailure(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.swapFundTokensStatus = RequestStatus.Failed
    },
    swapFundTokens(state, _: PayloadAction<SwapFundTokensPayload>) {
      state.swapFundTokensStatus = RequestStatus.Loading
    },
    investSuccess: (state) => {
      state.investStatus = RequestStatus.Succeeded
    },
    investFailure: (state, action: PayloadAction<RequestError>) => {
      state.investStatus = RequestStatus.Failed
      state.error = action.payload
    },
    invest(state, _: PayloadAction<InvestPayload>) {
      state.investStatus = RequestStatus.Loading
    },
    fetchSubgraphSingleSuccess: (state, action: PayloadAction<FundraiseData>) => {
      fundraiseAdapter.upsertOne(state, action.payload)
      state.fetchSubgraphSingleStatus = RequestStatus.Succeeded
    },
    fetchSubgraphSingleFailure: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.fetchSubgraphSingleStatus = RequestStatus.Failed
    },
    fetchSubgraphSingle: (state) => {
      state.fetchSubgraphSingleStatus = RequestStatus.Loading
    },
    fetchSubgraphAllSuccess: (state, action: PayloadAction<FundraiseData[]>) => {
      fundraiseAdapter.upsertMany(state, action.payload)
      state.fetchSubgraphAllStatus = RequestStatus.Succeeded
    },
    fetchSubgraphAllFailure: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.fetchSubgraphAllStatus = RequestStatus.Failed
    },
    fetchSubgraphAll: (state) => {
      state.fetchSubgraphAllStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.Fundraise,
  initialState: fundraiseInitialState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { entities } = payload[StoreKeys.Fundraise]
      const fundraises = Object.values(entities)
      const fundraisesToUpsert = fundraises.filter(isFundraise)
      return fundraiseAdapter.upsertMany(state, fundraisesToUpsert)
    }),
})

export const fundraiseAdapterSelectors = fundraiseAdapter.getSelectors()
export const fundraiseReducer = fundraiseSlice.reducer
export const fundraiseActions = fundraiseSlice.actions
export type FundraiseActions = ActionsType<typeof fundraiseActions>
