import type { FundraiseData } from './fundraise.types'

export enum Milestone {
  upcoming,
  softCapNotReached,
  softCapReached,
  fundraiseClosed,
}

export enum FundraiseStage {
  upcoming,
  privateSale,
  presale,
  ico,
  rewards,
  secondaryMarket,
  closed,
}

export const isFundraise = (fundraise: any): fundraise is FundraiseData => {
  return fundraise?.id !== undefined
}
