import type { ContractTransaction } from 'ethers'
import { BigNumber, utils } from 'ethers'
import { call, put, select } from 'typed-redux-saga'
import type { StrictEffect } from '@redux-saga/types'
import { generateRandomSalt } from '@sapiency-io/mosaico-contracts/dist'

import { selectChainId, selectSigner, selectWeb3Account } from '../../../web3/web3.selectors'
import { getFundraise } from '../../../web3/web3.utils'
import type { FundraiseData } from '../../fundraise.types'
import { getInvestmentTransactionSignature } from '../../../investment/sagas/transactionSignature.saga'
import { cryptocurrenciesSelectors } from '../../../cryptocurrencies/cryptocurrencies.selectors'
import { userInvestmentsActions } from '../../../userInvestments/userInvestments.slice'
import { InvestmentType, TransactionStatus } from '../../../userInvestments/userInvestments.types'
import { apiCall } from '../../../../utils/apiCall'
import { createInvestment } from '../../../userInvestments/sagas/createInvestment/createInvestment.saga'
import { BigDecimal } from '../../../../utils/bigDecimal'

export function* investTokenInContractTransactionSaga(
  fundraiseAddress: FundraiseData['address'],
  tokenInAmount: string,
  tokenOutAmount: string
): Generator<StrictEffect, ContractTransaction> {
  const account = yield* select(selectWeb3Account)
  if (!account) throw new Error('Web3 Account not found')

  const signer = yield* select(selectSigner)
  const chainId = yield* select(selectChainId)

  const fundraiseContract = yield* call(getFundraise, fundraiseAddress, signer)

  const usdcToken = yield* select(cryptocurrenciesSelectors.selectUSDC)

  if (!usdcToken) throw new Error('USDC Token not found')
  const decimalsOut = 18

  const salt = yield* call(generateRandomSalt)
  const tokenInAmountBN = utils.parseUnits(tokenInAmount, usdcToken.decimals)
  const tokenOutAmountBN = utils.parseUnits(tokenOutAmount, decimalsOut)

  const investmentData = {
    verifyingContract: fundraiseAddress,
    salt,
    investor: account,
    decimalsOut,
    decimalsIn: usdcToken.decimals,
    chainId,
    amountOut: '0',
    amountIn: tokenInAmountBN.toString(),
  }

  const signature = yield* call(getInvestmentTransactionSignature, investmentData)
  if (!signature) throw new Error('No Investment Transaction Signature')

  const investment = yield* apiCall(createInvestment, {
    walletAddress: account,
    type: InvestmentType.WEB3,
    fundraiseAddress,
    amountOut: BigDecimal.from(tokenOutAmountBN, decimalsOut).toFixed(decimalsOut),
    amountIn: BigDecimal.from(tokenInAmountBN, usdcToken.decimals).toFixed(usdcToken.decimals),
  })

  const transaction = yield* call([fundraiseContract, fundraiseContract.invest], tokenInAmountBN, BigNumber.from(0), salt, signature)

  yield* put(
    userInvestmentsActions.updateUserInvestment({
      transactionStatus: TransactionStatus.PENDING,
      transactionHash: transaction.hash,
      id: investment.id,
    })
  )
  const recipt = yield* call(transaction.wait)

  yield* put(
    userInvestmentsActions.updateUserInvestment({
      transactionStatus: recipt.status ? TransactionStatus.SUCCESSFUL : TransactionStatus.REJECTED,
      id: investment.id,
    })
  )

  return transaction
}
