import { createSelector } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import { selectReducer } from '../store.utils'
import { navigationSelectors } from '../navigation/navigation.selectors'

import { projectBaseAdapter } from './projectBase.slice'

const selectState = createSelector([selectReducer(StoreKeys.ProjectBase)], (state) => state)

const selectAll = createSelector([selectState], (state) => projectBaseAdapter.getSelectors().selectAll(state))

const selectCurrent = createSelector([selectAll, navigationSelectors.selectCurrentSlug], (all, slug) =>
  all.find((projectBase) => projectBase.slug === slug)
)

const makeSelectByProjectTokenAddress = () =>
  createSelector([selectAll, (_: unknown, address: string) => address], (all, address) =>
    all.find((projectBase) => projectBase.projectTokenAddress === address)
  )

const selectByProjectTokenAddress = makeSelectByProjectTokenAddress()

export const projectBaseSelectors = { selectCurrent, selectByProjectTokenAddress, selectAll, makeSelectByProjectTokenAddress }
