'use client'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { sessionActions } from '../store/session/session.slice'
import { hasAuthParams } from '../utils/auth0Params'

export const AuthWrapper = ({ children }: React.PropsWithChildren) => {
  const dispatch = useDispatch()

  const didInitialise = useRef(false)

  useEffect(() => {
    if (window !== undefined) {
      if (didInitialise.current) return
      didInitialise.current = true

      if (hasAuthParams()) dispatch(sessionActions.redirectStart())
      else dispatch(sessionActions.initializeSessionStart(window.location.origin))
    }
  }, [dispatch])

  return <React.Fragment>{children}</React.Fragment>
}
