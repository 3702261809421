import { call, select } from 'typed-redux-saga'

import type { Web3Actions } from '../../web3.slice'
import { web3Selectors } from '../../web3.selectors'

import { activateWebSaga } from './activateWeb3/activateWeb3.saga'

export function* accountChangedSaga({ payload }: Web3Actions['accountsChanged']): Generator {
  const web3 = yield* select(web3Selectors.selectWeb3)

  const [activeMetamaskAccount] = payload
  if (activeMetamaskAccount && web3 && activeMetamaskAccount.toLowerCase() !== web3.account?.toLowerCase())
    yield* call(activateWebSaga, web3, activeMetamaskAccount)
}
