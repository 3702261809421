import { createSelector } from '@reduxjs/toolkit'
import { ERC20__factory, TestTokenUSDC__factory } from '@sapiency-io/mosaico-contracts/dist'

import { StoreKeys } from '../store.keys'
import { selectReducer } from '../store.utils'
import { web3Selectors } from '../web3/web3.selectors'
import { Chains } from '../../../config/chains'

import { cryptocurrenciesAdapterSelectors } from './cryptocurrencies.slice'
import { CryptocurrencySymbolEnum, CryptocurrencyTypeEnum } from './cryptocurrencies.types'

const selectState = createSelector([selectReducer(StoreKeys.Cryptocurrencies)], (state) => state)

const selectAll = createSelector([selectState], (state) => cryptocurrenciesAdapterSelectors.selectAll(state))

const selectStablecoins = createSelector([selectAll], (tokens) => {
  return tokens.filter((currency) => currency.type === CryptocurrencyTypeEnum.STABLECOIN)
})

const selectNativeToken = createSelector([selectAll], (tokens) =>
  tokens.find((currency) => currency.type === CryptocurrencyTypeEnum.NATIVE)
)

const selectUSDC = createSelector([selectStablecoins], (tokens) => tokens.find((token) => token.symbol === CryptocurrencySymbolEnum.USDC))

const selectUSDCContract = createSelector([selectUSDC, web3Selectors.selectSigner], (usdcToken, signer) => {
  if (!usdcToken) throw new Error('USDC Token not found')

  if (usdcToken.chain === Chains.MUMBAI) return TestTokenUSDC__factory.connect(usdcToken.address, signer)

  return ERC20__factory.connect(usdcToken.address, signer)
})

export const selectUSDCReadOnlyContract = createSelector(
  [selectUSDC, web3Selectors.selectReadOnlyProvider],
  (usdcToken, readOnlyProvider) => {
    if (!usdcToken) throw new Error('USDC Token not found')

    if (usdcToken.chain === Chains.MUMBAI) return TestTokenUSDC__factory.connect(usdcToken.address, readOnlyProvider)

    return ERC20__factory.connect(usdcToken.address, readOnlyProvider)
  }
)

const selectMintingStatus = createSelector([selectState], (state) => state.mintUSDCStatus)

export const cryptocurrenciesSelectors = {
  selectUSDCReadOnlyContract,
  selectUSDCContract,
  selectUSDC,
  selectStablecoins,
  selectNativeToken,
  selectMintingStatus,
  selectAll,
}
