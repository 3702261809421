import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { SubgraphPhase } from '../../phases.types'
import { mapSubgraphPhaseQueryResultToSubgraphPhaseData } from '../../phases.mappers'
import { phasesActions } from '../../phases.slice'
import type { SubgraphPhaseQueryResult } from '../../../../types/subgraphQueryTypes'
import { ALL_PHASES_FOR_FUNDRAISE_QUERY } from '../../phases.queries'
import { SubgraphPhasesQueryResponseByAddress } from '../../../../../staticContent'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'

export async function fetchSubgraphForProjectPhases(client: GraphQLClient, fundraiseAddress: string): Promise<SubgraphPhase[]> {
  const result = await client.rawRequest<{ phases: SubgraphPhaseQueryResult[] }>(ALL_PHASES_FOR_FUNDRAISE_QUERY, { fundraiseAddress })

  return result.data.phases.map(mapSubgraphPhaseQueryResultToSubgraphPhaseData)
}

export function fetchStaticSubgraphForProjectPhases(fundraiseAddress: string): SubgraphPhase[] {
  return SubgraphPhasesQueryResponseByAddress(fundraiseAddress).map(mapSubgraphPhaseQueryResultToSubgraphPhaseData)
}

export function* fetchSubgraphForProjectPhasesSaga(): SagaGenerator<void> {
  try {
    const currentProject = yield* select(projectBaseSelectors.selectCurrent)
    if (!currentProject) throw new Error('fetchSubgraphSingleFundraiseSaga - currentProject is undefined')

    const fundraiseAddress = currentProject.fundrisingAddress

    const allPhasesOfProject = yield* currentProject.isLegacy
      ? call(fetchStaticSubgraphForProjectPhases, fundraiseAddress)
      : subgraphCall(fetchSubgraphForProjectPhases, fundraiseAddress)

    yield* put(phasesActions.fetchSubgraphForProjectSuccess(allPhasesOfProject))
  } catch (error) {
    if (error instanceof Error) yield* put(phasesActions.fetchSubgraphForProjectFailure(error.message))
  }
}
