import { RequestStatus } from '../types'

import type { MosaicoAssets } from './mosaicoAssets.types'

export class MosaicoAssetsState implements MosaicoAssets {
  error: string | null = null
  fetchAllMosaicoAssetsStatus = RequestStatus.Idle
  totalRewardsValue = '0'
  totalAssetsValue = '0'
  assetRewards = []
  assetProjectTokens = []
  assetBaseInfos = []
}
