import { GraphQLClient } from 'graphql-request'
import { Auth0Client } from '@auth0/auth0-spa-js'

import type { WalletTypeEnum } from '../../graphql/generated/graphql'
import envConfig from '../../../config/env'

const createClient = (uri: string): GraphQLClient => {
  return new GraphQLClient(uri, {
    errorPolicy: 'all',
  })
}

export const graphqlClient = (): GraphQLClient => {
  return createClient(envConfig.graphqlUri)
}

export const subgraphGraphqlClient = (): GraphQLClient => {
  return createClient(envConfig.subgraphGraphqlUri)
}

export const auth0Client = () => {
  return new Auth0Client({
    useRefreshTokens: true,
    domain: envConfig.auth0Domain,
    clientId: envConfig.auth0ClientId,
    cacheLocation: 'localstorage',
    authorizationParams: {
      scope: 'all:user profile email offline_access',
      redirect_uri: envConfig.auth0RedirectUri,
      audience: envConfig.auth0Audience,
    },
  })
}

export const saveLatestWalletType = (walletType: WalletTypeEnum) => {
  window.localStorage.setItem('latest-wallet-type', walletType)
}

export const saveLatestWalletAddress = (walletAddress: string) => {
  window.localStorage.setItem('latest-wallet-address', walletAddress)
}

export const getLatestWalletType = () => {
  return window.localStorage.getItem('latest-wallet-type') as WalletTypeEnum | undefined
}

export const getLatestWalletAddress = () => {
  return window.localStorage.getItem('latest-wallet-address') as string | undefined
}

export const saveIsVisited = () => {
  return window.localStorage.setItem('isVisited', 'true')
}

export const getIsVisited = () => {
  console.log('isVisible', Boolean(window?.localStorage?.getItem('isVisited')))
  return Boolean(window?.localStorage?.getItem('isVisited'))
}
