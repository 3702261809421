import type { EventChannel } from 'redux-saga'
import { eventChannel } from 'redux-saga'
import { call, put, select, take } from 'typed-redux-saga'
import snsWebSdk from '@sumsub/websdk'
import type { EventPayload } from '@sumsub/websdk/types/types'

import { getAccessToken } from '../../../../utils/getAccessToken'
import envConfig from '../../../../../config/env'
import type { ApplicantStatus, SumsubChannelActions } from '../../sumsub.types'
import type { SumsubLevels } from '../../sumsub.constants'
import { VerificationStatus, SumsubSteps } from '../../sumsub.constants'
import type { SumsubActions } from '../../sumsub.slice'
import { sumsubActions } from '../../sumsub.slice'
import { pushNotification } from '../../../../utils/pushNotification'
import { userSelectors } from '../../../user/user.selectors'
import type { Language } from '../../../../types/common'
import { userActions } from '../../../user/user.slice'

import type { IGetKycAuthTokenResponseDTO } from './startSumsubVerification.types'

// We must store callback here since tokenExpirationHandler must be async method - not generator
let tokenExpirationHandler: () => Promise<string>

const launchSumsubSDK = (sumsubToken: string, email: string | undefined, lang: Language = 'pl'): EventChannel<SumsubChannelActions> => {
  return eventChannel<SumsubChannelActions>((emit) => {
    const snsWebSdkInstance = snsWebSdk
      .init(sumsubToken, () => tokenExpirationHandler?.())
      .withConf({
        uiConf: {
          customCss: `${envConfig.nextPublicPath}sumsub-custom-styles.css`,
        },
        lang,
        email,
        country: 'POL',
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on('idCheck.onReady', () => {
        emit(sumsubActions.sumsubIsReady())
      })
      .on('idCheck.onStepInitiated', (payload: EventPayload<'idCheck.onStepInitiated'>) => {
        if (payload.idDocSetType === SumsubSteps.APPLICANT_DATA) emit(sumsubActions.setStep({ step: SumsubSteps.APPLICANT_DATA }))
        if (payload.idDocSetType === SumsubSteps.SELFIE) emit(sumsubActions.setStep({ step: SumsubSteps.SELFIE }))
      })
      .on('idCheck.onApplicantSubmitted', () => {
        emit(userActions.updateUserBasicKycStatus(VerificationStatus.VERIFICATION_STARTED))
        emit(sumsubActions.setStep({ step: SumsubSteps.COMPLETED }))
      })
      .on('idCheck.applicantStatus', (payload: ApplicantStatus) => {
        if (payload.reviewStatus === 'completed' && payload.reviewResult?.reviewAnswer === 'GREEN') {
          if (payload.reviewResult?.reviewAnswer === 'GREEN') emit(userActions.updateUserBasicKycStatus(VerificationStatus.ACCEPTED))
          emit(sumsubActions.applicantAccepted())
        }
      })
      .build()

    snsWebSdkInstance.launch('#sumsub-websdk-container')

    return () => {}
  })
}

async function getKycAuthToken(accessToken: string, level: SumsubLevels): Promise<IGetKycAuthTokenResponseDTO> {
  const { sumsubToken, email } = await fetch(`${envConfig.auth0Audience}/kyc/auth-token/${level}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => response.json())

  return { sumsubToken, email }
}

function* initVerification(level: SumsubLevels): Generator {
  const userLocalization = yield* select(userSelectors.selectUserLocalization)
  const accessToken = yield* call(getAccessToken)

  tokenExpirationHandler = async () => {
    const { sumsubToken } = await getKycAuthToken(accessToken, level)

    return sumsubToken
  }

  const { sumsubToken, email } = yield* call(getKycAuthToken, accessToken, level)
  const actionsChannel = yield* call(launchSumsubSDK, sumsubToken, email, userLocalization)

  try {
    while (true) {
      const action = yield* take(actionsChannel)
      yield* put(action)
    }
  } catch (error) {
    console.log('Error in initVerification', error)
  } finally {
    actionsChannel.close()
  }
}

export function* startSumsubVerificationSaga({ payload }: SumsubActions['startSumsubVerification']): Generator {
  try {
    yield* call(initVerification, payload)
  } catch (error) {
    if (error instanceof Error) pushNotification(`Init Sumsub SDK Error: ${error.message}`, 'error')
  }
}
