import type { BigNumber } from 'ethers'

export enum TransactionType {
  Approve,
  Invest,
  Transfer,
  Stake,
  Unstake,
  Swap,
}

export enum TransactionStatus {
  InProgress,
  Pending,
  Success,
  Failure,
}

export interface TransactionBase<T extends TransactionType = TransactionType, P = any> {
  id: string
  type: T
  source: string
  payload: P
}

export interface Transaction<T extends TransactionType = TransactionType, P = any> extends TransactionBase<T, P> {
  hash?: string
  status: TransactionStatus
  error?: string
}

export type ApproveTransaction = Transaction<
  TransactionType.Approve,
  {
    tokenInSymbol: string
    tokenInAmount: BigNumber
  }
>
export const isApproveTransaction = (transaction: Transaction): transaction is ApproveTransaction => {
  return transaction.type === TransactionType.Approve
}

export type InvestTransaction = Transaction<TransactionType.Invest>
export const isInvestTransaction = (transaction: Transaction): transaction is InvestTransaction => {
  return transaction.type === TransactionType.Invest
}

export type TransferTransaction = Transaction<TransactionType.Transfer>
export const isTransferTransaction = (transaction: Transaction): transaction is TransferTransaction => {
  return transaction.type === TransactionType.Transfer
}

export type StakeTransaction = Transaction<TransactionType.Stake>
export const isStakeTransaction = (transaction: Transaction): transaction is StakeTransaction => {
  return transaction.type === TransactionType.Stake
}

export type UnstakeTransaction = Transaction<TransactionType.Unstake>
export const isUnstakeTransaction = (transaction: Transaction): transaction is UnstakeTransaction => {
  return transaction.type === TransactionType.Unstake
}

export type SwapTransaction = Transaction<TransactionType.Swap>
export const isSwapTransaction = (transaction: Transaction): transaction is SwapTransaction => {
  return transaction.type === TransactionType.Swap
}
