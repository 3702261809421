import type { SagaGenerator } from 'typed-redux-saga'
import { call } from 'typed-redux-saga'

import { staticActions, type StaticActions } from '../../static.slice'
import { defaultStaticSetupSaga } from '../defaultStaticSetup/defaultStaticSetup'
import { fetchAllProjectsBaseSaga } from '../../../projectBase/sagas/fetchAllProjects/fetchAllProjects'
import { fetchSubgraphAllFundraiseSaga } from '../../../fundraise/saga/fetchFromSubgraph/all'
import { fetchBackendAllProjectTokensSaga } from '../../../projectToken/saga/fetchFromBackend/all'
import { fetchSubgraphAllProjectTokensSaga } from '../../../projectToken/saga/fetchFromSubgraph/all'
import { fetchBackendAllPhasesSaga } from '../../../phases/sagas/fetchFromBackend/all'
import { fetchSubgraphAllPhasesSaga } from '../../../phases/sagas/fetchFromSubgraph/all'
import { fetchSubgraphAllStakeTokensSaga } from '../../../stakeToken/saga/fetchFromSubgraph/all'
import { fetchBackendAllRewardsTokensERC20Saga } from '../../../rewardTokenERC20/saga/fetchFromBackend/fetchAll.saga'
import { fetchSubgraphAllRewardsTokensERC20Saga } from '../../../rewardTokenERC20/saga/fetchFromSubgraph/fetchAll.saga'
import { fetchProjectDescriptionsSaga } from '../../../projectContent/sagas/fetchDescriptionsData/fetchDescriptionsData.saga'
import { fetchProjectRewardsDescriptionsSaga } from '../../../projectContent/sagas/fetchRewardsData/fetchRewardsData.saga'
import { fetchAllRewardStablecoinsSaga } from '../../../rewardStablecoin/saga/fetchAll/fetchAll.saga'

export function* dashboardSubgraphSetupSaga(): SagaGenerator<void> {
  yield* call(fetchSubgraphAllFundraiseSaga)
  yield* call(fetchSubgraphAllProjectTokensSaga)
  yield* call(fetchSubgraphAllStakeTokensSaga)
  yield* call(fetchSubgraphAllRewardsTokensERC20Saga)
  yield* call(fetchAllRewardStablecoinsSaga)

  yield* call(fetchSubgraphAllPhasesSaga)
}

export function* dashboardStaticSetupSaga({ payload }: StaticActions['startDashboardStaticSetup']): SagaGenerator<void> {
  const { context } = payload

  yield* call(defaultStaticSetupSaga, staticActions.startDefaultStaticSetup({ context }))

  yield* call(fetchAllProjectsBaseSaga)

  yield* call(fetchProjectDescriptionsSaga)

  yield* call(fetchProjectRewardsDescriptionsSaga)

  yield* call(fetchBackendAllProjectTokensSaga)

  yield* call(fetchBackendAllRewardsTokensERC20Saga)

  yield* call(fetchBackendAllPhasesSaga)
}
