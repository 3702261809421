import type { Language } from '../../types/common'

import { DEFAULT_LOCALE, LOCALES } from './navigation.const'
import type { NavigationSlice } from './navigation.types'

export const CreateNavigationSlice = (currentLocalisation: Language = DEFAULT_LOCALE): NavigationSlice => {
  return {
    shouldPreserveScrollPosition: false,
    searchFallbackRoute: '/',
    scrollPosition: 0,
    keepSearchOpen: false,
    fallbackLocalisation: DEFAULT_LOCALE,
    currentSlug: '',
    currentLocalisation,
    bannerHeight: 0,
    availableLocalisations: LOCALES,
    activeMenu: null,
  }
}
