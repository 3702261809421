import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import { RequestStatus } from '../types'

import type { ErrorPayload, FetchAllMosaicoAssetsPayloadSuccess, MosaicoAssets } from './mosaicoAssets.types'
import { MosaicoAssetsState } from './mosaicoAssets.state'

export const initialMosaicoAssetsSliceState: MosaicoAssets = {
  ...new MosaicoAssetsState(),
}

const hydrate = createAction<{ [StoreKeys.MosaicoAssets]: MosaicoAssets }>(HYDRATE)

export const mosaicoAssetsSlice = createSlice({
  reducers: {
    fetchAllMosaicoAssetsSuccess: (state, action: PayloadAction<FetchAllMosaicoAssetsPayloadSuccess>) => {
      const { assetRewards, totalAssetsValue, totalRewardsValue, assetProjectTokens, assetBaseInfos } = action.payload
      state.totalAssetsValue = totalAssetsValue
      state.totalRewardsValue = totalRewardsValue
      state.assetRewards = assetRewards
      state.assetProjectTokens = assetProjectTokens
      state.assetBaseInfos = assetBaseInfos
      state.fetchAllMosaicoAssetsStatus = RequestStatus.Succeeded
    },
    fetchAllMosaicoAssetsError: (state, action: PayloadAction<ErrorPayload>) => {
      state.fetchAllMosaicoAssetsStatus = RequestStatus.Failed
      state.error = action.payload
    },
    fetchAllMosaicoAssets: (state) => {
      state.fetchAllMosaicoAssetsStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.MosaicoAssets,
  initialState: initialMosaicoAssetsSliceState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      return {
        ...state,
        ...payload[StoreKeys.MosaicoAssets],
      }
    }),
})

export const mosaicoAssetsActions = mosaicoAssetsSlice.actions
export const mosaicoAssetsReducer = mosaicoAssetsSlice.reducer

export type MosaicoAssetsActions = ActionsType<typeof mosaicoAssetsActions>
