export enum MetamaskActions {
  requestAccounts = 'eth_accounts',
  switchNetwork = 'wallet_switchEthereumChain',
  requestPermissions = 'wallet_requestPermissions',
  addNetwork = 'wallet_addEthereumChain',
}

export enum MetamaskError {
  UnlockWallet = 'UnlockWallet',
  RequestPermissionsAlreadyPending = 'RequestPermissionsAlreadyPending',
  ProviderNotDetected = 'ProviderNotDetected',
}
