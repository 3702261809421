import type { SubgraphFundraisesQueryResult } from '../../types/subgraphQueryTypes'
import { DEFAULT_INTERNAL_TOKEN_DECIMALS, USDC_DECIMALS } from '../../../config/decimals'
import { BigDecimal } from '../../utils/bigDecimal'

import type { FundraiseData } from './fundraise.types'

export const mapFundraisesQueryResultToFundraiseData = (fundraiseQueryResult: SubgraphFundraisesQueryResult): FundraiseData => {
  const {
    id,
    name,
    address,
    softCap,
    hardCap,
    phasesCounter,
    totalFundsRaised,
    fundraiseFee,
    expiryDate,
    activeMilestone,
    activePhaseIndex,
    phases,
    feeWalletAddress,
    projectWalletAddress,
    stablecoin,
    projectToken,
    stage,
    investorsCounter,
    tokenMaxSupply,
    totalTokensSold,
  } = fundraiseQueryResult

  return {
    totalTokensSold: BigDecimal.fromString(totalTokensSold, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(DEFAULT_INTERNAL_TOKEN_DECIMALS),
    totalFundsRaised: BigDecimal.fromString(totalFundsRaised, USDC_DECIMALS).toFixed(USDC_DECIMALS),
    tokenMaxSupply: BigDecimal.fromString(tokenMaxSupply, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(DEFAULT_INTERNAL_TOKEN_DECIMALS),
    stage: Number(stage),
    stablecoin: Number(stablecoin),
    softCap: BigDecimal.fromString(softCap, USDC_DECIMALS).toFixed(USDC_DECIMALS),
    projectWalletAddress,
    projectTokenAddress: projectToken.address,
    phasesCounter: Number(phasesCounter),
    phases: phases.map((phase) => phase.id),
    name,
    investorsCounter: Number(investorsCounter),
    id,
    hardCap: BigDecimal.fromString(hardCap, USDC_DECIMALS).toFixed(USDC_DECIMALS),
    fundraiseFee: BigDecimal.fromString(fundraiseFee, USDC_DECIMALS).toFixed(USDC_DECIMALS),
    feeWalletAddress,
    expiryDate: Number(expiryDate),
    address,
    activePhaseIndex: Number(activePhaseIndex),
    activeMilestone: Number(activeMilestone),
  }
}
