import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { SubgraphTranche } from '../../tranches.types'
import { mapSubgraphTrancheQueryResultToSubgraphTrancheData } from '../../tranches.mappers'
import { tranchesActions } from '../../tranches.slice'
import type { SubgraphTranchesQueryResult } from '../../../../types/subgraphQueryTypes'
import { MULTIPLE_TRANCHES_QUERY } from '../../tranches.queries'
import { SubgraphTranchesQueryResponseByAdresses } from '../../../../../staticContent'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'

export async function fetchSubgraphMultipleTranches(client: GraphQLClient, tokenAddresses: string[]): Promise<SubgraphTranche[]> {
  const result = await client.rawRequest<{ tranches: SubgraphTranchesQueryResult[] }>(MULTIPLE_TRANCHES_QUERY, {
    tokenAddresses,
  })

  return result.data.tranches.map(mapSubgraphTrancheQueryResultToSubgraphTrancheData)
}

export function fetchStaticSubgraphMultipleTranches(tokenAddress: string[]): SubgraphTranche[] {
  return SubgraphTranchesQueryResponseByAdresses(tokenAddress).map(mapSubgraphTrancheQueryResultToSubgraphTrancheData)
}

export function* fetchSubgraphAllTranchesSaga(): SagaGenerator<void> {
  try {
    const projectsBases = yield* select(projectBaseSelectors.selectAll)

    const legacyProjectTokenAddresses: string[] = []
    const projectTokenAddresses: string[] = []

    projectsBases.forEach((projectBase) => {
      if (projectBase.isLegacy) legacyProjectTokenAddresses.push(projectBase.projectTokenAddress)
      if (!projectBase.isLegacy) projectTokenAddresses.push(projectBase.projectTokenAddress)
    })

    const tranches: SubgraphTranche[] = []

    if (legacyProjectTokenAddresses.length > 0) {
      const legacyTrancheData = yield* call(fetchStaticSubgraphMultipleTranches, legacyProjectTokenAddresses)
      tranches.push(...legacyTrancheData)
    }

    if (projectTokenAddresses.length > 0) {
      const legacyTrancheData = yield* subgraphCall(fetchSubgraphMultipleTranches, projectTokenAddresses)
      tranches.push(...legacyTrancheData)
    }

    yield* put(tranchesActions.fetchSubgraphAllSuccess(tranches))
  } catch (error) {
    if (error instanceof Error) yield* put(tranchesActions.fetchSubgraphAllFailure(error.message))
  }
}
