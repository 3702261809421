import type { SagaGenerator } from 'typed-redux-saga'
import { put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { RewardStablecoinSubgraphData, RewardStablecoinData } from '../../rewardStablecoin.types'
import type { SubgraphRewardStablecoinQueryResult } from '../../../../types/subgraphQueryTypes'
import { rewardStablecoinActions } from '../../rewardStablecoin.slice'
import { mapSubgraphRewardTokenERC20DataToRewardTokenERC20Data } from '../../rewardStablecoin.mappers'
import { ALL_REWARD_STABLECOINS_QUERY } from '../../rewardStablecoin.queries'
import { cryptocurrenciesSelectors } from '../../../cryptocurrencies/cryptocurrencies.selectors'
import { RewardTokenType } from '../../../rewardTokenERC20/rewardTokenERC20.const'

async function fetchAllRewardStablecoins(client: GraphQLClient): Promise<RewardStablecoinSubgraphData[]> {
  const result = await client.rawRequest<{
    stablecoins: SubgraphRewardStablecoinQueryResult[]
  }>(ALL_REWARD_STABLECOINS_QUERY)

  return result.data.stablecoins.map(mapSubgraphRewardTokenERC20DataToRewardTokenERC20Data)
}

export function* fetchAllRewardStablecoinsSaga(): SagaGenerator<void> {
  try {
    const tokensData = yield* subgraphCall(fetchAllRewardStablecoins)
    const stablecoins = yield* select(cryptocurrenciesSelectors.selectStablecoins)

    const tokens: RewardStablecoinData[] = tokensData.map((token) => ({
      ...token,
      rewardTokenType: RewardTokenType.STABLECOIN,
      rewardPriceInUSD: '1',
      logo: stablecoins.find((stablecoin) => stablecoin.address === token.address)?.logo || '',
    }))

    yield* put(rewardStablecoinActions.fetchAllSuccess(tokens))
  } catch (error) {
    if (error instanceof Error) yield* put(rewardStablecoinActions.fetchAllFailure(error.message))
  }
}
