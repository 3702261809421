import { createBreakpoints } from '@mui/system'

export const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    xl: 1248,
    sm: 320,
    md: 768,
    lg: 1080,
  },
})
