import type { SagaGenerator } from 'typed-redux-saga'
import { call } from 'typed-redux-saga'

import envConfig from '../../../../config/env'
import { getAccessToken } from '../../../utils/getAccessToken'

export function* getInvestmentTransactionSignature(investmentData: object): SagaGenerator<string> {
  const accessToken = yield* call(getAccessToken)
  const response = yield* call(fetch, `${envConfig.mosaicoApiUrl}/investments/sign`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(investmentData),
  })
  const signature = yield* call([response, response.text])

  return signature
}
