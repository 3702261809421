import { useMemo } from 'react'
import { useTheme } from '@mui/material'

import type { IconButtonProps } from '../IconButton'

import { getSelectedStateCustomColor } from './ButtonBase.utils'

interface IUseSelectedStateIconColor {
  pressed: boolean
  hovered: boolean
  focusVisible: boolean
  disabled?: boolean
  variant?: IconButtonProps['variant']
  color?: IconButtonProps['color']
}
export const useSelectedStateIconColor = ({ pressed, hovered, focusVisible, disabled, color, variant }: IUseSelectedStateIconColor) => {
  const theme = useTheme()

  const iconColor = useMemo(
    () => getSelectedStateCustomColor(theme, hovered, focusVisible, pressed, disabled, variant, color),
    [theme, disabled, hovered, focusVisible, pressed, variant, color]
  )

  return { iconColor }
}
