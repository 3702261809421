import type { SagaGenerator } from 'typed-redux-saga'
import { call } from 'typed-redux-saga'

import { staticActions, type StaticActions } from '../../static.slice'
import { fetchSingleProjectBaseSaga } from '../../../projectBase/sagas/fetchSingleProject/fetchSingleProject'
import { defaultStaticSetupSaga } from '../defaultStaticSetup/defaultStaticSetup'
import { fetchProjectPageDataSaga } from '../../../projectContent/sagas/fetchProjectPageData/fetchProjectPageData.saga'
import { fetchSubgraphSingleFundraiseSaga } from '../../../fundraise/saga/fetchFromSubgraph/single'
import { fetchBackendSingleProjectTokenSaga } from '../../../projectToken/saga/fetchFromBackend/single'
import { fetchSubgraphSingleProjectTokenSaga } from '../../../projectToken/saga/fetchFromSubgraph/single'
import { fetchBackendForProjectPhasesSaga } from '../../../phases/sagas/fetchFromBackend/forProject'
import { fetchSubgraphForProjectPhasesSaga } from '../../../phases/sagas/fetchFromSubgraph/forProject'
import { fetchBackendForTokenTranchesSaga } from '../../../tranches/sagas/fetchFromBackend/forToken'
import { fetchSubgraphForTokenTranchesSaga } from '../../../tranches/sagas/fetchFromSubgraph/forToken'

export function* projectInvestSubgraphSetupSaga(): SagaGenerator<void> {
  yield* call(fetchSubgraphSingleFundraiseSaga)
  yield* call(fetchSubgraphSingleProjectTokenSaga)
  yield* call(fetchSubgraphForProjectPhasesSaga)
  yield* call(fetchSubgraphForTokenTranchesSaga)
}

export function* projectInvestStaticSetupSaga({ payload }: StaticActions['startProjectInvestStaticSetup']): SagaGenerator<void> {
  const { context } = payload

  yield* call(defaultStaticSetupSaga, staticActions.startDefaultStaticSetup({ context }))

  yield* call(fetchSingleProjectBaseSaga)

  yield* call(fetchProjectPageDataSaga)

  yield* call(fetchBackendSingleProjectTokenSaga)

  yield* call(fetchBackendForProjectPhasesSaga)

  yield* call(fetchBackendForTokenTranchesSaga)
}
