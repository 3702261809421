import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { TranchesBackendAllQueryResponse } from '../../../../../staticContent'
import type { BackendTrancheQueryResult } from '../../../../types/backendQueryTypes'
import type { BackendTranche, TrancheData } from '../../tranches.types'
import { mapBackendTrancheQueryResultToBackendTrancheData } from '../../tranches.mappers'
import { tranchesActions } from '../../tranches.slice'
import { emptySubgraphTranche } from '../../tranches.const'
import { apiCall } from '../../../../utils/apiCall'

export async function fetchBackendAllTranches(client: GraphQLClient): Promise<BackendTranche[]> {
  const result: BackendTrancheQueryResult[] = await Promise.resolve(TranchesBackendAllQueryResponse())

  return result.map(mapBackendTrancheQueryResultToBackendTrancheData)
}

export function* fetchBackendAllTranchesSaga(): SagaGenerator<void> {
  try {
    const allBackendTranches = yield* apiCall(fetchBackendAllTranches)

    const mappedToTranches: TrancheData[] = allBackendTranches.map((tranche) => ({
      ...emptySubgraphTranche,
      ...tranche,
    }))

    yield* put(tranchesActions.fetchBackendAllSuccess(mappedToTranches))
  } catch (error) {
    if (error instanceof Error) yield* put(tranchesActions.fetchBackendAllFailure(error.message))
  }
}
