import type { PhaseData, SubgraphPhase } from './phases.types'

export enum PhaseType {
  suspendedSale,
  privateSale,
  preSale,
  publicSale,
}

export enum PhaseStatus {
  inactive,
  active,
  expired,
}

export const isPhase = (phase: any): phase is PhaseData => {
  return phase?.id !== undefined
}

export const emptySubgraphPhase: SubgraphPhase = {
  type: 0,
  tokenRate: '0',
  status: 0,
  startDate: 0,
  soldTokens: '0',
  minInvest: '0',
  maxTokensAmount: '0',
  maxInvest: '0',
  index: 0,
  id: '',
  fundraiseAddress: '',
  endDate: 0,
}
