import type { Palette } from '@mui/material'

import type { CustomPalette } from '.'

export const accentPalette: Pick<CustomPalette, '_warning' | '_success' | '_error'> = {
  _warning: {
    600: '#BA8600',
    500: '#DC9F00',
    400: '#FFB800',
    300: '#FFD568',
    200: '#FFD56840',
    100: '#FFD56826',
  },

  _success: {
    600: '#0B5C42',
    500: '#0D6E4E',
    400: '#0F7F5A',
    300: '#71B39D',
    200: '#71B39D40',
    100: '#71B39D26',
  },

  _error: {
    600: '#9D170E',
    500: '#BA1B10',
    400: '#D71F13',
    300: '#E77A73',
    200: '#E77A7340',
    100: '#E77A7326',
  },
}
