import type { Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'

export const getPaper = (palette: CustomPalette): Components<Theme> => ({
  MuiPaper: {
    styleOverrides: {
      elevation2: { boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' },
      elevation0: {
        background: palette._transparent,
      },
    },
    defaultProps: {},
  },
})
