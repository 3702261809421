import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { userActions } from '../../../user/user.slice'
import { walletActions } from '../../../wallet/wallet.slice'
import { web3Actions } from '../../../web3/web3.slice'
import { sessionSelectors } from '../../session.selectors'
import type { SessionActions } from '../../session.slice'
import { sessionActions } from '../../session.slice'
import { navigationSelectors } from '../../../navigation/navigation.selectors'

export function* logoutHandler({ payload }: SessionActions['logoutStart']): SagaGenerator<void> {
  try {
    const auth0Client = yield* select(sessionSelectors.selectAuth0Client)

    const currentLanguage = yield* select(navigationSelectors.selectCurrentLocalisation)

    const url = `${window.location.origin}/${currentLanguage}`
    const redirectPathname = payload ? payload.redirectPathname : ''

    yield* call([auth0Client, auth0Client.logout], { logoutParams: { returnTo: url + redirectPathname } })

    yield* put(userActions.clearUser())

    yield* put(web3Actions.disconnectWallet())

    yield* put(walletActions.clearWallets())

    yield* put(sessionActions.logoutSuccess())
  } catch (error) {
    if (error instanceof Error) yield* put(sessionActions.logoutError(error.message))
  }
}
