import { createSelector } from '@reduxjs/toolkit'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'
import { RequestStatus } from '../types'

import { stakeTokenAdapter } from './stakeToken.slice'

export const selectState = createSelector([selectReducer(StoreKeys.StakeToken)], (state) => state)

export const selectAll = createSelector([selectState], (state) => stakeTokenAdapter.getSelectors().selectAll(state))

export const selectCombinedStakeTokens = (projectTokenAddress: string) =>
  createSelector([selectAll], (state) => state.filter((stakeToken) => stakeToken.projectTokenAddress === projectTokenAddress))

export const selectStakeStatus = createSelector([selectState], (state) => state.stakeStatus)
export const selectUnstakeStatus = createSelector([selectState], (state) => state.unstakeStatus)

export const selectIsStakingPending = createSelector(
  [selectStakeStatus, selectUnstakeStatus],
  (stakeStatus, unstakeStatus) => stakeStatus === RequestStatus.Loading || unstakeStatus === RequestStatus.Loading
)

export const makeSelectByProjectTokenAddress = () =>
  createSelector([selectAll, (_: unknown, address: string) => address], (all, address) =>
    all.filter((stakeToken) => stakeToken.projectTokenAddress === address)
  )
export const makeSelectByAddress = () =>
  createSelector([selectAll, (_: unknown, address: string) => address], (all, address) =>
    all.find((stakeToken) => stakeToken.address === address)
  )

export const selectByProjectTokenAddress = makeSelectByProjectTokenAddress()
export const selectByAddress = makeSelectByAddress()

const selectStakeTransactionId = createSelector([selectState], (state) => state.stakeTransactionId)

export const stakeTokenSelectors = {
  selectUnstakeStatus,
  selectState,
  selectStakeTransactionId,
  selectStakeStatus,
  selectIsStakingPending,
  selectCombinedStakeTokens,
  selectByProjectTokenAddress,
  selectByAddress,
  selectAll,
  makeSelectByProjectTokenAddress,
}
