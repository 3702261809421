import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { fundraiseActions } from './fundraise.slice'
import { investSaga } from './saga/invest/invest.saga'
import { swapFundTokensSaga } from './saga/swapFundTokens/swapFundTokens'
import { fetchSubgraphSingleFundraiseSaga } from './saga/fetchFromSubgraph/single'
import { fetchSubgraphAllFundraiseSaga } from './saga/fetchFromSubgraph/all'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(fundraiseActions.invest, investSaga),
    takeLatest(fundraiseActions.swapFundTokens, swapFundTokensSaga),
    takeLatest(fundraiseActions.fetchSubgraphSingle, fetchSubgraphSingleFundraiseSaga),
    takeLatest(fundraiseActions.fetchSubgraphAll, fetchSubgraphAllFundraiseSaga),
  ])
}

export function* fundraiseSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
