import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'

import type { StaticActions } from '../../static.slice'
import { navigationActions } from '../../../navigation/navigation.slice'

export function* defaultStaticSetupSaga({ payload }: StaticActions['startDefaultStaticSetup']): SagaGenerator<void> {
  const { context } = payload
  const { params } = context
  const language = params?.locale
  const slug = params?.slug || ''

  yield* put(navigationActions.updateLocalisation(language))
  yield* put(navigationActions.updateCurrentSlug(slug))
}
