import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { investSaga } from './saga/invest/invest.saga'
import { projectTokenActions } from './projectToken.slice'
import { fetchBackendAllProjectTokensSaga } from './saga/fetchFromBackend/all'
import { fetchBackendSingleProjectTokenSaga } from './saga/fetchFromBackend/single'
import { fetchSubgraphAllProjectTokensSaga } from './saga/fetchFromSubgraph/all'
import { fetchSubgraphSingleProjectTokenSaga } from './saga/fetchFromSubgraph/single'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(projectTokenActions.invest, investSaga),
    takeLatest(projectTokenActions.fetchBackendAll, fetchBackendAllProjectTokensSaga),
    takeLatest(projectTokenActions.fetchBackendSingle, fetchBackendSingleProjectTokenSaga),
    takeLatest(projectTokenActions.fetchSubgraphSingle, fetchSubgraphSingleProjectTokenSaga),
    takeLatest(projectTokenActions.fetchSubgraphAll, fetchSubgraphAllProjectTokensSaga),
  ])
}

export function* projectTokenSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
