// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgSend = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8 8H4m4.11 7.98L4 16m4.11-4H2m11.116.743-1.312 3.281c-.349.874.591 1.703 1.414 1.246l8.44-4.689a.665.665 0 0 0 0-1.16l-8.44-4.689c-.823-.457-1.764.372-1.414 1.246l1.312 3.281a1.993 1.993 0 0 1 0 1.484Z"
    />
  </svg>
)
export default SvgSend
