import type { SagaGenerator } from 'typed-redux-saga'
import { call, put } from 'typed-redux-saga'

import type { BalancesActions } from '../../balances.slice'
import { balancesActions } from '../../balances.slice'
import { pushNotification } from '../../../../utils/pushNotification'
import { TransferTokenType } from '../../../../containers/TransferPopup/TransferPopup.types'

import { createAndExecuteNativeTransferTransactionPlan } from './createAndExecuteNativeTransferTransactionPlan.saga'
import { createAndExecuteERC20TransferTransactionPlan } from './createAndExecuteERC20TransferTransactionPlan.saga'

export function* transferBalanceSaga({ payload }: BalancesActions['transferBalances']): SagaGenerator<void> {
  try {
    const { cryptocurrency } = payload

    if (cryptocurrency.type === TransferTokenType.NATIVE) {
      yield* call(createAndExecuteNativeTransferTransactionPlan, payload)
    } else {
      yield* call(createAndExecuteERC20TransferTransactionPlan, payload)
    }

    yield* put(balancesActions.transferBalancesSuccess())
    yield* put(balancesActions.updateBalances())
  } catch (error) {
    if (error instanceof Error) {
      yield* put(balancesActions.transferBalancesFailure(error.message))
      pushNotification(`Unable to create transfer transactions: ${error.message}`, 'error')
    }
  }
}
