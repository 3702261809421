import { put, type SagaGenerator } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { apiCall } from '../../../../utils/apiCall'
import { UPDATE_INVESTMENT } from '../../../../graphql/client/queries'
import type { UpdateUserInvestmentDto, UserInvestment } from '../../userInvestments.types'
import type { UserInvestmentsActions } from '../../userInvestments.slice'
import { userInvestmentsActions } from '../../userInvestments.slice'

export async function updateInvestment(client: GraphQLClient, dto: UpdateUserInvestmentDto): Promise<UserInvestment> {
  const { updateInvestment: updateInvestmentResponse } = await client.request(UPDATE_INVESTMENT, dto)
  return updateInvestmentResponse as UserInvestment
}

export function* updateInvestmentSaga({ payload }: UserInvestmentsActions['updateUserInvestment']): SagaGenerator<void> {
  try {
    if (payload) {
      const updatedInvestment = yield* apiCall(updateInvestment, payload)
      yield* put(userInvestmentsActions.updateUserInvestmentSuccess(updatedInvestment))
    }
  } catch (error) {
    if (error instanceof Error) console.log(error)
  }
}
