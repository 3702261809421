import { createSelector } from '@reduxjs/toolkit'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'

const selectState = createSelector([selectReducer(StoreKeys.Navigation)], (state) => state)

const selectCurrentLocalisation = createSelector([selectState], (state) => state.currentLocalisation)
const selectAvailableLocalisations = createSelector([selectState], (state) => state.availableLocalisations)
const selectFallbackLocalisation = createSelector([selectState], (state) => state.fallbackLocalisation)
const selectSearchFallbackRoute = createSelector([selectState], (state) => state.searchFallbackRoute)
const selectKeepSearchOpen = createSelector([selectState], (state) => state.keepSearchOpen)
const selectScrollPosition = createSelector([selectState], (state) => state.scrollPosition)
const selectShouldPreserveScrollPosition = createSelector([selectState], (state) => state.shouldPreserveScrollPosition)
const selectCurrentSlug = createSelector([selectState], (state) => state.currentSlug)
const selectActiveMenu = createSelector([selectState], (state) => state.activeMenu)
const selectBannerHeight = createSelector([selectState], (state) => state.bannerHeight)

export const navigationSelectors = {
  selectShouldPreserveScrollPosition,
  selectSearchFallbackRoute,
  selectScrollPosition,
  selectKeepSearchOpen,
  selectFallbackLocalisation,
  selectCurrentSlug,
  selectCurrentLocalisation,
  selectBannerHeight,
  selectAvailableLocalisations,
  selectActiveMenu,
}
