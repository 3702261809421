import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { phasesActions } from './phases.slice'
import { fetchBackendAllPhasesSaga } from './sagas/fetchFromBackend/all'
import { fetchBackendForProjectPhasesSaga } from './sagas/fetchFromBackend/forProject'
import { fetchSubgraphAllPhasesSaga } from './sagas/fetchFromSubgraph/all'
import { fetchSubgraphForProjectPhasesSaga } from './sagas/fetchFromSubgraph/forProject'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(phasesActions.fetchBackendAll, fetchBackendAllPhasesSaga),
    takeLatest(phasesActions.fetchBackendForProject, fetchBackendForProjectPhasesSaga),
    takeLatest(phasesActions.fetchSubgraphAll, fetchSubgraphAllPhasesSaga),
    takeLatest(phasesActions.fetchSubgraphForProject, fetchSubgraphForProjectPhasesSaga),
  ])
}

export function* phasesSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
