import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, spawn } from 'typed-redux-saga'
import type { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import type { JsonRpcError } from '@particle-network/auth/lib/types/types'

import { web3Actions } from '../../../web3.slice'
import { metamaskConnector } from '../../../../../utils/connectors/metaMask'
import { WalletTypeEnum } from '../../../../../graphql/generated/graphql'
import { pushNotification } from '../../../../../utils/pushNotification'
import { changeMetamaskAccount, detectMetamask, requestMetamaskAccounts } from '../utils'
import { MetamaskError } from '../metamask.types'
import { deactivateWeb3Saga } from '../../deacivateWeb3.saga'
import { observeMetaMaskEvents } from '../eventsObserver.saga'

export function* activateWebSaga(web3: Web3ReactContextInterface, walletAddress?: string): SagaGenerator<void> {
  const metamaskProvider = yield* call(detectMetamask)

  if (metamaskProvider) {
    yield* spawn(observeMetaMaskEvents, metamaskProvider)
    try {
      const [activeMetamaskAccount] = yield* call(requestMetamaskAccounts)

      if (activeMetamaskAccount) {
        yield* call(web3.activate, metamaskConnector)
        if (activeMetamaskAccount && activeMetamaskAccount !== walletAddress) {
          const response = yield* call(changeMetamaskAccount)
          const [newActiveMetamaskAccount] = yield* call(requestMetamaskAccounts)

          if (walletAddress && newActiveMetamaskAccount.toLowerCase() !== walletAddress.toLowerCase())
            pushNotification(`Please select ${walletAddress} account in your metamask extension`, 'warning')

          yield* put(
            web3Actions.connectWalletSuccess({
              walletType: WalletTypeEnum.Metamask,
              walletAddress: newActiveMetamaskAccount,
            })
          )
        }
      } else {
        try {
          yield* call(changeMetamaskAccount)
        } catch (error) {
          if ((error as JsonRpcError).code === -32_002)
            yield* put(
              web3Actions.connectWalletFailure({
                walletType: WalletTypeEnum.Metamask,
                error: MetamaskError.RequestPermissionsAlreadyPending,
              })
            )
          return
        }
        if (web3.active) yield* call(deactivateWeb3Saga)
        yield* put(web3Actions.connectWalletFailure({ walletType: WalletTypeEnum.Metamask, error: MetamaskError.UnlockWallet }))
      }
    } catch (error) {
      if ((error as JsonRpcError).code === -32_002)
        yield* put(
          web3Actions.connectWalletFailure({
            walletType: WalletTypeEnum.Metamask,
            error: MetamaskError.RequestPermissionsAlreadyPending,
          })
        )
    }
  } else {
    yield* put(web3Actions.connectWalletFailure({ walletType: WalletTypeEnum.Metamask, error: MetamaskError.ProviderNotDetected }))
    pushNotification(`Please install Metamask https://metamask.io/download/`, 'error')
  }
}
