import type { SagaGenerator } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { apiCall } from '../../../../utils/apiCall'
import { UPDATE_USER } from '../../../../graphql/client/queries'
import type { UserActions } from '../../user.slice'
import type { Language } from '../../../../types/common'

export async function updateUserLocalization(client: GraphQLClient, localization: Language): Promise<void> {
  await client.request(UPDATE_USER, { localization })
}

export function* updateUserLocalizationSaga({ payload }: UserActions['updateUserLocalization']): SagaGenerator<void> {
  try {
    if (payload) yield* apiCall(updateUserLocalization, payload)
  } catch (error) {
    if (error instanceof Error) console.log(error)
  }
}
