import { useConnect, useEthereum } from '@particle-network/auth-core-modal'
import { useEffect } from 'react'

import { particleNetworkConnector } from '../../utils/connectors/particleNetwork'

export const ParticleProviderUpdater = () => {
  const { connect, disconnect } = useConnect()
  const { provider, address } = useEthereum()

  useEffect(() => {
    particleNetworkConnector.setProperties({ provider, disconnect, connect, address })
  }, [connect, disconnect, provider, address])

  return <></>
}
