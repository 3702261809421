import { gql } from 'graphql-request'

export const REWARD_TOKEN_ERC20_BALANCE_FRAGMENT = gql`
  fragment rewardTokenERC20BalanceFragment on RewardTokenERC20Balance {
    id
    account {
      id
    }
    amount
    token {
      address
    }
  }
`
export const PROJECT_TOKEN_BALANCE_FRAGMENT = gql`
  fragment projectTokenBalanceFragment on ProjectTokenBalance {
    id
    account {
      id
    }
    amount
    token {
      address
    }
  }
`

export const STAKE_TOKEN_BALANCE_FRAGMENT = gql`
  fragment stakeTokenBalanceFragment on StakeTokenBalance {
    id
    account {
      id
    }
    amount
    token {
      address
    }
  }
`

export const FUNDRAISE_TOKEN_BALANCE_FRAGMENT = gql`
  fragment fundTokenBalanceFragment on FundTokenBalance {
    id
    account {
      id
    }
    amount
    token {
      address
    }
  }
`

export const ALL_REWARD_TOKEN_ERC20_BALANCES_QUERY = gql`
  query rewardTokenERC20Balances($addresses: [String!]) {
    rewardTokenERC20Balances(where: { account_: { id_in: $addresses } }) {
      ...rewardTokenERC20BalanceFragment
    }
  }
  ${REWARD_TOKEN_ERC20_BALANCE_FRAGMENT}
`

export const ALL_PROJECT_TOKEN_BALANCES_QUERY = gql`
  query projectTokenBalances($addresses: [String!]) {
    projectTokenBalances(where: { account_: { id_in: $addresses } }) {
      ...projectTokenBalanceFragment
    }
  }
  ${PROJECT_TOKEN_BALANCE_FRAGMENT}
`

export const ALL_STAKE_TOKEN_BALANCES_QUERY = gql`
  query stakeTokenBalances($addresses: [String!]) {
    stakeTokenBalances(where: { account_: { id_in: $addresses } }) {
      ...stakeTokenBalanceFragment
    }
  }
  ${STAKE_TOKEN_BALANCE_FRAGMENT}
`

export const ALL_FUNDRAISE_TOKEN_BALANCES_QUERY = gql`
  query fundraiserTokenBalances($addresses: [String!]) {
    fundTokenBalances(where: { account_: { id_in: $addresses } }) {
      ...fundTokenBalanceFragment
    }
  }
  ${FUNDRAISE_TOKEN_BALANCE_FRAGMENT}
`
