// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgDocumentsFile = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16 17H8m8-3.5H8m3-3.5H8m12-1.5h-3.5a2 2 0 0 1-2-2V3M7 21h10a3 3 0 0 0 3-3V8.372a3 3 0 0 0-.879-2.122L16.75 3.88A3 3 0 0 0 14.628 3H7a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3Z"
    />
  </svg>
)
export default SvgDocumentsFile
