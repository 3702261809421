import { select, call } from 'typed-redux-saga'
import type { SagaGenerator } from 'typed-redux-saga'

import { selectWeb3 } from '../../web3.selectors'
import { Web3NotDefined } from '../../web3.constants'

export function* disconnectWalletSaga(): SagaGenerator<void> {
  const web3 = yield* select(selectWeb3)

  if (!web3) throw Web3NotDefined

  yield* call(web3.deactivate)
}
