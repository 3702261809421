import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import type { WalletType } from '../../graphql/generated/graphql'
import { StoreKeys } from '../store.keys'

export const walletAdapter = createEntityAdapter<WalletType>({
  selectId: (wallet) => wallet.ethereumAddress,
})

export const walletSlice = createSlice({
  reducers: {
    walletsLoaded: (state, action: PayloadAction<WalletType[]>) => {
      walletAdapter.setAll(state, action.payload)
      return state
    },
    walletSaved: (state, action: PayloadAction<WalletType>) => {
      walletAdapter.upsertOne(state, action.payload)
      return state
    },
    clearWallets: (state) => {
      walletAdapter.removeAll(state)
      return state
    },
  },
  name: StoreKeys.Wallet,
  initialState: walletAdapter.getInitialState(),
})

export const walletActions = walletSlice.actions
export const walletReducer = walletSlice.reducer
