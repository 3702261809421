import type { ProjectTokenData, SubgraphProjectTokenData } from './projectToken.types'

export const isProjectToken = (projectToken: any): projectToken is ProjectTokenData => {
  return projectToken?.id !== undefined
}

export const emptySupgraphProjectTokenData: SubgraphProjectTokenData = {
  tranchesCounter: 0,
  tranches: [],
  totalSupply: '',
  symbol: '',
  stakeTokenAddresses: [],
  rewardTokenERC20Addresses: [],
  name: '',
  id: '',
  decimals: 18,
  address: '',
}
