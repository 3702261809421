export enum Chains {
  ETHEREUM = 1,
  GOERLI = 5,
  POLYGON = 137,
  MUMBAI = 80_001,
}

/**
 * Chains names should follow particle docs:
 * https://docs.particle.network/overview/available-networks/polygon#chain-information
 */
export const ChainNames: Record<number, string> = {
  [Chains.ETHEREUM]: 'Ethereum',
  [Chains.GOERLI]: 'Goerli',
  [Chains.POLYGON]: 'polygon',
  [Chains.MUMBAI]: 'polygon',
}
