import { call, put, select } from 'typed-redux-saga'
import { ClientError } from 'graphql-request'

import { sessionActions } from '../store/session/session.slice'
import { sessionSelectors } from '../store/session/session.selectors'

import { Auth0Messages } from './auth0Messages'

export function* getAccessToken() {
  try {
    const auth0Client = yield* select(sessionSelectors.selectAuth0Client)

    return yield* call([auth0Client, auth0Client.getTokenSilently])
  } catch (error) {
    if (error instanceof ClientError && error.response.errors) {
      const authenticationError = error.response.errors.find((error_) => error_.message === 'Unauthorized')
      if (authenticationError) yield* put(sessionActions.logoutStart())
    }

    if (error instanceof Error && error.message === Auth0Messages.INVALID_REFRESH_TOKEN) yield* put(sessionActions.logoutStart())

    throw error
  }
}
