import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import type { FetchProjectsDescriptionResult } from '../../projectContent.types'
import { projectContentActions } from '../../projectContent.slice'
import type { Language } from '../../../../types/common'
import { GetProjectDescriptionsData } from '../../../../../staticContent'
import { navigationSelectors } from '../../../navigation/navigation.selectors'

export function fetchProjectDescriptionsData(locale: Language): FetchProjectsDescriptionResult {
  return GetProjectDescriptionsData(locale) as FetchProjectsDescriptionResult
}

export function* fetchProjectDescriptionsSaga(): SagaGenerator<void> {
  try {
    const currentLocale = yield* select(navigationSelectors.selectCurrentLocalisation)

    const projectsDescriptions: FetchProjectsDescriptionResult = yield* call(fetchProjectDescriptionsData, currentLocale)

    yield* put(projectContentActions.fetchProjectDescriptionsSuccess(projectsDescriptions))
  } catch (error) {
    if (error instanceof Error) yield* put(projectContentActions.fetchProjectDescriptionsError(error.message))
  }
}
