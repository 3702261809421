import { createSelector } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import { selectReducer } from '../store.utils'

const selectState = createSelector([selectReducer(StoreKeys.User)], (state) => state)

const selectUserId = createSelector([selectState], (state) => state.id)

const selectUserAuthData = createSelector([selectState], (state) => state.auth0UserData)
const selectUserProfilePicture = createSelector([selectUserAuthData], (userData) => userData?.picture)
const selectUserEmail = createSelector([selectUserAuthData], (userData) => userData?.email ?? '')
const selectUserPhoneNumber = createSelector([selectState], (state) => state.sumsubApplicantData?.phone ?? '')

const selectBasicKycStatus = createSelector([selectState], (state) => state.basicKycStatus)
const selectAdvancedKycStatus = createSelector([selectState], (state) => state.advancedKycStatus)

const selectUserLocalization = createSelector([selectState], (state) => state.localization)

const selectLoadUserDataStatus = createSelector([selectState], (state) => state.loadUserDataStatus)

const selectSumsubApplicantData = createSelector([selectState], (state) => state.sumsubApplicantData)

const selectUserMarketingConsents = createSelector([selectState], (state) => state.marketingConsents)

const selectMonthlyInvestments = createSelector([selectState], (state) => state.monthlyInvestments)

const selectIsMigrated = createSelector([selectState], (state) => state.isMigrated)

const selectUserTokensMigrationStatus = createSelector([selectState], (state) => state.userTokensMigrationStatus)

export const userSelectors = {
  selectUserTokensMigrationStatus,
  selectUserProfilePicture,
  selectUserPhoneNumber,
  selectUserMarketingConsents,
  selectUserLocalization,
  selectUserId,
  selectUserEmail,
  selectUserAuthData,
  selectSumsubApplicantData,
  selectMonthlyInvestments,
  selectLoadUserDataStatus,
  selectIsMigrated,
  selectBasicKycStatus,
  selectAdvancedKycStatus,
}
