import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { ClaimRewardPayload, InvestPayload, ProjectToken, ProjectTokenData, SubgraphProjectTokenData } from './projectToken.types'
import { ProjectTokenState } from './projectToken.state'
import { isProjectToken } from './projectToken.const'

export const projectTokenAdapter = createEntityAdapter<ProjectTokenData>({
  sortComparer: (a, b) => a.address.localeCompare(b.address),
  selectId: (token) => token.address,
})

export const projectTokenInitialState: ProjectToken = projectTokenAdapter.getInitialState({
  ...new ProjectTokenState(),
})

const hydrate = createAction<{ [StoreKeys.ProjectToken]: ProjectToken }>(HYDRATE)

export const projectTokenSlice = createSlice({
  reducers: {
    investSuccess: (state) => {
      state.investStatus = RequestStatus.Succeeded
    },
    investFailure: (state, action: PayloadAction<RequestError>) => {
      state.investStatus = RequestStatus.Failed
      state.error = action.payload
    },
    invest(state, _: PayloadAction<InvestPayload>) {
      state.investStatus = RequestStatus.Loading
    },
    fetchSubgraphSingleSuccess: (state, action: PayloadAction<SubgraphProjectTokenData>) => {
      projectTokenAdapter.updateOne(state, {
        id: action.payload.address,
        changes: action.payload,
      })
      state.fetchSubgraphSingleStatus = RequestStatus.Succeeded
    },
    fetchSubgraphSingleFailure: (state, action: PayloadAction<RequestError>) => {
      state.fetchSubgraphSingleStatus = RequestStatus.Failed
      state.error = action.payload
    },
    fetchSubgraphSingle(state) {
      state.fetchSubgraphSingleStatus = RequestStatus.Loading
    },
    fetchSubgraphAllSuccess: (state, action: PayloadAction<SubgraphProjectTokenData[]>) => {
      projectTokenAdapter.updateMany(
        state,
        action.payload.map((token) => ({ id: token.address, changes: token }))
      )
      state.fetchSubgraphAllStatus = RequestStatus.Succeeded
    },
    fetchSubgraphAllFailure: (state, action: PayloadAction<RequestError>) => {
      state.fetchSubgraphAllStatus = RequestStatus.Failed
      state.error = action.payload
    },
    fetchSubgraphAll(state) {
      state.fetchSubgraphAllStatus = RequestStatus.Loading
    },
    fetchBackendSingleSuccess: (state, action: PayloadAction<ProjectTokenData>) => {
      projectTokenAdapter.upsertOne(state, action.payload)
      state.fetchFromBackend = RequestStatus.Succeeded
    },
    fetchBackendSingleFailure: (state, action: PayloadAction<RequestError>) => {
      state.fetchFromBackend = RequestStatus.Failed
      state.error = action.payload
    },

    fetchBackendSingle(state) {
      state.fetchFromBackend = RequestStatus.Loading
    },
    fetchBackendAllSuccess: (state, action: PayloadAction<ProjectTokenData[]>) => {
      projectTokenAdapter.upsertMany(state, action.payload)
      state.fetchFromBackend = RequestStatus.Succeeded
    },
    fetchBackendAllFailure: (state, action: PayloadAction<RequestError>) => {
      state.fetchFromBackend = RequestStatus.Failed
      state.error = action.payload
    },

    fetchBackendAll(state) {
      state.fetchFromBackend = RequestStatus.Loading
    },

    claimRewardSuccess: (state) => {
      state.claimRewardStatus = RequestStatus.Succeeded
    },
    claimRewardFailure: (state, action: PayloadAction<RequestError>) => {
      state.claimRewardStatus = RequestStatus.Failed
      state.error = action.payload
    },
    claimReward(state, _: PayloadAction<ClaimRewardPayload>) {
      state.claimRewardStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.ProjectToken,
  initialState: projectTokenInitialState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { entities } = payload[StoreKeys.ProjectToken]
      const tokens = Object.values(entities)

      const tokensToUpsert = tokens.filter(isProjectToken).map((token) => {
        const currentState = state.entities[token.address] || token

        return {
          ...currentState,
          logo: token.logo,
          address: token.address,
        }
      })

      return projectTokenAdapter.upsertMany(state, tokensToUpsert)
    }),
})

export const projectTokenAdapterSelectors = projectTokenAdapter.getSelectors()
export const projectTokenReducer = projectTokenSlice.reducer
export const projectTokenActions = projectTokenSlice.actions
export type ProjectTokenActions = ActionsType<typeof projectTokenActions>
