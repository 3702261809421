import type { SagaGenerator } from 'typed-redux-saga'
import { put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { TranchesBackendQueryResponseByAddress } from '../../../../../staticContent'
import type { BackendTrancheQueryResult } from '../../../../types/backendQueryTypes'
import type { BackendTranche, TrancheData } from '../../tranches.types'
import { mapBackendTrancheQueryResultToBackendTrancheData } from '../../tranches.mappers'
import type { TranchesActions } from '../../tranches.slice'
import { tranchesActions } from '../../tranches.slice'
import { emptySubgraphTranche } from '../../tranches.const'
import { apiCall } from '../../../../utils/apiCall'
import { projectTokenSelectors } from '../../../projectToken/projectToken.selectors'

export async function fetchBackendForTokenTranches(client: GraphQLClient, tokenAddress: string): Promise<BackendTranche[]> {
  const result: BackendTrancheQueryResult[] = await Promise.resolve(TranchesBackendQueryResponseByAddress(tokenAddress))

  return result.map(mapBackendTrancheQueryResultToBackendTrancheData)
}

export function* fetchBackendForTokenTranchesSaga(): SagaGenerator<void> {
  try {
    const projectToken = yield* select(projectTokenSelectors.selectCurrent)

    if (!projectToken) throw new Error('Project token not found')

    const backendTranches = yield* apiCall(fetchBackendForTokenTranches, projectToken.address)

    const mappedToTranches: TrancheData[] = backendTranches.map((backendTranche) => ({
      ...emptySubgraphTranche,
      ...backendTranche,
    }))

    yield* put(tranchesActions.fetchBackendForTokenSuccess(mappedToTranches))
  } catch (error) {
    if (error instanceof Error) yield* put(tranchesActions.fetchBackendForTokenFailure(error.message))
  }
}
