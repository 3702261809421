import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { SubgraphPhaseQueryResult } from '../../../../types/subgraphQueryTypes'
import type { SubgraphPhase } from '../../phases.types'
import { mapSubgraphPhaseQueryResultToSubgraphPhaseData } from '../../phases.mappers'
import { phasesActions } from '../../phases.slice'
import { MULTIPLE_PHASES_QUERY } from '../../phases.queries'
import { SubgraphPhasesQueryResponseByAdresses } from '../../../../../staticContent'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'

export async function fetchSubgraphMultiplePhases(client: GraphQLClient, fundraiseAddresses: string[]): Promise<SubgraphPhase[]> {
  const result = await client.rawRequest<{ phases: SubgraphPhaseQueryResult[] }>(MULTIPLE_PHASES_QUERY, { fundraiseAddresses })

  return result.data.phases.map(mapSubgraphPhaseQueryResultToSubgraphPhaseData)
}

export function fetchStaticSubgraphMultiplePhases(fundraiseAddresses: string[]): SubgraphPhase[] {
  return SubgraphPhasesQueryResponseByAdresses(fundraiseAddresses).map(mapSubgraphPhaseQueryResultToSubgraphPhaseData)
}

export function* fetchSubgraphAllPhasesSaga(): SagaGenerator<void> {
  try {
    const projectsBases = yield* select(projectBaseSelectors.selectAll)

    const legacyFundraisesAddresses: string[] = []
    const fundraisesAddresses: string[] = []

    projectsBases.forEach((projectBase) => {
      if (projectBase.isLegacy) legacyFundraisesAddresses.push(projectBase.fundrisingAddress)
      if (!projectBase.isLegacy) fundraisesAddresses.push(projectBase.fundrisingAddress)
    })

    const phases: SubgraphPhase[] = []

    if (legacyFundraisesAddresses.length > 0) {
      const legacyPhasesData = yield* call(fetchStaticSubgraphMultiplePhases, legacyFundraisesAddresses)
      phases.push(...legacyPhasesData)
    }

    if (fundraisesAddresses.length > 0) {
      const phasesData = yield* subgraphCall(fetchSubgraphMultiplePhases, fundraisesAddresses)
      phases.push(...phasesData)
    }

    yield* put(phasesActions.fetchSubgraphAllSuccess(phases))
  } catch (error) {
    if (error instanceof Error) yield* put(phasesActions.fetchSubgraphAllFailure(error.message))
  }
}
