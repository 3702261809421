import type { BigNumber } from 'ethers'
import { call, select } from 'typed-redux-saga'
import type { StrictEffect } from '@redux-saga/types'

import { selectSigner, selectWeb3Account } from '../../web3/web3.selectors'
import { getERC20 } from '../../web3/web3.utils'

export function* allowanceContractCallSaga(tokenInAddress: string, spenderAddress: string): Generator<StrictEffect, BigNumber> {
  const userWalletAddress = yield* select(selectWeb3Account)
  if (!userWalletAddress) throw new Error('Web3 Account not found')

  const signer = yield* select(selectSigner)
  const erc20 = yield* call(getERC20, tokenInAddress, signer)

  const allowance = yield* call(erc20.allowance, userWalletAddress, spenderAddress)
  return allowance
}
