import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { ProjectTokenStatuses } from './projectToken.types'

export class ProjectTokenState implements ProjectTokenStatuses {
  investStatus = RequestStatus.Idle
  fetchFromBackend = RequestStatus.Idle
  claimRewardStatus = RequestStatus.Idle
  fetchSubgraphAllStatus = RequestStatus.Idle
  fetchSubgraphSingleStatus = RequestStatus.Idle
  error: RequestError = null
}
