import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { StakeToken } from '@sapiency-io/mosaico-contracts/dist'
import { v4 as uuid } from 'uuid'
import type { StrictEffect } from 'redux-saga/effects'
import type { ContractTransaction } from 'ethers'

import { TransactionType, type TransactionBase } from '../../../transactions/transaction.types'
import { transactionsActions } from '../../../transactions/transaction.slice'
import { getStakeToken } from '../../../web3/web3.utils'
import { selectSigner } from '../../../web3/web3.selectors'
import { executeContractTransactionSaga } from '../../../transactions/saga/executeContractTransaction.saga'
import { stakeTokenActions, type StakeTokenActions } from '../../stakeToken.slice'
import { stakeTokenSelectors } from '../../stakeToken.selectors'
import { BigDecimal } from '../../../../utils/bigDecimal'

export function* unstakeTransaction(stakeTokenContract: StakeToken, amount: string): Generator<StrictEffect, ContractTransaction> {
  const tx = yield* call(stakeTokenContract.unstake, amount)
  yield* call(tx.wait)
  return tx
}

export function* createAndExecuteUnstakeTransactionPlanSaga({ payload }: StakeTokenActions['unstake']): SagaGenerator<void> {
  try {
    const unstakeTransactionPlan: TransactionBase[] = []

    const { amount, stakeTokenAddress } = payload
    const signer = yield* select(selectSigner)
    const transactionId = yield* select(stakeTokenSelectors.selectStakeTransactionId)

    if (!transactionId) throw new Error('Transfer transaction id is not defined')

    const stakeTokenContract = yield* call(getStakeToken, stakeTokenAddress, signer)

    const parsedAmount = BigDecimal.fromString(amount).toBigNumber().toString()

    const stakeTransactionData = {
      type: TransactionType.Unstake,
      source: transactionId,
      payload: {},
      id: uuid(),
    }

    unstakeTransactionPlan.push(stakeTransactionData)

    yield* put(transactionsActions.addTransactions(unstakeTransactionPlan))

    for (const transaction of unstakeTransactionPlan)
      if (transaction.type === TransactionType.Unstake)
        yield* call(executeContractTransactionSaga, transaction.id, call(unstakeTransaction, stakeTokenContract, parsedAmount))

    yield* put(stakeTokenActions.unstakeSuccess)
  } catch (error) {
    if (error instanceof Error) yield* put(stakeTokenActions.unstakeFailure(error.message))
  }
}
