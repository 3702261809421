import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { FundraiseData } from '../../fundraise.types'
import { fundraiseActions } from '../../fundraise.slice'
import type { SubgraphFundraisesQueryResult } from '../../../../types/subgraphQueryTypes'
import { mapFundraisesQueryResultToFundraiseData } from '../../fundraise.mappers'
import { MULTIPLE_FUNDRAISES_QUERY } from '../../fundraise.queries'
import { SubgraphFundraiseQueryResponseByAdresses } from '../../../../../staticContent'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'

export async function fetchSubgraphMultipleFundraise(client: GraphQLClient, fundraiseAddresses: string[]): Promise<FundraiseData[]> {
  const result = await client.rawRequest<{ fundraises: SubgraphFundraisesQueryResult[] }>(MULTIPLE_FUNDRAISES_QUERY, {
    addresses: fundraiseAddresses,
  })

  return result.data.fundraises.map(mapFundraisesQueryResultToFundraiseData)
}

export function fetchStaticSubgraphMultipleFundraise(fundraiseAddress: string[]): FundraiseData[] {
  const fundraises = SubgraphFundraiseQueryResponseByAdresses(fundraiseAddress)

  return fundraises.map(mapFundraisesQueryResultToFundraiseData)
}

export function* fetchSubgraphAllFundraiseSaga(): SagaGenerator<void> {
  try {
    const projectsBases = yield* select(projectBaseSelectors.selectAll)

    const legacyFundraisesAddresses: string[] = []
    const fundraisesAddresses: string[] = []

    projectsBases.forEach((projectBase) => {
      if (projectBase.isLegacy) legacyFundraisesAddresses.push(projectBase.fundrisingAddress)
      if (!projectBase.isLegacy) fundraisesAddresses.push(projectBase.fundrisingAddress)
    })

    const fundraises: FundraiseData[] = []

    if (legacyFundraisesAddresses.length > 0) {
      const legacyFundraisesData = yield* call(fetchStaticSubgraphMultipleFundraise, legacyFundraisesAddresses)
      fundraises.push(...legacyFundraisesData)
    }

    if (fundraisesAddresses.length > 0) {
      const fundraiseData = yield* subgraphCall(fetchSubgraphMultipleFundraise, fundraisesAddresses)
      fundraises.push(...fundraiseData)
    }

    yield* put(fundraiseActions.fetchSubgraphAllSuccess(fundraises))
  } catch (error) {
    if (error instanceof Error) yield* put(fundraiseActions.fetchSubgraphAllFailure(error.message))
  }
}
