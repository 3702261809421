// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgWallet = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20 16.5V19a2 2 0 0 1-2 2H5.5A2.5 2.5 0 0 1 3 18.5v-13m17 11a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1m0 5h-1.5a2.5 2.5 0 0 1 0-5H20m-17-6A1.5 1.5 0 0 1 4.5 4h4.854M3 5.5A1.5 1.5 0 0 0 4.5 7H18a2 2 0 0 1 2 2v2.5M8 7a4 4 0 0 1 8 0"
    />
  </svg>
)
export default SvgWallet
