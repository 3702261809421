import { RequestStatus } from '../types'

import type { BalancesStatuses } from './balances.types'

export class BalancesState implements BalancesStatuses {
  error: string | null = null
  fetchInternalBalancesStatus: RequestStatus = RequestStatus.Idle
  fetchLegacyBalancesStatus: RequestStatus = RequestStatus.Idle
  transferBalancesStatus: RequestStatus = RequestStatus.Idle
  balancesBlockchainStatus: RequestStatus = RequestStatus.Idle
  transferTransactionId: string | null = null
}
