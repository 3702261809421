import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { SubgraphTranche } from '../../tranches.types'
import { mapSubgraphTrancheQueryResultToSubgraphTrancheData } from '../../tranches.mappers'
import { tranchesActions } from '../../tranches.slice'
import type { SubgraphTranchesQueryResult } from '../../../../types/subgraphQueryTypes'
import { ALL_TRANCHES_FOR_TOKEN_QUERY } from '../../tranches.queries'
import { SubgraphTranchesQueryResponseByAddress } from '../../../../../staticContent'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'

export async function fetchSubgraphForTokenTranches(client: GraphQLClient, tokenAddress: string): Promise<SubgraphTranche[]> {
  const result = await client.rawRequest<{ tranches: SubgraphTranchesQueryResult[] }>(ALL_TRANCHES_FOR_TOKEN_QUERY, { tokenAddress })

  return result.data.tranches.map(mapSubgraphTrancheQueryResultToSubgraphTrancheData)
}

export function fetchStaticSubgraphForTokenTranches(tokenAddress: string): SubgraphTranche[] {
  return SubgraphTranchesQueryResponseByAddress(tokenAddress).map(mapSubgraphTrancheQueryResultToSubgraphTrancheData)
}

export function* fetchSubgraphForTokenTranchesSaga(): SagaGenerator<void> {
  try {
    const projectBase = yield* select(projectBaseSelectors.selectCurrent)
    if (!projectBase) throw new Error('Project token not found')

    const { projectTokenAddress } = projectBase

    const allTranches = yield* projectBase.isLegacy
      ? call(fetchStaticSubgraphForTokenTranches, projectTokenAddress)
      : subgraphCall(fetchSubgraphForTokenTranches, projectTokenAddress)

    yield* put(tranchesActions.fetchSubgraphForTokenSuccess(allTranches))
  } catch (error) {
    if (error instanceof Error) yield* put(tranchesActions.fetchSubgraphForTokenFailure(error.message))
  }
}
