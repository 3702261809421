import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { RewardStablecoin, RewardStablecoinData } from './rewardStablecoin.types'
import { RewardStablecoinState } from './rewardStablecoin.state'

export const rewardStablecoinAdapter = createEntityAdapter<RewardStablecoinData>({
  selectId: (rewardToken) => rewardToken.address,
})

export const rewardTokenInitialState: RewardStablecoin = rewardStablecoinAdapter.getInitialState(new RewardStablecoinState())

export const rewardStablecoinSlice = createSlice({
  reducers: {
    fetchAllSuccess(state, action: PayloadAction<RewardStablecoinData[]>) {
      rewardStablecoinAdapter.upsertMany(state, action.payload)
      state.fetchAllStatus = RequestStatus.Succeeded
    },
    fetchAllFailure(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.fetchAllStatus = RequestStatus.Failed
    },
    fetchAll(state) {
      state.fetchAllStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.RewardStablecoin,
  initialState: rewardTokenInitialState,
})

export const rewardStablecoinAdapterSelectors = rewardStablecoinAdapter.getSelectors()
export const rewardStablecoinReducer = rewardStablecoinSlice.reducer
export const rewardStablecoinActions = rewardStablecoinSlice.actions
export type RewardStablecoinActions = ActionsType<typeof rewardStablecoinActions>
