// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgThumbsUp = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8 10.572 3.649-4.751a1.734 1.734 0 0 1 2.714-.046c.265.32.409.725.409 1.14v3.272h3.096a1.8 1.8 0 0 1 1.496.799l.329.49a1.8 1.8 0 0 1 .234 1.5l-1.359 4.722A1.8 1.8 0 0 1 16.839 19H10.55a1.8 1.8 0 0 1-1.318-.574L8 17.1M6.944 19H5.056A1.056 1.056 0 0 1 4 17.944v-7.388C4 9.973 4.473 9.5 5.056 9.5h1.888C7.527 9.5 8 9.973 8 10.556v7.388C8 18.527 7.527 19 6.944 19Z"
    />
  </svg>
)
export default SvgThumbsUp
