// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgCopySuccess = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17 17v2a1.999 1.999 0 0 1-2 2H6a1.999 1.999 0 0 1-2-2V8a2 2 0 0 1 2-2h2m12 1.5h-3A1.5 1.5 0 0 1 15.5 6V3m.5 7-2.75 3L12 11.636M20 15V7.028a2 2 0 0 0-.586-1.414l-2.028-2.028A2 2 0 0 0 15.972 3H10a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2Z"
    />
  </svg>
)
export default SvgCopySuccess
