import type { Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'

export const getAppBar = (palette: CustomPalette): Components<Theme> => ({
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingRight: '0px!important',
        paddingLeft: '0px!important',
      },
    },
  },
  MuiAppBar: {
    variants: [
      {
        style: {
          background: palette._white,
        },
        props: { color: 'primary' },
      },
      {
        style: {
          borderColor: 'transparent',
        },
        props: { color: 'transparent' },
      },
    ],
    styleOverrides: {
      root: {
        transition: 'background-color 300ms ease-out',
      },
    },
  },
})
