import { Button as MuiButton } from '@mui/material'
import React from 'react'

import { ButtonBase } from '../ButtonBase/ButtonBase.component'

import type { ButtonProps } from './Button.types'

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({ children, startIcon, endIcon, ...props }) => {
  if (startIcon || endIcon)
    return (
      <ButtonBase {...props} startIcon={startIcon} endIcon={endIcon}>
        {children}
      </ButtonBase>
    )

  return <MuiButton {...props}>{children}</MuiButton>
}
