import { call, delay } from 'typed-redux-saga'

import { getAccessToken } from '../../../../utils/getAccessToken'
import envConfig from '../../../../../config/env'
import type { KycStatusResponse } from '../../sumsub.types'
import { VerificationStatus } from '../../sumsub.constants'

async function getKycStatus(accessToken: string): Promise<KycStatusResponse> {
  return fetch(`${envConfig.auth0Audience}/kyc/status`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => response.json())
}

export function* waitForWebhookToUpdateKycStatus(): Generator {
  const accessToken = yield* call(getAccessToken)

  let retries = 15

  while (retries) {
    const kycStatus = yield* call(getKycStatus, accessToken)

    if (kycStatus.basicKycStatus === VerificationStatus.ACCEPTED) {
      console.log('KYC VERIFIED')
      // TODO: Handle kyc verification success

      return
    }

    retries--
    yield* delay(1000)
  }
}
