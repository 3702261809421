import { DEFAULT_INTERNAL_TOKEN_DECIMALS, USDC_DECIMALS } from '../../../config/decimals'
import type { BackendPhaseQueryResult } from '../../types/backendQueryTypes'
import type { SubgraphPhaseQueryResult } from '../../types/subgraphQueryTypes'
import { BigDecimal } from '../../utils/bigDecimal'

import type { BackendPhase, SubgraphPhase } from './phases.types'

export const mapSubgraphPhaseQueryResultToSubgraphPhaseData = (subgraphPhase: SubgraphPhaseQueryResult): SubgraphPhase => ({
  type: Number(subgraphPhase.phaseType),
  tokenRate: subgraphPhase.tokenRate,
  status: Number(subgraphPhase.status),
  startDate: Number(subgraphPhase.startDate),
  soldTokens: BigDecimal.fromString(subgraphPhase.soldTokens, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(DEFAULT_INTERNAL_TOKEN_DECIMALS),
  minInvest: BigDecimal.fromString(subgraphPhase.minInvest, USDC_DECIMALS).toFixed(USDC_DECIMALS),
  maxTokensAmount: BigDecimal.fromString(subgraphPhase.maxTokensAmount, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(
    DEFAULT_INTERNAL_TOKEN_DECIMALS
  ),
  maxInvest: BigDecimal.fromString(subgraphPhase.maxInvest, USDC_DECIMALS).toFixed(USDC_DECIMALS),
  index: Number(subgraphPhase.phaseIndex),
  id: subgraphPhase.id,
  fundraiseAddress: subgraphPhase.fundraise.address,
  endDate: Number(subgraphPhase.endDate),
})

export const mapBackendPhaseQueryResultToBackendPhaseData = (backendPhase: BackendPhaseQueryResult): BackendPhase => ({
  roi: backendPhase.roi,
  id: backendPhase.id,
  fundraiseAddress: backendPhase.fundraise.address,
})
