import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { closedProjectBaseActions } from './closedProjectBase.slice'
import { fetchAllClosedProjectsBaseSaga } from './sagas/fetchAllClosedProjects/fetchAllClosedProjects'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([takeLatest(closedProjectBaseActions.fetchAllClosedProjects, fetchAllClosedProjectsBaseSaga)])
}

export function* closedProjectBaseSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
