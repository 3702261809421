import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { web3Actions } from '../web3/web3.slice'

import { addUserWalletSaga } from './sagas/addUserWallet/addUserWallet.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([takeLatest(web3Actions.web3InitializationSuccess.type, addUserWalletSaga)])
}

export function* walletSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
