import { all, fork } from 'typed-redux-saga'

import { sessionSaga } from './session/session.saga'
import { userSaga } from './user/user.saga'
import { walletSaga } from './wallet/wallet.saga'
import { web3Saga } from './web3/web3.saga'
import { projectTokenSaga } from './projectToken/projectToken.saga'
import { stakeTokenSaga } from './stakeToken/stakeToken.saga'
import { rewardTokenSaga } from './rewardTokenERC20/rewardTokenERC20.saga'
import { fundraiseSaga } from './fundraise/fundraise.saga'
import { sumsubSaga } from './sumsub/sumsub.saga'
import { balancesSaga } from './balances/balances.saga'
import { rewardsSaga } from './rewards/rewards.saga'
import { projectBaseSaga } from './projectBase/projectBase.saga'
import { projectContentSaga } from './projectContent/projectContent.saga'
import { staticSaga } from './static/static.saga'
import { platformSummarySaga } from './platformSummary/platformSummary.saga'
import { phasesSaga } from './phases/phases.saga'
import { tranchesSaga } from './tranches/tranches.saga'
import { cryptocurrenciesSaga } from './cryptocurrencies/cryptocurrencies.saga'
import { investmentMasterSaga } from './investment/investment.saga'
import { closedProjectBaseSaga } from './closedProjectBase/closedProjectBase.saga'
import { userInvestmentsSaga } from './userInvestments/userInvestments.saga'
import { rewardStablecoinSaga } from './rewardStablecoin/rewardStablecoin.saga'
import { legacyTokensSaga } from './legacyTokens/legacyTokens.saga'

export function* rootSaga(): Generator {
  yield all([
    fork(sessionSaga),
    fork(web3Saga),
    fork(walletSaga),
    fork(userSaga),
    fork(projectTokenSaga),
    fork(stakeTokenSaga),
    fork(rewardTokenSaga),
    fork(fundraiseSaga),
    fork(sumsubSaga),
    fork(balancesSaga),
    fork(rewardsSaga),
    fork(projectBaseSaga),
    fork(closedProjectBaseSaga),
    fork(projectContentSaga),
    fork(platformSummarySaga),
    fork(phasesSaga),
    fork(tranchesSaga),
    fork(cryptocurrenciesSaga),
    fork(investmentMasterSaga),
    fork(userInvestmentsSaga),
    fork(staticSaga),
    fork(rewardStablecoinSaga),
    fork(legacyTokensSaga),
  ])
}

export function* rootStaticSaga(): Generator {
  yield all([
    fork(projectBaseSaga),
    fork(projectContentSaga),
    fork(staticSaga),
    fork(phasesSaga),
    fork(tranchesSaga),
    fork(projectTokenSaga),
    fork(stakeTokenSaga),
    fork(rewardTokenSaga),
    fork(fundraiseSaga),
  ])
}
