import type { Breakpoints, Components, Theme } from '@mui/material'

import type { CustomTypographyVariants } from '../typography'
import type { CustomPalette } from '../palette'

import { getButton } from './button'
import { fontFace } from './fontFace'
import { getProgressBar } from './linearProgress'
import { getPaper } from './paper'
import { getAppBar } from './appBar'
import { getToggleButtonGroup } from './toggleButtonGroup'
import { getInput } from './input'
import { getTabs } from './tabs'
import { getChips } from './chip'
import { getDivider } from './divider'
import { getModal } from './modal'
import { getRadio } from './radio'
import { getCheckbox } from './checkbox'
import { getAccordion } from './accordion'
import { getTable } from './table'

export const getComponents = (
  palette: CustomPalette,
  typography: CustomTypographyVariants,
  breakpoints: Breakpoints
): Components<Theme> => ({
  ...fontFace,
  ...getButton(palette, typography),
  ...getProgressBar(palette, typography),
  ...getPaper(palette),
  ...getAppBar(palette),
  ...getToggleButtonGroup(palette, typography, breakpoints),
  ...getInput(palette, typography),
  ...getTabs(palette, typography, breakpoints),
  ...getChips(palette),
  ...getDivider(),
  ...getModal(palette),
  ...getRadio(),
  ...getCheckbox(),
  ...getAccordion(),
  ...getTable(palette, typography, breakpoints),
})
