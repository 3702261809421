import { createSelector } from '@reduxjs/toolkit'
import { Auth0Client } from '@auth0/auth0-spa-js'
import { GraphQLClient } from 'graphql-request'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'
import { RequestStatus } from '../types'
import envConfig from '../../../config/env'
import { navigationSelectors } from '../navigation/navigation.selectors'

const selectState = createSelector([selectReducer(StoreKeys.Session)], (state) => state)

const selectAuth0Client = createSelector([selectState, navigationSelectors.selectCurrentLocalisation], (state, currentLocalization) => {
  return new Auth0Client({
    useRefreshTokens: true,
    domain: envConfig.auth0Domain,
    clientId: envConfig.auth0ClientId,
    cacheLocation: 'localstorage',
    authorizationParams: {
      ui_locales: currentLocalization,
      scope: 'all:user profile email offline_access',
      redirect_uri: state.domainName,
      audience: envConfig.auth0Audience,
    },
  })
})

const selectGraphQLClient = createSelector([selectState], (state) => {
  return new GraphQLClient(envConfig.graphqlUri, { errorPolicy: 'all' })
})

const selectSubgraphGraphQLClient = createSelector([selectState], (state) => {
  return new GraphQLClient(envConfig.subgraphGraphqlUri, { errorPolicy: 'all' })
})

const selectIsLoggedIn = createSelector([selectState], (state) => state.loginStatus === RequestStatus.Succeeded)

const selectIsSessionLoading = createSelector([selectState], (state) => state.initializeSessionStatus === RequestStatus.Loading)
const selectIsSessionLoadedSuccessful = createSelector([selectState], (state) => state.initializeSessionStatus === RequestStatus.Succeeded)

const isAuth0SessionLoaded = createSelector([selectState], (state) => state.auth0SessionLoaded === RequestStatus.Succeeded)

const selectLogoutStatus = createSelector([selectState], (state) => state.logoutStatus)

const selectIsSessionLoaded = createSelector(
  [selectState],
  (state) => state.initializeSessionStatus !== RequestStatus.Loading && state.initializeSessionStatus !== RequestStatus.Idle
)

const selectSessionInitializationStatus = createSelector([selectState], (session) => {
  return session.initializeSessionStatus
})

const selectIsLogoutNeeded = createSelector([selectState], (state) => state.isLogoutNeeded)

export const sessionSelectors = {
  selectSubgraphGraphQLClient,
  selectSessionInitializationStatus,
  selectLogoutStatus,
  selectIsSessionLoading,
  selectIsSessionLoadedSuccessful,
  selectIsSessionLoaded,
  selectIsLogoutNeeded,
  selectIsLoggedIn,
  selectGraphQLClient,
  selectAuth0Client,
  isAuth0SessionLoaded,
}
