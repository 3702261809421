import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { RewardTokenERC20Subgraph } from '../../rewardTokenERC20.types'
import type { SubgraphRewardTokenERC20QueryResult } from '../../../../types/subgraphQueryTypes'
import { rewardTokenERC20Actions } from '../../rewardTokenERC20.slice'
import { mapSubgraphRewardTokenERC20DataToRewardTokenERC20Data } from '../../rewardTokenERC20.mappers'
import { ALL_REWARD_TOKEN_ERC20S_QUERY } from '../../rewardTokenERC20.queries'

async function fetchSubgraphAllRewardsTokensERC20(client: GraphQLClient): Promise<RewardTokenERC20Subgraph[]> {
  const result = await client.rawRequest<{
    rewardTokenERC20S: SubgraphRewardTokenERC20QueryResult[]
  }>(ALL_REWARD_TOKEN_ERC20S_QUERY)

  const rewardsERC20 = result.data.rewardTokenERC20S.map(mapSubgraphRewardTokenERC20DataToRewardTokenERC20Data)

  return rewardsERC20
}

export function* fetchSubgraphAllRewardsTokensERC20Saga(): SagaGenerator<void> {
  try {
    const tokensData = yield* subgraphCall(fetchSubgraphAllRewardsTokensERC20)

    yield* put(rewardTokenERC20Actions.fetchSubgraphAllSuccess(tokensData))
  } catch (error) {
    if (error instanceof Error) yield* put(rewardTokenERC20Actions.fetchSubgraphAllFailure(error.message))
  }
}
