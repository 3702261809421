import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import { LegacyTokensState } from './legacyTokens.state'
import type { SwapTokensPayload } from './legacyTokens.types'

export const initialLegacyTokensState = { ...new LegacyTokensState() }

export const legacyTokensSlice = createSlice({
  reducers: {
    swapTokensSuccess(state) {
      state.swapTokensStatus = RequestStatus.Succeeded
    },
    swapTokensFailure(state, action: PayloadAction<RequestError>) {
      state.swapTokensStatus = RequestStatus.Failed
      state.error = action.payload
      return state
    },
    swapTokens(state, _: PayloadAction<SwapTokensPayload>) {
      state.swapTokensStatus = RequestStatus.Loading
      state.swapTokensTransactionId = uuid()
    },
  },
  name: StoreKeys.LegacyTokens,
  initialState: initialLegacyTokensState,
})

export const legacyTokensActions = legacyTokensSlice.actions
export const legacyTokensReducer = legacyTokensSlice.reducer
export type LegacyTokensActions = ActionsType<typeof legacyTokensActions>
