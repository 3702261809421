import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { walletSelectors } from '../../../../wallet/wallet.selectors'
import { web3Selectors } from '../../../web3.selectors'
import { Web3NotDefined } from '../../../web3.constants'
import { metamaskConnector } from '../../../../../utils/connectors/metaMask'
import { pushNotification } from '../../../../../utils/pushNotification'
import { web3Actions } from '../../../web3.slice'
import { WalletTypeEnum } from '../../../../../graphql/generated/graphql'
import { changeMetamaskAccount, detectMetamask, requestMetamaskAccounts } from '../utils'
import { MetamaskError } from '../metamask.types'

export function* addNewWalletSaga(): SagaGenerator<void> {
  const metamaskProvider = yield* call(detectMetamask)
  const wallets = yield* select(walletSelectors.selectAll)

  if (metamaskProvider)
    try {
      const web3 = yield* select(web3Selectors.selectWeb3)

      if (!web3) throw Web3NotDefined

      yield* call(web3.activate, metamaskConnector)
      yield* call(changeMetamaskAccount)
      const [activeMetamaskAccount, ...metamaskAccounts] = yield* call(requestMetamaskAccounts)

      if (wallets.map(({ ethereumAddress }) => ethereumAddress.toLowerCase()).includes(activeMetamaskAccount.toLowerCase()))
        pushNotification(`Account ${activeMetamaskAccount} already added`, 'warning')

      yield* put(web3Actions.connectWalletSuccess({ walletType: WalletTypeEnum.Metamask }))
    } catch (error) {
      if (error instanceof Error)
        yield* put(web3Actions.connectWalletFailure({ walletType: WalletTypeEnum.Metamask, error: error.message }))
    }
  else {
    yield* put(web3Actions.connectWalletFailure({ walletType: WalletTypeEnum.Metamask, error: MetamaskError.ProviderNotDetected }))
    pushNotification(`Please install Metamask https://metamask.io/download/`, 'error')
  }
}
