// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgBell = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.708 18.344v.365a2.292 2.292 0 0 0 4.584-.001v-.365m-.459-12.592v-.918a1.833 1.833 0 1 0-3.666 0v.918m-3.604 4.437a4.53 4.53 0 0 1 4.531-4.531h1.813a4.53 4.53 0 0 1 4.531 4.531v2.797a2 2 0 0 0 .586 1.414l.641.641a2 2 0 0 1 .586 1.414 1.89 1.89 0 0 1-1.89 1.89H6.64a1.89 1.89 0 0 1-1.89-1.89 2 2 0 0 1 .586-1.414l.641-.641a2 2 0 0 0 .586-1.414v-2.797Z"
    />
  </svg>
)
export default SvgBell
