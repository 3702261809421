import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { tranchesActions } from './tranches.slice'
import { fetchBackendAllTranchesSaga } from './sagas/fetchFromBackend/all'
import { fetchBackendForTokenTranchesSaga } from './sagas/fetchFromBackend/forToken'
import { fetchSubgraphAllTranchesSaga } from './sagas/fetchFromSubgraph/all'
import { fetchSubgraphForTokenTranchesSaga } from './sagas/fetchFromSubgraph/forToken'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(tranchesActions.fetchBackendAll, fetchBackendAllTranchesSaga),
    takeLatest(tranchesActions.fetchBackendForToken, fetchBackendForTokenTranchesSaga),
    takeLatest(tranchesActions.fetchSubgraphAll, fetchSubgraphAllTranchesSaga),
    takeLatest(tranchesActions.fetchSubgraphForToken, fetchSubgraphForTokenTranchesSaga),
  ])
}

export function* tranchesSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
