import { call, put } from 'typed-redux-saga'
import type { Web3ReactContextInterface } from '@web3-react/core/dist/types'

import { web3Actions } from '../../web3.slice'
import { particleNetworkConnector } from '../../../../utils/connectors/particleNetwork'
import { getAccessToken } from '../../../../utils/getAccessToken'
import { WalletTypeEnum } from '../../../../graphql/generated/graphql'

export function* activateWeb3Saga(web3: Web3ReactContextInterface) {
  const accessToken = yield* call(getAccessToken)
  yield* call(particleNetworkConnector.setProperties, { accessToken })

  yield* call([web3, web3.activate], particleNetworkConnector)

  const particleAccount = yield* call([particleNetworkConnector, particleNetworkConnector.getAccount])

  yield* put(web3Actions.connectWalletSuccess({ walletType: WalletTypeEnum.ParticleNetwork, walletAddress: particleAccount || undefined }))
}
