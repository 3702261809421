import { BigNumber, utils } from 'ethers'

export const getStablecoinAmount = (tokenAmount: string, tokenRate: string, stablecoinDecimals: number): BigNumber => {
  const amount = utils.parseUnits(tokenAmount, stablecoinDecimals)
  const denominator = BigNumber.from(10 ** stablecoinDecimals)
  return amount.mul(tokenRate).div(denominator)
}

export const resolveAmountPercentage = (partAmount: number, totalAmount: number, capAt?: number): string => {
  const percentage = Math.round((partAmount / totalAmount) * 100).toFixed(2)

  return capAt && Number(percentage) > capAt ? capAt.toString() : percentage
}

export const resolveTokens = (percentage: string, tokensTotalSupply: number) =>
  Math.round((Number(percentage) * tokensTotalSupply) / 100).toFixed(0)
