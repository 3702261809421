// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgHeartCheckmark = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m14.889 11.056-3.611 3.611L9.111 12.5M12 6.711C12.689 5.905 13.881 5 15.696 5 18.87 5 21 7.98 21 10.755 21 16.556 13.778 21 12 21c-1.778 0-9-4.444-9-10.245C3 7.98 5.13 5 8.304 5c1.815 0 3.007.905 3.696 1.711Z"
    />
  </svg>
)
export default SvgHeartCheckmark
