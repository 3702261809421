import { call, put, select, take } from 'typed-redux-saga'
import type { providers } from 'ethers'
import type { EventChannel } from 'redux-saga'
import { eventChannel } from 'redux-saga'

import { web3Selectors } from '../../web3.selectors'
import { web3Actions } from '../../web3.slice'

export enum EventName {
  Block = 'block',
}

export function createBlockEventChannel(wsProvider: providers.WebSocketProvider): EventChannel<number> {
  return eventChannel((emitter) => {
    const blockHandler = (blockNumber: number) => {
      emitter(blockNumber)
    }

    wsProvider.on(EventName.Block, blockHandler)

    return () => {
      wsProvider.off(EventName.Block, blockHandler)
    }
  })
}

export function* watchLatestBlockNumber(): Generator {
  const wsProvider = yield* select(web3Selectors.selectWsProvider)

  if (wsProvider) {
    const blockEventChannel = yield* call(createBlockEventChannel, wsProvider)

    while (true) {
      const latestBlockNumber = yield* take(blockEventChannel)

      yield* put(web3Actions.setLatestBlock(latestBlockNumber))
    }
  }
}
