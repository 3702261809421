import { createSelector } from '@reduxjs/toolkit'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'
import { userSelectors } from '../user/user.selectors'
import { projectBaseSelectors } from '../projectBase/projectBase.selectors'
import { transactionsSelectors } from '../transactions/transactions.selectors'

import type {
  CryptoInvestmentResultData,
  InvestmentAmountData,
  InvestmentBase,
  InvestmentPaymentMethodData,
  InvestmentSetupData,
  RampInvestmentData,
  RampInvestmentResultData,
} from './investment.state'
import {
  isCryptoTransactionResultDataAvailable,
  isInvestmentAmountDataAvailable,
  isInvestmentPaymentMethodDataAvailable,
  isInvestmentSetupDataAvailable,
  isrampInvestmentDataAvailable,
  isrampInvestmentResultDataAvailable,
} from './investment.guards'
import type { InvestmentStep, PaymentMethod } from './investment.types'

const selectState = createSelector([selectReducer(StoreKeys.Investment)], (state) => state)

const selectNextInvestmentStep = createSelector([selectState], (state): InvestmentStep => {
  return state.nextStep
})
const selectInvestmentStep = createSelector([selectState], (state): InvestmentStep => {
  return state.step
})

const selectInvestmentId = createSelector([selectState], (investment): InvestmentBase['id'] | undefined => {
  return investment.id
})

const selectInvestmentSetupData = createSelector(
  [selectState, projectBaseSelectors.selectCurrent],
  (state, project): InvestmentSetupData | undefined => {
    if (isInvestmentSetupDataAvailable(state))
      return {
        tokenOutAddress: state.tokenOutAddress,
        tokenInAddress: state.tokenInAddress,
        projectId: state.projectId,
        fundraiseId: state.fundraiseId,
      }
  }
)

const selectInvestmentAmountData = createSelector([selectState], (state): InvestmentAmountData | undefined => {
  if (isInvestmentAmountDataAvailable(state))
    return {
      tokenOutAmount: state.tokenOutAmount,
      tokenInAmount: state.tokenInAmount,
    }
})

const selectInvestmentPaymentMethodData = createSelector([selectState], (state): InvestmentPaymentMethodData | undefined => {
  if (isInvestmentPaymentMethodDataAvailable(state))
    return {
      walletAddress: state.walletAddress,
      paymentMethod: state.paymentMethod,
      agreements: state.agreements,
    }
})

const selectPaymentMethod = createSelector([selectState], (state): PaymentMethod | undefined => {
  if (isInvestmentPaymentMethodDataAvailable(state)) return state.paymentMethod
})

const selectRampInvestmentData = createSelector([selectState], (state): RampInvestmentData | undefined => {
  if (isrampInvestmentDataAvailable(state))
    return {
      rampPurchaseId: state.rampPurchaseId,
    }
})

const selectRampInvestmentResultData = createSelector([selectState], (state): RampInvestmentResultData | undefined => {
  if (isrampInvestmentResultDataAvailable(state))
    return {
      status: state.status,
    }
})

const selectCryptoTransactionResultData = createSelector([selectState], (state): CryptoInvestmentResultData | undefined => {
  if (isCryptoTransactionResultDataAvailable(state))
    return {
      status: state.status,
    }
})

const selectRampInvestmentInput = createSelector(
  [
    userSelectors.selectUserId,
    userSelectors.selectUserEmail,
    selectInvestmentSetupData,
    selectInvestmentAmountData,
    selectInvestmentPaymentMethodData,
  ],
  (userId, userEmail, investmentSetupData, investmentAmountData, investmentPayoutMethodData) => {
    if (userId && userEmail && investmentAmountData && investmentSetupData && investmentPayoutMethodData)
      return {
        walletAddress: investmentPayoutMethodData.walletAddress,
        userId: String(userId),
        userEmail,
        investmentOutAmount: String(investmentAmountData.tokenOutAmount),
        investmentInAmount: String(investmentAmountData.tokenInAmount),
        fundraiseAddress: investmentSetupData.fundraiseId,
      }
  }
)

const selectInvestmentTransactions = createSelector(
  [selectState, transactionsSelectors.selectTransactionsBySource],
  (investment, transactionsBySource) => {
    return transactionsBySource
  }
)

export const investmentSelectors = {
  selectRampInvestmentResultData,
  selectRampInvestmentInput,
  selectRampInvestmentData,
  selectPaymentMethod,
  selectNextInvestmentStep,
  selectInvestmentTransactions,
  selectInvestmentStep,
  selectInvestmentSetupData,
  selectInvestmentPaymentMethodData,
  selectInvestmentId,
  selectInvestmentAmountData,
  selectCryptoTransactionResultData,
}
