import type { SagaGenerator } from 'typed-redux-saga'
import { race, select, take } from 'typed-redux-saga'
import type { Effect } from '@redux-saga/types'

import { sessionActions } from '../../session.slice'
import { sessionSelectors } from '../../session.selectors'
import { RequestStatus } from '../../../types'

export function* executeOnSessionInitializationSuccess(effect: SagaGenerator<Generator, Effect> | Generator<Effect>) {
  const sessionInitializationStatus = yield* select(sessionSelectors.selectSessionInitializationStatus)

  if (sessionInitializationStatus === RequestStatus.Idle || sessionInitializationStatus === RequestStatus.Loading) {
    const { initializeSessionSuccess, initializeSessionFailure } = yield* race({
      initializeSessionSuccess: take(sessionActions.initializeSessionSuccess),
      initializeSessionFailure: take(sessionActions.initializeSessionError),
    })

    if (initializeSessionSuccess) yield* effect
    else if (initializeSessionFailure) throw new Error("Can't connect Mosaico Wallet because of session initialization failure")
  } else if (sessionInitializationStatus === RequestStatus.Succeeded) yield* effect
}
