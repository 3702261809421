import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'
import type { Language } from '../../types/common'
import type { VerificationStatus } from '../sumsub/sumsub.constants'
import type { ApplicantData } from '../../graphql/generated/graphql'

import type { IUser, LoadUserDataSuccessPayload } from './user.types'

export const initialUserSliceState: IUser = {
  userTokensMigrationStatus: RequestStatus.Idle,
  updateMarketingConsentsStatus: RequestStatus.Idle,
  sumsubApplicantData: null,
  monthlyInvestments: '0',
  marketingConsents: false,
  localization: 'pl',
  loadUserDataStatus: RequestStatus.Idle,
  isMigrated: false,
  id: 0,
  fetchApplicantDataStatus: RequestStatus.Idle,
  error: null,
  email: '',
  deleteUserStatus: RequestStatus.Idle,
  basicKycStatus: null,
  auth0UserData: null,
  advancedKycStatus: null,
}

export const userSlice = createSlice({
  reducers: {
    updateUserLocalization: (state, action: PayloadAction<Language>) => {
      state.localization = action.payload
      return state
    },
    updateUserBasicKycStatus: (state, action: PayloadAction<VerificationStatus>) => {
      state.basicKycStatus = action.payload
      return state
    },
    updateMarketingConsentsSuccess: (state, action: PayloadAction<boolean>) => {
      state.updateMarketingConsentsStatus = RequestStatus.Succeeded
      state.marketingConsents = action.payload
    },
    updateMarketingConsentsFailure: (state, action: PayloadAction<RequestError>) => {
      state.updateMarketingConsentsStatus = RequestStatus.Failed
      state.error = action.payload
    },
    updateMarketingConsents: (state, _: PayloadAction<boolean>) => {
      state.updateMarketingConsentsStatus = RequestStatus.Loading
    },
    migrateUserTokensSuccess: (state) => {
      state.isMigrated = true
      state.userTokensMigrationStatus = RequestStatus.Succeeded
    },
    migrateUserTokensFailure: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.userTokensMigrationStatus = RequestStatus.Failed
    },
    migrateUserTokens: (state) => {
      state.userTokensMigrationStatus = RequestStatus.Loading
    },
    loadUserDataSuccess: (state, action: PayloadAction<LoadUserDataSuccessPayload>) => {
      state.id = action.payload.id
      state.loadUserDataStatus = RequestStatus.Succeeded
      state.auth0UserData = action.payload.auth0UserData
      state.email = action.payload.email
      state.basicKycStatus = action.payload.basicKycStatus
      state.advancedKycStatus = action.payload.advancedKycStatus
      state.localization = action.payload.localization
      state.sumsubApplicantData = action.payload.sumsubApplicantData
      state.marketingConsents = action.payload.marketingConsents
      state.monthlyInvestments = action.payload.monthlyInvestments
      state.isMigrated = action.payload.isMigrated
      return state
    },
    loadUserDataError: (state, action: PayloadAction<string>) => {
      state.loadUserDataStatus = RequestStatus.Failed
      state.error = action.payload
      return state
    },
    loadUserData: (state) => {
      state.loadUserDataStatus = RequestStatus.Loading
      return state
    },
    fetchApplicantDataSuccess: (state, action: PayloadAction<ApplicantData>) => {
      state.sumsubApplicantData = action.payload
      state.fetchApplicantDataStatus = RequestStatus.Succeeded
    },
    fetchApplicantDataFailure: (state) => {
      state.fetchApplicantDataStatus = RequestStatus.Failed
    },
    fetchApplicantData: (state) => {
      state.fetchApplicantDataStatus = RequestStatus.Loading
    },
    fetchAllBalances: (_, __: PayloadAction<string | undefined>) => {},
    deleteUserSuccess: (state) => {
      state.deleteUserStatus = RequestStatus.Succeeded
    },
    deleteUserFailure: (state, action: PayloadAction<RequestError>) => {
      state.deleteUserStatus = RequestStatus.Failed
      state.error = action.payload
    },
    deleteUser: (state) => {
      state.deleteUserStatus = RequestStatus.Loading
    },
    clearUser: (state) => {
      state.auth0UserData = null
      state.email = ''
      state.id = 0
      state.basicKycStatus = null
      state.advancedKycStatus = null
      state.loadUserDataStatus = RequestStatus.Idle
      return state
    },
  },
  name: StoreKeys.User,
  initialState: initialUserSliceState,
})

export const userActions = userSlice.actions
export const userReducer = userSlice.reducer
export type UserActions = ActionsType<typeof userActions>
