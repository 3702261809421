// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgPhone = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M13 6.991A3.944 3.944 0 0 1 17.009 11M13 2.964c2.056 0 4.113.785 5.682 2.354A8.012 8.012 0 0 1 21.036 11m-2.071 5.61-1.173-1.173a1.5 1.5 0 0 0-2.121 0l-.922.922a.71.71 0 0 1-.784.158c-1.336-.585-2.656-1.472-3.833-2.649a12.645 12.645 0 0 1-2.642-3.817.733.733 0 0 1 .162-.811l.826-.826c.671-.671.671-1.62.085-2.206L7.39 5.035a2 2 0 0 0-2.828 0l-.652.651c-.741.741-1.05 1.81-.85 2.87.494 2.613 2.012 5.474 4.461 7.923 2.449 2.449 5.31 3.967 7.923 4.461 1.06.2 2.129-.109 2.87-.85l.651-.651a2 2 0 0 0 0-2.829Z"
    />
  </svg>
)
export default SvgPhone
