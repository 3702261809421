import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { PlatformSummary } from './platformSummary.types'

export class PlatformSummaryState implements PlatformSummary {
  error: RequestError = null
  fetchPlatformSummaryStatus: RequestStatus = RequestStatus.Idle
  gatheredCapital = ''
  rewards = ''
  investors = ''
}
