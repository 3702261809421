import type { SubgraphStakeTokenQueryResult } from '../../types/subgraphQueryTypes'

import type { StakeTokenData } from './stakeToken.types'

export const mapSubgraphStakeTokenQueryResultToStakeTokenData = (data: SubgraphStakeTokenQueryResult): StakeTokenData => {
  const base = {
    totalSupply: data.totalSupply,
    symbol: data.symbol,
    rewardTokenERC20Addreses: data.rewardTokenERC20.map(({ address }) => address),
    projectTokenAddress: data.projectToken.address,
    name: data.name,
    id: data.id,
    decimals: Number(data.decimals),
    address: data.address,
  }

  if (data.rewardStablecoin) base.rewardTokenERC20Addreses.push(data.rewardStablecoin.address)

  return base
}
