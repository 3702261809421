// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgYoutube = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21.583 6.373c-.229-1.004-.907-1.796-1.769-2.068C18.255 3.818 12 3.818 12 3.818s-6.252 0-7.814.487c-.859.268-1.537 1.06-1.769 2.068C2 8.195 2 12 2 12s0 3.805.417 5.627c.229 1.004.907 1.796 1.769 2.068 1.562.487 7.814.487 7.814.487s6.255 0 7.814-.487c.859-.268 1.537-1.06 1.769-2.068C22 15.805 22 12 22 12s0-3.805-.417-5.627Z"
    />
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m10.002 15 5.196-3-5.196-3v6Z"
    />
  </svg>
)
export default SvgYoutube
