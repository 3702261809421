import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { mosaicoAssetsActions } from '../mosaicoAssets.slice'
import type { FetchAllMosaicoAssetsResult } from '../mosaicoAssets.types'
import { GetAllMosaicoAssetsContentByLanguage } from '../../../../staticContent'
import { navigationSelectors } from '../../navigation/navigation.selectors'
import type { Language } from '../../../types/common'

export function fetchAllMosaicoAssets(language: Language): FetchAllMosaicoAssetsResult {
  return GetAllMosaicoAssetsContentByLanguage(language) as FetchAllMosaicoAssetsResult
}
export function* fetchAllMosaicoAssetsInfoSaga(): SagaGenerator<void> {
  yield* put(mosaicoAssetsActions.fetchAllMosaicoAssets())

  try {
    const currentLocale = yield* select(navigationSelectors.selectCurrentLocalisation)
    const staticMosaicoAssets = yield* call(fetchAllMosaicoAssets, currentLocale)

    yield* put(mosaicoAssetsActions.fetchAllMosaicoAssetsSuccess(staticMosaicoAssets))
  } catch (error) {
    if (error instanceof Error) yield* put(mosaicoAssetsActions.fetchAllMosaicoAssetsError(error.message))
  }
}
