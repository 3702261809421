import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { TestTokenUSDC } from '@sapiency-io/mosaico-contracts/dist'

import { cryptocurrenciesActions } from '../../cryptocurrencies.slice'
import { web3Selectors } from '../../../web3/web3.selectors'
import { cryptocurrenciesSelectors } from '../../cryptocurrencies.selectors'

export function* mintUSDCSaga(): SagaGenerator<void> {
  try {
    const selectWeb3Account = yield* select(web3Selectors.selectWeb3Account)
    const USDC_TEST_CONTRACT: TestTokenUSDC = yield* select(cryptocurrenciesSelectors.selectUSDCContract) as SagaGenerator<TestTokenUSDC>

    if (!selectWeb3Account) throw new Error('No account selected')

    const tx = yield* call([USDC_TEST_CONTRACT, USDC_TEST_CONTRACT.mintTo], selectWeb3Account, 1_000_000_000)
    yield* call([tx, tx.wait])

    yield* put(cryptocurrenciesActions.mintUSDCSuccess())
  } catch (error) {
    if (error instanceof Error) yield* put(cryptocurrenciesActions.mintUSDCFailure(error.message))
  }
}
