import type { ProjectContentData, ProjectPageData, ProjectDescriptionsData, RewardDescriptionData } from './projectContent.types'

export const isProjectContent = (project: ProjectContentData | undefined): project is ProjectContentData => {
  return project?.projectId !== undefined
}

export const isProjectPageData = (projectPage: ProjectPageData | undefined): projectPage is ProjectPageData => {
  return projectPage?.projectPage !== undefined
}

export const isProjectDescriptionsData = (
  projectDescriptions: ProjectDescriptionsData | undefined
): projectDescriptions is ProjectDescriptionsData => {
  return projectDescriptions?.shortDescription !== undefined
}

export const isProjectRewardsDescriptionsData = (
  projectRewardsDescriptions: RewardDescriptionData | undefined
): projectRewardsDescriptions is RewardDescriptionData => {
  return projectRewardsDescriptions?.description !== undefined
}
