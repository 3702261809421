import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { projectBaseActions } from '../../projectBase.slice'
import type { FetchSingleProjectResult } from '../../projectBase.types'
import { OneProjectBaseQueryResponse } from '../../../../../staticContent'
import { navigationSelectors } from '../../../navigation/navigation.selectors'

export function fetchSingleProjectBase(slug: string): FetchSingleProjectResult {
  return OneProjectBaseQueryResponse(slug) as FetchSingleProjectResult
}
export function* fetchSingleProjectBaseSaga(): SagaGenerator<void> {
  try {
    const currentSlug = yield* select(navigationSelectors.selectCurrentSlug)

    const staticProject = yield* call(fetchSingleProjectBase, currentSlug)

    yield* put(projectBaseActions.fetchSingleProjectSuccess(staticProject))
  } catch (error) {
    if (error instanceof Error) yield* put(projectBaseActions.fetchSingleProjectError(error.message))
  }
}
