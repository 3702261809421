import {
  ERC20__factory,
  Fundraise__factory,
  ProjectToken__factory,
  RewardTokenERC1155__factory,
  RewardTokenERC20__factory,
  StakeToken__factory,
  UpgradeHelper__factory,
} from '@sapiency-io/mosaico-contracts/dist'
import type { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { InjectedConnector } from '@web3-react/injected-connector'

import { WalletTypeEnum } from '../../graphql/generated/graphql'
import { ParticleNetworkConnector } from '../../utils/connectors/particleNetwork'

export const getProjectToken = (address: string, signer: any) => {
  return ProjectToken__factory.connect(address, signer)
}

export const getStakeToken = (address: string, signer: any) => {
  return StakeToken__factory.connect(address, signer)
}

export const getFundraise = (address: string, signer: any) => {
  return Fundraise__factory.connect(address, signer)
}

export const getRewardTokenERC20 = (address: string, signer: any) => {
  return RewardTokenERC20__factory.connect(address, signer)
}

export const getRewardTokenERC1155 = (address: string, signer: any) => {
  return RewardTokenERC1155__factory.connect(address, signer)
}

export const getERC20 = (address: string, signer: any) => {
  return ERC20__factory.connect(address, signer)
}

export const getUpgradeHelper = (address: string, signer: any) => {
  return UpgradeHelper__factory.connect(address, signer)
}

export const getWeb3WalletType = (web3: Web3ReactContextInterface): WalletTypeEnum | null => {
  if (web3.connector instanceof InjectedConnector) return WalletTypeEnum.Metamask
  if (web3.connector instanceof ParticleNetworkConnector) return WalletTypeEnum.ParticleNetwork

  return null
}
