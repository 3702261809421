import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { rewardsActions } from './rewards.slice'
import { claimRewardsSaga } from './sagas/claimRewards/claimRewards'
import { calculateRewardsActionHandler, claimRewardsSuccessActionHandler } from './sagas/calculateRewards/calculateRewards'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(rewardsActions.claimRewards.type, claimRewardsSaga),
    takeLatest(rewardsActions.calculateRewards.type, calculateRewardsActionHandler),
    takeLatest(
      [rewardsActions.claimLiquidStakeRewardsSuccess.type, rewardsActions.claimTimeStakeRewardsSuccess.type],
      claimRewardsSuccessActionHandler
    ),
  ])
}

export function* rewardsSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
