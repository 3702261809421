import type { Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'
import type { CustomTypographyVariants } from '../typography'

declare module '@mui/material/Button/Button' {
  interface ButtonPropsVariantOverrides {
    link: true
  }
  interface ButtonPropsColorOverrides {
    white: true
    pale: true
  }
}

export const getButton = (palette: CustomPalette, typography: CustomTypographyVariants): Components<Theme> => ({
  MuiButton: {
    variants: [
      {
        style: {
          color: palette._text[600],
          background: palette._white,
          '&:hover': {
            background: palette._fill[200],
          },
          '&:focus-visible': {
            boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
            background: palette._fill[200],
          },
          '&:disabled': {
            color: palette._text[400],
            background: palette._fill[100],
          },
          '&:active': {
            background: palette._fill[300],
          },
        },
        props: { variant: 'contained', color: 'white' },
      },
      // {
      //   style: {
      //     color: palette._text[600],
      //     border: `1px solid ${palette._stroke[300]}`,
      //     background: palette._white,
      //
      //     '&:hover': {
      //       color: palette._primary[400],
      //       border: `1px solid ${palette._primary[300]}`,
      //     },
      //     '&:focus-visible': {
      //       color: palette._primary[400],
      //       border: `1px solid ${palette._primary[300]}`,
      //       background: palette._primary[100],
      //     },
      //     '&:disabled': {
      //       color: palette._shade[600],
      //       border: `1px solid ${palette._stroke[100]}`,
      //       background: palette._white,
      //     },
      //     '&:active': {
      //       color: palette._primary[400],
      //       border: `1px solid ${palette._primary[300]}`,
      //       background: palette._primary[100],
      //     },
      //   },
      //   props: { variant: 'outlined', color: 'secondary' },
      // },
      {
        style: {
          color: palette._text[600],
          border: `1px solid ${palette._stroke[300]}`,
          background: palette._white,

          '&:hover': {
            border: `1px solid ${palette._stroke[500]}`,
            background: palette._shade[100],
          },
          '&:focus-visible': {
            boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
            border: `1px solid ${palette._stroke[400]}`,
            background: palette._fill[100],
          },
          '&:disabled': {
            color: palette._shade[600],
            border: `1px solid ${palette._shade[300]}`,
          },
          '&:active': {
            border: `1px solid ${palette._stroke[500]}`,
            background: palette._shade[200],
          },
        },
        props: { variant: 'outlined', color: 'pale' },
      },
      {
        style: {
          ...typography.link,
          padding: 0,
          fontWeight: 600,
          color: palette._text[600],
          background: palette._transparent,
          '&:hover': {
            color: palette._primary[400],
            background: palette._transparent,
          },
          '&:focus-visible': {
            padding: '0px 8px ',
            boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
            background: palette._transparent,
          },
          '&:disabled': {
            color: palette._shade[600],
            background: palette._transparent,
          },
          '&:active': {
            color: palette._primary[500],
            background: palette._transparent,
          },
        },
        props: { variant: 'link', color: 'primary' },
      },
      {
        style: {
          ...typography.link,
          padding: 0,
          color: palette._text[600],
          background: palette._transparent,
          '&:hover': {
            color: palette._primary[400],
            background: palette._transparent,
          },
          '&:focus-visible': {
            padding: '0px 8px ',
            boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
            background: palette._transparent,
          },
          '&:disabled': {
            color: palette._shade[600],
            background: palette._transparent,
          },
          '&:active': {
            color: palette._primary[500],
            background: palette._transparent,
          },
        },
        props: { variant: 'link', color: 'secondary' },
      },
      {
        style: {
          ...typography.link,
          padding: 0,
          color: palette._white,
          background: palette._transparent,

          '&:hover,&:active,&:focus-visible': {
            textDecoration: 'none',
            color: palette._stroke[200],
            background: palette._transparent,
          },
          '&:disabled': {
            color: palette._shade[600],
            background: palette._transparent,
          },
        },
        props: { variant: 'link', color: 'white' },
      },
    ],
    styleOverrides: {
      textSecondary: {
        color: palette._white,
        background: palette._tint[400],

        '&:hover': {
          color: palette._white,
          background: palette._tint[500],
        },

        '&:focus-visible': {
          color: palette._white,
          boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
          background: palette._tint[500],
        },

        '&:disabled': {
          color: palette._tint[600],
          background: palette._tint[300],
        },

        '&:active': {
          color: palette._white,
          background: palette._tint[600],
        },
      },

      textPrimary: {
        color: palette._text[600],
        background: palette._transparent,

        '&:hover': {
          color: palette._primary[400],
          background: palette._primary[100],
        },

        '&:focus-visible': {
          color: palette._text[600],
          boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
          background: palette._fill[100],
        },

        '&:disabled': {
          color: palette._shade[600],
        },

        '&:active': {
          color: palette._primary[400],
          background: palette._primary[200],
        },
      },

      sizeSmall: {
        ...typography.buttonSm,
        padding: '8px 24px',
      },

      sizeMedium: {
        ...typography.buttonMd,

        padding: '12px 32px',
      },

      sizeLarge: {
        ...typography.buttonLg,
        padding: '16px 40px',
      },
      root: {
        textTransform: 'none',
        minWidth: 'unset',
        borderRadius: '32px',
      },
      outlinedSecondary: {
        color: palette._white,
        border: `1px solid ${palette._white}`,
        background: palette._transparent,

        '&:hover': {
          border: `1px solid ${palette._white}`,
          background: palette._tint[200],
        },
        '&:focus-visible': {
          boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
          border: `1px solid ${palette._white}`,
          background: palette._tint[200],
        },
        '&:disabled': {
          color: palette._tint[600],
          border: `1px solid ${palette._tint[100]}`,
        },
        '&:active': {
          border: `1px solid ${palette._white}`,
          background: palette._tint[400],
        },
      },
      outlinedPrimary: {
        color: palette._text[600],
        border: `1px solid ${palette._black}`,
        background: palette._transparent,

        '&:hover': {
          color: palette._white,
          border: `1px solid ${palette._text[600]}`,
          background: palette._text[600],
        },
        '&:focus-visible': {
          color: palette._white,
          boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
          border: `1px solid ${palette._text[600]}`,
          background: palette._text[600],
        },
        '&:disabled': {
          color: palette._shade[600],
          border: `1px solid ${palette._shade[300]}`,
        },
        '&:active': {
          color: palette._white,
          border: `1px solid ${palette._black}`,
          background: palette._black,
        },
      },
      containedSecondary: {
        color: palette._text[600],
        background: `
            linear-gradient(270deg, ${palette._gradientColors.orange} 0,  ${palette._gradientColors.lightOrange} 100%)
        `,

        '&:hover': {
          background: `
                linear-gradient(270deg, ${palette._secondary[500]} 0,  ${palette._secondary[300]} 100%)
            `,
        },
        '&:focus-visible': {
          boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
          background: `
                linear-gradient(270deg, ${palette._secondary[500]} 0,  ${palette._secondary[300]} 100%)
            `,
        },
        '&:disabled': {
          color: palette._shade[600],
          background: palette._stroke[300],
        },
        '&:active': {
          background: `
                linear-gradient(270deg, ${palette._secondary[600]} 0,  ${palette._secondary[400]} 100%)
            `,
        },
      },
      containedPrimary: {
        color: palette._white,
        background: `
            linear-gradient(270deg, ${palette._gradientColors.blue} 0,  ${palette._gradientColors.lightBlue} 100%)
        `,

        '&:hover': {
          background: `
                linear-gradient(270deg, ${palette._primary[500]} 0,  ${palette._primary[300]} 100%)
            `,
        },
        '&:focus-visible': {
          boxShadow: `0 0 0 1px ${palette._white}, 0 0 0 3px ${palette._primary[300]}`,
          background: `
                linear-gradient(270deg, ${palette._primary[500]} 0,  ${palette._primary[300]} 100%)
            `,
        },
        '&:disabled': {
          color: palette._shade[600],
          background: palette._stroke[300],
        },
        '&:active': {
          background: `
                linear-gradient(270deg, ${palette._primary[600]} 0,  ${palette._primary[400]} 100%)
            `,
        },
      },
    },
    defaultProps: {
      disableTouchRipple: true,
      disableRipple: true,
      disableFocusRipple: true,
      disableElevation: true,
    },
  },
})
