import type { Palette } from '@mui/material'

import type { CustomPalette } from '.'

export const extraPalette: Pick<
  CustomPalette,
  '_vividSkyBlue' | '_turquoiseCyan' | '_theNewBlack' | '_snakeFruit' | '_niblue' | '_kingNacho' | '_gold'
> = {
  _vividSkyBlue: {
    900: '#002474',
    800: '#002F97',
    700: '#003ABA',
    600: '#0045DC',
    500: '#0050FF',
    400: '#2368FF',
    300: '#4580FF',
    200: '#6897FF',
    100: '#8BAFFF',
    0: '#9CBBFF',
  },
  _turquoiseCyan: {
    900: '#073A29',
    800: '#094B35',
    700: '#0B5C42',
    600: '#0D6E4E',
    500: '#0F7F5A',
    400: '#309070',
    300: '#50A287',
    200: '#71B39D',
    100: '#92C5B4',
    0: '#A2CDBF',
  },
  _theNewBlack: {
    900: '#743B00',
    800: '#974D00',
    700: '#BA5F00',
    600: '#DC7000',
    500: '#FF8200',
    400: '#FF9323',
    300: '#FFA445',
    200: '#FFB568',
    100: '#FFC68B',
    0: '#FFCF9C',
  },
  _snakeFruit: {
    900: '#620E09',
    800: '#7F120B',
    700: '#9D170E',
    600: '#BA1B10',
    500: '#D71F13',
    400: '#DC3D33',
    300: '#E25C53',
    200: '#E77A73',
    100: '#ED9993',
    0: '#EFA8A3',
  },
  _niblue: {
    900: '#005B73',
    800: '#007696',
    700: '#0092B8',
    600: '#00ADDB',
    500: '#00C8FD',
    400: '#23CFFD',
    300: '#45D7FE',
    200: '#68DEFE',
    100: '#8BE6FE',
    0: '#9CEAFE',
  },
  _kingNacho: {
    900: '#745400',
    800: '#976D00',
    700: '#BA8600',
    600: '#DC9F00',
    500: '#FFB800',
    400: '#FFC223',
    300: '#FFCB45',
    200: '#FFD568',
    100: '#FFDF8B',
    0: '#FFE39C',
  },
  _gold: {
    900: '#746200',
    800: '#977F00',
    700: '#BA9D00',
    600: '#DCBA00',
    500: '#FFD700',
    400: '#FFDC23',
    300: '#FFE245',
    200: '#FFE768',
    100: '#FFED8B',
    0: '#FFEF9C',
  },
}
