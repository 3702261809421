import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { investmentSelectors } from '../investment.selectors'
import { TransactionType } from '../../transactions/transaction.types'
import { investTokenInContractTransactionSaga } from '../../fundraise/saga/invest/investTokenInContractTransaction.saga'
import { executeContractTransactionSaga } from '../../transactions/saga/executeContractTransaction.saga'
import { investmentActions } from '../investment.slice'

import { approveContractTransactionSaga } from './approveContractTransaction.saga'
import { createInvestmentTransactionsPlanSaga } from './createInvestmentTransactionsPlanSaga.saga'

export function* cryptoInvestmentSaga(): SagaGenerator<void> {
  yield* put(investmentActions.cryptoInvestment())
  const investmentAmountData = yield* select(investmentSelectors.selectInvestmentAmountData)
  const investmentSetupData = yield* select(investmentSelectors.selectInvestmentSetupData)
  if (!investmentAmountData || !investmentSetupData) throw new Error('Investment data not provided')

  const investmentTransactionsPlan = yield* call(createInvestmentTransactionsPlanSaga)

  try {
    if (!investmentTransactionsPlan.length) {
      yield* put(investmentActions.cryptoInvestmentFailure())
      return
    }

    for (const transaction of investmentTransactionsPlan) {
      if (transaction.type === TransactionType.Approve)
        yield* call(
          executeContractTransactionSaga,
          transaction.id,
          call(
            approveContractTransactionSaga,
            investmentSetupData.tokenInAddress,
            investmentSetupData.fundraiseId,
            String(investmentAmountData.tokenInAmount)
          )
        )

      if (transaction.type === TransactionType.Invest)
        yield* call(
          executeContractTransactionSaga,
          transaction.id,
          call(
            investTokenInContractTransactionSaga,
            investmentSetupData.fundraiseId,
            String(investmentAmountData.tokenInAmount),
            String(investmentAmountData.tokenOutAmount)
          )
        )
    }

    yield* put(investmentActions.cryptoInvestmentSuccess())
  } catch {
    yield* put(investmentActions.cryptoInvestmentFailure())
  }
}
