import { utils } from 'ethers'
import type { SagaGenerator } from 'typed-redux-saga'
import { select, put, call } from 'typed-redux-saga'

import { selectSigner, selectWeb3Account } from '../../../web3/web3.selectors'
import { getProjectToken } from '../../../web3/web3.utils'
import type { ProjectTokenActions } from '../../projectToken.slice'
import { projectTokenActions } from '../../projectToken.slice'

export function* investSaga({ payload }: ProjectTokenActions['invest']): SagaGenerator<void> {
  const account = yield* select(selectWeb3Account)
  if (!account) return

  try {
    const signer = yield* select(selectSigner)
    const projectToken = yield* call(getProjectToken, payload.tokenAddress, signer)

    const parsedAmount = utils.parseUnits(payload.amount, payload.tokenDecimals)

    const tx = yield* call(projectToken.mint, account, parsedAmount)
    yield* call(tx.wait)

    yield* put(projectTokenActions.investSuccess())
  } catch (error) {
    if (error instanceof Error) yield* put(projectTokenActions.investFailure(error.message))
  }
}
