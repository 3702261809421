// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgIconsTokenPrice = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m11.89 12.11.55-.55m-3.87 3.87-.55.55m-.007-1.909c-.091.377-.009.783.286 1.078l.605.605a1.096 1.096 0 0 0 1.715-1.338l-.775-1.294a1.097 1.097 0 0 1 1.715-1.338l.605.605c.294.294.376.699.287 1.075m3.356-5.271a.371.371 0 1 1-.524.524.371.371 0 0 1 .524-.524ZM13.512 4h4.385C19.059 4 20 4.941 20 6.102v4.385c0 .558-.222 1.092-.616 1.487l-8.41 8.41a2.102 2.102 0 0 1-2.973 0l-4.385-4.385a2.102 2.102 0 0 1 0-2.973l8.41-8.41A2.103 2.103 0 0 1 13.512 4Z"
    />
  </svg>
)
export default SvgIconsTokenPrice
