const CODE_RE = 'code='
const STATE_RE = 'state='
const ERROR_RE = 'error='
const SUPPORT_SIGN_UP = 'supportSignUp='
const SUPPORT_FORGOT_PASSWORD = 'supportForgotPassword='
const MESSAGE = 'message='
const SUCCESS = 'success='

export const LOGOUT_PARAM = 'logout'

export const hasLogoutParam = (searchParams = window.location.search): boolean => searchParams.includes(LOGOUT_PARAM)

export const hasCodeParams = (searchParams = window.location.search): boolean => searchParams.includes('code')

export const hasStateParams = (searchParams = window.location.search): boolean => searchParams.includes('state')

export const hasAuthParams = (searchParams = window.location.search): boolean => hasCodeParams(searchParams) || hasStateParams(searchParams)

export const hasAnyParams = (path: string): boolean =>
  path.includes(CODE_RE || STATE_RE || ERROR_RE || SUPPORT_SIGN_UP || SUPPORT_FORGOT_PASSWORD || MESSAGE || SUCCESS)
