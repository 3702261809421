import type { SagaGenerator } from 'typed-redux-saga'
import { call } from 'typed-redux-saga'

import { staticActions, type StaticActions } from '../../static.slice'
import { defaultStaticSetupSaga } from '../defaultStaticSetup/defaultStaticSetup'
import { fetchAllClosedProjectsBaseSaga } from '../../../closedProjectBase/sagas/fetchAllClosedProjects/fetchAllClosedProjects'

export function* raiseCapitalStaticSetupSaga({ payload }: StaticActions['startRaiseCapitalStaticSetup']): SagaGenerator<void> {
  const { context } = payload

  yield* call(defaultStaticSetupSaga, staticActions.startDefaultStaticSetup({ context }))

  yield* call(fetchAllClosedProjectsBaseSaga)
}
