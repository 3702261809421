import type { Components, Theme } from '@mui/material'

export const getRadio = (): Components<Theme> => ({
  MuiRadio: {
    defaultProps: {
      disableTouchRipple: true,
      disableRipple: true,
      disableFocusRipple: true,
    },
  },
})
