import type { SagaGenerator } from 'typed-redux-saga'
import { put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { StakeTokenData } from '../../stakeToken.types'
import { stakeTokenActions } from '../../stakeToken.slice'
import type { SubgraphStakeTokenQueryResult } from '../../../../types/subgraphQueryTypes'
import { mapSubgraphStakeTokenQueryResultToStakeTokenData } from '../../stakeToken.mappers'
import { projectTokenSelectors } from '../../../projectToken/projectToken.selectors'
import { STAKE_TOKENS_FOR_PROJECT } from '../../stakeToken.queries'

export async function fetchSubgraphForProjectStakeTokens(client: GraphQLClient, projectTokenAddress: string): Promise<StakeTokenData[]> {
  const result = await client.rawRequest<{ stakeTokens: SubgraphStakeTokenQueryResult[] }>(STAKE_TOKENS_FOR_PROJECT, {
    projectAddress: projectTokenAddress,
  })
  return result.data.stakeTokens.map(mapSubgraphStakeTokenQueryResultToStakeTokenData)
}

export function* fetchSubgraphForProjectStakeTokensSaga(): SagaGenerator<void> {
  try {
    const projectToken = yield* select(projectTokenSelectors.selectCurrent)

    if (!projectToken) throw new Error('Project token not found')

    const stakeTokens = yield* subgraphCall(fetchSubgraphForProjectStakeTokens, projectToken.address)

    yield* put(stakeTokenActions.fetchSubgraphForProjectSuccess(stakeTokens))
  } catch (error) {
    if (error instanceof Error) yield* put(stakeTokenActions.fetchSubgraphForProjectFailure(error.message))
  }
}
