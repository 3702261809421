import type { SubgraphTranche, TrancheData } from './tranches.types'

export enum TrancheAction {
  none,
  mint,
  vest,
}
export const isTranche = (tranche: any): tranche is TrancheData =>
  tranche.id !== undefined &&
  tranche.trancheAction !== undefined &&
  tranche.account !== undefined &&
  tranche.amount !== undefined &&
  tranche.start !== undefined &&
  tranche.duration !== undefined &&
  tranche.name !== undefined

export const emptySubgraphTranche: SubgraphTranche = {
  trancheAction: 0,
  tokenAddress: '',
  start: 0,
  id: '',
  duration: 0,
  amount: '0',
  account: '',
}
