import type { Breakpoints, Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'
import type { CustomTypographyVariants } from '../typography'

export const getTable = (palette: CustomPalette, _: CustomTypographyVariants, breakpoints: Breakpoints): Components<Theme> => ({
  MuiTableHead: {
    styleOverrides: {
      root: {
        '.MuiTableCell-root': {
          lineHeight: '120%',
          letterSpacing: '-0.12px',
          fontWeight: 600,
          fontStyle: 'normal',
          fontSize: '12px' /* 14.4px */,
          color: palette._text[500],
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '12px',
        [breakpoints.up('md')]: {
          padding: '24px',
        },
        borderColor: palette._stroke[400],
      },
    },
  },
})
