import React from 'react'

interface IUseComponentActionsProps {
  onMouseDown?: React.MouseEventHandler<HTMLElement> | undefined
  onMouseUp?: React.MouseEventHandler<HTMLElement> | undefined
  onMouseEnter?: React.MouseEventHandler<HTMLElement> | undefined
  onMouseLeave?: React.MouseEventHandler<HTMLElement> | undefined
  onFocus?: React.FocusEventHandler<HTMLElement> | undefined
  onBlur?: React.FocusEventHandler<HTMLElement> | undefined
  disabled?: boolean
}
export const useHTMLElementEvents = ({
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  disabled,
}: IUseComponentActionsProps) => {
  const [hovered, setHovered] = React.useState(false)
  const [focusVisible, setFocusVisible] = React.useState(false)
  const [pressed, setPressed] = React.useState(false)

  React.useEffect(() => {
    if (disabled) {
      setPressed(false)
      setFocusVisible(false)
      setHovered(false)
    }
  }, [disabled])

  const handleMouseEnter = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onMouseEnter && onMouseEnter(event)
      setHovered(true)
    },
    [onMouseEnter]
  )

  const handleMouseLeave = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onMouseLeave && onMouseLeave(event)
      setHovered(false)
    },
    [onMouseLeave]
  )

  const handleFocus = React.useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      onFocus && onFocus(event)
      setFocusVisible(true)
    },
    [onFocus]
  )

  const handleBlur = React.useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      onBlur && onBlur(event)
      setFocusVisible(false)
    },
    [onBlur]
  )

  const handleMouseUp = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onMouseUp && onMouseUp(event)
      setPressed(false)
    },
    [onMouseUp]
  )

  const handleMouseDown = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onMouseDown && onMouseDown(event)
      document.addEventListener('mouseup', () => setPressed(false), { once: true })
      setPressed(true)
    },
    [onMouseDown]
  )

  return {
    pressed,
    hovered,
    handleMouseUp,
    handleMouseLeave,
    handleMouseEnter,
    handleMouseDown,
    handleFocus,
    handleBlur,
    focusVisible,
  }
}
