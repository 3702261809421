// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgColourPalette = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.72 7.93a2 2 0 0 1 2.353.89l1.653 2.827a1.998 1.998 0 0 1-.736 2.746l-10.052 5.73A6.682 6.682 0 0 1 6.626 21M6.5 21a3.5 3.5 0 0 0 3.5-3.5V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12.5A3.5 3.5 0 0 0 6.5 21Zm0 0a4.693 4.693 0 0 0 4.053-2.324l5.95-10.174a2 2 0 0 0-.736-2.747L12.881 4.11a2 2 0 0 0-2.717.728l-.164.28M10 9H3m7 6H3"
    />
  </svg>
)
export default SvgColourPalette
