import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'

import type { Web3Actions } from '../web3.slice'
import { web3Actions } from '../web3.slice'
import { getLatestWalletAddress, getLatestWalletType } from '../../session/session.utils'
import { ParticleNetworkConnector } from '../../../utils/connectors/particleNetwork'
import { WalletTypeEnum } from '../../../graphql/generated/graphql'
import { walletSelectors } from '../../wallet/wallet.selectors'

import { activateWeb3Saga } from './activateWeb3.saga'

export const getConnectorWalletType = (connector: AbstractConnector): WalletTypeEnum | null => {
  if (connector instanceof InjectedConnector) return WalletTypeEnum.Metamask
  if (connector instanceof ParticleNetworkConnector) return WalletTypeEnum.ParticleNetwork

  return null
}

export function* web3InitializationRequestSaga({ payload }: Web3Actions['web3InitializationRequest']): SagaGenerator<void> {
  const { web3 } = payload

  const wallets = yield* select(walletSelectors.selectAll)

  try {
    if (web3)
      if (web3.active && web3.connector) {
        const walletType = getConnectorWalletType(web3.connector)
        yield* put(web3Actions.web3InitializationSuccess({ web3, walletType }))
      } else {
        const latestWalletType = yield* call(getLatestWalletType)
        const latestWalletAddress = yield* call(getLatestWalletAddress)

        if (wallets.some(({ walletType }) => walletType === latestWalletType))
          yield* call(activateWeb3Saga, web3, latestWalletType, latestWalletAddress)
        else yield* call(activateWeb3Saga, web3, WalletTypeEnum.ParticleNetwork)
      }
  } catch (error) {
    if (error instanceof Error) yield* put(web3Actions.web3InitializationFailure(error.message))
  }
}
