// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgBlockchain = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 10.432V8.177a2 2 0 0 1 1.029-1.749L6 5.333m9 15-2.029 1.127a1.999 1.999 0 0 1-1.943 0L9 20.333m12-6.765v2.255a2 2 0 0 1-1.029 1.748L18 18.667m3-8.235V8.177a2 2 0 0 0-1.029-1.748L18 5.333M9 3.667l2.029-1.127a1.999 1.999 0 0 1 1.943 0L15 3.667M3 13.568v2.255a2 2 0 0 0 1.029 1.748L6 18.667m2.757-8.045a.72.72 0 0 1 .371-.63l2.522-1.401a.72.72 0 0 1 .7 0l2.522 1.401a.72.72 0 0 1 .371.63v2.756a.72.72 0 0 1-.371.63l-2.522 1.401a.72.72 0 0 1-.7 0l-2.522-1.401a.72.72 0 0 1-.371-.63v-2.756Z"
    />
  </svg>
)
export default SvgBlockchain
