export enum InvestmentStep {
  Idle,
  Setup,
  Amount,
  PaymentMethod,
  Transaction,
  TransactionResult,
}

export enum PaymentMethod {
  CryptoWallet = 'crypto-wallet',
  Ramp = 'ramp',
}

export enum LegalDocuments {
  Regulations = 'Regulations',
  PrivacyPolicy = 'Privacy policy',
}

export enum Agreement {
  RegulationsAndPrivacyPolicy = 'regulations-and-privacy-policy',
  PersonalData = 'personal-data',
  NewsletterAndMarketing = 'newsletter-and-marketing',
}
