import { gql } from 'graphql-request'

export const FUNDRAISE_FRAGMENT = gql`
  fragment fundraise on Fundraise {
    id
    name
    address
    softCap
    hardCap
    activePhaseIndex
    phasesCounter
    activeMilestone
    totalFundsRaised
    fundraiseFee
    expiryDate
    feeWalletAddress
    projectWalletAddress
    totalTokensSold
    projectToken {
      address
    }
    stablecoin
    phases {
      id
    }
    stage
    investorsCounter
    tokenMaxSupply
  }
`

export const MULTIPLE_FUNDRAISES_QUERY = gql`
  query multipleFundraises($addresses: [String!]) {
    fundraises(where: { address_in: $addresses }) {
      ...fundraise
    }
  }
  ${FUNDRAISE_FRAGMENT}
`
export const SINGLE_FUNDRAISE_QUERY = gql`
  query fundraise($address: String!) {
    fundraises(where: { address: $address }) {
      ...fundraise
    }
  }
  ${FUNDRAISE_FRAGMENT}
`
