import type { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types'
import { AbstractConnector } from '@web3-react/abstract-connector'
import type { ConnectOptions } from '@particle-network/auth-core-modal'
import type { EVMProvider } from '@particle-network/auth'
import { type UserInfo } from '@particle-network/auth-core'

import { SUPPORTED_CHAINS } from '../../store/web3/web3.constants'
import envConfig from '../../../config/env'

type ParticleNetworkConnectorProps = {
  accessToken: string | undefined | null
  provider: EVMProvider
  address: string | null
  chainId: number
  connect: (options?: ConnectOptions) => Promise<UserInfo | undefined>
  disconnect: () => Promise<void>
}

export class ParticleNetworkConnector extends AbstractConnector {
  accessToken: string | undefined | null
  provider: EVMProvider
  address: string | null = null
  chainId: number
  connect: ((options?: ConnectOptions) => Promise<UserInfo | undefined>) | null = null
  disconnect: (() => Promise<void>) | null = null

  constructor(abstractConnectorArguments: AbstractConnectorArguments) {
    super(abstractConnectorArguments)
    this.chainId = Number(envConfig.chainId)
  }

  public setProperties = (props: Partial<ParticleNetworkConnectorProps>) => {
    if (props.accessToken) this.accessToken = props.accessToken
    if (props.provider) this.provider = props.provider
    if (props.address) this.address = props.address
    if (props.connect) this.connect = props.connect
    if (props.disconnect) this.disconnect = props.disconnect
  }

  public activate = async (): Promise<ConnectorUpdate> => {
    if (!this.accessToken || !this.connect) return { provider: this.getProvider(), account: '' }

    const userInfo = await this.connect({
      jwt: this.accessToken,
      chain: { name: 'Polygon', id: this.chainId },
    })

    return { provider: this.getProvider(), account: userInfo?.wallets[0].public_address }
  }

  public getProvider = (): any => {
    return this.provider
  }

  public getChainId = (): Promise<number | string> => {
    return Promise.resolve(this.chainId || Number(envConfig.chainId))
  }

  public getAccount = async (): Promise<string | null> => {
    return Promise.resolve(this.address)
  }

  public deactivate = async () => {
    this.disconnect && (await this.disconnect())
    this.accessToken = null
  }

  public isAuthorized = (): boolean => {
    return Boolean(this.address)
  }
}

export const particleNetworkConnector = new ParticleNetworkConnector({ supportedChainIds: SUPPORTED_CHAINS })
