import type { SagaGenerator } from 'typed-redux-saga'
import { put, select, call } from 'typed-redux-saga'

import { web3Selectors } from '../../../../web3/web3.selectors'
import { walletSelectors } from '../../../../wallet/wallet.selectors'
import { LegacyBalanceType } from '../../../balances.const'
import { legacyTokensSelectors } from '../../../../legacyTokens/legacyTokens.selectors'
import { balancesActions } from '../../../balances.slice'
import { BigDecimal } from '../../../../../utils/bigDecimal'

import { fetchLegacyBalancesForWallets } from './fetchLegacyBalances.utils'

export function* fetchLegacyRewardTokensBalancesSaga(): SagaGenerator<void> {
  try {
    const legacyRewardTokens = yield* select(legacyTokensSelectors.selectRewardTokens)
    const readOnlyProvider = yield* select(web3Selectors.selectReadOnlyProvider)
    const userWallets = yield* select(walletSelectors.selectAll)

    const rewardTokensBalances = yield* call(
      fetchLegacyBalancesForWallets,
      legacyRewardTokens.map((token) => token.address),
      userWallets.map((wallet) => wallet.ethereumAddress),
      readOnlyProvider,
      LegacyBalanceType.RewardToken
    )

    const filteredRewardTokensBalances = rewardTokensBalances.filter((balance) =>
      BigDecimal.fromString(balance.amount).gt(BigDecimal.from(0))
    )

    if (filteredRewardTokensBalances?.length) yield* put(balancesActions.fetchInternalBalancesSuccess(filteredRewardTokensBalances))
  } catch (error) {
    if (error instanceof Error) yield* put(balancesActions.fetchInternalBalancesError(error.message))
  }
}
