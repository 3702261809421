// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgShare = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.082 8.951a9.666 9.666 0 0 0-8.582 9.6v.612a11.4 11.4 0 0 1 8.582-4.117v3.227a1.222 1.222 0 0 0 1.979.959l7.99-6.309a1.18 1.18 0 0 0 0-1.852l-7.99-6.309a1.222 1.222 0 0 0-1.979.959v3.23Z"
    />
  </svg>
)
export default SvgShare
