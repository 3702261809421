import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { PlatformSummary, PlatformSummaryData } from './platformSummary.types'
import { PlatformSummaryState } from './platformSummary.state'

export const initialPlatformSummarySliceState: PlatformSummary = {
  ...new PlatformSummaryState(),
}

const hydrate = createAction<{ [StoreKeys.PlatformSummary]: PlatformSummary }>(HYDRATE)

export const platformSummarySlice = createSlice({
  reducers: {
    fetchPlatformSummarySuccess: (state, action: PayloadAction<PlatformSummaryData>) => {
      const { gatheredCapital, investors, rewards } = action.payload
      state.fetchPlatformSummaryStatus = RequestStatus.Succeeded
      state.gatheredCapital = gatheredCapital
      state.investors = investors
      state.rewards = rewards
    },
    fetchPlatformSummaryError: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.fetchPlatformSummaryStatus = RequestStatus.Failed
      return state
    },
    fetchPlatformSummary: (state) => {
      return state
    },
  },
  name: StoreKeys.PlatformSummary,
  initialState: initialPlatformSummarySliceState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { gatheredCapital, investors, rewards } = state

      if (gatheredCapital && investors && rewards) return state

      return {
        ...state,
        ...payload[StoreKeys.PlatformSummary],
      }
    }),
})

export const platformSummaryActions = platformSummarySlice.actions
export const platformSummaryReducer = platformSummarySlice.reducer
export type PlatformSummaryActions = ActionsType<typeof platformSummaryActions>
