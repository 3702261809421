import {
  ALL_FUNDRAISE_TOKEN_BALANCES_QUERY,
  ALL_PROJECT_TOKEN_BALANCES_QUERY,
  ALL_REWARD_TOKEN_ERC20_BALANCES_QUERY,
  ALL_STAKE_TOKEN_BALANCES_QUERY,
} from './balances.queries'
import type { FetchAllQueriesNames, TokenBalanceData } from './balances.types'

export enum InternalBalanceType {
  ProjectToken = 'ProjectToken',
  FundraiseToken = 'FundraiseToken',
  StakeToken = 'StakeToken',
  RewardTokenERC20 = 'RewardTokenERC20',
}

export enum LegacyBalanceType {
  ProjectToken = 'LegacyProjectToken',
  RewardToken = 'LegacyRewardToken',
}

export enum ExternalBalanceType {
  STABLECOIN = 'STABLECOIN',
  NATIVE = 'NATIVE',
}

export const FetchBalanceQueryNames: Record<InternalBalanceType, FetchAllQueriesNames> = Object.freeze({
  StakeToken: 'stakeTokenBalances',
  RewardTokenERC20: 'rewardTokenERC20Balances',
  ProjectToken: 'projectTokenBalances',
  FundraiseToken: 'fundTokenBalances',
})

export const FetchBalanceQuery: Record<InternalBalanceType, string> = Object.freeze({
  StakeToken: ALL_STAKE_TOKEN_BALANCES_QUERY,
  RewardTokenERC20: ALL_REWARD_TOKEN_ERC20_BALANCES_QUERY,
  ProjectToken: ALL_PROJECT_TOKEN_BALANCES_QUERY,
  FundraiseToken: ALL_FUNDRAISE_TOKEN_BALANCES_QUERY,
})

export const isTokenBalance = (tokenBalance: TokenBalanceData | undefined): tokenBalance is TokenBalanceData => {
  return tokenBalance?.account !== undefined
}
