import type { SagaGenerator } from 'typed-redux-saga'
import { put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { ProjectTokensBackendQueryResponseByAddress } from '../../../../../staticContent'
import type { BackendProjectTokenQueryResult } from '../../../../types/backendQueryTypes'
import type { BackendProjectTokenData, ProjectTokenData } from '../../projectToken.types'
import { mapBackendProjectTokenQueryResultToBackendProjectTokenData } from '../../projectToken.mappers'
import { projectTokenActions } from '../../projectToken.slice'
import { apiCall } from '../../../../utils/apiCall'
import { emptySupgraphProjectTokenData } from '../../projectToken.const'
import { projectTokenSelectors } from '../../projectToken.selectors'
import { fundraiseSelectors } from '../../../fundraise/fundraise.selectors'

export async function fetchBackendSingleProjectToken(client: GraphQLClient, projectTokenAddress: string): Promise<BackendProjectTokenData> {
  const result: BackendProjectTokenQueryResult | undefined = await Promise.resolve(
    ProjectTokensBackendQueryResponseByAddress(projectTokenAddress)
  )

  if (!result) throw new Error(`Project token with address ${projectTokenAddress} not found`)

  return mapBackendProjectTokenQueryResultToBackendProjectTokenData(result)
}

export function* fetchBackendSingleProjectTokenSaga(): SagaGenerator<void> {
  try {
    const currentFundraise = yield* select(fundraiseSelectors.selectCurrent)

    if (!currentFundraise) throw new Error('Fundraise not found')

    const backendProjectToken = yield* apiCall(fetchBackendSingleProjectToken, currentFundraise.projectTokenAddress)

    const mappedToProjectToken: ProjectTokenData = {
      ...emptySupgraphProjectTokenData,
      ...backendProjectToken,
    }

    yield* put(projectTokenActions.fetchBackendSingleSuccess(mappedToProjectToken))
  } catch (error) {
    if (error instanceof Error) yield* put(projectTokenActions.fetchBackendSingleFailure(error.message))
  }
}
