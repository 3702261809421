// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgClose = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke={props.color || '#25262D'} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m6 6 12 12m0-12L6 18" />
  </svg>
)
export default SvgClose
