import { AuthCoreContextProvider } from '@particle-network/auth-core-modal'
import type { PropsWithChildren } from 'react'
import React from 'react'

import { ParticleProviderUpdater } from '../ParticleProviderUpdater/ParticleProviderUpdater'

export const ParticleNetworkProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <AuthCoreContextProvider
      options={{
        wallet: { visible: false },
        projectId: process.env.PARTICLE_NETWORK_PROJECT_ID || '',
        clientKey: process.env.PARTICLE_NETWORK_CLIENT_KEY || '',
        appId: process.env.PARTICLE_NETWORK_APP_ID || '',
      }}
    >
      <ParticleProviderUpdater />
      {children}
    </AuthCoreContextProvider>
  )
}
