import { call, put, select } from 'typed-redux-saga'
import type { ERC20 } from '@sapiency-io/mosaico-contracts/dist'
import { v4 as uuid } from 'uuid'
import type { StrictEffect } from 'redux-saga/effects'
import type { ContractTransaction } from 'ethers'

import { type BalancesActions } from '../../balances.slice'
import { TransactionType, type TransactionBase } from '../../../transactions/transaction.types'
import { transactionsActions } from '../../../transactions/transaction.slice'
import { getERC20 } from '../../../web3/web3.utils'
import { selectSigner } from '../../../web3/web3.selectors'
import { executeContractTransactionSaga } from '../../../transactions/saga/executeContractTransaction.saga'
import { balancesSelectors } from '../../balances.selectors'

export function* transferTransaction(erc20: ERC20, to: string, amount: string): Generator<StrictEffect, ContractTransaction> {
  const tx = yield* call(erc20.transfer, to, amount)
  yield* call(tx.wait)
  return tx
}

export function* createAndExecuteERC20TransferTransactionPlan(payload: BalancesActions['transferBalances']['payload']) {
  const signer = yield* select(selectSigner)
  const { amount, cryptocurrency, to } = payload

  if (!signer) throw new Error('Signer is invalid')
  const token = getERC20(cryptocurrency.address, signer)
  const transferId = yield* select(balancesSelectors.selectTransferTransactionId)

  if (!transferId) throw new Error('Transfer transaction id is not defined')

  const transferTransactionData: TransactionBase = {
    type: TransactionType.Transfer,
    source: transferId,
    payload: {},
    id: uuid(),
  }

  yield* put(transactionsActions.addTransactions([transferTransactionData]))

  yield* call(executeContractTransactionSaga, transferTransactionData.id, call(transferTransaction, token, to, amount))
}
