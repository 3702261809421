import { createSelector } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import { selectReducer } from '../store.utils'
import { web3Selectors } from '../web3/web3.selectors'
import { RequestStatus } from '../types'
import { BigDecimal } from '../../utils/bigDecimal'
import { cryptocurrenciesSelectors } from '../cryptocurrencies/cryptocurrencies.selectors'
import { compareAddresses } from '../../utils/compareAddresses'
import { walletSelectors } from '../wallet/wallet.selectors'

import { balancesAdapterSelectors } from './balances.slice'
import { ExternalBalanceType, InternalBalanceType, LegacyBalanceType } from './balances.const'
import { sumBalancesDataForToken } from './balances.utils'

const selectState = createSelector([selectReducer(StoreKeys.Balances)], (state) => state)

const selectAllBalances = createSelector([selectState], (state) => balancesAdapterSelectors.selectAll(state))

const selectExternalBalances = createSelector([selectAllBalances], (balances) => {
  return balances.filter(
    (balance) => balance.balanceType === ExternalBalanceType.NATIVE || balance.balanceType === ExternalBalanceType.STABLECOIN
  )
})

const selectProjectsBalances = createSelector([selectAllBalances], (balances) => {
  return balances.filter(
    (balance) => balance.balanceType === InternalBalanceType.ProjectToken || balance.balanceType === InternalBalanceType.StakeToken
  )
})

const selectFundraisesBalances = createSelector([selectAllBalances], (balances) => {
  return balances.filter((balance) => balance.balanceType === InternalBalanceType.FundraiseToken)
})

export const selectStablecoinBalances = createSelector([selectAllBalances], (balances) => {
  return balances.filter((balance) => balance.balanceType === ExternalBalanceType.STABLECOIN)
})

export const selectUSDCBalances = createSelector([selectStablecoinBalances, cryptocurrenciesSelectors.selectUSDC], (balances, usdc) => {
  return balances.filter((balance) => compareAddresses(balance.tokenAddress, usdc?.address))
})

export const selectAreBalancesLoading = createSelector(
  [selectState, web3Selectors.selectWeb3ConnectionStatus],
  (state, _) => state.balancesBlockchainStatus === RequestStatus.Loading
)

export const selectUSDCSum = createSelector([selectUSDCBalances], (usdcBalances) =>
  usdcBalances
    .map((balance) => BigDecimal.fromString(balance.amount))
    .reduce((accumulator, amount) => accumulator.add(amount), BigDecimal.zero)
)

export const selectNativeBalances = createSelector([selectAllBalances], (balances) => {
  return balances.filter((balance) => balance.balanceType === ExternalBalanceType.NATIVE)
})

export const selectNativeBalanceSum = createSelector([selectNativeBalances], (nativeBalances) =>
  nativeBalances
    .map((balance) => BigDecimal.fromString(balance.amount))
    .reduce((accumulator, amount) => accumulator.add(amount), BigDecimal.zero)
)

export const selectFundraiseTokensBalances = createSelector([selectAllBalances], (balancesData) =>
  balancesData.filter((balanceData) => balanceData.balanceType === InternalBalanceType.FundraiseToken)
)

export const selectProjectTokensBalances = createSelector([selectAllBalances], (balancesData) =>
  balancesData.filter((balanceData) => balanceData.balanceType === InternalBalanceType.ProjectToken)
)

export const selectStakeTokensBalances = createSelector([selectAllBalances], (balancesData) =>
  balancesData.filter((balanceData) => balanceData.balanceType === InternalBalanceType.StakeToken)
)

export const selectERC20RewardTokensBalances = createSelector([selectAllBalances], (balancesData) =>
  balancesData.filter((balanceData) => balanceData.balanceType === InternalBalanceType.RewardTokenERC20)
)

export const selectLegacyProjectTokensBalances = createSelector([selectAllBalances], (balancesData) =>
  balancesData.filter((balanceData) => balanceData.balanceType === LegacyBalanceType.ProjectToken)
)

export const selectLegacyProjectTokensBalancesSum = createSelector([selectLegacyProjectTokensBalances], (legacyProjectTokensBalances) =>
  sumBalancesDataForToken(legacyProjectTokensBalances)
)

export const selectLegacyRewardTokensBalances = createSelector([selectAllBalances], (balancesData) =>
  balancesData.filter((balanceData) => balanceData.balanceType === LegacyBalanceType.RewardToken)
)

export const selectLegacyRewardTokensBalancesSum = createSelector([selectLegacyRewardTokensBalances], (legacyRewardTokensBalances) =>
  sumBalancesDataForToken(legacyRewardTokensBalances)
)

export const selectTransferBalancesStatus = createSelector([selectState], (state) => state.transferBalancesStatus)

export const selectTransferTransactionId = createSelector([selectState], (state) => state.transferTransactionId)

export const selectAllForActiveWallet = createSelector([walletSelectors.selectActiveWallet, selectAllBalances], (wallet, balances) =>
  balances.filter((balance) => compareAddresses(balance.account, wallet.ethereumAddress))
)

export const balancesSelectors = {
  selectUSDCSum,
  selectUSDCBalances,
  selectTransferTransactionId,
  selectTransferBalancesStatus,
  selectStakeTokensBalances,
  selectStablecoinBalances,
  selectProjectTokensBalances,
  selectProjectsBalances,
  selectNativeBalanceSum,
  selectNativeBalances,
  selectLegacyRewardTokensBalancesSum,
  selectLegacyRewardTokensBalances,
  selectLegacyProjectTokensBalancesSum,
  selectLegacyProjectTokensBalances,
  selectFundraiseTokensBalances,
  selectFundraisesBalances,
  selectExternalBalances,
  selectERC20RewardTokensBalances,
  selectAreBalancesLoading,
  selectAllForActiveWallet,
  selectAllBalances,
}
