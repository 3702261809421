// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgInvest = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8 17.5 2 2m0 0-2 2m2-2H4m9.998.774a8.998 8.998 0 1 0-10.056-4.77M12 8.494v-1m0 7v1m-1.734-1.714a1.443 1.443 0 0 0 1.234.714h1.1a1.4 1.4 0 0 0 .34-2.766l-1.874-.471a1.4 1.4 0 0 1 .334-2.763h1.1a1.442 1.442 0 0 1 1.235.714"
    />
  </svg>
)
export default SvgInvest
