import type { SubgraphTranchesQueryResult } from '../../types/subgraphQueryTypes'
import type { BackendTrancheQueryResult } from '../../types/backendQueryTypes'
import { BigDecimal } from '../../utils/bigDecimal'
import { DEFAULT_INTERNAL_TOKEN_DECIMALS } from '../../../config/decimals'

import type { BackendTranche, SubgraphTranche } from './tranches.types'

export const mapBackendTrancheQueryResultToBackendTrancheData = (backendTrancheQueryResult: BackendTrancheQueryResult): BackendTranche => {
  return {
    tokenAddress: backendTrancheQueryResult.token.address,
    name: backendTrancheQueryResult.name,
    id: backendTrancheQueryResult.id,
  }
}

export const mapSubgraphTrancheQueryResultToSubgraphTrancheData = (
  subgraphTrancheQueryResult: SubgraphTranchesQueryResult
): SubgraphTranche => {
  return {
    trancheAction: Number(subgraphTrancheQueryResult.trancheAction),
    tokenAddress: subgraphTrancheQueryResult.token.address,
    start: Number(subgraphTrancheQueryResult.start),
    id: subgraphTrancheQueryResult.id,
    duration: Number(subgraphTrancheQueryResult.duration),
    amount: BigDecimal.fromString(subgraphTrancheQueryResult.amount, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(
      DEFAULT_INTERNAL_TOKEN_DECIMALS
    ),
    account: subgraphTrancheQueryResult.account,
  }
}
