// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgWalletAdress = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 7h16a2 2 0 0 1 2 2v9.5a2.5 2.5 0 0 1-2.5 2.5H13m4-14V6a2 2 0 0 0-2-2H5.5A2.5 2.5 0 0 0 3 6.5V15m7 4H3m7 0-2 2m2-2-2-2m9-3h-2"
    />
  </svg>
)
export default SvgWalletAdress
