import '../styles/globals.css'
/* eslint-disable unicorn/no-await-expression-member */
import { Web3ReactProvider } from '@web3-react/core'
import type { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import React from 'react'
import Head from 'next/head'
import type { EmotionCache } from '@emotion/react'
import { CacheProvider } from '@emotion/react'
import { appWithTranslation } from 'next-i18next'
import { SnackbarProvider } from 'notistack'

import createEmotionCache from '../src/utils/createEmotionCache'
import { ThemeProvider } from '../styles/theme/utils'
import { storeWrapper } from '../src/store'
import { AuthWrapper } from '../src/containers/AuthWrapper'
import { Web3Initialization } from '../src/containers/Web3Initialization/Web3Initialization'
import { getWeb3Library } from '../src/utils/getWeb3Library'
import { LinkingAccountsDialog } from '../src/containers/LinkingAccountsDialog/LinkingAccountsDialog.container'
import envConfig from '../config/env'
import { NewUserDialog } from '../src/containers/NewUserDialog/NewUserDialog.container'
import { ParticleNetworkProvider } from '../src/containers/ParticleNetworkProvider/ParticleNetworkProvider'

const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

const GTM_SCRIPT = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${envConfig.gtmContainerId}');
`

const MyApp = ({ Component, emotionCache = clientSideEmotionCache, ...rest }: MyAppProps) => {
  const { props, store } = storeWrapper.useWrappedStore(rest)
  const gtmHead = { __html: GTM_SCRIPT }

  return (
    <Provider store={store}>
      <AuthWrapper>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Mosaico</title>
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            <script dangerouslySetInnerHTML={gtmHead} />
          </Head>
          <ThemeProvider>
            <ParticleNetworkProvider>
              <Web3ReactProvider getLibrary={getWeb3Library}>
                <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                  <Web3Initialization>
                    <Component {...props} />
                    <LinkingAccountsDialog />
                    {typeof window !== 'undefined' && <NewUserDialog />}
                  </Web3Initialization>
                </SnackbarProvider>
              </Web3ReactProvider>
            </ParticleNetworkProvider>
          </ThemeProvider>
        </CacheProvider>
      </AuthWrapper>
    </Provider>
  )
}

export default appWithTranslation(MyApp)
