import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import { RequestStatus } from '../types'

import type {
  ClosedProjectBase,
  ClosedProjectBaseData,
  ErrorPayload,
  FetchAllClosedProjectsPayloadSuccess,
} from './closedProjectBase.types'
import { ClosedProjectBaseState } from './closedProjectBase.state'
import { isClosedProjectBase } from './closedProjectBase.const'

export const closedProjectBaseAdapter = createEntityAdapter<ClosedProjectBaseData>({
  selectId: (closedProjectBase) => closedProjectBase.id,
})

export const initialClosedProjectBaseSliceState: ClosedProjectBase = closedProjectBaseAdapter.getInitialState({
  ...new ClosedProjectBaseState(),
})

const hydrate = createAction<{ [StoreKeys.ClosedProjectBase]: ClosedProjectBase }>(HYDRATE)

export const closedProjectBaseSlice = createSlice({
  reducers: {
    fetchAllClosedProjectsSuccess: (state, action: PayloadAction<FetchAllClosedProjectsPayloadSuccess>) => {
      state.fetchAllClosedProjectsStatus = RequestStatus.Succeeded
      closedProjectBaseAdapter.upsertMany(state, action.payload)
    },
    fetchAllClosedProjectsError: (state, action: PayloadAction<ErrorPayload>) => {
      state.fetchAllClosedProjectsStatus = RequestStatus.Failed
      state.error = action.payload
    },
    fetchAllClosedProjects: (state) => {
      state.fetchAllClosedProjectsStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.ClosedProjectBase,
  initialState: initialClosedProjectBaseSliceState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { entities } = payload[StoreKeys.ClosedProjectBase]
      const projects = Object.values(entities)
      const projectsToUpsert = projects.filter(isClosedProjectBase)

      return closedProjectBaseAdapter.upsertMany(state, projectsToUpsert)
    }),
})

export const closedProjectBaseActions = closedProjectBaseSlice.actions
export const closedProjectBaseReducer = closedProjectBaseSlice.reducer

export type ClosedProjectBaseActions = ActionsType<typeof closedProjectBaseActions>
