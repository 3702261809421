import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { cryptocurrenciesActions } from './cryptocurrencies.slice'
import { mintUSDCSaga } from './sagas/MintUSDC/MintUSDC.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([takeLatest(cryptocurrenciesActions.mintUSDC, mintUSDCSaga)])
}

export function* cryptocurrenciesSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
