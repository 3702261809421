/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query GetAllProjects($languageCode: String!, $deviceType: String!) {\n    projects(languageCode: $languageCode, deviceType: $deviceType) {\n      id\n      description\n      tags\n      logoSrc\n      contractAddress\n      mainImageSrc\n    }\n  }\n": types.GetAllProjectsDocument,
    "\n  mutation SaveWallet($walletType: String!, $ethereumAddress: String!){\n    saveWallet(walletType: $walletType, ethereumAddress: $ethereumAddress){\n      walletType,\n      ethereumAddress\n    }\n  }\n": types.SaveWalletDocument,
    "\n  query GetUser {\n    user {\n      id,\n      email,\n      basicKycStatus,\n      advancedKycStatus,\n      localization,\n      marketingConsents,\n      monthlyInvestments,\n      isMigrated,\n      wallets {\n        walletType,\n        ethereumAddress\n      }\n    }\n  }\n": types.GetUserDocument,
    "\n  mutation UpdateUser($localization: String, $marketingConsents: Boolean){\n    updateUser(localization: $localization, marketingConsents: $marketingConsents) {\n      localization\n      marketingConsents\n    }\n  }\n": types.UpdateUserDocument,
    "\n  query GetApplicantData {\n    applicantData {\n      firstName,\n      lastName,\n      email,\n      dateOfBirth,\n      phone,\n      country,\n      postCode,\n      town,\n      street,\n      streetNumber\n    }\n  }\n": types.GetApplicantDataDocument,
    "\n  query getUserInvestments {\n    investments {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      fundraiseAddress\n      amountIn\n      amountOut\n      userId\n      walletAddress\n      createdAt\n      updatedAt\n    }\n  }\n": types.GetUserInvestmentsDocument,
    "\n  mutation updateInvestment($id: Float!, $transactionStatus: String!, $transactionHash: String) {\n    updateInvestment(id: $id, transactionStatus: $transactionStatus, transactionHash: $transactionHash) {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      amountIn\n      amountOut\n      walletAddress\n      fundraiseAddress\n      createdAt\n      updatedAt\n    }\n  }\n": types.UpdateInvestmentDocument,
    "\n  mutation createInvestment($type: String!, $amountIn: String!, $amountOut: String!, $walletAddress: String!, $fundraiseAddress: String!) {\n    createInvestment(type: $type, amountIn: $amountIn, amountOut: $amountOut, walletAddress: $walletAddress, fundraiseAddress: $fundraiseAddress) {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      fundraiseAddress\n      amountIn\n      amountOut\n      walletAddress\n      createdAt\n      updatedAt\n    }\n  }\n": types.CreateInvestmentDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAllProjects($languageCode: String!, $deviceType: String!) {\n    projects(languageCode: $languageCode, deviceType: $deviceType) {\n      id\n      description\n      tags\n      logoSrc\n      contractAddress\n      mainImageSrc\n    }\n  }\n"): (typeof documents)["\n  query GetAllProjects($languageCode: String!, $deviceType: String!) {\n    projects(languageCode: $languageCode, deviceType: $deviceType) {\n      id\n      description\n      tags\n      logoSrc\n      contractAddress\n      mainImageSrc\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveWallet($walletType: String!, $ethereumAddress: String!){\n    saveWallet(walletType: $walletType, ethereumAddress: $ethereumAddress){\n      walletType,\n      ethereumAddress\n    }\n  }\n"): (typeof documents)["\n  mutation SaveWallet($walletType: String!, $ethereumAddress: String!){\n    saveWallet(walletType: $walletType, ethereumAddress: $ethereumAddress){\n      walletType,\n      ethereumAddress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUser {\n    user {\n      id,\n      email,\n      basicKycStatus,\n      advancedKycStatus,\n      localization,\n      marketingConsents,\n      monthlyInvestments,\n      isMigrated,\n      wallets {\n        walletType,\n        ethereumAddress\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUser {\n    user {\n      id,\n      email,\n      basicKycStatus,\n      advancedKycStatus,\n      localization,\n      marketingConsents,\n      monthlyInvestments,\n      isMigrated,\n      wallets {\n        walletType,\n        ethereumAddress\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUser($localization: String, $marketingConsents: Boolean){\n    updateUser(localization: $localization, marketingConsents: $marketingConsents) {\n      localization\n      marketingConsents\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($localization: String, $marketingConsents: Boolean){\n    updateUser(localization: $localization, marketingConsents: $marketingConsents) {\n      localization\n      marketingConsents\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetApplicantData {\n    applicantData {\n      firstName,\n      lastName,\n      email,\n      dateOfBirth,\n      phone,\n      country,\n      postCode,\n      town,\n      street,\n      streetNumber\n    }\n  }\n"): (typeof documents)["\n  query GetApplicantData {\n    applicantData {\n      firstName,\n      lastName,\n      email,\n      dateOfBirth,\n      phone,\n      country,\n      postCode,\n      town,\n      street,\n      streetNumber\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getUserInvestments {\n    investments {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      fundraiseAddress\n      amountIn\n      amountOut\n      userId\n      walletAddress\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query getUserInvestments {\n    investments {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      fundraiseAddress\n      amountIn\n      amountOut\n      userId\n      walletAddress\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateInvestment($id: Float!, $transactionStatus: String!, $transactionHash: String) {\n    updateInvestment(id: $id, transactionStatus: $transactionStatus, transactionHash: $transactionHash) {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      amountIn\n      amountOut\n      walletAddress\n      fundraiseAddress\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation updateInvestment($id: Float!, $transactionStatus: String!, $transactionHash: String) {\n    updateInvestment(id: $id, transactionStatus: $transactionStatus, transactionHash: $transactionHash) {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      amountIn\n      amountOut\n      walletAddress\n      fundraiseAddress\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createInvestment($type: String!, $amountIn: String!, $amountOut: String!, $walletAddress: String!, $fundraiseAddress: String!) {\n    createInvestment(type: $type, amountIn: $amountIn, amountOut: $amountOut, walletAddress: $walletAddress, fundraiseAddress: $fundraiseAddress) {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      fundraiseAddress\n      amountIn\n      amountOut\n      walletAddress\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  mutation createInvestment($type: String!, $amountIn: String!, $amountOut: String!, $walletAddress: String!, $fundraiseAddress: String!) {\n    createInvestment(type: $type, amountIn: $amountIn, amountOut: $amountOut, walletAddress: $walletAddress, fundraiseAddress: $fundraiseAddress) {\n      id\n      type\n      status\n      transactionStatus\n      transactionHash\n      fundraiseAddress\n      amountIn\n      amountOut\n      walletAddress\n      createdAt\n      updatedAt\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;