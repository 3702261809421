import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import { RequestStatus } from '../types'

import { UserInvestmentsState } from './userInvestments.state'
import type { CreateUserInvestmentDto, UpdateUserInvestmentDto, UserInvestment } from './userInvestments.types'

export const investmentsAdapter = createEntityAdapter<UserInvestment>({
  selectId: (tx) => tx.id,
})

export const initialUserInvestmentsSliceState: EntityState<UserInvestment> & UserInvestmentsState = investmentsAdapter.getInitialState({
  ...new UserInvestmentsState(),
})

export const userInvestmentsSlice = createSlice({
  reducers: {
    updateUserInvestmentSuccess: (state, action: PayloadAction<UserInvestment>) => {
      investmentsAdapter.setOne(state, action.payload)
      state.updateUserInvestmentStatus = RequestStatus.Succeeded
    },

    updateUserInvestmentError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.updateUserInvestmentStatus = RequestStatus.Failed
    },

    updateUserInvestment: (state, _: PayloadAction<UpdateUserInvestmentDto>) => {
      state.updateUserInvestmentStatus = RequestStatus.Loading
    },
    fetchUserInvestmentsSuccess: (state, action: PayloadAction<UserInvestment[]>) => {
      investmentsAdapter.addMany(state, action.payload)
      state.loadUserInvestmentsStatus = RequestStatus.Succeeded
    },

    fetchUserInvestmentsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.loadUserInvestmentsStatus = RequestStatus.Failed
    },

    fetchUserInvestments: (state) => {
      state.loadUserInvestmentsStatus = RequestStatus.Loading
    },
    createUserInvestmentSuccess: (state, action: PayloadAction<UserInvestment>) => {
      investmentsAdapter.addOne(state, action.payload)
      state.createUserInvestmentStatus = RequestStatus.Succeeded
    },

    createUserInvestmentError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.createUserInvestmentStatus = RequestStatus.Failed
    },

    createUserInvestment: (state, _: PayloadAction<CreateUserInvestmentDto>) => {
      state.updateUserInvestmentStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.UserInvestments,
  initialState: initialUserInvestmentsSliceState,
})

export const userInvestmentsAdapterSelectors = investmentsAdapter.getSelectors()
export const userInvestmentsActions = userInvestmentsSlice.actions
export const userInvestmentsReducer = userInvestmentsSlice.reducer
export type UserInvestmentsActions = ActionsType<typeof userInvestmentsActions>
