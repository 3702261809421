import { gql } from 'graphql-request'

export const PHASE_FRAGMENT = gql`
  fragment phase on Phase {
    id
    fundraise {
      address
    }
    phaseIndex
    phaseType
    status
    startDate
    endDate
    tokenRate
    soldTokens
    minInvest
    maxInvest
    maxTokensAmount
  }
`
export const MULTIPLE_PHASES_QUERY = gql`
  query multiplePhases($fundraiseAddresses: [String!]) {
    phases(where: { fundraise_: { address_in: $fundraiseAddresses } }) {
      ...phase
    }
  }
  ${PHASE_FRAGMENT}
`

export const ALL_PHASES_FOR_FUNDRAISE_QUERY = gql`
  query allPhasesForFundraise($fundraiseAddress: String!) {
    phases(where: { fundraise_: { address: $fundraiseAddress } }) {
      ...phase
    }
  }
  ${PHASE_FRAGMENT}
`
