import { utils } from 'ethers'
import type { SagaGenerator } from 'typed-redux-saga'
import { select, put, call } from 'typed-redux-saga'

import { pushNotification } from '../../../../utils/pushNotification'
import { selectChainId, selectSigner, selectWeb3Account } from '../../../web3/web3.selectors'
import { getRewardTokenERC20 } from '../../../web3/web3.utils'
import type { RewardTokenERC20Actions } from '../../rewardTokenERC20.slice'
import { rewardTokenERC20Actions } from '../../rewardTokenERC20.slice'
import type { ConsumePayload } from '../../rewardTokenERC20.types'

function* consumeERC20(account: string, { amount, tokenAddress, decimals, tokenName }: ConsumePayload): SagaGenerator<void> {
  const signer = yield* select(selectSigner)
  const chainId = yield* select(selectChainId)
  const rewardToken = getRewardTokenERC20(tokenAddress, signer)

  const parsedAmount = utils.parseUnits(amount, decimals)

  const signature = yield* call(
    [signer, signer._signTypedData],
    {
      version: '0.1.0',
      verifyingContract: tokenAddress,
      name: tokenName,
      chainId,
    },
    {
      ConsumeData: [
        { type: 'address', name: 'account' },
        { type: 'uint256', name: 'amount' },
      ],
    },
    {
      amount: parsedAmount,
      account,
    }
  )

  const tx = yield* call(rewardToken.consume, account, parsedAmount, signature)
  yield* call(tx.wait)
}

export function* consumeSaga({ payload }: RewardTokenERC20Actions['consume']): SagaGenerator<void> {
  const account = yield* select(selectWeb3Account)
  if (!account) return

  try {
    yield* call(consumeERC20, account, payload)

    yield* put(rewardTokenERC20Actions.consumeSuccess())
    pushNotification(`You've successfully consumed your reward`)
  } catch (error) {
    if (error instanceof Error) {
      yield* put(rewardTokenERC20Actions.consumeFailure(error.message))

      pushNotification(`Consume Error: ${error.message}`, 'error')
    }
  }
}
