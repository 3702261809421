import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { apiCall } from '../../../../utils/apiCall'
import { FETCH_APPLICANT_DATA, GET_USER } from '../../../../graphql/client/queries'
import type { ApplicantData, UserType } from '../../../../graphql/generated/graphql'
import { sessionSelectors } from '../../../session/session.selectors'
import { userActions } from '../../user.slice'
import { walletActions } from '../../../wallet/wallet.slice'
import { UserErrors } from '../../user.const'
import { VerificationStatus } from '../../../sumsub/sumsub.constants'
import { Auth0Messages } from '../../../../utils/auth0Messages'
import { sessionActions } from '../../../session/session.slice'
import { navigationSelectors } from '../../../navigation/navigation.selectors'

export async function fetchUser(client: GraphQLClient): Promise<Omit<UserType, 'investments'>> {
  const { user } = await client.request(GET_USER)
  return user
}

export async function fetchApplicantData(client: GraphQLClient): Promise<ApplicantData> {
  const { applicantData } = await client.request(FETCH_APPLICANT_DATA)
  return applicantData
}

export function* loadUserDataSaga(): SagaGenerator<void> {
  try {
    const currentLocalization = yield* select(navigationSelectors.selectCurrentLocalisation)
    const auth0Client = yield* select(sessionSelectors.selectAuth0Client)

    const auth0User = yield* call([auth0Client, auth0Client.getUser])

    const backendUser = yield* apiCall(fetchUser)

    let sumsubApplicantData = null

    if (backendUser.basicKycStatus !== VerificationStatus.UNVERIFIED) sumsubApplicantData = yield* apiCall(fetchApplicantData)

    if (auth0User && backendUser) {
      if (currentLocalization && currentLocalization !== backendUser.localization)
        yield* put(userActions.updateUserLocalization(currentLocalization))

      yield* put(
        userActions.loadUserDataSuccess({
          sumsubApplicantData,
          monthlyInvestments: backendUser.monthlyInvestments,
          marketingConsents: backendUser.marketingConsents,
          localization: currentLocalization,
          isMigrated: backendUser.isMigrated,
          id: backendUser.id,
          email: backendUser.email,
          basicKycStatus: backendUser.basicKycStatus as VerificationStatus,
          auth0UserData: auth0User,
          advancedKycStatus: backendUser.advancedKycStatus as VerificationStatus,
        })
      )
      yield* put(walletActions.walletsLoaded(backendUser.wallets))
    } else throw new Error(UserErrors.DATA_LOAD_ERROR)
  } catch (error) {
    if (error instanceof Error) {
      yield* put(userActions.loadUserDataError(error.message))
      if (error.message === Auth0Messages.INVALID_REFRESH_TOKEN) yield* put(sessionActions.logoutStart())
    }
  }
}
