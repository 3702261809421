import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { RequestError } from '../types'
import { RequestStatus } from '../types'
import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'

import type { ISession, LoginStartPayload, LogoutStartPayload } from './session.types'

export const initSessionSliceState: ISession = {
  redirectStatus: RequestStatus.Idle,
  logoutStatus: RequestStatus.Idle,
  loginStatus: RequestStatus.Idle,
  isLogoutNeeded: false,
  initializeSessionStatus: RequestStatus.Idle,
  error: null,
  domainName: '',
  auth0SessionLoaded: RequestStatus.Idle,
}

export const sessionSlice = createSlice({
  reducers: {
    updateIsLogoutNeeded: (state, action: PayloadAction<boolean>) => {
      state.isLogoutNeeded = action.payload
    },
    redirectSuccess: (state) => {
      state.redirectStatus = RequestStatus.Succeeded
      return state
    },
    redirectStart: (state) => {
      state.redirectStatus = RequestStatus.Loading
      return state
    },
    redirectError: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.redirectStatus = RequestStatus.Failed
      return state
    },
    logoutSuccess: (state) => {
      state.logoutStatus = RequestStatus.Succeeded
      state.loginStatus = RequestStatus.Idle
      state.auth0SessionLoaded = RequestStatus.Idle
      return state
    },
    logoutStart: (state, _action: PayloadAction<LogoutStartPayload | undefined>) => {
      state.logoutStatus = RequestStatus.Loading
      return state
    },
    logoutError: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.logoutStatus = RequestStatus.Failed
      return state
    },
    loginSuccess: (state) => {
      state.loginStatus = RequestStatus.Succeeded

      state.logoutStatus = RequestStatus.Idle
      return state
    },
    loginStart: (state, _action: PayloadAction<LoginStartPayload | undefined>) => {
      state.loginStatus = RequestStatus.Loading
      return state
    },
    loginError: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.loginStatus = RequestStatus.Failed
      return state
    },
    initializeSessionSuccess: (state) => {
      state.initializeSessionStatus = RequestStatus.Succeeded
      state.loginStatus = RequestStatus.Succeeded
      return state
    },
    initializeSessionStart: (state, action: PayloadAction<string>) => {
      state.initializeSessionStatus = RequestStatus.Loading
      state.domainName = action.payload
      return state
    },
    initializeSessionError: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.initializeSessionStatus = RequestStatus.Failed
      return state
    },
    auth0SessionLoadedSuccess: (state) => {
      state.auth0SessionLoaded = RequestStatus.Succeeded
      return state
    },
  },
  name: StoreKeys.Session,
  initialState: initSessionSliceState,
})

export const sessionActions = sessionSlice.actions
export const sessionReducer = sessionSlice.reducer
export type SessionActions = ActionsType<typeof sessionActions>
