import type { MetaMaskInpageProvider } from '@metamask/providers'
import type { EventChannel } from 'redux-saga'
import { eventChannel } from 'redux-saga'
import { call, put, take } from 'typed-redux-saga'

import { web3Actions } from '../../web3.slice'

function createMetaMaskEventsChannel(
  provider: MetaMaskInpageProvider
): EventChannel<ReturnType<typeof web3Actions.setExpectedChain> | ReturnType<typeof web3Actions.accountsChanged>> {
  return eventChannel((emitter) => {
    const onChainChanged = () => emitter(web3Actions.setExpectedChain())
    const onAccountChanged = (accounts: string[]) => emitter(web3Actions.accountsChanged(accounts))

    provider.on('chainChanged', onChainChanged)
    provider.on('accountsChanged', (payload) => {
      onAccountChanged(payload as string[])
    })

    return () => {
      provider.removeListener('chainChanged', onChainChanged)
      provider.removeListener('onAccountChanged', onAccountChanged)
    }
  })
}

export function* observeMetaMaskEvents(provider: MetaMaskInpageProvider): Generator {
  const channel = yield* call(createMetaMaskEventsChannel, provider)
  while (true) {
    const action = yield* take(channel)
    yield* put(action)
  }
}
