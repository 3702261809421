import type { SagaGenerator } from 'typed-redux-saga'
import { put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { web3Selectors } from '../../../web3/web3.selectors'
import { apiCall } from '../../../../utils/apiCall'
import { SAVE_WALLET_MUTATION } from '../../../../graphql/client/queries'
import type { WalletType, WalletTypeEnum } from '../../../../graphql/generated/graphql'
import { walletSelectors } from '../../wallet.selectors'
import { walletActions } from '../../wallet.slice'
import { pushNotification } from '../../../../utils/pushNotification'
import { navigationSelectors } from '../../../navigation/navigation.selectors'
import { sessionActions } from '../../../session/session.slice'

export async function uploadWallet(client: GraphQLClient, walletAddress: string, walletType: WalletTypeEnum): Promise<WalletType> {
  const { saveWallet } = await client.request(SAVE_WALLET_MUTATION, {
    walletType,
    ethereumAddress: walletAddress,
  })

  return saveWallet
}

export function* addUserWalletSaga(): SagaGenerator<void> {
  try {
    const account = yield* select(web3Selectors.selectWeb3Account)
    const walletType = yield* select(web3Selectors.selectWalletType)

    const userWallets = yield* select(walletSelectors.selectAll)

    if (account && walletType) {
      const isWalletAlreadyExists = userWallets.some((wallet) => wallet.walletType === walletType && wallet.ethereumAddress === account)

      if (!isWalletAlreadyExists) {
        const result = yield* apiCall(uploadWallet, account, walletType)
        yield* put(walletActions.walletSaved({ walletType: result.walletType, ethereumAddress: result.ethereumAddress }))
      }
    }
  } catch (error) {
    const locale = yield* select(navigationSelectors.selectCurrentLocalisation)

    if (error instanceof Error) {
      if (error.message.includes('Wallet already assigned to another user')) {
        pushNotification(
          locale === 'pl' ? 'Portfel jest już przypisany do innego użytkownika' : 'Wallet already assigned to another user',
          'warning'
        )
        return
      }
      if (error.message.includes('User already have a particle wallet')) {
        yield* put(sessionActions.updateIsLogoutNeeded(true))
        return
      }
      pushNotification(error.message)
    }
  }
}
