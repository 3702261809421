/* eslint-disable unicorn/consistent-function-scoping */
import type { Dictionary } from '@reduxjs/toolkit'

import { RequestStatus } from '../types'
import envConfig from '../../../config/env'
import { Chains } from '../../../config/chains'

import type { Cryptocurrencies, CryptocurrenciesData } from './cryptocurrencies.types'
import { MUMBAI_TOKENS, POLYGON_TOKENS } from './cryptocurrencies.data'

const chainId = envConfig.chainId as unknown as Chains
const currentChainTokens = chainId === Chains.POLYGON ? POLYGON_TOKENS : MUMBAI_TOKENS

const CryptocurrenciesDataDictionary: Dictionary<CryptocurrenciesData> = Object.values(currentChainTokens).reduce((accumulator, token) => {
  accumulator[token.address] = token
  return accumulator
}, {} as Dictionary<CryptocurrenciesData>)

export class CryptocurrenciesState implements Cryptocurrencies {
  error: string | null = null
  loadCryptocurrencies: RequestStatus = RequestStatus.Idle
  mintUSDCStatus: RequestStatus = RequestStatus.Idle
  ids = Object.values(currentChainTokens).map((token) => token.address)
  entities = CryptocurrenciesDataDictionary
}
