// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgHot = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.2 7.562 9.682 3.5l3.753 4.379 2.429-2.834L17.8 7.304a8.008 8.008 0 0 1 1.927 5.211v.258a7.728 7.728 0 0 1-15.454 0c0-1.912.684-3.76 1.927-5.211Z"
    />
  </svg>
)
export default SvgHot
