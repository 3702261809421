import type { JsonRpcProvider } from '@ethersproject/providers'
import { ERC20__factory } from '@sapiency-io/mosaico-contracts/dist'
import type { BigNumber } from 'ethers'

import { DEFAULT_INTERNAL_TOKEN_DECIMALS } from '../../../../../../config/decimals'
import { BigDecimal } from '../../../../../utils/bigDecimal'
import type { LegacyBalanceType } from '../../../balances.const'

export async function fetchLegacyBalancesForWallets(
  tokenAddresses: string[],
  userWalletsAddresses: string[],
  provider: JsonRpcProvider,
  balanceType: LegacyBalanceType
) {
  const balancesPromises: Record<string, BigNumber[]> = {}

  for (const tokenAddress of tokenAddresses) {
    const tokenContract = ERC20__factory.connect(tokenAddress, provider)
    balancesPromises[tokenAddress] = []

    // TODO: Deploy contract that fetches all balances for wallets at once to prevent rpc calls spam
    for (const userWalletsAddress of userWalletsAddresses) {
      // eslint-disable-next-line no-await-in-loop
      balancesPromises[tokenAddress].push(await tokenContract.balanceOf(userWalletsAddress))
    }
  }
  const balances = await Promise.all(Object.values(balancesPromises).flat())

  return balances.map((amount, balanceIndex) => {
    return {
      tokenAddress: tokenAddresses[Math.floor(balanceIndex / userWalletsAddresses.length)],
      balanceType,
      amount: BigDecimal.from(amount.toString(), DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(DEFAULT_INTERNAL_TOKEN_DECIMALS),
      account: userWalletsAddresses[balanceIndex % userWalletsAddresses.length],
    }
  })
}
