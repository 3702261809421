// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgEye = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 12a3 3 0 1 1-3-3m-9 3c0-.663.153-1.317.446-1.912A9.453 9.453 0 0 1 12 5a9.453 9.453 0 0 1 8.554 5.088 4.321 4.321 0 0 1 0 3.824A9.453 9.453 0 0 1 12 19a9.452 9.452 0 0 1-8.554-5.088A4.346 4.346 0 0 1 3 12Z"
    />
  </svg>
)
export default SvgEye
