import type { Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'

export const getChips = (palette: CustomPalette): Components<Theme> => ({
  MuiChip: {
    variants: [
      {
        style: {
          padding: '6px 12px',
          lineHeight: '135%',
          letterSpacing: '0.12px',
          fontWeight: 600,
          fontSize: '12px',
          color: palette._success[600],
          backgroundColor: palette._success[200],
        },
        props: { color: 'success' },
      },
      {
        style: {
          padding: '6px 12px',
          lineHeight: '135%',
          letterSpacing: '0.12px',
          fontWeight: 600,
          fontSize: '12px',
          color: palette._error[600],
          backgroundColor: palette._error[200],
        },
        props: { color: 'error' },
      },
      {
        style: {
          padding: '6px 12px',
          lineHeight: '135%',
          letterSpacing: '0.12px',
          fontWeight: 600,
          fontSize: '12px',
          color: palette._warning[600],
          backgroundColor: palette._warning[200],
        },
        props: { color: 'warning' },
      },
    ],
    styleOverrides: {
      root: {
        padding: '4px 12px',
        lineHeight: '24px',
        fontWeight: 600,
        color: palette._text[600],
        backgroundColor: palette._stroke[100],
      },
    },
  },
})
