import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { staticActions } from './static.slice'
import { defaultStaticSetupSaga } from './sagas/defaultStaticSetup/defaultStaticSetup'
import { projectInvestStaticSetupSaga, projectInvestSubgraphSetupSaga } from './sagas/projectInvestStaticSetup/projectInvestStaticSetup'
import { projectPageStaticSetupSaga, projectPageSubgraphSetupSaga } from './sagas/projectPageStaticSetup/projectPageStaticSetup'
import { projectsStaticSetupSaga, projectsSubgraphSetupSaga } from './sagas/projectsStaticSetup/projectsStaticSetup'
import { landingStaticSetupSaga, landingStaticSubgraphSaga } from './sagas/landingStaticSetup/landingStaticSetup'
import { dashboardStaticSetupSaga, dashboardSubgraphSetupSaga } from './sagas/dashboardStaticSetup/dashboardStaticSetup'
import { raiseCapitalStaticSetupSaga } from './sagas/raiseCapitalStaticSetup/raiseCapitalStaticSetup'
import { searchStaticSetupSaga, searchSubgraphSetupSaga } from './sagas/searchStaticSetup/searchStaticSetup.saga'
import { transactionsStaticSetupSaga, transactionsSubgraphSetupSaga } from './sagas/transactionsStaticSetup/transactionsStaticSetup'
import { mosaicoAssetsStaticSetupSaga } from './sagas/mosaicoAssetsStaticSetup/mosaicoAssetsStaticSetup'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(staticActions.startDefaultStaticSetup, defaultStaticSetupSaga),
    takeLatest(staticActions.startProjectInvestStaticSetup, projectInvestStaticSetupSaga),
    takeLatest(staticActions.startProjectPageStaticSetup, projectPageStaticSetupSaga),
    takeLatest(staticActions.startRaiseCapitalStaticSetup, raiseCapitalStaticSetupSaga),
    takeLatest(staticActions.startMosaicoAssetsStaticSetup, mosaicoAssetsStaticSetupSaga),
    takeLatest(staticActions.startProjectsStaticSetup, projectsStaticSetupSaga),
    takeLatest(staticActions.startLandingStaticSetup, landingStaticSetupSaga),
    takeLatest(staticActions.startDashboardStaticSetup, dashboardStaticSetupSaga),
    takeLatest(staticActions.startTransactionsStaticSetup.type, transactionsStaticSetupSaga),
    takeLatest(staticActions.startSearchStaticSetup, searchStaticSetupSaga),

    takeLatest(staticActions.startDashboardSubgraphSetup, dashboardSubgraphSetupSaga),
    takeLatest(staticActions.startProjectInvestSubgraphSetup, projectInvestSubgraphSetupSaga),
    takeLatest(staticActions.startProjectPageSubgraphSetup, projectPageSubgraphSetupSaga),
    takeLatest(staticActions.startProjectsSubgraphSetup, projectsSubgraphSetupSaga),
    takeLatest(staticActions.startSearchSubgraphSetup, searchSubgraphSetupSaga),
    takeLatest(staticActions.startTransactionsSubgraphSetup, transactionsSubgraphSetupSaga),
    takeLatest(staticActions.startLandingSubgraphSetup, landingStaticSubgraphSaga),
  ])
}
export function* staticSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
