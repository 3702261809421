import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { legacyTokensActions } from './legacyTokens.slice'
import { swapTokensSaga } from './saga/swapTokens/swapTokens.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([takeLatest(legacyTokensActions.swapTokens, swapTokensSaga)])
}

export function* legacyTokensSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
