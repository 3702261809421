import type { OptionsObject, SnackbarKey, VariantType } from 'notistack'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

import { IconButton } from '../core-components/buttons/IconButton'
import { CloseCircle } from '../core-components/SVG/icons/mono'

const notificationAction = (snackbarId: SnackbarKey) => (
  <IconButton color="white" size="small" onClick={() => closeSnackbar(snackbarId)}>
    <CloseCircle color="white" />
  </IconButton>
)

export const pushNotification = (
  message: string,
  variant: VariantType = 'success',
  options: OptionsObject<'default' | 'error' | 'success' | 'warning' | 'info'> = {}
) => {
  enqueueSnackbar(message, {
    variant,
    preventDuplicate: true,
    action: notificationAction,
    ...options,
  })
}
