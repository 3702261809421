// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgUpload = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.667 49h10.666M19.667 1h10.666M1 19.667v10.666m48-10.666v10.666M11.667 49A10.667 10.667 0 0 1 1 38.333M38.333 1A10.667 10.667 0 0 1 49 11.667m-48 0A10.667 10.667 0 0 1 11.667 1M49 38.333A10.667 10.667 0 0 1 38.333 49m-20-25.333L25 17m0 0 6.667 6.667M25 17v16"
    />
  </svg>
)
export default SvgUpload
