// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgFlagFinish = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m10 13 .544 1.632A2 2 0 0 0 12.442 16H19a2 2 0 0 0 2-2V8.155a2 2 0 0 0-2-2h-4.932M9 21 3.877 5.632A2 2 0 0 1 5.775 3h6.293a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H6.333"
    />
  </svg>
)
export default SvgFlagFinish
