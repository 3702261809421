import { put, type SagaGenerator } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { apiCall } from '../../../../utils/apiCall'
import { CREATE_INVESTMENT } from '../../../../graphql/client/queries'
import type { CreateUserInvestmentDto, UserInvestment } from '../../userInvestments.types'
import type { UserInvestmentsActions } from '../../userInvestments.slice'
import { userInvestmentsActions } from '../../userInvestments.slice'

export async function createInvestment(client: GraphQLClient, dto: CreateUserInvestmentDto): Promise<UserInvestment> {
  const { createInvestment: createInvestmentResponse } = await client.request(CREATE_INVESTMENT, dto)
  return createInvestmentResponse as UserInvestment
}

export function* createInvestmentSaga({ payload }: UserInvestmentsActions['createUserInvestment']): SagaGenerator<void> {
  try {
    if (payload) {
      const investment = yield* apiCall(createInvestment, payload)
      yield* put(userInvestmentsActions.createUserInvestmentSuccess(investment))
    }
  } catch (error) {
    if (error instanceof Error) console.log(error)
  }
}
