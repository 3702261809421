import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import type { SubgraphProjectTokenData } from '../../projectToken.types'
import { mapSubgraphProjectTokenQueryResultToSubgraphProjectTokenData } from '../../projectToken.mappers'
import { projectTokenActions } from '../../projectToken.slice'
import { subgraphCall } from '../../../../utils/subgraphCall'
import type { SubgraphProjectTokenQueryResult } from '../../../../types/subgraphQueryTypes'
import { MULTIPLE_PROJECT_TOKENS_QUERY } from '../../projectToken.queries'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'
import { SubgraphProjectTokenQueryResponseByAdresses } from '../../../../../staticContent'

export async function fetchSubgraphMultipleProjectToken(
  client: GraphQLClient,
  projectTokenAddresses: string[]
): Promise<SubgraphProjectTokenData[]> {
  const result = await client.rawRequest<{ projectTokens: SubgraphProjectTokenQueryResult[] }>(MULTIPLE_PROJECT_TOKENS_QUERY, {
    addresses: projectTokenAddresses,
  })

  return result.data.projectTokens.map(mapSubgraphProjectTokenQueryResultToSubgraphProjectTokenData)
}

export function fetchStaticSubgraphMultipleProjectToken(projectTokenAddress: string[]): SubgraphProjectTokenData[] {
  const projectToken = SubgraphProjectTokenQueryResponseByAdresses(projectTokenAddress)

  return projectToken.map(mapSubgraphProjectTokenQueryResultToSubgraphProjectTokenData)
}

export function* fetchSubgraphAllProjectTokensSaga(): SagaGenerator<void> {
  try {
    const projectsBases = yield* select(projectBaseSelectors.selectAll)

    const legacyProjectTokenAddresses: string[] = []
    const projectTokenAddresses: string[] = []

    projectsBases.forEach((projectBase) => {
      if (projectBase.isLegacy) legacyProjectTokenAddresses.push(projectBase.projectTokenAddress)
      if (!projectBase.isLegacy) projectTokenAddresses.push(projectBase.projectTokenAddress)
    })

    const projectTokens: SubgraphProjectTokenData[] = []

    if (legacyProjectTokenAddresses.length > 0) {
      const legacyProjectTokensData = yield* call(fetchStaticSubgraphMultipleProjectToken, legacyProjectTokenAddresses)
      projectTokens.push(...legacyProjectTokensData)
    }

    if (projectTokenAddresses.length > 0) {
      const projectTokensData = yield* subgraphCall(fetchSubgraphMultipleProjectToken, projectTokenAddresses)
      projectTokens.push(...projectTokensData)
    }

    yield* put(projectTokenActions.fetchSubgraphAllSuccess(projectTokens))
  } catch (error) {
    if (error instanceof Error) yield* put(projectTokenActions.fetchSubgraphAllFailure(error.message))
  }
}
