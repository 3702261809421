import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { stakeTokenActions } from './stakeToken.slice'
import { fetchSubgraphAllStakeTokensSaga } from './saga/fetchFromSubgraph/all'
import { fetchSubgraphForProjectStakeTokensSaga } from './saga/fetchFromSubgraph/forProject'
import { createAndExecuteStakeTransactionPlanSaga } from './saga/createAndExecuteStakeTransactionPlan/createAndExecuteStakeTransactionPlan.saga'
import { createAndExecuteUnstakeTransactionPlanSaga } from './saga/createAndExecuteUnstakeTransactionPlan/createAndExecuteUnstakeTransactionPlan.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(stakeTokenActions.stake, createAndExecuteStakeTransactionPlanSaga),
    takeLatest(stakeTokenActions.unstake, createAndExecuteUnstakeTransactionPlanSaga),
    takeLatest(stakeTokenActions.fetchSubgraphAll, fetchSubgraphAllStakeTokensSaga),
    takeLatest(stakeTokenActions.fetchSubgraphForProject, fetchSubgraphForProjectStakeTokensSaga),
  ])
}

export function* stakeTokenSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
