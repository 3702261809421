import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { FundraiseStatuses } from './fundraise.types'

export class FundraiseState implements FundraiseStatuses {
  investStatus = RequestStatus.Idle
  fetchSubgraphAllStatus = RequestStatus.Idle
  fetchSubgraphSingleStatus = RequestStatus.Idle
  swapFundTokensStatus = RequestStatus.Idle
  error: RequestError = null
}
