import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, fork, takeLatest, takeLeading } from 'typed-redux-saga'

import { getBalancesBlockchainSaga } from '../balances/saga/fetchBalances/getBalancesBlockchain/getBalancesBlockchain.saga'

import { connectLatestWalletSaga } from './saga/connectLatestWallet/connectLatestWallet.saga'
import { disconnectWalletSaga } from './saga/disconnectWallet/disconnectWallet.saga'
import { web3Actions } from './web3.slice'
import { web3InitializationRequestSaga } from './saga/web3InitializationRequest.saga'
import { activateWeb3RequestSaga } from './saga/activateWeb3.saga'
import { deactivateWeb3Saga } from './saga/deacivateWeb3.saga'
import { watchLatestBlockNumber } from './saga/watchLatestBlock/watchLatestBlock.saga'
import { addNewWalletSaga as addNewMetamaskWalletSaga } from './saga/metamask/addNewWallet/addNewWallet.saga'
import { setExpectedMetamaskChainSaga } from './saga/metamask/changeNetwork/changeNetwork.saga'
import { accountChangedSaga } from './saga/metamask/accountChanged.saga'

export function* watchers(): Generator {
  yield* all([fork(watchLatestBlockNumber)])
}
function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(web3Actions.disconnectWallet.type, disconnectWalletSaga),
    takeLatest(web3Actions.connectLatestWallet.type, connectLatestWalletSaga),
    takeLatest(web3Actions.connectWalletRequest.type, activateWeb3RequestSaga),
    takeLatest(web3Actions.addNewMetamaskWalletRequest.type, addNewMetamaskWalletSaga),
    takeLeading(web3Actions.web3InitializationRequest.type, web3InitializationRequestSaga),
    takeLatest(web3Actions.web3InitializationSuccess.type, getBalancesBlockchainSaga),
    takeLatest(web3Actions.setExpectedChain.type, setExpectedMetamaskChainSaga),
    takeLatest(web3Actions.accountsChanged.type, accountChangedSaga),
    takeLatest(web3Actions.web3DeactivationRequest.type, deactivateWeb3Saga),
  ])
}

export function* web3Saga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
