import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { PhasesBackendAllQueryResponse } from '../../../../../staticContent'
import type { BackendPhase, PhaseData } from '../../phases.types'
import { mapBackendPhaseQueryResultToBackendPhaseData } from '../../phases.mappers'
import type { BackendPhaseQueryResult } from '../../../../types/backendQueryTypes'
import { phasesActions } from '../../phases.slice'
import { apiCall } from '../../../../utils/apiCall'
import { emptySubgraphPhase } from '../../phases.const'

export async function fetchBackendAllPhases(client: GraphQLClient): Promise<BackendPhase[]> {
  const result: BackendPhaseQueryResult[] = await Promise.resolve(PhasesBackendAllQueryResponse())

  return result.map(mapBackendPhaseQueryResultToBackendPhaseData)
}

export function* fetchBackendAllPhasesSaga(): SagaGenerator<void> {
  try {
    const allBackendPhases = yield* apiCall(fetchBackendAllPhases)

    const mappedToPhases: PhaseData[] = allBackendPhases.map((backendPhase) => ({
      ...emptySubgraphPhase,
      ...backendPhase,
    }))

    yield* put(phasesActions.fetchBackendAllSuccess(mappedToPhases))
  } catch (error) {
    if (error instanceof Error) yield* put(phasesActions.fetchBackendAllFailure(error.message))
  }
}
