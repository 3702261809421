import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'
import { ClientError } from 'graphql-request'

import { sessionActions } from '../store/session/session.slice'
import { sessionSelectors } from '../store/session/session.selectors'

import { getAccessToken } from './getAccessToken'

export function* apiCall<Fn extends (graphQLClientPromise: GraphQLClient, ...args: any[]) => any>(
  action: Fn,
  ...args: Parameters<Fn> extends [GraphQLClient, ...infer Rest] ? Rest : never
) {
  try {
    const auth0Client = yield* select(sessionSelectors.selectAuth0Client)
    const graphqlClient = yield* select(sessionSelectors.selectGraphQLClient)

    if (typeof window !== 'undefined') {
      const isAuth = yield* call([auth0Client, auth0Client.isAuthenticated])
      if (isAuth) {
        const token = yield* call(getAccessToken)
        graphqlClient.setHeader('Authorization', `Bearer ${token}`)
      }
    }

    return yield* call(action, graphqlClient, ...args)
  } catch (error) {
    if (error instanceof ClientError && error.response.errors) {
      const authenticationError = error.response.errors.find((error_) => error_.message === 'Unauthorized')
      if (authenticationError) yield* put(sessionActions.logoutStart())
    }
    throw error
  }
}
