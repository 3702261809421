import type { SagaGenerator } from 'typed-redux-saga'
import { call } from 'typed-redux-saga'

import { staticActions, type StaticActions } from '../../static.slice'
import { defaultStaticSetupSaga } from '../defaultStaticSetup/defaultStaticSetup'
import { fetchAllProjectsBaseSaga } from '../../../projectBase/sagas/fetchAllProjects/fetchAllProjects'
import { fetchSubgraphAllFundraiseSaga } from '../../../fundraise/saga/fetchFromSubgraph/all'
import { fetchBackendAllProjectTokensSaga } from '../../../projectToken/saga/fetchFromBackend/all'
import { fetchSubgraphAllProjectTokensSaga } from '../../../projectToken/saga/fetchFromSubgraph/all'
import { fetchBackendAllPhasesSaga } from '../../../phases/sagas/fetchFromBackend/all'
import { fetchSubgraphAllPhasesSaga } from '../../../phases/sagas/fetchFromSubgraph/all'
import { fetchUserInvestmentsSaga } from '../../../userInvestments/sagas/fetchUserInvestments/fetchUserInvestments.saga'

export function* transactionsSubgraphSetupSaga(): SagaGenerator<void> {
  yield* call(fetchSubgraphAllFundraiseSaga)
  yield* call(fetchSubgraphAllProjectTokensSaga)
  yield* call(fetchSubgraphAllPhasesSaga)
  yield* call(fetchUserInvestmentsSaga)
}

export function* transactionsStaticSetupSaga({ payload }: StaticActions['startDashboardStaticSetup']): SagaGenerator<void> {
  const { context } = payload

  yield* call(defaultStaticSetupSaga, staticActions.startDefaultStaticSetup({ context }))

  yield* call(fetchAllProjectsBaseSaga)

  yield* call(fetchBackendAllProjectTokensSaga)

  yield* call(fetchBackendAllPhasesSaga)
}
