import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import type { SubgraphProjectTokenData } from '../../projectToken.types'
import { mapSubgraphProjectTokenQueryResultToSubgraphProjectTokenData } from '../../projectToken.mappers'
import { projectTokenActions } from '../../projectToken.slice'
import type { SubgraphProjectTokenQueryResult } from '../../../../types/subgraphQueryTypes'
import { subgraphCall } from '../../../../utils/subgraphCall'
import { SINGLE_PROJECT_TOKEN_QUERY } from '../../projectToken.queries'
import { SubgraphProjectTokenQueryResponseByAddress } from '../../../../../staticContent'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'

export async function fetchSubgraphSingleProjectToken(
  client: GraphQLClient,
  projectTokenAddress: string
): Promise<SubgraphProjectTokenData> {
  const result = await client.rawRequest<{ projectTokens: SubgraphProjectTokenQueryResult[] }>(SINGLE_PROJECT_TOKEN_QUERY, {
    address: projectTokenAddress,
  })

  if (!result.data) throw new Error(`Project token with address ${projectTokenAddress} not found`)

  return result.data.projectTokens.map(mapSubgraphProjectTokenQueryResultToSubgraphProjectTokenData)[0]
}

export function fetchStaticSubgraphSingleProjectToken(projectTokenAddress: string): SubgraphProjectTokenData {
  const projectToken = SubgraphProjectTokenQueryResponseByAddress(projectTokenAddress)
  if (!projectToken) throw new Error(`Project token with address ${projectTokenAddress} not found`)

  return mapSubgraphProjectTokenQueryResultToSubgraphProjectTokenData(projectToken)
}

export function* fetchSubgraphSingleProjectTokenSaga(): SagaGenerator<void> {
  try {
    const currentProject = yield* select(projectBaseSelectors.selectCurrent)
    if (!currentProject) throw new Error('fetchSubgraphSingleFundraiseSaga - currentProject is undefined')

    const singleProjectToken = yield* currentProject.isLegacy
      ? call(fetchStaticSubgraphSingleProjectToken, currentProject.projectTokenAddress)
      : subgraphCall(fetchSubgraphSingleProjectToken, currentProject.projectTokenAddress)

    yield* put(projectTokenActions.fetchSubgraphSingleSuccess(singleProjectToken))
  } catch (error) {
    if (error instanceof Error) yield* put(projectTokenActions.fetchSubgraphSingleFailure(error.message))
  }
}
