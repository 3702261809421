import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import { v4 as uuid } from 'uuid'
import { utils } from 'ethers'

import { investmentSelectors } from '../investment.selectors'
import { transactionsActions } from '../../transactions/transaction.slice'
import type { TransactionBase } from '../../transactions/transaction.types'
import { TransactionType } from '../../transactions/transaction.types'
import { pushNotification } from '../../../utils/pushNotification'

import { allowanceContractCallSaga } from './allowanceContractCall.saga'

export function* createInvestmentTransactionsPlanSaga(): SagaGenerator<TransactionBase[]> {
  const investmentTransactionsPlan: TransactionBase[] = []

  try {
    const investmentAmountData = yield* select(investmentSelectors.selectInvestmentAmountData)
    const investmentSetupData = yield* select(investmentSelectors.selectInvestmentSetupData)
    const investmentId = yield* select(investmentSelectors.selectInvestmentId)

    if (!investmentAmountData || !investmentSetupData || !investmentId) throw new Error('Investment data not provided')

    const allowance = yield* call(allowanceContractCallSaga, investmentSetupData.tokenInAddress, investmentSetupData.fundraiseId)

    const tokenInAmountBN = utils.parseUnits(String(investmentAmountData.tokenInAmount), 6)
    if (allowance.lt(tokenInAmountBN))
      investmentTransactionsPlan.push({
        type: TransactionType.Approve,
        source: investmentId,
        payload: { spenderAddress: investmentSetupData.fundraiseId, amount: tokenInAmountBN.toString() },
        id: uuid(),
      })
    investmentTransactionsPlan.push({
      type: TransactionType.Invest,
      source: investmentId,
      payload: {
        amount: tokenInAmountBN.toString(),
      },
      id: uuid(),
    })

    yield* put(transactionsActions.addTransactions(investmentTransactionsPlan))
  } catch (error) {
    if (error instanceof Error) pushNotification(`Unable to create investment transactions: ${error.message}`, 'error')
  }

  return investmentTransactionsPlan
}
