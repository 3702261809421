import { createSelector } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import { selectReducer } from '../store.utils'

const selectState = createSelector([selectReducer(StoreKeys.LegacyTokens)], (state) => state)

export const selectLegacyProjectTokens = createSelector([selectState], (state) => state.projectTokens)
export const selectLegacyRewardTokens = createSelector([selectState], (state) => state.rewardTokens)

export const selectSwapTokensTransactionId = createSelector([selectState], (state) => state.swapTokensTransactionId)
export const selectSwapTokensTransactionStatus = createSelector([selectState], (state) => state.swapTokensStatus)

export const legacyTokensSelectors = {
  selectSwapTokensTransactionStatus,
  selectSwapTokensTransactionId,
  selectRewardTokens: selectLegacyRewardTokens,
  selectProjectTokens: selectLegacyProjectTokens,
}
