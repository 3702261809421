import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { FetchBalancesPayload, TokenBalance, TokenBalanceData, TransferBalancePayload } from './balances.types'
import { BalancesState } from './balances.state'

export const balancesAdapter = createEntityAdapter<TokenBalanceData>({
  sortComparer: (a, b) => `${a.tokenAddress}|${a.account}`.localeCompare(`${b.tokenAddress}|${b.account}`),
  selectId: (balance) => `${balance.tokenAddress}|${balance.account}`,
})

export const initialBalancesSliceState: TokenBalance = balancesAdapter.getInitialState({
  ...new BalancesState(),
})

export const balancesSlice = createSlice({
  reducers: {
    updateBalancesSuccess: (state, action: PayloadAction<TokenBalanceData[]>) => {
      balancesAdapter.upsertMany(state, action.payload)
      state.balancesBlockchainStatus = RequestStatus.Succeeded
      return state
    },
    updateBalancesFailure: (state, action: PayloadAction<RequestError>) => {
      state.balancesBlockchainStatus = RequestStatus.Failed
      state.error = action.payload
      return state
    },
    updateBalances: (state) => {
      state.balancesBlockchainStatus = RequestStatus.Loading
      return state
    },
    transferBalancesSuccess: (state) => {
      state.transferBalancesStatus = RequestStatus.Succeeded
      state.transferTransactionId = null
    },
    transferBalancesFailure: (state, action: PayloadAction<RequestError>) => {
      state.transferBalancesStatus = RequestStatus.Failed
      state.error = action.payload
      state.transferTransactionId = null
    },
    transferBalances: (state, _: PayloadAction<TransferBalancePayload>) => {
      state.transferBalancesStatus = RequestStatus.Loading
      state.transferTransactionId = uuid()
    },

    fetchLegacyBalancesSuccess: (state, action: PayloadAction<TokenBalanceData[]>) => {
      state.fetchLegacyBalancesStatus = RequestStatus.Succeeded
      balancesAdapter.upsertMany(state, action.payload)

      return state
    },
    fetchLegacyBalancesError: (state, action: PayloadAction<RequestError>) => {
      state.fetchLegacyBalancesStatus = RequestStatus.Failed
      state.error = action.payload
      return state
    },
    fetchLegacyBalances: (state, _: PayloadAction<FetchBalancesPayload>) => {
      state.fetchLegacyBalancesStatus = RequestStatus.Loading
      return state
    },
    fetchInternalBalancesSuccess: (state, action: PayloadAction<TokenBalanceData[]>) => {
      state.fetchInternalBalancesStatus = RequestStatus.Succeeded
      balancesAdapter.upsertMany(state, action.payload)

      return state
    },
    fetchInternalBalancesError: (state, action: PayloadAction<RequestError>) => {
      state.fetchInternalBalancesStatus = RequestStatus.Failed
      state.error = action.payload
      return state
    },
    fetchInternalBalances: (state, _: PayloadAction<FetchBalancesPayload>) => {
      state.fetchInternalBalancesStatus = RequestStatus.Loading
      return state
    },
    fetchAllLegacyBalances: () => {},
    fetchAllInternalBalances: () => {},
    clearBalances: (state) => {
      balancesAdapter.removeAll(state)
      state.fetchInternalBalancesStatus = RequestStatus.Idle
      state.balancesBlockchainStatus = RequestStatus.Idle
      return state
    },
  },
  name: StoreKeys.Balances,
  initialState: initialBalancesSliceState,
})

export const balancesAdapterSelectors = balancesAdapter.getSelectors()
export const balancesActions = balancesSlice.actions
export const balancesReducer = balancesSlice.reducer
export type BalancesActions = ActionsType<typeof balancesActions>
