// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgDashboard = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m16 8.505-2.5 2.5m4.796 1.5h-.9m-11.701 0h.9m5.401-6.3v.9M7.54 8.05l.45.45M14 12.505a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM11.719 3.5A9.1 9.1 0 0 0 3 12.481 8.979 8.979 0 0 0 6.739 19.8c.667.466 1.463.71 2.276.7h5.978a3.814 3.814 0 0 0 2.227-.677A8.995 8.995 0 0 0 11.719 3.5Z"
    />
  </svg>
)
export default SvgDashboard
