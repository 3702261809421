import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { Cryptocurrencies, CryptocurrenciesData } from './cryptocurrencies.types'
import { CryptocurrenciesState } from './cryptocurrencies.state'

export const cryptocurrenciesAdapter = createEntityAdapter<CryptocurrenciesData>({
  selectId: (cryptocurrenciesData) => cryptocurrenciesData.address,
})
export const initialCryptocurrenciesSliceState: Cryptocurrencies = {
  ...cryptocurrenciesAdapter.getInitialState(new CryptocurrenciesState()),
}

export const cryptocurrenciesSlice = createSlice({
  reducers: {
    mintUSDCSuccess: (state) => {
      state.mintUSDCStatus = RequestStatus.Succeeded
    },
    mintUSDCFailure: (state, action: PayloadAction<RequestError>) => {
      state.mintUSDCStatus = RequestStatus.Failed
      state.error = action.payload
    },
    mintUSDC: (state) => {
      state.mintUSDCStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.Cryptocurrencies,
  initialState: initialCryptocurrenciesSliceState,
})

export const cryptocurrenciesAdapterSelectors = cryptocurrenciesAdapter.getSelectors()
export const cryptocurrenciesActions = cryptocurrenciesSlice.actions
export const cryptocurrenciesReducer = cryptocurrenciesSlice.reducer
export type CryptocurrenciesActions = ActionsType<typeof cryptocurrenciesActions>
