import { RequestStatus } from '../types'

import type { PhaseStatuses } from './phases.types'

export class PhasesState implements PhaseStatuses {
  error: string | null = null
  fetchSubgraphAllStatus: RequestStatus = RequestStatus.Idle
  fetchSubgraphSingleStatus: RequestStatus = RequestStatus.Idle
  fetchFromBackendStatus: RequestStatus = RequestStatus.Idle
}
