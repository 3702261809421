import type { SagaGenerator } from 'typed-redux-saga'
import { call, put } from 'typed-redux-saga'

import { web3Actions } from '../../web3.slice'
import { WalletTypeEnum } from '../../../../graphql/generated/graphql'
import { getLatestWalletType } from '../../../session/session.utils'

export function* connectLatestWalletSaga(): SagaGenerator<void> {
  const latestWalletType = yield* call(getLatestWalletType)

  if (latestWalletType === WalletTypeEnum.Metamask) {
    yield* put(web3Actions.connectWalletRequest({ walletType: WalletTypeEnum.Metamask }))
    return
  }

  if (latestWalletType === WalletTypeEnum.WalletConnect) {
    yield* put(web3Actions.connectWalletRequest({ walletType: WalletTypeEnum.WalletConnect }))
    return
  }

  yield* put(web3Actions.connectWalletRequest({ walletType: WalletTypeEnum.ParticleNetwork }))
}
