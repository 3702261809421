import type { CallEffect } from '@redux-saga/core/effects'
import type { MetaMaskInpageProvider } from '@metamask/providers'
import type { SagaGenerator } from 'typed-redux-saga'
import { call, put } from 'typed-redux-saga'
import detectEthereumProvider from '@metamask/detect-provider'
import type { Effect, StrictEffect } from '@redux-saga/types'
import type { ContractTransaction } from 'ethers'

import { web3Actions } from '../../web3.slice'
import { pushNotification } from '../../../../utils/pushNotification'
import { WalletTypeEnum } from '../../../../graphql/generated/graphql'

import { MetamaskActions, MetamaskError } from './metamask.types'

export function* detectMetamask(): Generator<CallEffect, MetaMaskInpageProvider> {
  const metamaskProvider: MetaMaskInpageProvider = (yield* call(detectEthereumProvider, { mustBeMetaMask: true })) as MetaMaskInpageProvider

  return metamaskProvider
}

export const changeMetamaskAccount = async () => {
  await window.ethereum.request({
    params: [
      {
        eth_accounts: {},
      },
    ],
    method: MetamaskActions.requestPermissions,
  })
}

export const requestMetamaskAccounts = async () => {
  const accounts = await window.ethereum.request({
    params: [
      {
        eth_accounts: {},
      },
    ],
    method: MetamaskActions.requestAccounts,
  })
  return accounts as string[]
}

export function* metamaskInstalled(
  effect: SagaGenerator<Generator, Effect> | Generator<Effect>
): Generator<typeof effect | StrictEffect, ContractTransaction | void> {
  const metamaskProvider = yield* call(detectMetamask)

  if (metamaskProvider) yield effect
  else {
    yield* put(web3Actions.connectWalletFailure({ walletType: WalletTypeEnum.Metamask, error: MetamaskError.ProviderNotDetected }))
    pushNotification(`Please install Metamask https://metamask.io/download/`, 'error')
  }
}
