import type { SagaGenerator } from 'typed-redux-saga'
import { call } from 'typed-redux-saga'

import { staticActions, type StaticActions } from '../../static.slice'
import { defaultStaticSetupSaga } from '../defaultStaticSetup/defaultStaticSetup'
import { fetchAllMosaicoAssetsInfoSaga } from '../../../mosaicoAssets/sagas/fetchAllMosaicoAssetsInfo'

export function* mosaicoAssetsStaticSetupSaga({ payload }: StaticActions['startMosaicoAssetsStaticSetup']): SagaGenerator<void> {
  const { context } = payload

  yield* call(defaultStaticSetupSaga, staticActions.startDefaultStaticSetup({ context }))

  yield* call(fetchAllMosaicoAssetsInfoSaga)
}
