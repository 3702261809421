import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { FundraiseData } from '../../fundraise.types'
import { fundraiseActions } from '../../fundraise.slice'
import type { SubgraphFundraisesQueryResult } from '../../../../types/subgraphQueryTypes'
import { mapFundraisesQueryResultToFundraiseData } from '../../fundraise.mappers'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'
import { SINGLE_FUNDRAISE_QUERY } from '../../fundraise.queries'
import { SubgraphFundraiseQueryResponseByAddress } from '../../../../../staticContent'

export async function fetchSubgraphSingleFundraise(client: GraphQLClient, fundraiseAddress: string): Promise<FundraiseData> {
  const result = await client.rawRequest<{ fundraises: SubgraphFundraisesQueryResult[] }>(SINGLE_FUNDRAISE_QUERY, {
    address: fundraiseAddress,
  })
  if (!result) throw new Error(`Fundraise with address ${fundraiseAddress} not found`)

  return result.data.fundraises.map(mapFundraisesQueryResultToFundraiseData)[0]
}

export function fetchStaticSubgraphSingleFundraise(fundraiseAddress: string): FundraiseData {
  const fundraise = SubgraphFundraiseQueryResponseByAddress(fundraiseAddress)
  if (!fundraise) throw new Error(`Fundraise with address ${fundraiseAddress} not found`)

  return mapFundraisesQueryResultToFundraiseData(fundraise)
}

export function* fetchSubgraphSingleFundraiseSaga(): SagaGenerator<void> {
  try {
    const currentProject = yield* select(projectBaseSelectors.selectCurrent)
    if (!currentProject) throw new Error('fetchSubgraphSingleFundraiseSaga - currentProject is undefined')

    const singleFundraise = yield* currentProject.isLegacy
      ? call(fetchStaticSubgraphSingleFundraise, currentProject.fundrisingAddress)
      : subgraphCall(fetchSubgraphSingleFundraise, currentProject.fundrisingAddress)

    yield* put(fundraiseActions.fetchSubgraphSingleSuccess(singleFundraise))
  } catch (error) {
    if (error instanceof Error) yield* put(fundraiseActions.fetchSubgraphSingleFailure(error.message))
  }
}
