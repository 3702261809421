import { gql } from 'graphql-request'

export const PROJECT_TOKEN_FRAGMENT = gql`
  fragment projectToken on ProjectToken {
    id
    name
    symbol
    decimals
    address
    totalSupply
    tranches {
      id
    }
    tranchesCounter
    rewardTokenERC20 {
      address
    }
    rewardStablecoin {
      address
    }
  }
`
export const MULTIPLE_PROJECT_TOKENS_QUERY = gql`
  query multipleProjectTokens($addresses: [String!]) {
    projectTokens(where: { address_in: $addresses }) {
      ...projectToken
    }
  }
  ${PROJECT_TOKEN_FRAGMENT}
`

export const SINGLE_PROJECT_TOKEN_QUERY = gql`
  query projectToken($address: String!) {
    projectTokens(where: { address: $address }) {
      ...projectToken
    }
  }
  ${PROJECT_TOKEN_FRAGMENT}
`
