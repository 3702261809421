import legacyProjectTokens from '../../../staticContent/legacy-tokens/project-tokens.json'
import legacyRewardTokens from '../../../staticContent/legacy-tokens/reward-tokens.json'
import { RequestStatus } from '../types'

import type { LegacyProjectToken, LegacyRewardToken } from './legacyTokens.types'

export class LegacyTokensState {
  projectTokens: LegacyProjectToken[] = legacyProjectTokens as LegacyProjectToken[]
  rewardTokens: LegacyRewardToken[] = legacyRewardTokens
  swapTokensStatus: RequestStatus = RequestStatus.Idle
  error: string | null = null
  swapTokensTransactionId: string | null = null
}
