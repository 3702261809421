import type { RampInstantPurchase } from '@ramp-network/ramp-instant-sdk'

import type { FundraiseData } from '../fundraise/fundraise.types'
import type { ProjectBaseData } from '../projectBase/projectBase.types'

import type { Agreement, InvestmentStep, PaymentMethod } from './investment.types'

export interface InvestmentBase {
  id: string
}

export interface InvestmentSetupData {
  tokenInAddress: string
  tokenOutAddress: string
  projectId: ProjectBaseData['id']
  fundraiseId: FundraiseData['id']
}

export interface InvestmentAmountData {
  tokenInAmount: string
  tokenOutAmount: string
}

export interface InvestmentPaymentMethodData {
  walletAddress: string
  paymentMethod: PaymentMethod
  agreements: Agreement[]
}

export enum InvestmentStatus {
  Success,
  Failure,
}

export interface RampInvestmentData {
  rampPurchaseId: RampInstantPurchase['id']
}

export interface RampInvestmentResultData {
  status: InvestmentStatus
}

export interface CryptoInvestmentData {}

export interface CryptoInvestmentResultData {
  status: InvestmentStatus
}

export type TInvestmentStep = { step: InvestmentStep; nextStep: InvestmentStep }

export type InvestmentIdleStep = TInvestmentStep

export type InvestmentSetupStep = TInvestmentStep & InvestmentBase & InvestmentSetupData

export type InvestmentAmountStep = TInvestmentStep & InvestmentBase & InvestmentAmountData & InvestmentSetupData

export type InvestmentPaymentMethodStep = TInvestmentStep &
  InvestmentBase &
  InvestmentAmountData &
  InvestmentSetupData &
  InvestmentPaymentMethodData

export type RampInvestmentStep = TInvestmentStep &
  InvestmentBase &
  InvestmentAmountData &
  InvestmentSetupData &
  InvestmentPaymentMethodData &
  RampInvestmentData

export type RampInvestmentResultStep = TInvestmentStep &
  InvestmentBase &
  InvestmentAmountData &
  InvestmentSetupData &
  InvestmentPaymentMethodData &
  RampInvestmentData &
  RampInvestmentResultData

export type CryptoInvestmentStep = TInvestmentStep &
  InvestmentBase &
  InvestmentAmountData &
  InvestmentSetupData &
  InvestmentPaymentMethodData &
  CryptoInvestmentData

export type CryptoInvestmentResultStep = TInvestmentStep &
  InvestmentBase &
  InvestmentAmountData &
  InvestmentSetupData &
  InvestmentPaymentMethodData &
  CryptoInvestmentStep &
  CryptoInvestmentResultData

export type InvestmentState =
  | InvestmentIdleStep &
      Partial<
        | InvestmentSetupStep
        | InvestmentAmountStep
        | InvestmentPaymentMethodStep
        | RampInvestmentStep
        | RampInvestmentResultStep
        | CryptoInvestmentStep
        | CryptoInvestmentResultStep
      >
