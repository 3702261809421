import React from 'react'

import type { IconProps } from '../../SVG/Icon.types'
import { useHTMLElementEvents } from '../ButtonBase/useHTMLElementEvents.hook'
import { useSelectedStateIconColor } from '../ButtonBase/useSelectedStateColor.hook'
import { ButtonBaseStyled } from '../ButtonBase/ButtonBase.styles'

import type { IconButtonProps } from './IconButton.types'

export const IconButton: React.FC<IconButtonProps> = ({
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  children,
  ...props
}) => {
  const { disabled, variant, color } = props
  const isIcon = React.isValidElement(children)

  const { pressed, focusVisible, hovered, handleMouseDown, handleMouseEnter, handleMouseLeave, handleMouseUp, handleFocus, handleBlur } =
    useHTMLElementEvents({
      onMouseUp,
      onMouseLeave,
      onMouseEnter,
      onMouseDown,
      onFocus,
      onBlur,
      disabled,
    })

  const { iconColor } = useSelectedStateIconColor({ variant, pressed, hovered, focusVisible, disabled, color })

  return (
    <ButtonBaseStyled
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onFocusVisible={handleFocus}
      {...props}
    >
      {children &&
        isIcon &&
        React.cloneElement<IconProps>(children, { size: children.props.size || 24, color: children.props.color || iconColor })}
    </ButtonBaseStyled>
  )
}
