import { call, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { sessionSelectors } from '../store/session/session.selectors'

export function* subgraphCall<Fn extends (graphQLClientPromise: GraphQLClient, ...args: any[]) => any>(
  action: Fn,
  ...args: Parameters<Fn> extends [GraphQLClient, ...infer Rest] ? Rest : never
) {
  const subgraphGraphqlClient = yield* select(sessionSelectors.selectSubgraphGraphQLClient)
  return yield* call(action, subgraphGraphqlClient, ...args)
}
