import { createSelector } from '@reduxjs/toolkit'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'
import { RequestStatus } from '../types'
import { projectBaseSelectors } from '../projectBase/projectBase.selectors'

import { projectTokenAdapterSelectors } from './projectToken.slice'

export const selectState = createSelector([selectReducer(StoreKeys.ProjectToken)], (state) => state)

export const selectAll = createSelector([selectState], (state) => projectTokenAdapterSelectors.selectAll(state))

const selectCurrent = createSelector([selectState, projectBaseSelectors.selectCurrent], (state, projectBase) =>
  projectTokenAdapterSelectors.selectById(state, projectBase?.projectTokenAddress || '')
)

export const makeSelectByAddress = () =>
  createSelector([selectAll, (_: unknown, address: string) => address], (all, address) =>
    all.find((projectToken) => projectToken.address === address)
  )

export const selectByAddress = makeSelectByAddress()

export const selectInvestStatus = createSelector([selectState], (state) => state.investStatus)
export const selectIsInvestPending = createSelector([selectInvestStatus], (investStatus) => investStatus === RequestStatus.Loading)

export const projectTokenSelectors = {
  selectState,
  selectIsInvestPending,
  selectInvestStatus,
  selectCurrent,
  selectByAddress,
  selectAll,
  makeSelectByAddress,
}
