// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgExternalLink = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g stroke={props.color ?? '#0050FF'} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M7 7.5v-1a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-1" />
      <path d="M3 10.611v7.778A3.111 3.111 0 0 0 6.111 21.5h7.778A3.111 3.111 0 0 0 17 18.389V10.61A3.111 3.111 0 0 0 13.889 7.5H6.11A3.111 3.111 0 0 0 3 10.611ZM8 16.5l4-4" />
      <path d="M8 12.5h4v4" />
    </g>
    <defs>
      <clipPath id="external-link_svg__a">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgExternalLink
