import { RequestStatus } from '../types'

import type { RewardsStatusess } from './rewards.types'

export class RewardsState implements RewardsStatusess {
  error = null
  calculateRewardsStatus: RequestStatus = RequestStatus.Idle
  claimLiquidStakeRewardsStatus: RequestStatus = RequestStatus.Idle
  claimTimeStakeRewardsStatus: RequestStatus = RequestStatus.Idle
}
