import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { WalletTypeEnum } from '../../graphql/generated/graphql'
import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'

import { Web3ActivationStatus, Web3ConnectionStatus, Web3State } from './web3.state'
import type { MetamaskError } from './saga/metamask/metamask.types'

export const initialWeb3State = { ...new Web3State() }

export const web3Slice = createSlice({
  reducers: {
    web3InitializationSuccess(state, action: PayloadAction<Pick<Web3State, 'web3' | 'walletType'>>) {
      state.walletType = action.payload.walletType
      state.connectionStatus = Web3ConnectionStatus.Success
      state.activationStatus = Web3ActivationStatus.Success
      state.error = null
    },
    web3InitializationRequest(state, action: PayloadAction<Pick<Web3State, 'web3'>>) {
      state.web3 = action.payload.web3
      state.connectionStatus = Web3ConnectionStatus.InProgress
      state.activationStatus = Web3ActivationStatus.InProgress
    },
    web3InitializationFailure(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.connectionStatus = Web3ConnectionStatus.Failure
      state.activationStatus = Web3ActivationStatus.Failure
    },
    web3DeactivationSuccess(state) {
      state.activationStatus = Web3ActivationStatus.Deactivated
    },
    web3DeactivationRequest() {},
    updateAccount() {},
    subscribeMetamaskActions() {},
    setLatestBlock(state, action: PayloadAction<number>) {
      state.latestBlock = action.payload
    },
    setExpectedChain() {},
    disconnectWallet(state) {
      state.walletType = null
    },
    connectWalletSuccess(state, action: PayloadAction<{ walletType: WalletTypeEnum; walletAddress?: string }>) {
      const { walletType, walletAddress } = action.payload
      state.walletType = walletType
      state.walletAddress = walletAddress
      state.error = null
      state.connectionStatus = Web3ConnectionStatus.Success
      state.activationStatus = Web3ActivationStatus.Success
    },
    connectWalletRequest(state, _action: PayloadAction<{ walletType: WalletTypeEnum; walletAddress?: string }>) {
      state.connectionStatus = Web3ConnectionStatus.InProgress
    },
    connectWalletFailure(state, action: PayloadAction<{ error: RequestError | MetamaskError; walletType: WalletTypeEnum }>) {
      state.error = action.payload.error
      state.walletType = action.payload.walletType
      state.connectionStatus = Web3ConnectionStatus.Failure
    },
    connectWalletConnect(state) {
      state.walletType = null
    },
    connectParticleNetwork(state) {
      state.walletType = null
    },
    connectLatestWallet(state) {
      state.walletType = null
    },
    addNewMetamaskWalletSuccess(state) {
      state.walletType = null
    },
    addNewMetamaskWalletRequest(state) {
      state.walletType = null
    },
    accountsChanged(state, action: PayloadAction<string[]>) {},
  },
  name: StoreKeys.Web3,
  initialState: initialWeb3State,
})

export const web3Reducer = web3Slice.reducer
export const web3Actions = web3Slice.actions
export type Web3Actions = ActionsType<typeof web3Actions>
