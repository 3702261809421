import type { ButtonProps as MuiButtonProps, Theme } from '@mui/material'

export const getSelectedStateCustomColor = (
  theme: Theme,
  hover: boolean,
  focusVisible: boolean,
  pressed: boolean,
  disabled = false,
  variant: MuiButtonProps['variant'] = 'contained',
  color: MuiButtonProps['color'] = 'primary'
) => {
  if (disabled)
    switch (variant) {
      case 'contained': {
        if (color === 'primary') return theme.palette._white
        if (color === 'white') return theme.palette._shade[600]
        break
      }
      case 'outlined': {
        if (color === 'primary') return theme.palette._text[400]
        if (color === 'secondary') return theme.palette._tint[600]
        if (color === 'pale') return theme.palette._shade[600]
        break
      }
      case 'text': {
        if (color === 'primary') return theme.palette._shade[600]
        if (color === 'secondary') return theme.palette._tint[600]
        break
      }
      default: {
        break
      }
    }

  if (pressed && variant === 'outlined' && color === 'primary') return theme.palette._white

  if ((hover || focusVisible) && variant === 'outlined' && color === 'primary') return theme.palette._white

  switch (variant) {
    case 'contained': {
      if (color === 'primary') return theme.palette._white
      if (color === 'white') return theme.palette._text[600]
      break
    }
    case 'outlined': {
      if (color === 'primary') return theme.palette._text[600]
      if (color === 'secondary') return theme.palette._white
      if (color === 'pale') return theme.palette._text[600]
      break
    }
    case 'text': {
      if (color === 'primary') {
        if (hover || pressed || focusVisible) return theme.palette._primary[400]
        return theme.palette._text[600]
      }
      if (color === 'secondary') return theme.palette._white
      break
    }
    default: {
      return theme.palette._text[600]
    }
  }
}
