// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgPlay = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m10.313 6.208 7.934 4.521a1.45 1.45 0 0 1 0 2.542l-7.934 4.52C9.293 18.376 8 17.665 8 16.52V7.48c0-1.144 1.292-1.855 2.313-1.272Z"
    />
  </svg>
)
export default SvgPlay
