import type { Task } from 'redux-saga'
import createSagaMiddleware from 'redux-saga'
import type { Store } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import type { MakeStore } from 'next-redux-wrapper'
import { createWrapper } from 'next-redux-wrapper'

import { rootReducer } from './root.reducer'
import { rootSaga, rootStaticSaga } from './root.saga'

export interface SagaStore extends Store {
  sagaTask?: Task
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
    ],
    devTools: true,
  })
}

const makeStore: MakeStore<SagaStore> = () => {
  const sagaMiddleware = createSagaMiddleware()
  const store: SagaStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
    ],
    devTools: true,
  })

  store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}
const makeStaticStore: MakeStore<SagaStore> = () => {
  const sagaMiddleware = createSagaMiddleware()
  const store: SagaStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
    ],
    devTools: true,
  })

  store.sagaTask = sagaMiddleware.run(rootStaticSaga)

  return store
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>

export const store = createStore()
export const storeWrapper = createWrapper<AppStore>(makeStore)
export const storeStaticWrapper = createWrapper<AppStore>(makeStaticStore)
