import type { SubgraphRewardStablecoinQueryResult } from '../../types/subgraphQueryTypes'
import { BigDecimal } from '../../utils/bigDecimal'
import { DEFAULT_INTERNAL_TOKEN_DECIMALS } from '../../../config/decimals'

import type { RewardStablecoinSubgraphData } from './rewardStablecoin.types'

export const mapSubgraphRewardTokenERC20DataToRewardTokenERC20Data = (
  subgraphRewardTokenERC20Data: SubgraphRewardStablecoinQueryResult
): RewardStablecoinSubgraphData => {
  return {
    totalSupply: BigDecimal.fromString(subgraphRewardTokenERC20Data.totalSupply, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(
      DEFAULT_INTERNAL_TOKEN_DECIMALS
    ),
    symbol: subgraphRewardTokenERC20Data.symbol,
    stakeTokenAddresses: subgraphRewardTokenERC20Data.stakeToken.map((stakeToken) => stakeToken.address),
    projectTokenAddresses: subgraphRewardTokenERC20Data.projectToken.map((projectToken) => projectToken.address),
    name: subgraphRewardTokenERC20Data.name,
    id: subgraphRewardTokenERC20Data.id,
    decimals: Number(subgraphRewardTokenERC20Data.decimals),
    dailyReward: BigDecimal.fromString(subgraphRewardTokenERC20Data.dailyReward, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(
      DEFAULT_INTERNAL_TOKEN_DECIMALS
    ),
    address: subgraphRewardTokenERC20Data.address,
  }
}
