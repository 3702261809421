import { gql } from 'graphql-request'

export const REWARD_TOKEN_ERC20_FRAGMENT = gql`
  fragment rewardTokenERC20Fragment on RewardTokenERC20 {
    id
    name
    symbol
    decimals
    address
    totalSupply
    dailyReward
    projectToken {
      address
    }
    stakeToken {
      address
    }
  }
`

export const ALL_REWARD_TOKEN_ERC20S_QUERY = gql`
  query rewardTokens {
    rewardTokenERC20S {
      ...rewardTokenERC20Fragment
    }
  }
  ${REWARD_TOKEN_ERC20_FRAGMENT}
`
