import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../utils/subgraphCall'
import type { StakeTokenData } from '../../stakeToken.types'
import { stakeTokenActions } from '../../stakeToken.slice'
import type { SubgraphStakeTokenQueryResult } from '../../../../types/subgraphQueryTypes'
import { mapSubgraphStakeTokenQueryResultToStakeTokenData } from '../../stakeToken.mappers'
import { ALL_STAKE_TOKENS_QUERY } from '../../stakeToken.queries'

export async function fetchSubgraphAllStakeTokens(client: GraphQLClient): Promise<StakeTokenData[]> {
  const result = await client.rawRequest<{ stakeTokens: SubgraphStakeTokenQueryResult[] }>(ALL_STAKE_TOKENS_QUERY)
  return result.data.stakeTokens.map(mapSubgraphStakeTokenQueryResultToStakeTokenData)
}

export function* fetchSubgraphAllStakeTokensSaga(): SagaGenerator<void> {
  try {
    const stakeTokens = yield* subgraphCall(fetchSubgraphAllStakeTokens)

    yield* put(stakeTokenActions.fetchSubgraphAllSuccess(stakeTokens))
  } catch (error) {
    if (error instanceof Error) yield* put(stakeTokenActions.fetchSubgraphAllFailure(error.message))
  }
}
