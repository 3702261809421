import type { RewardTokenERC20Data, RewardTokenERC20Subgraph } from './rewardTokenERC20.types'

export enum RewardTokenType {
  ERC20 = 'ERC20',
  ERC1155 = 'ERC1155',
  STABLECOIN = 'STABLECOIN',
}

export const isRewardTokenERC20 = (token: RewardTokenERC20Data | undefined): token is RewardTokenERC20Data => {
  return token !== undefined && token.address !== undefined
}

export const emptySubgraphRewardTokenERC20Data: RewardTokenERC20Subgraph = {
  totalSupply: '0',
  symbol: '',
  stakeTokenAddress: null,
  rewardTokenType: RewardTokenType.ERC20,
  projectTokenAddress: null,
  name: '',
  id: '',
  decimals: 0,
  dailyReward: '0',
  address: '',
}
