// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgWarningTriangle = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#BA8600'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 12.12V8.38m0 8v-1m2.029-12.24 7.659 13.403c.89 1.558-.235 3.497-2.029 3.497H4.341c-1.795 0-2.92-1.939-2.029-3.497L9.971 3.14c.897-1.57 3.161-1.57 4.058 0Z"
    />
  </svg>
)
export default SvgWarningTriangle
