const envConfig = {
  subgraphGraphqlUri: process.env.SUBGRAPH_GRAPHQL_URI ?? '',
  rampWebhookUrl: process.env.RAMP_WEBHOOK_URL,
  rampUrl: process.env.RAMP_URL,
  rampAppName: process.env.RAMP_APP_NAME ?? 'Mosaico',
  rampApiKey: process.env.RAMP_API_KEY,
  polygonWsUrl: process.env.POLYGON_WS_URL ?? '',
  polygonJsonRpcUrl: process.env.POLYGON_JSON_RPC_URL ?? '',
  nextPublicPath: process.env.NEXT_PUBLIC_PATH ?? '',
  network: process.env.NETWORK ?? '',
  mosaicoApiUrl: process.env.MOSAICO_API_URL ?? '',
  kmsAddress: process.env.KMS_ADDRESS ?? '',
  gtmContainerId: process.env.GTM_CONTAINER_ID ?? '',
  graphqlUri: process.env.BASE_GRAPHQL_URI ?? 'https://api.v3.mosaico.ai/graphql',
  environment: process.env.ENVIRONMENT ?? '',
  documentsUrl: process.env.DOCUMENTS_URL ?? '',
  contactEmail: process.env.CONTACT_EMAIL ?? '',
  chainId: Number(process.env.CHAIN_ID ?? ''),
  auth0RedirectUri: process.env.AUTH0_REDIRECT_URI ?? '',
  auth0Domain: process.env.AUTH0_ISSUER_BASE_URL ?? '',
  auth0ClientId: process.env.AUTH0_CLIENT_ID ?? '',
  auth0BaseUrl: process.env.AUTH0_BASE_URL ?? '',
  auth0Audience: process.env.AUTH0_API_AUDIENCE ?? '',
}

export default envConfig
