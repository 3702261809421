import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'

import type { Transaction, TransactionBase } from './transaction.types'
import { TransactionStatus } from './transaction.types'

export const transactionsAdapter = createEntityAdapter<Transaction>()

export const transactionsInitialState = transactionsAdapter.getInitialState()

export const transactionsSlice = createSlice({
  reducers: {
    updateTransactionStatus(state, action: PayloadAction<Pick<Transaction, 'id' | 'status' | 'hash' | 'error'>>) {
      const { id: transactionId, error, status: transactionStatus, hash: transactionHash } = action.payload
      transactionsAdapter.updateOne(state, {
        id: transactionId,
        changes: {
          status: transactionStatus,
          hash: transactionHash,
          error,
        },
      })
    },
    clearTransactions(state, action: PayloadAction<TransactionBase[]>) {
      const { payload: transactions } = action
      transactionsAdapter.removeMany(
        state,
        transactions.map(({ id }) => id)
      )
    },
    addTransactions(state, action: PayloadAction<TransactionBase[]>) {
      const { payload: transactions } = action
      transactionsAdapter.addMany(
        state,
        transactions.map((transaction) => ({
          ...transaction,
          status: TransactionStatus.Pending,
        }))
      )
    },
    addTransaction(state, action: PayloadAction<TransactionBase>) {
      const { payload: transaction } = action
      transactionsAdapter.addOne(state, {
        ...transaction,
        status: TransactionStatus.Pending,
      })
    },
  },
  name: StoreKeys.Transactions,
  initialState: transactionsInitialState,
})

export const transactionsReducer = transactionsSlice.reducer
export const transactionsActions = transactionsSlice.actions
