import type { EntityState } from '@reduxjs/toolkit'

import type { RequestStatus } from '../types'
import type { Chains } from '../../../config/chains'

export enum CryptocurrencySymbolEnum {
  MATIC = 'MATIC',
  USDC = 'USDC',
  USDT = 'USDT',
}

export enum CryptocurrencyTypeEnum {
  NATIVE = 'NATIVE',
  STABLECOIN = 'STABLECOIN',
}

export type CryptocurrenciesData = {
  decimals: number
  name: string
  symbol: CryptocurrencySymbolEnum
  address: string
  logo: string
  type: CryptocurrencyTypeEnum
  chain: Chains
}

export type CryptocurrenciesStatuses = {
  error: string | null
  loadCryptocurrencies: RequestStatus
  mintUSDCStatus: RequestStatus
}

export type Cryptocurrencies = EntityState<CryptocurrenciesData> & CryptocurrenciesStatuses
