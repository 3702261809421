import type { CustomPalette } from '.'

export const basePalette: Pick<CustomPalette, '_primary' | '_secondary' | '_stroke' | '_fill' | '_text'> = {
  _text: {
    600: '#25262D',
    500: '#25262DA6',
    400: '#25262D73',
  },
  _stroke: {
    600: '#84858B',
    500: '#909198',
    400: '#AFB0B6',
    300: '#D2D3DB',
    200: '#DCDEE5',
    100: '#E7E8EE',
  },
  _secondary: {
    600: '#BA5F00',
    500: '#DC7000',
    400: '#FF8200',
    300: '#FFB568',
    200: '#FFB56840',
    100: '#FFB56826',
  },
  _primary: {
    600: '#003ABA',
    500: '#0045DC',
    400: '#0050FF',
    300: '#6897FF',
    200: '#6897FF40',
    100: '#6897FF26',
  },
  _fill: {
    600: '#D7D8E0',
    500: '#E1E3EA',
    400: '#EDEEF3',
    300: '#F2F3F7',
    200: '#F8F9FC',
    100: '#FFFFFF',
  },
}
