import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { userActions } from '../../user.slice'
import { sessionSelectors } from '../../../session/session.selectors'
import envConfig from '../../../../../config/env'
import { SessionCustomErrorMessages } from '../../../session/session.const'
import { sessionActions } from '../../../session/session.slice'

export async function deleteUser(accessToken: string): Promise<void> {
  await fetch(`${envConfig.mosaicoApiUrl}/users/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export function* deleteUserSaga(): SagaGenerator<void> {
  try {
    const auth0Client = yield* select(sessionSelectors.selectAuth0Client)

    const accessToken = yield* call([auth0Client, auth0Client.getTokenWithPopup as any], { authorizationParams: { prompt: 'login' } }, {})
    if (!accessToken) throw new Error(SessionCustomErrorMessages.USER_NOT_AUTHENTICATED)

    yield* call(deleteUser, accessToken as string)
    yield* put(userActions.deleteUserSuccess())

    yield* put(sessionActions.logoutStart())
  } catch (error) {
    if (error instanceof Error) yield* put(userActions.deleteUserFailure(error.message))
  }
}
