export enum StoreKeys {
  Session = 'Session',
  User = 'User',
  Wallet = 'Wallet',
  Web3 = 'Web3',
  ProjectToken = 'ProjectToken',
  StakeToken = 'StakeToken',
  Transactions = 'Transactions',
  Fundraise = 'Fundraise',
  Sumsub = 'Sumsub',
  Navigation = 'Navigation',
  ProjectBase = 'ProjectBase',
  ClosedProjectBase = 'ClosedProjectBase',
  MosaicoAssets = 'MosaicoAssets',
  ProjectContent = 'ProjectContent',
  Balances = 'Balances',
  Rewards = 'Rewards',
  Static = 'Static',
  PlatformSummary = 'PlatformSummary',
  Phases = 'Phases',
  Tranches = 'Tranches',
  Investment = 'Investment',
  RewardTokenERC20 = 'RewardTokenERC20',
  RewardStablecoin = 'RewardStablecoin',
  Cryptocurrencies = 'Cryptocurrencies',
  UserInvestments = 'UserInvestments',
  LegacyTokens = 'LegacyTokens',
}
