import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'

import type { StaticPayload } from './static.types'

export const initialStaticSliceState = {}

export const staticSlice = createSlice({
  reducers: {
    startTransactionsSubgraphSetup: () => {},
    startTransactionsStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startSearchSubgraphSetup: () => {},
    startSearchStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startRaiseCapitalStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startProjectsSubgraphSetup: () => {},
    startProjectsStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startProjectPageSubgraphSetup: () => {},
    startProjectPageStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startProjectInvestSubgraphSetup: () => {},
    startProjectInvestStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startMosaicoAssetsStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startLandingSubgraphSetup: () => {},
    startLandingStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startDefaultSubgraphSetup: () => {},
    startDefaultStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
    startDashboardSubgraphSetup: () => {},
    startDashboardStaticSetup: (_, __: PayloadAction<StaticPayload>) => {},
  },
  name: StoreKeys.Static,
  initialState: initialStaticSliceState,
})

export const staticActions = staticSlice.actions
export const staticReducer = staticSlice.reducer
export type StaticActions = ActionsType<typeof staticActions>
