import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { rewardTokenERC20Actions } from './rewardTokenERC20.slice'
import { consumeSaga } from './saga/consume/consume.saga'
import { fetchSubgraphAllRewardsTokensERC20Saga } from './saga/fetchFromSubgraph/fetchAll.saga'
import { fetchBackendAllRewardsTokensERC20Saga } from './saga/fetchFromBackend/fetchAll.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(rewardTokenERC20Actions.fetchSubgraphAll, fetchSubgraphAllRewardsTokensERC20Saga),
    takeLatest(rewardTokenERC20Actions.fetchBackendAll, fetchBackendAllRewardsTokensERC20Saga),
    takeLatest(rewardTokenERC20Actions.consume, consumeSaga),
  ])
}

export function* rewardTokenSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
