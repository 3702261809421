import type { Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'

export const getModal = (palette: CustomPalette): Components<Theme> => ({
  MuiModal: {
    styleOverrides: {
      root: {
        '.MuiPaper-root:not(.MuiMenu-paper)': {
          boxShadow: 'none',
          borderRadius: '24px',
          border: `1px solid ${palette._stroke[400]}`,
        },
        '&:not(.MuiMenu-root, .MuiDialog-root)': {
          background: '#0D0D0EE6',
        },
      },
    },
  },
})
