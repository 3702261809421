// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgMarkerPin = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 9.955a2 2 0 1 0 4 0v-.037a2 2 0 1 0-4 0m-4.873.127v-.172a6.873 6.873 0 1 1 13.746 0v.172c0 3.461-4.382 8.671-6.148 10.631a.973.973 0 0 1-1.45 0c-1.766-1.96-6.148-7.17-6.148-10.631Z"
    />
  </svg>
)
export default SvgMarkerPin
