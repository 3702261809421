import type { CustomPalette } from '.'

export const gradientColors: Pick<CustomPalette, '_gradientColors'> = {
  _gradientColors: {
    orange: '#FF8200',
    lightOrange: '#FFB568',
    lightBlue: '#00C8FD',
    blue: '#0050FF',
  },
}
