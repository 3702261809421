import type { SagaGenerator } from 'typed-redux-saga'
import { call, put } from 'typed-redux-saga'

import { userActions } from '../../user.slice'
import envConfig from '../../../../../config/env'
import { SessionCustomErrorMessages } from '../../../session/session.const'
import { getAccessToken } from '../../../../utils/getAccessToken'

export async function migrateUserTokens(accessToken: string): Promise<void> {
  await fetch(`${envConfig.mosaicoApiUrl}/users/migrate-tokens`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export function* migrateUserTokensSaga(): SagaGenerator<void> {
  try {
    const accessToken = yield* call(getAccessToken)
    if (!accessToken) throw new Error(SessionCustomErrorMessages.USER_NOT_AUTHENTICATED)

    yield* call(migrateUserTokens, accessToken as string)
    yield* put(userActions.migrateUserTokensSuccess())
  } catch (error) {
    if (error instanceof Error) yield* put(userActions.migrateUserTokensFailure(error.message))
  }
}
