import { Button, styled } from '@mui/material'

export const ButtonBaseStyled = styled(Button)(({ theme }) => ({
  '&:hover': {
    '&.MuiButton-outlinedPrimary > svg': {
      stroke: theme.palette._white,
    },
  },

  '&:focus-visible': {
    '&.MuiButton-outlinedPrimary > svg': {
      stroke: theme.palette._white,
    },
  },

  '&:disabled': {
    '&.MuiButton-textSecondary > svg': {
      stroke: theme.palette._tint[600],
    },
    '&.MuiButton-textPrimary > svg': {
      stroke: theme.palette._shade[600],
    },
    '&.MuiButton-outlinedSecondary > svg': {
      stroke: theme.palette._tint[600],
    },
    '&.MuiButton-outlinedPrimary > svg': {
      stroke: theme.palette._text[600],
    },

    '&.MuiButton-outlinedPale > svg': {
      stroke: theme.palette._shade[600],
    },
    '&.MuiButton-containedWhite > svg': {
      stroke: theme.palette._shade[600],
    },
    '&.MuiButton-containedPrimary > svg': {
      stroke: theme.palette._white,
    },
  },

  '&:active': {
    '&.MuiButton-outlinedPrimary > svg': {
      stroke: theme.palette._white,
    },
  },

  '&.MuiButton-textSecondary > svg': {
    stroke: theme.palette._white,
  },

  '&.MuiButton-textPrimary > svg': {
    stroke: theme.palette._text[600],
  },

  '&.MuiButton-sizeSmall': {
    padding: theme.spacing(2),
  },

  '&.MuiButton-sizeMedium': {
    padding: theme.spacing(3),
  },

  '&.MuiButton-sizeLarge': {
    padding: theme.spacing(4),
  },

  '&.MuiButton-outlinedSecondary > svg': {
    stroke: theme.palette._white,
  },

  '&.MuiButton-outlinedPrimary > svg': {
    stroke: theme.palette._text[600],
  },

  '&.MuiButton-outlinedPale > svg': {
    stroke: theme.palette._text[600],
  },

  '&.MuiButton-containedWhite > svg': {
    stroke: theme.palette._text[600],
  },
  '&.MuiButton-containedPrimary > svg': {
    stroke: theme.palette._white,
  },
}))
