/* eslint-disable import/exports-last */
import type { Palette as TPalette } from '@mui/material'

import { accentPalette } from './accent'
import { basePalette } from './base'
import { extraPalette } from './extra'
import { gradientColors } from './gradietColors'
import { utilityPalette } from './utility'

interface PrimaryColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}

interface SecondaryColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}

interface TextColor {
  400: string
  500: string
  600: string
}

interface FillColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}
interface StrokeColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}
interface ShadeColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}

interface GradientColors {
  blue: string
  lightBlue: string
  lightOrange: string
  orange: string
}
interface TintColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}

interface ErrorColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}
interface SuccessColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}
interface WarningColor {
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
}

interface NIBlueColor {
  0: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

interface VividSkyBlueColor {
  0: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

interface TheNewBlackColor {
  0: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

interface GoldColor {
  0: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

interface SnakeFruitColor {
  0: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

interface TurquoiseCyanColor {
  0: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

interface KingNachoColor {
  0: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}
export interface CustomPalette {
  _primary: PrimaryColor
  _secondary: SecondaryColor
  _text: TextColor
  _fill: FillColor
  _stroke: StrokeColor
  _shade: ShadeColor
  _gradientColors: GradientColors
  _tint: TintColor
  _black: string
  _white: string
  _transparent: string
  _error: ErrorColor
  _success: SuccessColor
  _warning: WarningColor
  _niblue: NIBlueColor
  _vividSkyBlue: VividSkyBlueColor
  _theNewBlack: TheNewBlackColor
  _gold: GoldColor
  _snakeFruit: SnakeFruitColor
  _turquoiseCyan: TurquoiseCyanColor
  _kingNacho: KingNachoColor
  white: TPalette['primary']
  pale: TPalette['primary']
}
declare module '@mui/material/styles/createPalette' {
  interface Palette extends CustomPalette {}

  interface PaletteOptions {
    _primary?: PrimaryColor
    _secondary?: SecondaryColor
    _text?: TextColor
    _fill?: FillColor
    _stroke?: StrokeColor
    _shade?: ShadeColor
    _gradientColors?: GradientColors
    _tint?: TintColor
    _black?: string
    _white?: string
    _transparent?: string
    _error?: ErrorColor
    _success?: SuccessColor
    _warning?: WarningColor
    _niblue?: NIBlueColor
    _vividSkyBlue?: VividSkyBlueColor
    _theNewBlack?: TheNewBlackColor
    _gold?: GoldColor
    _snakeFruit?: SnakeFruitColor
    _turquoiseCyan?: TurquoiseCyanColor
    _kingNacho?: KingNachoColor
    white?: PaletteOptions['primary']
    pale?: PaletteOptions['primary']
  }
}

export const getPalette = (): CustomPalette => ({
  ...basePalette,
  ...utilityPalette,
  ...accentPalette,
  ...extraPalette,
  ...gradientColors,
  white: {
    main: '#fff',
    light: '#fff',
    dark: '#000',
    contrastText: '#000',
  },
  pale: {
    main: '#f5f5f5',
    light: '#fff',
    dark: '#000',
    contrastText: '#000',
  },
})
