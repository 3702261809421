import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { ProjectToken, StakeToken } from '@sapiency-io/mosaico-contracts/dist'
import { v4 as uuid } from 'uuid'
import type { StrictEffect } from 'redux-saga/effects'
import type { ContractTransaction } from 'ethers'

import { TransactionType, type TransactionBase } from '../../../transactions/transaction.types'
import { transactionsActions } from '../../../transactions/transaction.slice'
import { getProjectToken, getStakeToken } from '../../../web3/web3.utils'
import { selectSigner } from '../../../web3/web3.selectors'
import { executeContractTransactionSaga } from '../../../transactions/saga/executeContractTransaction.saga'
import { stakeTokenActions, type StakeTokenActions } from '../../stakeToken.slice'
import { stakeTokenSelectors } from '../../stakeToken.selectors'
import { BigDecimal } from '../../../../utils/bigDecimal'

export function* approveTransaction(
  projectTokenContract: ProjectToken,
  from: string,
  amount: string
): Generator<StrictEffect, ContractTransaction> {
  const tx = yield* call(projectTokenContract.approve, from, amount)
  yield* call(tx.wait)
  return tx
}

export function* stakeTransaction(stakeTokenContract: StakeToken, amount: string): Generator<StrictEffect, ContractTransaction> {
  const tx = yield* call(stakeTokenContract.stake, amount)
  yield* call(tx.wait)
  return tx
}

export function* createAndExecuteStakeTransactionPlanSaga({ payload }: StakeTokenActions['stake']): SagaGenerator<void> {
  try {
    const stakeTransactionPlan: TransactionBase[] = []

    const { amount, projectTokenAddress, stakeTokenAddress } = payload
    const signer = yield* select(selectSigner)
    const transactionId = yield* select(stakeTokenSelectors.selectStakeTransactionId)

    if (!transactionId) throw new Error('Transfer transaction id is not defined')

    const projectTokenContract = yield* call(getProjectToken, projectTokenAddress, signer)
    const stakeTokenContract = yield* call(getStakeToken, stakeTokenAddress, signer)

    const parsedAmount = BigDecimal.fromString(amount).toBigNumber().toString()

    const approveTransactionData = {
      type: TransactionType.Approve,
      source: transactionId,
      payload: {},
      id: uuid(),
    }

    const stakeTransactionData = {
      type: TransactionType.Stake,
      source: transactionId,
      payload: {},
      id: uuid(),
    }

    stakeTransactionPlan.push(approveTransactionData, stakeTransactionData)

    yield* put(transactionsActions.addTransactions(stakeTransactionPlan))

    for (const transaction of stakeTransactionPlan) {
      if (transaction.type === TransactionType.Approve)
        yield* call(
          executeContractTransactionSaga,
          transaction.id,
          call(approveTransaction, projectTokenContract, stakeTokenAddress, parsedAmount)
        )
      if (transaction.type === TransactionType.Stake)
        yield* call(executeContractTransactionSaga, transaction.id, call(stakeTransaction, stakeTokenContract, parsedAmount))
    }

    yield* put(stakeTokenActions.stakeSuccess)
  } catch (error) {
    if (error instanceof Error) yield* put(stakeTokenActions.stakeFailure(error.message))
  }
}
