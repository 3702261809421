import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { v4 as uuid } from 'uuid'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { StakeTokenData, StakeToken, StakeTokenPayload, UnstakeTokenPayload } from './stakeToken.types'
import { StakeTokenState } from './stakeToken.state'
import { isStakeToken } from './stakeToken.const'

export const stakeTokenAdapter = createEntityAdapter<StakeTokenData>({
  sortComparer: (a, b) => a.address.localeCompare(b.address),
  selectId: (data) => data.address,
})

export const stakeTokenInitialState: StakeToken = stakeTokenAdapter.getInitialState(new StakeTokenState())

const hydrate = createAction<{ [StoreKeys.StakeToken]: StakeToken }>(HYDRATE)

export const stakeTokenSlice = createSlice({
  reducers: {
    unstakeSuccess: (state) => {
      state.unstakeStatus = RequestStatus.Succeeded
    },
    unstakeFailure: (state, action: PayloadAction<RequestError>) => {
      state.unstakeStatus = RequestStatus.Failed
      state.error = action.payload
    },
    unstake(state, _: PayloadAction<UnstakeTokenPayload>) {
      state.unstakeStatus = RequestStatus.Loading
      state.stakeTransactionId = uuid()
    },
    stakeSuccess: (state) => {
      state.stakeStatus = RequestStatus.Succeeded
    },
    stakeFailure: (state, action: PayloadAction<RequestError>) => {
      state.stakeStatus = RequestStatus.Failed
      state.error = action.payload
    },
    stake(state, _: PayloadAction<StakeTokenPayload>) {
      state.stakeStatus = RequestStatus.Loading
      state.stakeTransactionId = uuid()
    },
    setStakeTransactionId: (state, action: PayloadAction<string>) => {
      state.stakeTransactionId = action.payload
    },
    fetchSubgraphForProjectSuccess: (state, action: PayloadAction<StakeTokenData[]>) => {
      stakeTokenAdapter.upsertMany(state, action.payload)
      state.fetchSubgraphSingleStatus = RequestStatus.Succeeded
    },
    fetchSubgraphForProjectFailure: (state, action: PayloadAction<RequestError>) => {
      state.fetchSubgraphSingleStatus = RequestStatus.Failed
      state.error = action.payload
    },
    fetchSubgraphForProject: (state) => {
      state.fetchSubgraphSingleStatus = RequestStatus.Loading
    },
    fetchSubgraphAllSuccess: (state, action: PayloadAction<StakeTokenData[]>) => {
      stakeTokenAdapter.upsertMany(state, action.payload)
      state.fetchSubgraphAllStatus = RequestStatus.Succeeded
    },
    fetchSubgraphAllFailure: (state, action: PayloadAction<RequestError>) => {
      state.fetchSubgraphAllStatus = RequestStatus.Failed
      state.error = action.payload
    },
    fetchSubgraphAll: (state) => {
      state.fetchSubgraphAllStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.StakeToken,
  initialState: stakeTokenInitialState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { entities } = payload[StoreKeys.StakeToken]
      const stakeTokens = Object.values(entities)
      const stakeTokensToUpsert = stakeTokens.filter(isStakeToken)
      return stakeTokenAdapter.upsertMany(state, stakeTokensToUpsert)
    }),
})

export const stakeTokenReducer = stakeTokenSlice.reducer
export const stakeTokenActions = stakeTokenSlice.actions
export type StakeTokenActions = ActionsType<typeof stakeTokenActions>
