import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { AppState } from '..'
import type { Language } from '../../types/common'
import type { ActionsType } from '../store.types'

import type { NavigationSlice, NavigationManu } from './navigation.types'
import { CreateNavigationSlice } from './navigation.state'

export const initialState: NavigationSlice = CreateNavigationSlice()

const hydrate = createAction<AppState>(HYDRATE)

export const navigationSlice = createSlice({
  reducers: {
    updateShouldPreserveScrollPosition: (state, action: PayloadAction<boolean>) => {
      state.shouldPreserveScrollPosition = action.payload
    },
    updateSearchFallbackRoute: (state, action: PayloadAction<string>) => {
      state.searchFallbackRoute = action.payload
    },
    updateScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload
    },
    updateLocalisation: (state, action: PayloadAction<Language | undefined>) => {
      if (action.payload === undefined || !state.availableLocalisations.includes(action.payload)) {
        state.currentLocalisation = state.fallbackLocalisation
        return
      }

      state.currentLocalisation = action.payload
    },
    updateKeepSearchOpen: (state, action: PayloadAction<boolean>) => {
      state.keepSearchOpen = action.payload
    },
    updateCurrentSlug: (state, action: PayloadAction<string>) => {
      state.currentSlug = action.payload
    },
    updateBannerHeight: (state, action: PayloadAction<number>) => {
      state.bannerHeight = action.payload
    },
    updateActiveMenu: (state, action: PayloadAction<NavigationManu | null>) => {
      state.activeMenu = action.payload
    },
  },
  name: StoreKeys.Navigation,
  initialState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => ({
      ...state,
      ...payload[StoreKeys.Navigation],
      searchFallbackRoute: state.searchFallbackRoute,
      scrollPosition: state.scrollPosition,
      keepSearchOpen: state.keepSearchOpen,
      currentSlug: payload[StoreKeys.Navigation].currentSlug ? payload[StoreKeys.Navigation].currentSlug : state.currentSlug,
      activeMenu: state.activeMenu,
    })),
})

export const navigationReducer = navigationSlice.reducer
export const navigationActions = navigationSlice.actions
export type NavigationActions = ActionsType<typeof navigationActions>
