import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'
import type { ERC20, UpgradeHelper } from '@sapiency-io/mosaico-contracts/dist'
import { v4 as uuid } from 'uuid'
import type { StrictEffect } from 'redux-saga/effects'
import { type ContractTransaction } from 'ethers'
import { addresses } from '@sapiency-io/mosaico-contracts/src/addresses'

import { pushNotification } from '../../../../utils/pushNotification'
import type { LegacyTokensActions } from '../../legacyTokens.slice'
import { legacyTokensActions } from '../../legacyTokens.slice'
import { TransactionType, type TransactionBase } from '../../../transactions/transaction.types'
import { transactionsActions } from '../../../transactions/transaction.slice'
import { getERC20, getUpgradeHelper } from '../../../web3/web3.utils'
import { selectSigner } from '../../../web3/web3.selectors'
import { executeContractTransactionSaga } from '../../../transactions/saga/executeContractTransaction.saga'
import { legacyTokensSelectors } from '../../legacyTokens.selectors'
import { BigDecimal } from '../../../../utils/bigDecimal'
import envConfig from '../../../../../config/env'

export function* approveTransaction(erc20: ERC20, spender: string, amount: string): Generator<StrictEffect, ContractTransaction> {
  const tx = yield* call(erc20.approve, spender, amount)
  yield* call(tx.wait)
  return tx
}

export function* swapTransaction(
  updgradeHelper: UpgradeHelper,
  legacyToken: string,
  amount: string
): Generator<StrictEffect, ContractTransaction> {
  const tx = yield* call(updgradeHelper.swapTokens, legacyToken, amount)
  yield* call(tx.wait)
  return tx
}

const updgradeHelperAddress = addresses[String(envConfig.chainId) as '137' | '80001'].upgradeHelper

export function* swapTokensSaga({ payload }: LegacyTokensActions['swapTokens']): SagaGenerator<void> {
  try {
    const transferBalanceTransactionPlan: TransactionBase[] = []

    const { tokenAddress, decimals, balance } = payload
    const signer = yield* select(selectSigner)
    const legacyToken = getERC20(tokenAddress, signer)
    const upgradeHelper = getUpgradeHelper(updgradeHelperAddress, signer)
    const transactionId = yield* select(legacyTokensSelectors.selectSwapTokensTransactionId)
    const amount = BigDecimal.fromString(balance, decimals).toBigNumber().toString()

    if (!transactionId) throw new Error('Swap tokens transaction id is not defined')

    const approveTransactionData = {
      type: TransactionType.Approve,
      source: transactionId,
      payload: {},
      id: uuid(),
    }

    const swapTransactionData = {
      type: TransactionType.Swap,
      source: transactionId,
      payload: {},
      id: uuid(),
    }

    transferBalanceTransactionPlan.push(approveTransactionData, swapTransactionData)

    yield* put(transactionsActions.addTransactions(transferBalanceTransactionPlan))

    for (const transaction of transferBalanceTransactionPlan) {
      if (transaction.type === TransactionType.Approve)
        yield* call(executeContractTransactionSaga, transaction.id, call(approveTransaction, legacyToken, updgradeHelperAddress, amount))
      if (transaction.type === TransactionType.Swap)
        yield* call(executeContractTransactionSaga, transaction.id, call(swapTransaction, upgradeHelper, tokenAddress, amount))
    }

    yield* put(legacyTokensActions.swapTokensSuccess())
    pushNotification(`Tokens swapped successfully. It takes a while to update balances..`, 'success')
  } catch (error) {
    if (error instanceof Error) {
      yield* put(legacyTokensActions.swapTokensFailure(error.message))
      pushNotification(`Unable to create swap tokens transaction: ${error.message}`, 'error')
    }
  }
}
