import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { loadUserDataSaga } from './sagas/loadUserData/loadUserData.saga'
import { updateUserLocalizationSaga } from './sagas/updateUserLocalization/updateUserLocalization.saga'
import { userActions } from './user.slice'
import { deleteUserSaga } from './sagas/deleteUser/deleteUser.saga'
import { fetchApplicantDataSaga } from './sagas/fetchApplicantData/fetchApplicantData.saga'
import { updateMarketingConsentsSaga } from './sagas/updateMarketingConsents/updateMarketingConsents.saga'
import { migrateUserTokensSaga } from './sagas/migrateUserTokens/migrateUserTokens.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(userActions.loadUserData, loadUserDataSaga),
    takeLatest(userActions.updateUserLocalization.type, updateUserLocalizationSaga),
    takeLatest(userActions.deleteUser.type, deleteUserSaga),
    takeLatest(userActions.fetchApplicantData.type, fetchApplicantDataSaga),
    takeLatest(userActions.updateMarketingConsents.type, updateMarketingConsentsSaga),
    takeLatest(userActions.migrateUserTokens.type, migrateUserTokensSaga),
  ])
}

export function* userSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
