import { call, put, select } from 'typed-redux-saga'
import type { JsonRpcSigner } from '@ethersproject/providers'
import { v4 as uuid } from 'uuid'
import type { StrictEffect } from 'redux-saga/effects'
import type { ContractTransaction } from 'ethers'

import { type BalancesActions } from '../../balances.slice'
import { TransactionType, type TransactionBase } from '../../../transactions/transaction.types'
import { transactionsActions } from '../../../transactions/transaction.slice'
import { selectSigner } from '../../../web3/web3.selectors'
import { executeContractTransactionSaga } from '../../../transactions/saga/executeContractTransaction.saga'
import { balancesSelectors } from '../../balances.selectors'

export function* transferTransaction(signer: JsonRpcSigner, to: string, amount: string): Generator<StrictEffect, ContractTransaction> {
  const tx = yield* call([signer, signer.sendTransaction], { value: amount, to })
  yield* call(tx.wait)

  return tx
}

export function* createAndExecuteNativeTransferTransactionPlan(payload: BalancesActions['transferBalances']['payload']) {
  const { amount, to } = payload
  const signer = yield* select(selectSigner)
  const transferId = yield* select(balancesSelectors.selectTransferTransactionId)

  if (!signer) throw new Error('Signer is invalid')
  if (!transferId) throw new Error('Transfer transaction id is not defined')

  const transferBalanceTransactionPlan: TransactionBase = {
    type: TransactionType.Transfer,
    source: transferId,
    payload: {},
    id: uuid(),
  }

  yield* put(transactionsActions.addTransactions([transferBalanceTransactionPlan]))
  yield* call(executeContractTransactionSaga, transferBalanceTransactionPlan.id, call(transferTransaction, signer, to, amount))
}
