import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { TranchesStatuses } from './tranches.types'

export class TranchesState implements TranchesStatuses {
  error: RequestError = null
  fetchSubgraphAllStatus: RequestStatus = RequestStatus.Idle
  fetchSubgraphSingleStatus: RequestStatus = RequestStatus.Idle
  fetchFromBackendStatus: RequestStatus = RequestStatus.Idle
}
