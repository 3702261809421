import type { SagaGenerator } from 'typed-redux-saga'
import { select, put, call } from 'typed-redux-saga'

import { selectSigner, selectWeb3Account } from '../../../web3/web3.selectors'
import { getProjectToken, getStakeToken } from '../../../web3/web3.utils'
import { projectTokenSelectors } from '../../../projectToken/projectToken.selectors'
import type { RewardBalance, RewardParams } from '../../rewards.types'
import { RewardSourceType, RewardType } from '../../rewards.const'
import { rewardsActions, type RewardsActions } from '../../rewards.slice'
import { stakeTokenSelectors } from '../../../stakeToken/stakeToken.selectors'
import { walletSelectors } from '../../../wallet/wallet.selectors'
import { BigDecimal } from '../../../../utils/bigDecimal'

export function* calculateRewardsSaga(payload: RewardParams): SagaGenerator<void> {
  try {
    const { sourceTokenAddress, rewardTokenAddress, rewardType, rewardSource } = payload

    const web3Account = yield* select(selectWeb3Account)
    const signer = yield* select(selectSigner)

    if (!signer) throw new Error('Signer is not provided')
    if (!web3Account) throw new Error('Web3 account is not provided')
    if (sourceTokenAddress === '') throw new Error('Source address undefined')

    const sourceToken =
      rewardSource === RewardSourceType.ProjectToken
        ? yield* select(projectTokenSelectors.selectByAddress, sourceTokenAddress)
        : yield* select(stakeTokenSelectors.selectByAddress, sourceTokenAddress)
    const decimals = sourceToken?.decimals ?? 18

    const contract =
      rewardSource === RewardSourceType.ProjectToken
        ? getProjectToken(sourceTokenAddress, signer)
        : getStakeToken(sourceTokenAddress, signer)
    const rewardsBalances: RewardBalance[] = []

    const wallets = yield* select(walletSelectors.selectAll)

    for (const wallet of wallets) {
      const rewardAmount =
        rewardType === RewardType.TimeStake
          ? yield* call([contract, contract.calculateTimeStakeReward], rewardTokenAddress, wallet.ethereumAddress)
          : yield* call([contract, contract.calculateLiquidStakeReward], rewardTokenAddress, wallet.ethereumAddress)

      const rewardBalance: RewardBalance = {
        tokenAddress: rewardTokenAddress,
        rewardSource,
        amount: BigDecimal.from(rewardAmount, decimals).toFixed(decimals),
        account: wallet.ethereumAddress,
      }
      rewardsBalances.push(rewardBalance)
    }
    yield* put(rewardsActions.calculateRewardsSuccess(rewardsBalances))
  } catch (error) {
    if (error instanceof Error) yield* put(rewardsActions.calculateRewardsError(error.message))
  }
}

export function* calculateRewardsActionHandler({ payload }: RewardsActions['calculateRewards']): SagaGenerator<void> {
  for (const rewardToken of payload) yield* call(calculateRewardsSaga, rewardToken)
}

export function* claimRewardsSuccessActionHandler({ payload }: RewardsActions['claimRewards']): SagaGenerator<void> {
  yield* call(calculateRewardsSaga, payload)
}
