import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import { RewardTokenERC20State } from './rewardTokenERC20.state'
import type { ConsumePayload, RewardTokenERC20, RewardTokenERC20Data, RewardTokenERC20Subgraph } from './rewardTokenERC20.types'
import { isRewardTokenERC20 } from './rewardTokenERC20.const'

export const rewardTokenERC20Adapter = createEntityAdapter<RewardTokenERC20Data>({
  selectId: (rewardToken) => rewardToken.address,
})

export const rewardTokenInitialState: RewardTokenERC20 = rewardTokenERC20Adapter.getInitialState(new RewardTokenERC20State())

const hydrate = createAction<{ [StoreKeys.RewardTokenERC20]: RewardTokenERC20 }>(HYDRATE)

export const rewardTokenERC20Slice = createSlice({
  reducers: {
    fetchSubgraphAllSuccess(state, action: PayloadAction<RewardTokenERC20Subgraph[]>) {
      rewardTokenERC20Adapter.updateMany(
        state,
        action.payload.map((rewardToken) => ({ id: rewardToken.address, changes: rewardToken }))
      )
      state.fetchSubgraphAllStatus = RequestStatus.Succeeded
    },
    fetchSubgraphAllFailure(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.fetchSubgraphAllStatus = RequestStatus.Failed
    },
    fetchSubgraphAll(state) {
      state.fetchSubgraphAllStatus = RequestStatus.Loading
    },
    fetchBackendAllSuccess(state, action: PayloadAction<RewardTokenERC20Data[]>) {
      rewardTokenERC20Adapter.upsertMany(state, action.payload)
      state.fetchFromBackendStatus = RequestStatus.Succeeded
    },
    fetchBackendAllFailure(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.fetchFromBackendStatus = RequestStatus.Failed
    },
    fetchBackendAll(state) {
      state.fetchFromBackendStatus = RequestStatus.Loading
    },
    consumeSuccess(state) {
      state.consumeStatus = RequestStatus.Succeeded
    },
    consumeFailure(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.consumeStatus = RequestStatus.Failed
    },
    consume(state, _: PayloadAction<ConsumePayload>) {
      state.consumeStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.RewardTokenERC20,
  initialState: rewardTokenInitialState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { entities } = payload[StoreKeys.RewardTokenERC20]
      const tokens = Object.values(entities)

      const tokensToUpsert = tokens.filter(isRewardTokenERC20).map((token) => {
        const currentState = state.entities[token.address] || token

        return {
          ...currentState,
          rewardPriceInUSD: token.rewardPriceInUSD,
          logo: token.logo,
          address: token.address,
        }
      })

      return rewardTokenERC20Adapter.upsertMany(state, tokensToUpsert)
    }),
})

export const rewardTokenERC20AdapterSelectors = rewardTokenERC20Adapter.getSelectors()
export const rewardTokenERC20Reducer = rewardTokenERC20Slice.reducer
export const rewardTokenERC20Actions = rewardTokenERC20Slice.actions
export type RewardTokenERC20Actions = ActionsType<typeof rewardTokenERC20Actions>
