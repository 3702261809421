/* eslint-disable import/exports-last */

import type { CSSProperties, FontStyle } from '@mui/material/styles/createTypography'

export interface CustomTypographyVariants extends Pick<FontStyle, 'fontSize' | 'fontFamily'> {
  displayLg: CSSProperties
  displayMd: CSSProperties
  displaySm: CSSProperties
  bodyLg: CSSProperties
  bodyLgSemiBold: CSSProperties
  bodyMd: CSSProperties
  bodyMdSemiBold: CSSProperties
  bodySm: CSSProperties
  bodyXs: CSSProperties
  bodyXsSemiBold: CSSProperties
  buttonLg: CSSProperties
  buttonMd: CSSProperties
  buttonSm: CSSProperties
  bodySmSemiBold: CSSProperties
  link: CSSProperties
  uppercase: CSSProperties
  label: CSSProperties
  h1: CSSProperties
  h2: CSSProperties
  h3: CSSProperties
  h4: CSSProperties
  h5: CSSProperties
  h6: CSSProperties
}

declare module '@mui/material/styles' {
  interface TypographyVariants extends CustomTypographyVariants {}

  interface TypographyVariantsOptions extends Partial<CustomTypographyVariants> {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    displayLg: true
    displayMd: true
    displaySm: true
    bodyLg: true
    bodyLgSemiBold: true
    bodyMd: true
    bodyMdSemiBold: true
    bodySm: true
    bodyXs: true
    bodyXsSemiBold: true
    buttonLg: true
    buttonMd: true
    buttonSm: true
    bodySmSemiBold: true
    link: true
    uppercase: true
    label: true
    h1: true
    h2: true
    h3: true
    h4: true
    h5: true
    h6: true
  }
}

export const getTypography = (): CustomTypographyVariants => ({
  uppercase: {
    textTransform: 'uppercase',
    lineHeight: '150%',
    letterSpacing: '0.01em',
    fontWeight: 600,
    fontSize: '0.8125rem',
  },
  link: { lineHeight: '150%', letterSpacing: '0.01em', fontWeight: 400, fontSize: '0.825rem' },
  label: { lineHeight: '120%', letterSpacing: '0.01em', fontWeight: 600, fontSize: '0.750rem' },
  h6: { lineHeight: '120%', letterSpacing: 0, fontWeight: 600, fontSize: '1.000rem' },
  h5: { lineHeight: '120%', letterSpacing: 0, fontWeight: 600, fontSize: '1.125rem' },
  h4: { lineHeight: '120%', letterSpacing: 0, fontWeight: 600, fontSize: '1.250rem' },
  h3: { lineHeight: '120%', letterSpacing: 0, fontWeight: 600, fontSize: '1.438rem' },
  h2: { lineHeight: '120%', letterSpacing: 0, fontWeight: 600, fontSize: '1.625rem' },
  h1: { lineHeight: '120%', letterSpacing: 0, fontWeight: 600, fontSize: '1.812rem' },
  fontSize: 16,
  fontFamily: 'Poppins',

  displaySm: { lineHeight: '120%', letterSpacing: '-0.01em', fontWeight: 400, fontSize: '2.000rem' },
  displayMd: { lineHeight: '120%', letterSpacing: '-0.04em', fontWeight: 400, fontSize: '2.875rem' },
  displayLg: { lineHeight: '120%', letterSpacing: '-0.04em', fontWeight: 400, fontSize: '3.750rem' },
  buttonSm: { lineHeight: '155%', letterSpacing: '0.01em', fontWeight: 500, fontSize: '0.875rem' },
  buttonMd: { lineHeight: '150%', letterSpacing: '0.01em', fontWeight: 500, fontSize: '1.000rem' },
  buttonLg: { lineHeight: '135%', letterSpacing: '0.01em', fontWeight: 500, fontSize: '1.125rem' },
  bodyXsSemiBold: { lineHeight: '135%', letterSpacing: '0.01em', fontWeight: 600, fontSize: '0.750rem' },
  bodyXs: { lineHeight: '135%', letterSpacing: '0.01em', fontWeight: 400, fontSize: '0.750rem' },
  bodySmSemiBold: { lineHeight: '145%', letterSpacing: '0.01em', fontWeight: 600, fontSize: '0.875rem' },
  bodySm: { lineHeight: '145%', letterSpacing: '0.01em', fontWeight: 400, fontSize: '0.875rem' },
  bodyMdSemiBold: { lineHeight: '175%', letterSpacing: '0.01em', fontWeight: 600, fontSize: '1.000rem' },
  bodyMd: { lineHeight: '175%', letterSpacing: '0.01em', fontWeight: 400, fontSize: '1.000rem' },
  bodyLgSemiBold: { lineHeight: '155%', letterSpacing: '0.01em', fontWeight: 600, fontSize: '1.125rem' },
  bodyLg: { lineHeight: '155%', letterSpacing: '0.01em', fontWeight: 400, fontSize: '1.125rem' },
})

export { fontFace } from '../components/fontFace'
