// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgAddWallet = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4 6.5h4m-2-2v4m14.5 6v3A2.5 2.5 0 0 1 18 20H6a2.5 2.5 0 0 1-2.5-2.5V12m17 2.5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1m0 5H19a2.5 2.5 0 0 1 0-5h1.5m0 0v-3A2.5 2.5 0 0 0 18 4h-6.5"
    />
  </svg>
)
export default SvgAddWallet
