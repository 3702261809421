// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgGlobe = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.5 9h-17m17 6h-17M16 12c0 4.97-1.79 9-4 9s-4-4.03-4-9 1.79-9 4-9 4 4.03 4 9Zm-5-9h2a8 8 0 0 1 8 8v2a8 8 0 0 1-8 8h-2a8 8 0 0 1-8-8v-2a8 8 0 0 1 8-8Z"
    />
  </svg>
)
export default SvgGlobe
