import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { closedProjectBaseActions } from '../../closedProjectBase.slice'
import { apiCall } from '../../../../utils/apiCall'
import type { FetchAllClosedProjectsResult } from '../../closedProjectBase.types'
import { AllClosedProjectBaseQueryResponse } from '../../../../../staticContent'

export async function fetchAllClosedProjectsBase(client: GraphQLClient): Promise<FetchAllClosedProjectsResult> {
  return (await Promise.resolve(AllClosedProjectBaseQueryResponse())) as FetchAllClosedProjectsResult
}
export function* fetchAllClosedProjectsBaseSaga(): SagaGenerator<void> {
  try {
    const staticProjects = yield* apiCall(fetchAllClosedProjectsBase)

    yield* put(closedProjectBaseActions.fetchAllClosedProjectsSuccess(staticProjects))
  } catch (error) {
    if (error instanceof Error) yield* put(closedProjectBaseActions.fetchAllClosedProjectsError(error.message))
  }
}
