import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import type { PlatformSummaryData } from '../../platformSummary.types'
import { subgraphCall } from '../../../../utils/subgraphCall'
import { platformSummaryActions } from '../../platformSummary.slice'
import { PLATFORM_SUMMARY_QUERY } from '../../platformSummary.queries'

export async function fetchPlatformSummary(client: GraphQLClient): Promise<{ platformSummary: PlatformSummaryData }> {
  const result = await client.request(PLATFORM_SUMMARY_QUERY)

  return result
}

export function* fetchPlatformSummarySaga(): SagaGenerator<void> {
  try {
    const platformSummaryData = yield* subgraphCall(fetchPlatformSummary)

    yield* put(platformSummaryActions.fetchPlatformSummarySuccess(platformSummaryData.platformSummary))
  } catch (error) {
    if (error instanceof Error) yield* put(platformSummaryActions.fetchPlatformSummaryError(error.message))
  }
}
