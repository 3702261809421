import type { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import type { Web3Provider } from '@ethersproject/providers'

import type { WalletTypeEnum } from '../../graphql/generated/graphql'
import type { RequestError } from '../types'

import type { MetamaskError } from './saga/metamask/metamask.types'

export enum Web3ConnectionStatus {
  Idle = 'Idle',
  InProgress = 'InProgress',
  Success = 'Success',
  Failure = 'Failure',
}

export enum Web3ActivationStatus {
  Idle = 'Idle',
  InProgress = 'InProgress',
  Success = 'Success',
  Failure = 'Failure',
  Deactivated = 'Deactivated',
}

export class Web3State {
  walletType?: WalletTypeEnum | null
  walletAddress?: string
  web3?: Web3ReactContextInterface<Web3Provider>
  connectionStatus?: Web3ConnectionStatus = Web3ConnectionStatus.Idle
  activationStatus?: Web3ActivationStatus = Web3ActivationStatus.Idle
  error?: RequestError | MetamaskError = null
  latestBlock?: number | null = null
}
