import { RequestStatus } from '../types'

import type { ProjectContentStatuses } from './projectContent.types'

export class ProjectContentState implements ProjectContentStatuses {
  error: string | null = null
  fetchProjectPageDataStatus: RequestStatus = RequestStatus.Idle
  fetchProjectDescriptionsStatus: RequestStatus = RequestStatus.Idle
  fetchProjectRewardsDescriptionsStatus: RequestStatus = RequestStatus.Idle
}
