import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { projectBaseActions } from './projectBase.slice'
import { fetchAllProjectsBaseSaga } from './sagas/fetchAllProjects/fetchAllProjects'
import { fetchSingleProjectBaseSaga } from './sagas/fetchSingleProject/fetchSingleProject'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(projectBaseActions.fetchAllProjects, fetchAllProjectsBaseSaga),
    takeLatest(projectBaseActions.fetchSingleProject, fetchSingleProjectBaseSaga),
  ])
}

export function* projectBaseSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
