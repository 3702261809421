import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { rewardTokenERC20Actions } from '../../rewardTokenERC20.slice'
import { mapBackendRewardTokenERC20DataToRewardTokenERC20Data } from '../../rewardTokenERC20.mappers'
import type { BackendRewardTokenERC20QueryResult } from '../../../../types/backendQueryTypes'
import { RewardTokensBackendQueryResponse } from '../../../../../staticContent'
import { apiCall } from '../../../../utils/apiCall'
import { emptySubgraphRewardTokenERC20Data } from '../../rewardTokenERC20.const'

async function fetchBackendAllRewardsTokensERC20(client: GraphQLClient): Promise<BackendRewardTokenERC20QueryResult[]> {
  const result = await Promise.resolve(RewardTokensBackendQueryResponse())
  return result.map(mapBackendRewardTokenERC20DataToRewardTokenERC20Data)
}

export function* fetchBackendAllRewardsTokensERC20Saga(): SagaGenerator<void> {
  try {
    const tokensData = yield* apiCall(fetchBackendAllRewardsTokensERC20)
    const extendedWithEmptySubgraphData = tokensData.map((token) => ({
      ...emptySubgraphRewardTokenERC20Data,
      ...token,
    }))

    yield* put(rewardTokenERC20Actions.fetchBackendAllSuccess(extendedWithEmptySubgraphData))
  } catch (error) {
    if (error instanceof Error) yield* put(rewardTokenERC20Actions.fetchBackendAllFailure(error.message))
  }
}
