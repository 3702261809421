import { createTheme } from '@mui/material/styles'

import { breakpoints } from './breakpoints'
import { getComponents } from './components'
import { getPalette } from './palette'
import { getTypography } from './typography'

const SPACING = 4

const palette = getPalette()
const typography = getTypography()
const components = getComponents(palette, typography, breakpoints)

export const theme = createTheme({
  typography,
  spacing: SPACING,
  palette,
  components,
  breakpoints,
})
