import type { SagaGenerator } from 'typed-redux-saga'
import { put, select } from 'typed-redux-saga'
import type { Effect, StrictEffect } from '@redux-saga/types'
import type { ContractTransaction } from 'ethers'
import type { TransactionResponse } from '@ethersproject/abstract-provider'

import { transactionsActions } from '../transaction.slice'
import { transactionsSelectors } from '../transactions.selectors'
import { TransactionStatus } from '../transaction.types'

type ErrorWithMessage = {
  message: string
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return typeof error === 'object' && error !== null && 'message' in error && typeof (error as Record<string, unknown>).message === 'string'
}

export function* executeContractTransactionSaga(
  transactionId: string,
  effect: SagaGenerator<Generator, Effect> | Generator<Effect>
): Generator<typeof effect | StrictEffect, ContractTransaction | undefined> {
  const transaction = yield* select(transactionsSelectors.selectTransactionById, transactionId)

  if (!transaction) throw new Error('Transaction not registered')

  try {
    yield* put(
      transactionsActions.updateTransactionStatus({
        status: TransactionStatus.InProgress,
        id: transactionId,
      })
    )

    const transactionResponse = (yield effect) as TransactionResponse

    yield* put(
      transactionsActions.updateTransactionStatus({
        status: TransactionStatus.Success,
        id: transactionId,
        hash: transactionResponse.hash,
      })
    )

    return transactionResponse
  } catch (error) {
    if (transactionId && isErrorWithMessage(error)) {
      yield* put(
        transactionsActions.updateTransactionStatus({
          status: TransactionStatus.Failure,
          id: transactionId,
          error: error.message,
        })
      )

      throw error
    }
  }
}
