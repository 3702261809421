import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { investmentActions } from './investment.slice'
import { cryptoInvestmentSaga } from './sagas/cryptoInvestment.saga'
import { rampInvestmentSaga } from './sagas/rampInvestment.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(investmentActions.rampInvestmentRequest.type, rampInvestmentSaga),
    takeLatest(investmentActions.cryptoInvestmentRequest.type, cryptoInvestmentSaga),
  ])
}

export function* investmentMasterSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
