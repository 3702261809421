import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { apiCall } from '../../../../utils/apiCall'
import { UPDATE_USER } from '../../../../graphql/client/queries'
import type { UserActions } from '../../user.slice'
import { userActions } from '../../user.slice'

export async function updateMarketingConsents(client: GraphQLClient, marketingConsents: boolean): Promise<boolean> {
  const { updateUser } = await client.request(UPDATE_USER, { marketingConsents })

  return updateUser.marketingConsents
}

export function* updateMarketingConsentsSaga({ payload }: UserActions['updateMarketingConsents']): SagaGenerator<void> {
  try {
    const updatedMarketingConsents = yield* apiCall(updateMarketingConsents, payload)
    yield* put(userActions.updateMarketingConsentsSuccess(updatedMarketingConsents))
  } catch (error) {
    if (error instanceof Error) yield* put(userActions.updateMarketingConsentsFailure(error.message))
  }
}
