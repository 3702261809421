import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'

import type {
  InvestmentAmountData,
  InvestmentPaymentMethodData,
  InvestmentSetupData,
  InvestmentState,
  RampInvestmentData,
} from './investment.state'
import { InvestmentStatus } from './investment.state'
import { InvestmentStep } from './investment.types'
import {
  canProceedToCryptoTransactionResultStep,
  canProceedToCryptoTransactionStep,
  canProceedToInvestmentAmountStep,
  canProceedToInvestmentSetupStep,
  canProceedTorampInvestmentResultStep,
  canProceedTorampInvestmentStep,
  canProceedToStepInvestmentPaymentMethod,
  isInvestmentAmountDataAvailable,
} from './investment.guards'

export const initInvestmentSliceState: InvestmentState = {
  step: InvestmentStep.Idle,
  nextStep: InvestmentStep.Setup,
}

export const investmentSlice = createSlice({
  reducers: {
    setupInvestment: (state, { payload }: PayloadAction<InvestmentSetupData>) => {
      if (canProceedToInvestmentSetupStep(state)) {
        state.tokenInAddress = payload.tokenInAddress
        state.tokenOutAddress = payload.tokenOutAddress

        state.fundraiseId = payload.fundraiseId
        state.projectId = payload.projectId

        state.step = InvestmentStep.Setup
        state.nextStep = InvestmentStep.Amount
        state.id = uuid()
      }

      return state
    },
    setInvestmentPaymentMethod: (state, { payload }: PayloadAction<InvestmentPaymentMethodData>) => {
      if (canProceedToStepInvestmentPaymentMethod(state)) {
        state.step = InvestmentStep.PaymentMethod
        state.nextStep = InvestmentStep.Transaction

        state.paymentMethod = payload.paymentMethod
        state.agreements = payload.agreements
        state.walletAddress = payload.walletAddress
      }

      return state
    },
    setInvestmentAmount: (state, { payload }: PayloadAction<InvestmentAmountData>) => {
      if (canProceedToInvestmentAmountStep(state)) {
        state.step = InvestmentStep.Amount
        state.nextStep = InvestmentStep.PaymentMethod

        state.tokenInAmount = payload.tokenInAmount
        state.tokenOutAmount = payload.tokenOutAmount
      }

      return state
    },
    resetInvestmentPaymentMethod: (state) => {
      if (isInvestmentAmountDataAvailable(state))
        return {
          ...state,
          nextStep: InvestmentStep.Amount,
        }
    },
    resetInvestment: () => initInvestmentSliceState,

    rampInvestmentSuccess: (state) => {
      if (canProceedTorampInvestmentResultStep(state)) {
        state.step = InvestmentStep.TransactionResult
        state.nextStep = InvestmentStep.TransactionResult

        state.status = InvestmentStatus.Success
      }

      return state
    },
    rampInvestmentRequest: (state) => state,
    rampInvestmentFailure: (state) => {
      if (canProceedToCryptoTransactionResultStep(state)) {
        state.step = InvestmentStep.TransactionResult
        state.nextStep = InvestmentStep.TransactionResult

        state.status = InvestmentStatus.Failure
      }

      return state
    },
    rampInvestment: (state, { payload }: PayloadAction<RampInvestmentData>) => {
      if (canProceedTorampInvestmentStep(state)) {
        state.step = InvestmentStep.Transaction
        state.nextStep = InvestmentStep.Transaction
        state.rampPurchaseId = payload.rampPurchaseId
      }

      return state
    },

    cryptoInvestmentSuccess: (state) => {
      if (canProceedToCryptoTransactionResultStep(state)) {
        state.step = InvestmentStep.TransactionResult
        state.nextStep = InvestmentStep.TransactionResult
        state.status = InvestmentStatus.Success
      }

      return state
    },
    cryptoInvestmentRequest: (state) => state,
    cryptoInvestmentFailure: (state) => {
      if (canProceedToCryptoTransactionResultStep(state)) {
        state.step = InvestmentStep.TransactionResult
        state.nextStep = InvestmentStep.TransactionResult
        state.status = InvestmentStatus.Failure
      }

      return state
    },
    cryptoInvestment: (state) => {
      if (canProceedToCryptoTransactionStep(state)) {
        state.step = InvestmentStep.Transaction
        state.nextStep = InvestmentStep.Transaction
      }

      return state
    },
  },
  name: StoreKeys.Investment,
  initialState: initInvestmentSliceState,
})

export const investmentActions = investmentSlice.actions
export const investmentReducer = investmentSlice.reducer

export type InvestmentActions = ActionsType<typeof investmentActions>
