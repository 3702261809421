import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import { RequestStatus } from '../types'

import type { Phase, PhaseData, SubgraphPhase } from './phases.types'
import { PhasesState } from './phases.state'
import { isPhase } from './phases.const'

export const phasesAdapter = createEntityAdapter<PhaseData>({
  sortComparer: (a, b) => a.id.localeCompare(b.id),
  selectId: (phase) => phase.id,
})

export const initialPhasesSliceState: Phase = phasesAdapter.getInitialState(new PhasesState())

const hydrate = createAction<{ [StoreKeys.Phases]: Phase }>(HYDRATE)

export const phasesSlice = createSlice({
  reducers: {
    fetchSubgraphForProjectSuccess: (state, action: PayloadAction<SubgraphPhase[]>) => {
      phasesAdapter.updateMany(
        state,
        action.payload.map((phase) => ({ id: phase.id, changes: phase }))
      )
      state.fetchSubgraphSingleStatus = RequestStatus.Succeeded
    },
    fetchSubgraphForProjectFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.fetchSubgraphSingleStatus = RequestStatus.Failed
    },
    fetchSubgraphForProject: (state) => {
      state.fetchSubgraphSingleStatus = RequestStatus.Loading
    },
    fetchSubgraphAllSuccess: (state, action: PayloadAction<SubgraphPhase[]>) => {
      phasesAdapter.updateMany(
        state,
        action.payload.map((phase) => ({ id: phase.id, changes: phase }))
      )
      state.fetchSubgraphAllStatus = RequestStatus.Succeeded
    },
    fetchSubgraphAllFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.fetchSubgraphAllStatus = RequestStatus.Failed
    },
    fetchSubgraphAll: (state) => {
      state.fetchSubgraphAllStatus = RequestStatus.Loading
    },
    fetchBackendForProjectSuccess: (state, action: PayloadAction<PhaseData[]>) => {
      phasesAdapter.upsertMany(state, action.payload)
      state.fetchFromBackendStatus = RequestStatus.Succeeded
    },
    fetchBackendForProjectFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.fetchFromBackendStatus = RequestStatus.Failed
    },
    fetchBackendForProject: (state) => {
      state.fetchFromBackendStatus = RequestStatus.Loading
    },
    fetchBackendAllSuccess: (state, action: PayloadAction<PhaseData[]>) => {
      phasesAdapter.upsertMany(state, action.payload)
      state.fetchFromBackendStatus = RequestStatus.Succeeded
    },
    fetchBackendAllFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.fetchFromBackendStatus = RequestStatus.Failed
    },
    fetchBackendAll: (state) => {
      state.fetchFromBackendStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.Phases,
  initialState: initialPhasesSliceState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { entities } = payload[StoreKeys.Phases]
      const phases = Object.values(entities)

      const phasesToUpsert = phases.filter(isPhase).map((phase) => {
        const currentState = state.entities[phase.id] || phase

        return {
          ...currentState,
          roi: phase.roi,
          id: phase.id,
          fundraiseAddress: phase.fundraiseAddress,
        }
      })

      return phasesAdapter.upsertMany(state, phasesToUpsert)
    }),
})

export const phasesAdapterSelectors = phasesAdapter.getSelectors()
export const phasesActions = phasesSlice.actions
export const phasesReducer = phasesSlice.reducer
export type PhasesActions = ActionsType<typeof phasesActions>
