import { createSelector } from '@reduxjs/toolkit'
import type { JsonRpcSigner } from '@ethersproject/providers'
import { providers } from 'ethers'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'
import type { Chains } from '../../../config/chains'
import envConfig from '../../../config/env'
import { WalletTypeEnum } from '../../graphql/generated/graphql'

import { Web3ActivationStatus, Web3ConnectionStatus } from './web3.state'
import { MetamaskError } from './saga/metamask/metamask.types'

const selectState = createSelector([selectReducer(StoreKeys.Web3)], (state) => state)

export const selectWeb3 = createSelector([selectState], (state) => {
  return state.web3
})

export const selectWeb3Library = createSelector([selectWeb3], (web3) => {
  return web3?.library
})

export const selectWeb3Account = createSelector([selectWeb3], (web3) => {
  return web3?.account || (web3?.connector as any)?.address
})

export const select3IsWebActive = createSelector([selectWeb3], (web3) => {
  return web3?.active
})

export const selectSigner = createSelector([selectWeb3], (web3) => {
  return web3?.library?.getSigner(web3?.account as string) as JsonRpcSigner
})

export const selectWsProvider = createSelector([selectState], () => {
  const applicationChainId = envConfig.chainId as unknown as Chains

  return new providers.WebSocketProvider(envConfig.polygonWsUrl, Number(applicationChainId))
})

export const selectWalletType = createSelector([selectState], (state) => {
  return state.walletType
})

export const selectChainId = createSelector([selectWeb3, selectWeb3Library, selectWalletType], (web3, web3Library, walletType) => {
  const chainId = walletType === WalletTypeEnum.ParticleNetwork ? envConfig.chainId : web3?.chainId
  return chainId as Chains
})

export const selectIsExpectedNetwork = createSelector([selectChainId], (chainId) => {
  return Boolean(chainId && chainId === envConfig.chainId)
})

export const selectReadOnlyProvider = createSelector([selectState], () => {
  const applicationChainId = envConfig.chainId as unknown as Chains

  return new providers.JsonRpcProvider(envConfig.polygonJsonRpcUrl, Number(applicationChainId))
})

export const selectWeb3ConnectionStatus = createSelector([selectState], (web3State) => {
  return web3State?.connectionStatus
})

export const selectWeb3ActivationStatus = createSelector([selectState], (web3State) => {
  return web3State?.activationStatus
})

export const selectIsWeb3Activated = createSelector([select3IsWebActive, selectWeb3ActivationStatus], (isWeb3Active, activationStatus) => {
  return activationStatus === Web3ActivationStatus.Success && isWeb3Active
})

export const selectIsWeb3ConnectionLocked = createSelector([selectState], (web3State) => {
  return web3State?.connectionStatus === Web3ConnectionStatus.Failure && web3State?.error === MetamaskError.UnlockWallet
})

export const selectIsWeb3ConnectionLInProgress = createSelector([selectState], (web3State) => {
  return web3State?.connectionStatus === Web3ConnectionStatus.InProgress
})

export const selectWalletConnectError = createSelector([selectState], (web3State) => {
  return web3State?.error
})
export const web3Selectors = {
  selectWsProvider,
  selectWeb3ConnectionStatus,
  selectWeb3ActivationStatus,
  selectWeb3Account,
  selectWeb3,
  selectWalletType,
  selectWalletConnectError,
  selectSigner,
  selectReadOnlyProvider,
  selectIsWeb3ConnectionLocked,
  selectIsWeb3ConnectionLInProgress,
  selectIsWeb3Activated,
  selectIsExpectedNetwork,
  selectChainId,
  select3IsWebActive,
}
