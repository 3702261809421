import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { StakeTokenStatuses } from './stakeToken.types'

export class StakeTokenState implements StakeTokenStatuses {
  fetchSubgraphAllStatus = RequestStatus.Idle
  fetchSubgraphSingleStatus = RequestStatus.Idle
  stakeStatus = RequestStatus.Idle
  unstakeStatus = RequestStatus.Idle
  error: RequestError = null
  stakeTransactionId: string | null = null
}
