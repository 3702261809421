import { createSelector } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import { selectReducer } from '../store.utils'
import { fundraiseSelectors } from '../fundraise/fundraise.selectors'

import { phasesAdapterSelectors } from './phases.slice'
import { isPhase, PhaseType } from './phases.const'

const selectState = createSelector([selectReducer(StoreKeys.Phases)], (state) => state)
const selectAll = createSelector([selectState], (state) => phasesAdapterSelectors.selectAll(state))

const selectCurrentPhase = createSelector([selectAll, fundraiseSelectors.selectCurrent], (state, fundraise) => {
  return state.find((phase) => phase.index === fundraise?.activePhaseIndex && phase.fundraiseAddress === fundraise?.address)
})

const selectNextPhase = createSelector([selectAll, fundraiseSelectors.selectCurrent], (state, fundraise) => {
  return state.find(
    (phase) => fundraise && phase.index === fundraise?.activePhaseIndex + 1 && phase.fundraiseAddress === fundraise?.address
  )
})

const selectAllOfCurrentFundraise = createSelector([selectAll, fundraiseSelectors.selectCurrent], (state, fundraise) => {
  return state.filter((phase) => phase.fundraiseAddress === fundraise?.address).filter(isPhase)
})

const selectIsCurrentPhaseSaleType = createSelector(
  [selectCurrentPhase],
  (phase) => phase !== undefined && phase.type !== PhaseType.suspendedSale
)

const selectIsPhaseActive = createSelector(
  [selectCurrentPhase, fundraiseSelectors.selectIsFundraiseActive],
  (currentPhase, isFundraiseActive) => isFundraiseActive && currentPhase !== undefined && currentPhase.endDate > Date.now() / 1000
)

const selectAllActivePhases = createSelector([selectAll, fundraiseSelectors.selectAll], (phases, fundraises) =>
  fundraises.map((fundraise) =>
    phases.find((phase) => phase.index === fundraise.activePhaseIndex && phase.fundraiseAddress === fundraise.address)
  )
)

export const phasesSelectors = {
  selectState,
  selectNextPhase,
  selectIsPhaseActive,
  selectIsCurrentPhaseSaleType,
  selectCurrentPhase,
  selectAllOfCurrentFundraise,
  selectAllActivePhases,
  selectAll,
}
