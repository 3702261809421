import { gql } from 'graphql-request'

export const STAKE_TOKEN_FRAGMENT = gql`
  fragment stakeTokenFragment on StakeToken {
    id
    name
    symbol
    decimals
    address
    projectToken {
      address
    }
    rewardTokenERC20 {
      address
    }
    rewardStablecoin {
      address
    }
    totalSupply
  }
`
export const ALL_STAKE_TOKENS_QUERY = gql`
  query stakeTokens {
    stakeTokens {
      ...stakeTokenFragment
    }
  }
  ${STAKE_TOKEN_FRAGMENT}
`

export const STAKE_TOKENS_FOR_PROJECT = gql`
  query stakeTokensForProject($projectAddress: String!) {
    stakeTokens(where: { projectToken_: { address: $projectAddress } }) {
      ...stakeTokenFragment
    }
  }
  ${STAKE_TOKEN_FRAGMENT}
`
