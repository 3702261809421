import { combineReducers } from '@reduxjs/toolkit'

import { sessionReducer } from './session/session.slice'
import { StoreKeys } from './store.keys'
import { userReducer } from './user/user.slice'
import { walletReducer } from './wallet/wallet.slice'
import { web3Reducer } from './web3/web3.slice'
import { projectTokenReducer } from './projectToken/projectToken.slice'
import { stakeTokenReducer } from './stakeToken/stakeToken.slice'
import { rewardTokenERC20Reducer } from './rewardTokenERC20/rewardTokenERC20.slice'
import { fundraiseReducer } from './fundraise/fundraise.slice'
import { sumsubReducer } from './sumsub/sumsub.slice'
import { navigationReducer } from './navigation/navigation.slice'
import { projectBaseReducer } from './projectBase/projectBase.slice'
import { projectContentReducer } from './projectContent/projectContent.slice'
import { balancesReducer } from './balances/balances.slice'
import { rewardsReducer } from './rewards/rewards.slice'
import { staticReducer } from './static/static.slice'
import { platformSummaryReducer } from './platformSummary/platformSummary.slice'
import { phasesReducer } from './phases/phases.slice'
import { tranchesReducer } from './tranches/tranches.slice'
import { investmentReducer } from './investment/investment.slice'
import { transactionsReducer } from './transactions/transaction.slice'
import { cryptocurrenciesReducer } from './cryptocurrencies/cryptocurrencies.slice'
import { closedProjectBaseReducer } from './closedProjectBase/closedProjectBase.slice'
import { userInvestmentsReducer } from './userInvestments/userInvestments.slice'
import { rewardStablecoinReducer } from './rewardStablecoin/rewardStablecoin.slice'
import { legacyTokensReducer } from './legacyTokens/legacyTokens.slice'
import { mosaicoAssetsReducer } from './mosaicoAssets/mosaicoAssets.slice'

export const rootReducer = combineReducers({
  [StoreKeys.Session]: sessionReducer,
  [StoreKeys.User]: userReducer,
  [StoreKeys.Web3]: web3Reducer,
  [StoreKeys.Wallet]: walletReducer,
  [StoreKeys.ProjectToken]: projectTokenReducer,
  [StoreKeys.StakeToken]: stakeTokenReducer,
  [StoreKeys.RewardTokenERC20]: rewardTokenERC20Reducer,
  [StoreKeys.Transactions]: transactionsReducer,
  [StoreKeys.Fundraise]: fundraiseReducer,
  [StoreKeys.Sumsub]: sumsubReducer,
  [StoreKeys.Navigation]: navigationReducer,
  [StoreKeys.ProjectBase]: projectBaseReducer,
  [StoreKeys.ClosedProjectBase]: closedProjectBaseReducer,
  [StoreKeys.MosaicoAssets]: mosaicoAssetsReducer,
  [StoreKeys.ProjectContent]: projectContentReducer,
  [StoreKeys.Balances]: balancesReducer,
  [StoreKeys.Rewards]: rewardsReducer,
  [StoreKeys.Static]: staticReducer,
  [StoreKeys.PlatformSummary]: platformSummaryReducer,
  [StoreKeys.Phases]: phasesReducer,
  [StoreKeys.Tranches]: tranchesReducer,
  [StoreKeys.Investment]: investmentReducer,
  [StoreKeys.Cryptocurrencies]: cryptocurrenciesReducer,
  [StoreKeys.UserInvestments]: userInvestmentsReducer,
  [StoreKeys.RewardStablecoin]: rewardStablecoinReducer,
  [StoreKeys.LegacyTokens]: legacyTokensReducer,
})
