import type { Components, Theme } from '@mui/material'

export const getDivider = (): Components<Theme> => ({
  MuiDivider: {
    styleOverrides: {
      wrapper: {
        padding: 0,
      },
    },
  },
})
