import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest, takeLeading } from 'typed-redux-saga'

import { projectTokenActions } from '../projectToken/projectToken.slice'
import { stakeTokenActions } from '../stakeToken/stakeToken.slice'
import { fundraiseActions } from '../fundraise/fundraise.slice'
import { rewardTokenERC20Actions } from '../rewardTokenERC20/rewardTokenERC20.slice'
import { investmentActions } from '../investment/investment.slice'
import { cryptocurrenciesActions } from '../cryptocurrencies/cryptocurrencies.slice'
import { rewardsActions } from '../rewards/rewards.slice'

import {
  fetchBalancesActionHandler,
  fetchFundraiseTokensBalancesSaga,
  fetchProjectTokensBalancesSaga,
  fetchStakeTokensBalancesSaga,
  fetchRewardTokensERC20BalancesSaga,
  fetchAllInternalBalancesSaga,
} from './saga/fetchBalances/fetchInternalBalances/fetchInternalBalances.saga'
import { balancesActions } from './balances.slice'
import { transferBalanceSaga } from './saga/transferBalance/transferBalance.saga'
import { getBalancesBlockchainSaga } from './saga/fetchBalances/getBalancesBlockchain/getBalancesBlockchain.saga'
import { fetchLegacyProjectTokensBalancesSaga } from './saga/fetchBalances/fetchLegacyBalances/fetchLegacyProjectTokensBalances.saga'
import { fetchLegacyRewardTokensBalancesSaga } from './saga/fetchBalances/fetchLegacyBalances/fetchLegacyRewardTokensBalances.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(balancesActions.fetchInternalBalances, fetchBalancesActionHandler),
    takeLatest([balancesActions.fetchAllInternalBalances.type], fetchAllInternalBalancesSaga),
    takeLeading([balancesActions.fetchAllLegacyBalances.type], fetchLegacyProjectTokensBalancesSaga),
    takeLeading([balancesActions.fetchAllLegacyBalances.type], fetchLegacyRewardTokensBalancesSaga),
    takeLatest(
      [
        projectTokenActions.investSuccess.type,
        stakeTokenActions.stakeSuccess.type,
        stakeTokenActions.unstakeSuccess.type,
        investmentActions.cryptoInvestmentSuccess.type,
      ],
      fetchProjectTokensBalancesSaga
    ),
    takeLatest(
      [fundraiseActions.investSuccess.type, investmentActions.cryptoInvestmentSuccess.type, fundraiseActions.swapFundTokens.type],
      fetchFundraiseTokensBalancesSaga
    ),
    takeLatest(
      [stakeTokenActions.stakeSuccess.type, stakeTokenActions.unstakeSuccess.type, balancesActions.fetchAllInternalBalances.type],
      fetchStakeTokensBalancesSaga
    ),
    takeLatest(
      [
        rewardTokenERC20Actions.consumeSuccess.type,
        rewardsActions.claimTimeStakeRewardsSuccess.type,
        rewardsActions.claimLiquidStakeRewardsSuccess.type,
      ],
      fetchRewardTokensERC20BalancesSaga
    ),
    takeLatest(
      [balancesActions.updateBalances.type, cryptocurrenciesActions.mintUSDCSuccess, balancesActions.transferBalancesSuccess],
      getBalancesBlockchainSaga
    ),
    takeLatest(balancesActions.transferBalances.type, transferBalanceSaga),
  ])
}

export function* balancesSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
