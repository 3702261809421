import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { navigationSelectors } from '../../../navigation/navigation.selectors'
import { sessionSelectors } from '../../session.selectors'
import type { SessionActions } from '../../session.slice'
import { sessionActions } from '../../session.slice'
import type { TAppState } from '../../session.types'

export function* loginHandler({ payload }: SessionActions['loginStart']): SagaGenerator<void> {
  try {
    const auth0Client = yield* select(sessionSelectors.selectAuth0Client)

    const currentLanguage = yield* select(navigationSelectors.selectCurrentLocalisation)

    const url = `${window.location.origin}/${currentLanguage}`
    const redirectPathname = payload?.redirectPathname ?? ''

    const appState: TAppState = { returnTo: url + redirectPathname }

    yield* call([auth0Client, auth0Client.loginWithRedirect], { authorizationParams: { scope: 'offline_access' }, appState })
  } catch (error) {
    if (error instanceof Error) yield* put(sessionActions.loginError(error.message))
  }
}
