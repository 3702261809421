import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { ProjectTokensBackendQueryResponse } from '../../../../../staticContent'
import type { BackendProjectTokenQueryResult } from '../../../../types/backendQueryTypes'
import type { BackendProjectTokenData, ProjectTokenData } from '../../projectToken.types'
import { mapBackendProjectTokenQueryResultToBackendProjectTokenData } from '../../projectToken.mappers'
import { projectTokenActions } from '../../projectToken.slice'
import { apiCall } from '../../../../utils/apiCall'
import { emptySupgraphProjectTokenData } from '../../projectToken.const'

export async function fetchBackendAllProjectTokens(client: GraphQLClient): Promise<BackendProjectTokenData[]> {
  const result: BackendProjectTokenQueryResult[] = await Promise.resolve(ProjectTokensBackendQueryResponse())

  return result.map(mapBackendProjectTokenQueryResultToBackendProjectTokenData)
}

export function* fetchBackendAllProjectTokensSaga(): SagaGenerator<void> {
  try {
    const allBackendProjectTokens = yield* apiCall(fetchBackendAllProjectTokens)

    const mappedToProjectTokens: ProjectTokenData[] = allBackendProjectTokens.map((backendProjectToken) => ({
      ...emptySupgraphProjectTokenData,
      ...backendProjectToken,
    }))

    yield* put(projectTokenActions.fetchBackendAllSuccess(mappedToProjectTokens))
  } catch (error) {
    if (error instanceof Error) yield* put(projectTokenActions.fetchBackendAllFailure(error.message))
  }
}
