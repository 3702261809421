// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgReward = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.984 7.454H7.016m9.12 6.767 4.293-4.763c.367-.408.571-.936.571-1.482v-2.75C21 4.003 19.988 3 18.75 3H5.25C4.007 3 3 3.996 3 5.227v2.749c0 .546.204 1.074.571 1.482l4.293 4.763M3.641 3.7l7.324 8.14m9.394-8.14-7.324 8.14m2.28 1.239a4.606 4.606 0 0 1 0 6.561 4.722 4.722 0 0 1-6.63 0 4.606 4.606 0 0 1 0-6.561 4.723 4.723 0 0 1 6.63 0Z"
    />
  </svg>
)
export default SvgReward
