import { call, select } from 'typed-redux-saga'

import { MetamaskActions } from '../metamask.types'
import envConfig from '../../../../../../config/env'
import { web3Selectors } from '../../../web3.selectors'
import type { Chains } from '../../../../../../config/chains'
import { getWeb3WalletType } from '../../../web3.utils'
import { WalletTypeEnum } from '../../../../../graphql/generated/graphql'

export function* switchMetamaskNetworkSaga(): Generator {
  const web3 = yield* select(web3Selectors.selectWeb3)
  if (web3) {
    const provider = web3.library

    if (!provider) throw new Error('Provider not found')
    if (!envConfig.chainId) throw new Error('Missing network config')
    try {
      yield* call([provider, provider.send], MetamaskActions.switchNetwork, [{ chainId: `0x${envConfig.chainId.toString(16)}` }])
    } catch (switchNetworkError) {
      if (switchNetworkError instanceof Error) console.log(switchNetworkError)
    }
  }
}

export function* setExpectedMetamaskChainSaga(): Generator {
  const web3 = yield* select(web3Selectors.selectWeb3)
  const actualChainId = yield* select(web3Selectors.selectChainId)
  const expectedChainId = envConfig.chainId as unknown as Chains

  if (web3) {
    const walletType = yield* call(getWeb3WalletType, web3)
    if (walletType === WalletTypeEnum.Metamask && actualChainId && actualChainId !== expectedChainId) yield* call(switchMetamaskNetworkSaga)
  }
}
