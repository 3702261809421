import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { platformSummaryActions } from './platformSummary.slice'
import { fetchPlatformSummarySaga } from './sagas/fetchPlatformSummary/fetchPlatformSummary.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([takeLatest(platformSummaryActions.fetchPlatformSummary, fetchPlatformSummarySaga)])
}

export function* platformSummarySaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
