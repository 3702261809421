import type { RedirectLoginResult } from '@auth0/auth0-spa-js'
import { call, put, select } from 'typed-redux-saga'

import { sessionSelectors } from '../../session.selectors'
import { sessionActions } from '../../session.slice'
import type { TAppState } from '../../session.types'

export function* redirectHandler() {
  try {
    const auth0Client = yield* select(sessionSelectors.selectAuth0Client)

    const result = yield* call([auth0Client, auth0Client.handleRedirectCallback])

    const { appState } = result as RedirectLoginResult<TAppState>

    yield* call([window.location, window.location.replace], appState?.returnTo || window.location.pathname)

    yield* put(sessionActions.redirectSuccess())
    yield* put(sessionActions.initializeSessionStart(window.location.origin))
  } catch (error) {
    if (error instanceof Error) yield* put(sessionActions.redirectError(error.message))
  }
}
