import type { InvestMessageData } from '@sapiency-io/mosaico-contracts/dist'
import { generateRandomSalt, signInvestmentTypedData } from '@sapiency-io/mosaico-contracts/dist'
import { BigNumber, utils } from 'ethers'
import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { projectTokenActions } from '../../../projectToken/projectToken.slice'
import { selectChainId, selectSigner, selectWeb3Account } from '../../../web3/web3.selectors'
import { getFundraise } from '../../../web3/web3.utils'
import type { FundraiseActions } from '../../fundraise.slice'
import { fundraiseActions } from '../../fundraise.slice'
import { getStablecoinAmount } from '../../../../utils/calculateAmounts'
import { pushNotification } from '../../../../utils/pushNotification'
import { projectTokenSelectors } from '../../../projectToken/projectToken.selectors'
import { phasesSelectors } from '../../../phases/phases.selectors'
import { cryptocurrenciesSelectors } from '../../../cryptocurrencies/cryptocurrencies.selectors'

export function* investSaga({ payload }: FundraiseActions['invest']): SagaGenerator<void> {
  const account = yield* select(selectWeb3Account)
  if (!account) return

  const { fundraiseAddress, amount } = payload

  try {
    const signer = yield* select(selectSigner)
    const chainId = yield* select(selectChainId)
    const fundraise = getFundraise(fundraiseAddress, signer)
    const stablecoin = yield* select(cryptocurrenciesSelectors.selectUSDCContract)

    const projectToken = yield* select(projectTokenSelectors.selectCurrent)

    if (!projectToken) throw new Error('Project token not found')

    const activePhase = yield* select(phasesSelectors.selectCurrentPhase)
    if (!activePhase) throw new Error('Active phase not found')

    const stablecoinDecimals = yield* call(stablecoin.decimals)
    const stablecoinAmount = getStablecoinAmount(amount, activePhase.tokenRate, stablecoinDecimals)
    const parsedAmount = utils.parseUnits(amount, projectToken.decimals)
    const salt = yield* call(generateRandomSalt)

    const dataToSign: InvestMessageData = {
      verifyingContract: fundraise.address,
      salt,
      investor: account,
      chainId,
      amountOut: parsedAmount,
      amountIn: BigNumber.from(0),
    }

    const approveTx = yield* call(stablecoin.approve, fundraiseAddress, stablecoinAmount)
    yield* call(approveTx.wait)

    const signature = yield* call(signInvestmentTypedData, signer, dataToSign)

    const tx = yield* call(fundraise.invest, account, parsedAmount, salt, signature)
    yield* call(tx.wait)

    yield* put(fundraiseActions.investSuccess())
    pushNotification("You've successfully invested")
  } catch (error) {
    if (error instanceof Error) {
      yield* put(projectTokenActions.investFailure(error.message))
      pushNotification(`Invest Error: ${error.message}`, 'error')
    }
  }
}
