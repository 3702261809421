import type { SagaGenerator } from 'typed-redux-saga'
import { put, select } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { PhasesBackendQueryResponseByAddress } from '../../../../../staticContent'
import type { BackendPhase, PhaseData } from '../../phases.types'
import { mapBackendPhaseQueryResultToBackendPhaseData } from '../../phases.mappers'
import type { BackendPhaseQueryResult } from '../../../../types/backendQueryTypes'
import { phasesActions } from '../../phases.slice'
import { apiCall } from '../../../../utils/apiCall'
import { emptySubgraphPhase } from '../../phases.const'
import { fundraiseSelectors } from '../../../fundraise/fundraise.selectors'

export async function fetchBackendForProjectPhases(client: GraphQLClient, fundraiseId: string): Promise<BackendPhase[]> {
  const result: BackendPhaseQueryResult[] = await Promise.resolve(PhasesBackendQueryResponseByAddress(fundraiseId))

  return result.map(mapBackendPhaseQueryResultToBackendPhaseData)
}

export function* fetchBackendForProjectPhasesSaga(): SagaGenerator<void> {
  try {
    const currentFundraise = yield* select(fundraiseSelectors.selectCurrent)

    if (!currentFundraise) throw new Error('Fundraise address not found')

    const backendPhases = yield* apiCall(fetchBackendForProjectPhases, currentFundraise.id)

    const mappedToPhases: PhaseData[] = backendPhases.map((backendPhase) => ({
      ...emptySubgraphPhase,
      ...backendPhase,
    }))

    yield* put(phasesActions.fetchBackendForProjectSuccess(mappedToPhases))
  } catch (error) {
    if (error instanceof Error) yield* put(phasesActions.fetchBackendForProjectFailure(error.message))
  }
}
