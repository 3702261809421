import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ApplicantData = {
  __typename?: 'ApplicantData';
  country: Scalars['String'];
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  postCode: Scalars['String'];
  street: Scalars['String'];
  streetNumber: Scalars['String'];
  town: Scalars['String'];
};

export type ContentModulePropertyType = {
  __typename?: 'ContentModulePropertyType';
  contentModuleField?: Maybe<ContentModuleType>;
  imageField?: Maybe<ImageFieldType>;
  name: Scalars['String'];
  textField?: Maybe<TextFieldType>;
  type: PropertyType;
};

export type ContentModuleType = {
  __typename?: 'ContentModuleType';
  properties: Array<ContentModulePropertyType>;
  type: Scalars['String'];
};

export type ImageFieldType = {
  __typename?: 'ImageFieldType';
  src: Scalars['String'];
};

export type InvestmentObjectType = {
  __typename?: 'InvestmentObjectType';
  amountIn: Scalars['String'];
  amountOut: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fundraiseAddress: Scalars['String'];
  id: Scalars['Float'];
  status: Scalars['String'];
  transactionHash?: Maybe<Scalars['String']>;
  transactionStatus: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
  walletAddress: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createInvestment: InvestmentObjectType;
  saveWallet: WalletType;
  updateInvestment: InvestmentObjectType;
  updateUser: UserType;
};


export type MutationCreateInvestmentArgs = {
  amountIn: Scalars['String'];
  amountOut: Scalars['String'];
  fundraiseAddress: Scalars['String'];
  type: Scalars['String'];
  walletAddress: Scalars['String'];
};


export type MutationSaveWalletArgs = {
  ethereumAddress: Scalars['String'];
  walletType: Scalars['String'];
};


export type MutationUpdateInvestmentArgs = {
  id: Scalars['Float'];
  transactionHash?: InputMaybe<Scalars['String']>;
  transactionStatus: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  localization?: InputMaybe<Scalars['String']>;
  marketingConsents?: InputMaybe<Scalars['Boolean']>;
};

export type PageType = {
  __typename?: 'PageType';
  contentModules: Array<ContentModuleType>;
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type ProjectType = {
  __typename?: 'ProjectType';
  contractAddress: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
  logoSrc: Scalars['String'];
  mainImageSrc: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export enum PropertyType {
  ContentModule = 'CONTENT_MODULE',
  Image = 'IMAGE',
  Text = 'TEXT'
}

export type Query = {
  __typename?: 'Query';
  applicantData: ApplicantData;
  investments: Array<InvestmentObjectType>;
  page: PageType;
  project: ProjectType;
  projects: Array<ProjectType>;
  user: UserType;
};


export type QueryPageArgs = {
  deviceType: Scalars['String'];
  languageCode: Scalars['String'];
  pageId: Scalars['Float'];
};


export type QueryProjectArgs = {
  deviceType: Scalars['String'];
  languageCode: Scalars['String'];
  projectId?: InputMaybe<Scalars['Float']>;
};


export type QueryProjectsArgs = {
  deviceType: Scalars['String'];
  languageCode: Scalars['String'];
  projectId?: InputMaybe<Scalars['Float']>;
};

export type TextFieldType = {
  __typename?: 'TextFieldType';
  value: Scalars['String'];
};

export type UserType = {
  __typename?: 'UserType';
  advancedKycStatus: Scalars['String'];
  basicKycStatus: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Float'];
  investments: Array<InvestmentObjectType>;
  isMigrated: Scalars['Boolean'];
  localization: Scalars['String'];
  marketingConsents: Scalars['Boolean'];
  monthlyInvestments: Scalars['String'];
  wallets: Array<WalletType>;
};

export type WalletType = {
  __typename?: 'WalletType';
  ethereumAddress: Scalars['String'];
  walletType: WalletTypeEnum;
};

export enum WalletTypeEnum {
  Metamask = 'METAMASK',
  ParticleNetwork = 'PARTICLE_NETWORK',
  WalletConnect = 'WALLET_CONNECT'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ApplicantData: ResolverTypeWrapper<ApplicantData>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ContentModulePropertyType: ResolverTypeWrapper<ContentModulePropertyType>;
  ContentModuleType: ResolverTypeWrapper<ContentModuleType>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ImageFieldType: ResolverTypeWrapper<ImageFieldType>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  InvestmentObjectType: ResolverTypeWrapper<InvestmentObjectType>;
  Mutation: ResolverTypeWrapper<{}>;
  PageType: ResolverTypeWrapper<PageType>;
  ProjectType: ResolverTypeWrapper<ProjectType>;
  PropertyType: PropertyType;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
  TextFieldType: ResolverTypeWrapper<TextFieldType>;
  UserType: ResolverTypeWrapper<UserType>;
  WalletType: ResolverTypeWrapper<WalletType>;
  WalletTypeEnum: WalletTypeEnum;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ApplicantData: ApplicantData;
  Boolean: Scalars['Boolean'];
  ContentModulePropertyType: ContentModulePropertyType;
  ContentModuleType: ContentModuleType;
  DateTime: Scalars['DateTime'];
  Float: Scalars['Float'];
  ImageFieldType: ImageFieldType;
  Int: Scalars['Int'];
  InvestmentObjectType: InvestmentObjectType;
  Mutation: {};
  PageType: PageType;
  ProjectType: ProjectType;
  Query: {};
  String: Scalars['String'];
  TextFieldType: TextFieldType;
  UserType: UserType;
  WalletType: WalletType;
};

export type ApplicantDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantData'] = ResolversParentTypes['ApplicantData']> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dateOfBirth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  town?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentModulePropertyTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentModulePropertyType'] = ResolversParentTypes['ContentModulePropertyType']> = {
  contentModuleField?: Resolver<Maybe<ResolversTypes['ContentModuleType']>, ParentType, ContextType>;
  imageField?: Resolver<Maybe<ResolversTypes['ImageFieldType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  textField?: Resolver<Maybe<ResolversTypes['TextFieldType']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentModuleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentModuleType'] = ResolversParentTypes['ContentModuleType']> = {
  properties?: Resolver<Array<ResolversTypes['ContentModulePropertyType']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type ImageFieldTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImageFieldType'] = ResolversParentTypes['ImageFieldType']> = {
  src?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvestmentObjectTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvestmentObjectType'] = ResolversParentTypes['InvestmentObjectType']> = {
  amountIn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amountOut?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  fundraiseAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transactionStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  walletAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createInvestment?: Resolver<ResolversTypes['InvestmentObjectType'], ParentType, ContextType, RequireFields<MutationCreateInvestmentArgs, 'amountIn' | 'amountOut' | 'fundraiseAddress' | 'type' | 'walletAddress'>>;
  saveWallet?: Resolver<ResolversTypes['WalletType'], ParentType, ContextType, RequireFields<MutationSaveWalletArgs, 'ethereumAddress' | 'walletType'>>;
  updateInvestment?: Resolver<ResolversTypes['InvestmentObjectType'], ParentType, ContextType, RequireFields<MutationUpdateInvestmentArgs, 'id' | 'transactionStatus'>>;
  updateUser?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, Partial<MutationUpdateUserArgs>>;
};

export type PageTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageType'] = ResolversParentTypes['PageType']> = {
  contentModules?: Resolver<Array<ResolversTypes['ContentModuleType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectType'] = ResolversParentTypes['ProjectType']> = {
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logoSrc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mainImageSrc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  applicantData?: Resolver<ResolversTypes['ApplicantData'], ParentType, ContextType>;
  investments?: Resolver<Array<ResolversTypes['InvestmentObjectType']>, ParentType, ContextType>;
  page?: Resolver<ResolversTypes['PageType'], ParentType, ContextType, RequireFields<QueryPageArgs, 'deviceType' | 'languageCode' | 'pageId'>>;
  project?: Resolver<ResolversTypes['ProjectType'], ParentType, ContextType, RequireFields<QueryProjectArgs, 'deviceType' | 'languageCode'>>;
  projects?: Resolver<Array<ResolversTypes['ProjectType']>, ParentType, ContextType, RequireFields<QueryProjectsArgs, 'deviceType' | 'languageCode'>>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
};

export type TextFieldTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TextFieldType'] = ResolversParentTypes['TextFieldType']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserType'] = ResolversParentTypes['UserType']> = {
  advancedKycStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  basicKycStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  investments?: Resolver<Array<ResolversTypes['InvestmentObjectType']>, ParentType, ContextType>;
  isMigrated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  localization?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  marketingConsents?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  monthlyInvestments?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  wallets?: Resolver<Array<ResolversTypes['WalletType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WalletTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletType'] = ResolversParentTypes['WalletType']> = {
  ethereumAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  walletType?: Resolver<ResolversTypes['WalletTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ApplicantData?: ApplicantDataResolvers<ContextType>;
  ContentModulePropertyType?: ContentModulePropertyTypeResolvers<ContextType>;
  ContentModuleType?: ContentModuleTypeResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  ImageFieldType?: ImageFieldTypeResolvers<ContextType>;
  InvestmentObjectType?: InvestmentObjectTypeResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PageType?: PageTypeResolvers<ContextType>;
  ProjectType?: ProjectTypeResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  TextFieldType?: TextFieldTypeResolvers<ContextType>;
  UserType?: UserTypeResolvers<ContextType>;
  WalletType?: WalletTypeResolvers<ContextType>;
};

