import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'

import type { SumsubLevels } from './sumsub.constants'
import { SumsubStore } from './sumsub.state'
import type { ISetStep } from './sumsub.types'

const initialState = { ...new SumsubStore() }

export const sumsubSlice = createSlice({
  reducers: {
    sumsubIsReady: (state) => {
      state.isSumsubReady = true
    },

    startSumsubVerification: (_, __: PayloadAction<SumsubLevels>) => {},

    setStep: (state, action: PayloadAction<ISetStep>) => {
      state.sumsubStep = action.payload.step
    },

    applicantAccepted: () => {},
  },
  name: StoreKeys.Sumsub,
  initialState,
})

const { actions: sumsubActions, reducer: sumsubReducer } = sumsubSlice
export { sumsubActions, sumsubReducer }
export type SumsubActions = ActionsType<typeof sumsubActions>
