import type { Breakpoints, Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'
import type { CustomTypographyVariants } from '../typography'

export const getTabs = (palette: CustomPalette, _: CustomTypographyVariants, breakpoints: Breakpoints): Components<Theme> => ({
  MuiTabs: {
    styleOverrides: {
      root: {
        [breakpoints.down('xl')]: {
          padding: '0 16px',
        },
      },
      indicator: {
        backgroundColor: palette._primary[400],
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        padding: '24px 0px',
        minWidth: 0,
        fontWeight: 400,
        fontSize: '1rem',
        alignItems: 'center',
        '&:not(:last-child)': {
          marginRight: '24px',
          [breakpoints.up('md')]: {
            marginRight: '48px',
          },
        },
        '&.Mui-selected': {
          color: palette._primary[400],
        },
      },
    },
  },
})
