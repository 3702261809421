'use client'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import type { Web3Provider } from '@ethersproject/providers'

import { web3Actions } from '../../store/web3/web3.slice'
import { sessionSelectors } from '../../store/session/session.selectors'
import { web3Selectors } from '../../store/web3/web3.selectors'
import { Web3ActivationStatus } from '../../store/web3/web3.state'

export const Web3Initialization: React.FC<React.PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const web3 = useWeb3React<Web3Provider>()

  const isSessionLoadedSuccessful = useSelector(sessionSelectors.selectIsSessionLoadedSuccessful)
  const web3ActivationStatus = useSelector(web3Selectors.selectWeb3ActivationStatus)
  const isWeb3Deactivated = web3ActivationStatus === Web3ActivationStatus.Deactivated

  useEffect(() => {
    if (isSessionLoadedSuccessful && !isWeb3Deactivated) dispatch(web3Actions.web3InitializationRequest({ web3 }))
  }, [dispatch, web3, isWeb3Deactivated, isSessionLoadedSuccessful])

  return <>{children}</>
}
