import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select, take } from 'typed-redux-saga'

import { SessionCustomErrorMessages } from '../../session.const'
import { sessionSelectors } from '../../session.selectors'
import { sessionActions } from '../../session.slice'
import { userActions } from '../../../user/user.slice'
import { userSelectors } from '../../../user/user.selectors'
import { RequestStatus } from '../../../types'

export function* initializeSessionHandler(): SagaGenerator<void> {
  try {
    const auth0Client = yield* select(sessionSelectors.selectAuth0Client)

    yield* call([auth0Client, auth0Client.checkSession], { detailedResponse: true })

    const isAuthenticated = yield* call([auth0Client, auth0Client.isAuthenticated])

    if (!isAuthenticated) throw new Error(SessionCustomErrorMessages.USER_NOT_AUTHENTICATED)

    yield* put(sessionActions.auth0SessionLoadedSuccess())

    yield* put(userActions.loadUserData())

    yield* take([userActions.loadUserDataSuccess, userActions.loadUserDataError])
    const userDataLoadStatus = yield* select(userSelectors.selectLoadUserDataStatus)
    if (userDataLoadStatus === RequestStatus.Failed) throw new Error(SessionCustomErrorMessages.USER_DATA_UNDEFINED)

    yield* put(sessionActions.initializeSessionSuccess())
  } catch (error) {
    if (error instanceof Error)
      // When user is not logged in, it will throw error => User is not authenticated
      // Message is hardcoded in Auth0Messages.USER_NOT_AUTHENTICATED

      yield* put(sessionActions.initializeSessionError(error.message))
  }
}
