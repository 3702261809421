import { all, takeLatest, takeLeading } from 'typed-redux-saga'

import { sumsubActions } from './sumsub.slice'
import { startSumsubVerificationSaga } from './saga/startSumsubVerification/startSumsubVerification.saga'
import { waitForWebhookToUpdateKycStatus } from './saga/waitForWebhook/waitForWebhook.saga'

export function* sumsubSaga(): Generator {
  yield* all([
    takeLatest(sumsubActions.startSumsubVerification, startSumsubVerificationSaga),
    takeLeading(sumsubActions.applicantAccepted, waitForWebhookToUpdateKycStatus),
  ])
}
