import { DEFAULT_INTERNAL_TOKEN_DECIMALS } from '../../../config/decimals'
import type { SubgraphDefaultBalanceQueryResult } from '../../types/subgraphQueryTypes'
import { BigDecimal } from '../../utils/bigDecimal'

import { type InternalBalanceType } from './balances.const'
import type { TokenBalanceData } from './balances.types'

export const mapSubgraphDefaultBalanceQueryResultToTokenBalanceData = (
  balanceData: SubgraphDefaultBalanceQueryResult,
  balanceType: InternalBalanceType
): TokenBalanceData => {
  const { account, token, amount } = balanceData
  return {
    tokenAddress: token.address,
    balanceType,
    amount: BigDecimal.fromString(amount, DEFAULT_INTERNAL_TOKEN_DECIMALS).toFixed(DEFAULT_INTERNAL_TOKEN_DECIMALS),
    account: account.id,
  }
}
