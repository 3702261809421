import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { createInvestmentSaga } from './sagas/createInvestment/createInvestment.saga'
import { fetchUserInvestmentsSaga } from './sagas/fetchUserInvestments/fetchUserInvestments.saga'
import { updateInvestmentSaga } from './sagas/updateInvestment/updateInvestment.saga'
import { userInvestmentsActions } from './userInvestments.slice'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(userInvestmentsActions.fetchUserInvestments.type, fetchUserInvestmentsSaga),
    takeLatest(userInvestmentsActions.createUserInvestment.type, createInvestmentSaga),
    takeLatest(userInvestmentsActions.updateUserInvestment.type, updateInvestmentSaga),
  ])
}

export function* userInvestmentsSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
