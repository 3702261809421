import { gql } from 'graphql-request'

export const REWARD_STABLECOIN_FRAGMENT = gql`
  fragment rewardStablecoinFragment on Stablecoin {
    id
    name
    symbol
    decimals
    address
    totalSupply
    dailyReward
    projectToken {
      address
    }
    stakeToken {
      address
    }
  }
`

export const ALL_REWARD_STABLECOINS_QUERY = gql`
  query rewardStablecoins {
    stablecoins {
      ...rewardStablecoinFragment
    }
  }
  ${REWARD_STABLECOIN_FRAGMENT}
`
