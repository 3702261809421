import { gql } from 'graphql-request'

export const TRANCHE_FRAGMENT = gql`
  fragment tranche on Tranche {
    id
    token {
      address
    }
    trancheAction
    account
    amount
    start
    duration
  }
`

export const MULTIPLE_TRANCHES_QUERY = gql`
  query multipleTranches($tokenAddresses: [String!]) {
    tranches(where: { token_: { address_in: $tokenAddresses } }) {
      ...tranche
    }
  }
  ${TRANCHE_FRAGMENT}
`

export const ALL_TRANCHES_FOR_TOKEN_QUERY = gql`
  query allTranchesForToken($tokenAddress: String!) {
    tranches(where: { token: $tokenAddress }) {
      ...tranche
    }
  }
  ${TRANCHE_FRAGMENT}
`
