// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgLink = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m14.126 17.262-1.457 1.457a5.373 5.373 0 1 1-7.6-7.6L6.53 9.666m2.365 5.231 6.21-6.21m-5.23-2.365 1.456-1.457a5.37 5.37 0 0 1 7.6 0 5.373 5.373 0 0 1 0 7.6l-1.46 1.453"
    />
  </svg>
)
export default SvgLink
