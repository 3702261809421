import type { SagaGenerator } from 'typed-redux-saga'
import { put } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { apiCall } from '../../../../utils/apiCall'
import { GET_USER_INVESTMENTS } from '../../../../graphql/client/queries'
import type { UserInvestment } from '../../userInvestments.types'
import { userInvestmentsActions } from '../../userInvestments.slice'

export async function fetchUserInvestments(client: GraphQLClient): Promise<UserInvestment[]> {
  const { investments } = await client.request(GET_USER_INVESTMENTS)
  return investments as UserInvestment[]
}

export function* fetchUserInvestmentsSaga(): SagaGenerator<void> {
  try {
    const userInvestments: UserInvestment[] = yield* apiCall(fetchUserInvestments)

    yield* put(userInvestmentsActions.fetchUserInvestmentsSuccess(userInvestments))
  } catch (error) {
    if (error instanceof Error) yield* put(userInvestmentsActions.fetchUserInvestmentsError(error.message))
  }
}
