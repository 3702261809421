export enum VerificationStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFICATION_STARTED = 'VERIFICATION_STARTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum SumsubSteps {
  INIT = 'INIT',
  APPLICANT_DATA = 'APPLICANT_DATA',
  SELFIE = 'SELFIE',
  COMPLETED = 'COMPLETED',
}

export enum SumsubLevels {
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
}
