import type { Components, Theme } from '@mui/material'

export const fontFace: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins100.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
      }
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins200.ttf') format('truetype');
        font-weight: 200;
        font-style: normal;
      }
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins300.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
      }
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins400.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins500.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
      }

      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins600.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
      }

      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins700.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins800.ttf') format('truetype');
        font-weight: 800;
        font-style: bold;
      }
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins900.ttf') format('truetype');
        font-weight: 900;
        font-style: bold;
      }
    `,
  },
}
