import { createSelector } from '@reduxjs/toolkit'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'
import { RequestStatus } from '../types'
import { projectBaseSelectors } from '../projectBase/projectBase.selectors'

import { fundraiseAdapterSelectors } from './fundraise.slice'
import { FundraiseStage, Milestone } from './fundraise.const'

export const selectState = createSelector([selectReducer(StoreKeys.Fundraise)], (state) => state)

export const selectAll = createSelector([selectState], (state) => fundraiseAdapterSelectors.selectAll(state))

export const selectCurrent = createSelector([selectAll, projectBaseSelectors.selectCurrent], (fundraises, projectBase) =>
  fundraises.find((fundraise) => fundraise.address === projectBase?.fundrisingAddress)
)

export const selectProjectStage = createSelector([selectCurrent], (fundraise) => fundraise?.stage)

export const selectInvestStatus = createSelector([selectState], (state) => state.investStatus)
export const selectIsInvestPending = createSelector([selectInvestStatus], (investStatus) => investStatus === RequestStatus.Loading)

export const selectSwapStatus = createSelector([selectState], (state) => state.swapFundTokensStatus)
export const selectIsSwapPending = createSelector([selectSwapStatus], (swapStatus) => swapStatus === RequestStatus.Loading)

export const selectInvestors = createSelector([selectCurrent], (fundraise) => fundraise?.investorsCounter)

export const selectIsFundraiseActive = createSelector(
  [selectCurrent],
  (currentFundraise) =>
    currentFundraise !== undefined && currentFundraise.stage !== FundraiseStage.upcoming && currentFundraise.activePhaseIndex > 0
)

export const selectIsSoftCapReached = createSelector(
  [selectCurrent],
  (fundraise) => fundraise?.activeMilestone === Milestone.softCapReached
)

export const fundraiseSelectors = {
  selectSwapStatus,
  selectState,
  selectProjectStage,
  selectIsSwapPending,
  selectIsSoftCapReached,
  selectIsInvestPending,
  selectIsFundraiseActive,
  selectInvestStatus,
  selectInvestors,
  selectCurrent,
  selectAll,
}
