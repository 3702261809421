import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import { RequestStatus } from '../types'

import type { SubgraphTranche, TrancheData, Tranches } from './tranches.types'
import { TranchesState } from './tranches.state'
import { isTranche } from './tranches.const'

export const tranchesAdapter = createEntityAdapter<TrancheData>({
  selectId: (tranche) => tranche.id,
})

const hydrate = createAction<{ [StoreKeys.Tranches]: Tranches }>(HYDRATE)

export const initialTranchesSliceState: Tranches = tranchesAdapter.getInitialState(new TranchesState())
export const tranchesSlice = createSlice({
  reducers: {
    fetchSubgraphForTokenSuccess: (state, action: PayloadAction<SubgraphTranche[]>) => {
      tranchesAdapter.updateMany(
        state,
        action.payload.map((tranche) => ({ id: tranche.id, changes: tranche }))
      )
      state.fetchSubgraphSingleStatus = RequestStatus.Succeeded
    },
    fetchSubgraphForTokenFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.fetchSubgraphSingleStatus = RequestStatus.Failed
    },
    fetchSubgraphForToken: (state, _action: PayloadAction<string>) => {
      state.fetchSubgraphSingleStatus = RequestStatus.Loading
    },
    fetchSubgraphAllSuccess: (state, action: PayloadAction<SubgraphTranche[]>) => {
      tranchesAdapter.updateMany(
        state,
        action.payload.map((phase) => ({ id: phase.id, changes: phase }))
      )
      state.fetchSubgraphAllStatus = RequestStatus.Succeeded
    },
    fetchSubgraphAllFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.fetchSubgraphAllStatus = RequestStatus.Failed
    },
    fetchSubgraphAll: (state) => {
      state.fetchSubgraphAllStatus = RequestStatus.Loading
    },
    fetchBackendForTokenSuccess: (state, action: PayloadAction<TrancheData[]>) => {
      tranchesAdapter.upsertMany(state, action.payload)
      state.fetchFromBackendStatus = RequestStatus.Succeeded
    },
    fetchBackendForTokenFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.fetchFromBackendStatus = RequestStatus.Failed
    },
    fetchBackendForToken: (state, _action: PayloadAction<string>) => {
      state.fetchFromBackendStatus = RequestStatus.Loading
    },
    fetchBackendAllSuccess: (state, action: PayloadAction<TrancheData[]>) => {
      tranchesAdapter.upsertMany(state, action.payload)
      state.fetchFromBackendStatus = RequestStatus.Succeeded
    },
    fetchBackendAllFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.fetchFromBackendStatus = RequestStatus.Failed
    },
    fetchBackendAll: (state) => {
      state.fetchFromBackendStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.Tranches,
  initialState: initialTranchesSliceState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { entities } = payload[StoreKeys.Tranches]
      const tranches = Object.values(entities)

      const tranchesToUpsert = tranches.filter(isTranche).map((tranche) => {
        const currentState = state.entities[tranche.id] || tranche

        return {
          ...currentState,
          tokenAddress: tranche.tokenAddress,
          name: tranche.name,
          id: tranche.id,
        }
      })

      return tranchesAdapter.upsertMany(state, tranchesToUpsert)
    }),
})

export const tranchesEntityAdapterSelectors = tranchesAdapter.getSelectors()
export const tranchesActions = tranchesSlice.actions
export const tranchesReducer = tranchesSlice.reducer
export type TranchesActions = ActionsType<typeof tranchesActions>
