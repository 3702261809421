import React from 'react'

import type { IconProps } from '../../SVG/Icon.types'

import { useHTMLElementEvents } from './useHTMLElementEvents.hook'
import { useSelectedStateIconColor } from './useSelectedStateColor.hook'
import type { ButtonBaseProps } from './ButtonBase.types'
import { ButtonBaseStyled } from './ButtonBase.styles'

export const ButtonBase: React.FC<React.PropsWithChildren<ButtonBaseProps>> = ({
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  children,
  endIcon,
  startIcon,
  ...props
}) => {
  const { disabled, color, variant } = props

  const { pressed, focusVisible, hovered, handleMouseDown, handleMouseEnter, handleMouseLeave, handleMouseUp, handleFocus, handleBlur } =
    useHTMLElementEvents({
      onMouseUp,
      onMouseLeave,
      onMouseEnter,
      onMouseDown,
      onFocus,
      onBlur,
      disabled,
    })

  const { iconColor } = useSelectedStateIconColor({ variant, pressed, hovered, focusVisible, disabled, color })

  return (
    <ButtonBaseStyled
      {...props}
      endIcon={
        endIcon && React.cloneElement<IconProps>(endIcon, { size: endIcon?.props.size ?? 24, color: endIcon?.props.color ?? iconColor })
      }
      startIcon={
        startIcon &&
        React.cloneElement<IconProps>(startIcon, { size: startIcon?.props.size ?? 24, color: startIcon?.props.color ?? iconColor })
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onFocusVisible={handleFocus}
    >
      {children}
    </ButtonBaseStyled>
  )
}
