import type { CustomPalette } from '.'

export const utilityPalette: Pick<CustomPalette, '_white' | '_transparent' | '_tint' | '_shade' | '_black'> = {
  _white: '#FFFFFF',
  _transparent: '#FFFFFF00',
  _tint: {
    600: '#FFFFFF66',
    500: '#FFFFFF4D',
    400: '#FFFFFF33',
    300: '#FFFFFF26',
    200: '#FFFFFF1A',
    100: '#FFFFFF0D',
  },
  _shade: {
    600: '#05202E52',
    500: '#05202E3D',
    400: '#05202E29',
    300: '#05202E1F',
    200: '#05202E14',
    100: '#05202E0A',
  },
  _black: '#000000',
}
