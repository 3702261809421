import type { Breakpoints, Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'
import type { CustomTypographyVariants } from '../typography'

export const getToggleButtonGroup = (palette: CustomPalette, _: CustomTypographyVariants, breakpoints: Breakpoints): Components<Theme> => ({
  MuiToggleButtonGroup: {
    variants: [
      {
        style: {
          color: palette._text[500],
          background: palette._white,
        },
        props: { color: 'standard' },
      },
    ],
    styleOverrides: {
      grouped: {
        textTransform: 'none',
        padding: '0.75rem 1.25rem',
        fontSize: '12px',
        boxShadow: `0px 1px 2px rgba(5, 32, 46, 0.04)`,
        borderRadius: '32px',

        [breakpoints.down('md')]: {
          borderRadius: '0',
        },

        border: `1px solid ${palette._shade[400]}!important`,
      },
    },
  },
  MuiToggleButton: {
    variants: [
      {
        style: {
          fontWeight: 600,
          color: `${palette._text[600]}!important`,
          backgroundColor: `${palette._fill[300]}!important`,
        },
        props: { selected: true, color: 'standard' },
      },
      {
        style: {
          fontWeight: 400,
          boxSizing: 'border-box',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: `${palette._fill[300]}!important`,
          },
          '&:focus-visible': {
            backgroundColor: `${palette._fill[300]}!important`,
          },
          '&:active': {
            backgroundColor: `${palette._fill[300]}!important`,
          },
        },
        props: { selected: false, color: 'standard' },
      },
    ],
  },
})
