// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgRocket = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16 13v4.382a1 1 0 0 1-.553.894l-2.416 1.208a1 1 0 0 1-1.396-.578L11 17m-4-4-1.906-.635a1 1 0 0 1-.578-1.396l1.208-2.416A1 1 0 0 1 6.618 8H11m6.096 4.123-5.397 4.318a1 1 0 0 1-1.332-.074l-2.734-2.734a1 1 0 0 1-.074-1.332l4.317-5.397A10.404 10.404 0 0 1 20 3a1 1 0 0 1 1 1 10.402 10.402 0 0 1-3.904 8.123ZM5.75 20.587 3 21l.413-2.75a2.054 2.054 0 1 1 2.337 2.337Z"
    />
  </svg>
)
export default SvgRocket
