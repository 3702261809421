import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import type { FetchProjectPageDataResult } from '../../projectContent.types'
import { projectContentActions } from '../../projectContent.slice'
import { projectBaseSelectors } from '../../../projectBase/projectBase.selectors'
import type { Language } from '../../../../types/common'
import { GetProjectPageData } from '../../../../../staticContent'
import { navigationSelectors } from '../../../navigation/navigation.selectors'

export function fetchProjectPageData(id: number, locale: Language): FetchProjectPageDataResult {
  return GetProjectPageData(locale, id) as FetchProjectPageDataResult
}

export function* fetchProjectPageDataSaga(): SagaGenerator<void> {
  try {
    const currentLocale = yield* select(navigationSelectors.selectCurrentLocalisation)

    const baseProject = yield* select(projectBaseSelectors.selectCurrent)
    if (!baseProject) throw new Error('Base Project not found')

    const { projectPage }: FetchProjectPageDataResult = yield* call(fetchProjectPageData, baseProject.id, currentLocale)

    yield* put(projectContentActions.fetchProjectPageDataSuccess({ projectPage, projectId: baseProject.id }))
  } catch (error) {
    if (error instanceof Error) yield* put(projectContentActions.fetchProjectPageDataError(error.message))
  }
}
