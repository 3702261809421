import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type { RewardTokenERC20Statuses } from './rewardTokenERC20.types'

export class RewardTokenERC20State implements RewardTokenERC20Statuses {
  fetchSubgraphAllStatus = RequestStatus.Idle
  fetchFromBackendStatus: RequestStatus = RequestStatus.Idle
  consumeStatus = RequestStatus.Idle
  error: RequestError = null
}
