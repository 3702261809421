import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import type { RequestError } from '../types'
import { RequestStatus } from '../types'

import type {
  Rewards,
  RewardBalance,
  CalculateRewardsPayload,
  CalculateRewardsSuccessPayload,
  ClaimRewardsPayload,
  RewardParams,
} from './rewards.types'
import { RewardsState } from './rewards.state'

export const rewardsAdapter = createEntityAdapter<RewardBalance>({
  sortComparer: (a, b) => `${a.tokenAddress}|${a.account}`.localeCompare(`${b.tokenAddress}|${b.account}`),
  selectId: (data) => `${data.tokenAddress}|${data.account}`,
})

export const initialRewardsSliceState: Rewards = rewardsAdapter.getInitialState(new RewardsState())

export const rewardsSlice = createSlice({
  reducers: {
    clearRewards(state) {
      rewardsAdapter.removeAll(state)
      state.calculateRewardsStatus = RequestStatus.Idle
    },
    claimTimeStakeRewardsSuccess(state, action: PayloadAction<RewardParams>) {
      state.claimTimeStakeRewardsStatus = RequestStatus.Succeeded
    },
    claimTimeStakeRewardsError(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.claimTimeStakeRewardsStatus = RequestStatus.Failed
    },
    claimRewards(state, action: PayloadAction<ClaimRewardsPayload>) {
      state.claimLiquidStakeRewardsStatus = RequestStatus.Loading
      state.claimTimeStakeRewardsStatus = RequestStatus.Loading
    },
    claimLiquidStakeRewardsSuccess(state, action: PayloadAction<RewardParams>) {
      state.claimLiquidStakeRewardsStatus = RequestStatus.Succeeded
    },
    claimLiquidStakeRewardsError(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.claimLiquidStakeRewardsStatus = RequestStatus.Failed
    },
    calculateRewardsSuccess(state, action: PayloadAction<CalculateRewardsSuccessPayload>) {
      rewardsAdapter.upsertMany(state, action.payload)
      state.calculateRewardsStatus = RequestStatus.Succeeded
    },
    calculateRewardsError(state, action: PayloadAction<RequestError>) {
      state.error = action.payload
      state.calculateRewardsStatus = RequestStatus.Failed
    },
    calculateRewards(state, action: PayloadAction<CalculateRewardsPayload>) {
      state.calculateRewardsStatus = RequestStatus.Loading
    },
    calculateAllRewards(state) {
      state.calculateRewardsStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.Rewards,
  initialState: initialRewardsSliceState,
})

export const rewardsAdapterSelectors = rewardsAdapter.getSelectors()
export const rewardsActions = rewardsSlice.actions
export const rewardsReducer = rewardsSlice.reducer
export type RewardsActions = ActionsType<typeof rewardsActions>
