import { SumsubSteps } from './sumsub.constants'

export class SumsubStore {
  public isSumsubReady: boolean
  public sumsubStep: SumsubSteps

  constructor() {
    this.isSumsubReady = false
    this.sumsubStep = SumsubSteps.INIT
  }
}
