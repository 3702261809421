import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from '../../core-components/buttons/Button'
import { sessionSelectors } from '../../store/session/session.selectors'
import { sessionActions } from '../../store/session/session.slice'

export const LinkingAccountsDialog = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const isLogoutNeeded = useSelector(sessionSelectors.selectIsLogoutNeeded)

  const handleSignOut = useCallback(() => {
    dispatch(sessionActions.logoutStart())
  }, [dispatch])

  return (
    <Dialog open={isLogoutNeeded}>
      <DialogTitle sx={{ p: 6, m: 0 }}>{t('linking-accounts')}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="bodySm">{t('noticed-accounts-duplicate')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" fullWidth onClick={handleSignOut}>
          {t('relog')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
