import type { ContractTransaction } from 'ethers'
import { utils } from 'ethers'
import { call, select } from 'typed-redux-saga'
import type { StrictEffect } from '@redux-saga/types'

import { selectSigner, selectWeb3Account } from '../../web3/web3.selectors'
import { getERC20 } from '../../web3/web3.utils'

export function* approveContractTransactionSaga(
  tokenInAddress: string,
  spenderAddress: string,
  tokenInAmount: string
): Generator<StrictEffect, ContractTransaction> {
  const account = yield* select(selectWeb3Account)
  if (!account) throw new Error('Web3 Account not found')

  const signer = yield* select(selectSigner)
  const erc20 = yield* call(getERC20, tokenInAddress, signer)

  const tokenInDecimals = yield* call(erc20.decimals)
  const tokenInAmountBN = utils.parseUnits(tokenInAmount, tokenInDecimals)

  const transaction = yield* call(erc20.approve, spenderAddress, tokenInAmountBN)
  yield* call(transaction.wait)

  return transaction
}
