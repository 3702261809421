import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select } from 'typed-redux-saga'

import { projectContentActions } from '../../projectContent.slice'
import type { Language } from '../../../../types/common'
import { GetProjectRewardsDescriptionsData } from '../../../../../staticContent'
import { navigationSelectors } from '../../../navigation/navigation.selectors'
import type { RewardDescriptionData } from '../../projectContent.types'

export function fetchProjectRewardsDescriptionsData(locale: Language): RewardDescriptionData[] {
  return GetProjectRewardsDescriptionsData(locale) as RewardDescriptionData[]
}

export function* fetchProjectRewardsDescriptionsSaga(): SagaGenerator<void> {
  try {
    const currentLocale = yield* select(navigationSelectors.selectCurrentLocalisation)

    const rewardsDescriptions: RewardDescriptionData[] = yield* call(fetchProjectRewardsDescriptionsData, currentLocale)

    yield* put(projectContentActions.fetchProjectRewardsDescriptionsSuccess(rewardsDescriptions))
  } catch (error) {
    if (error instanceof Error) yield* put(projectContentActions.fetchProjectRewardsDescriptionsError(error.message))
  }
}
