export enum InvestmentStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
}

export enum InvestmentType {
  RAMP = 'RAMP',
  WEB3 = 'WEB3',
}

export enum TransactionStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  REJECTED = 'REJECTED',
}

export type UserInvestment = {
  id: number
  fundraiseAddress: string
  type: InvestmentType
  status: InvestmentStatus
  transactionStatus: TransactionStatus
  transactionHash: string | null
  amountIn: string
  amountOut: string
  walletAddress: string
  createdAt: string
  updatedAt: string
}

export type CreateUserInvestmentDto = {
  type: InvestmentType
  amountIn: string
  walletAddress: string
  fundraiseAddress: string
  amountOut: string
}

export type UpdateUserInvestmentDto = {
  id: number
  transactionStatus: TransactionStatus
  transactionHash?: string
}
