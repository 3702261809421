// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgLogout = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 12h12.5m0 0-3-3m3 3-3 3M8 8V6.334A2 2 0 0 1 9.671 4.36l8-1.333A2 2 0 0 1 20 5v13.998a2 2 0 0 1-2.329 1.973l-8-1.333A2 2 0 0 1 8 17.666V16"
    />
  </svg>
)
export default SvgLogout
