import { gql } from 'graphql-request'

export const PLATFORM_SUMMARY_QUERY = gql`
  query {
    platformSummary(id: "mosaicoV3") {
      id
      investors
      rewards
      gatheredCapital
    }
  }
`
