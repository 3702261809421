import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select, spawn } from 'typed-redux-saga'
import type { Web3ReactContextInterface } from '@web3-react/core/dist/types'

import { WalletTypeEnum } from '../../../graphql/generated/graphql'
import type { Web3Actions } from '../web3.slice'
import { web3Actions } from '../web3.slice'
import { web3Selectors } from '../web3.selectors'
import { Web3NotDefined } from '../web3.constants'
import { executeOnSessionInitializationSuccess } from '../../session/sagas/executeOnSessionInitializationSuccess/executeOnSessionInitializationSuccess.saga'

import { activateWeb3Saga as activateParticleWeb3Saga } from './connectParticleNetwork/connectParticleNetwork.saga'
import { activateWebSaga as activateMetamaskWeb3Saga } from './metamask/activateWeb3/activateWeb3.saga'

export function* activateWeb3Saga(
  web3: Web3ReactContextInterface,
  walletType?: WalletTypeEnum | null,
  walletAddress?: string
): SagaGenerator<void> {
  if (walletType === WalletTypeEnum.Metamask) yield* call(activateMetamaskWeb3Saga, web3, walletAddress)
  else yield* spawn(executeOnSessionInitializationSuccess, call(activateParticleWeb3Saga, web3))
}

export function* activateWeb3RequestSaga({ payload }: Web3Actions['connectWalletRequest']): SagaGenerator<void> {
  const { walletType, walletAddress } = payload
  const web3 = yield* select(web3Selectors.selectWeb3)

  if (!web3) throw Web3NotDefined

  try {
    yield* call(activateWeb3Saga, web3, walletType, walletAddress)
  } catch (error) {
    if (error instanceof Error) yield* put(web3Actions.connectWalletFailure({ walletType, error: error.message }))
  }
}
