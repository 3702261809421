import { call } from 'typed-redux-saga'

import type { Web3Actions } from '../../../web3/web3.slice'
import { saveLatestWalletAddress, saveLatestWalletType } from '../../session.utils'

export function* saveLatestWalletTypeSaga({ payload }: Web3Actions['connectWalletSuccess']) {
  const { walletType } = payload
  yield* call(saveLatestWalletType, walletType)
}

export function* saveLatestWalletAddressSaga({ payload }: Web3Actions['connectWalletSuccess']) {
  const { walletAddress } = payload
  if (walletAddress) yield* call(saveLatestWalletAddress, walletAddress)
}
