import type { SagaGenerator } from 'typed-redux-saga'
import { select, put, call } from 'typed-redux-saga'

import { pushNotification } from '../../../../utils/pushNotification'
import { selectSigner, selectWeb3Account } from '../../../web3/web3.selectors'
import { getStakeToken, getProjectToken } from '../../../web3/web3.utils'
import { rewardsActions, type RewardsActions } from '../../rewards.slice'
import { RewardSourceType, RewardType } from '../../rewards.const'

export function* claimRewardsSaga({ payload }: RewardsActions['claimRewards']): SagaGenerator<void> {
  const { rewardSource, sourceTokenAddress, rewardTokenAddress, rewardType } = payload

  const account = yield* select(selectWeb3Account)
  if (!account) throw new Error('No account found')

  const signer = yield* select(selectSigner)
  if (!signer) throw new Error('No signer found')

  const contract =
    rewardSource === RewardSourceType.ProjectToken ? getProjectToken(sourceTokenAddress, signer) : getStakeToken(sourceTokenAddress, signer)

  if (rewardType === RewardType.LiquidStake)
    try {
      // claim Liquid Stake Reward
      const txLiquidStake = yield* call(contract.claimLiquidStakeReward, rewardTokenAddress)
      yield* call(txLiquidStake.wait)
      yield* put(rewardsActions.claimLiquidStakeRewardsSuccess(payload))

      pushNotification(`You've successfully claimed your Liquid Stake Reward`)
    } catch (error) {
      if (error instanceof Error) {
        yield* put(rewardsActions.claimLiquidStakeRewardsError(error.message))
        pushNotification(`Claim Liquid Stake Reward Error: ${error.message}`, 'error')
      }
    }
  else
    try {
      // claim Time Stake Reward
      const txTimeStake = yield* call(contract.claimTimeStakeReward, rewardTokenAddress)
      yield* call(txTimeStake.wait)

      yield* put(rewardsActions.claimTimeStakeRewardsSuccess(payload))

      pushNotification(`You've successfully claimed your Time Stake Reward`)
    } catch (error) {
      if (error instanceof Error) {
        yield* put(rewardsActions.claimTimeStakeRewardsError(error.message))
        pushNotification(`Claim Time Stake Reward Error: ${error.message}`, 'error')
      }
    }
}
