// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgSuccess = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#success_svg__a)">
      <path
        fill={props.color ?? '#262B30'}
        d="M8 0a8 8 0 1 0 8 8 8.024 8.024 0 0 0-8-8ZM6.667 11.61 3.057 8 4 7.057l2.667 2.667L12 4.391l.943.942-6.276 6.276Z"
      />
    </g>
    <defs>
      <clipPath id="success_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgSuccess
