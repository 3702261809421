import type { SagaGenerator } from 'typed-redux-saga'
import { select, put, call } from 'typed-redux-saga'
import type { GraphQLClient } from 'graphql-request'

import { subgraphCall } from '../../../../../utils/subgraphCall'
import { balancesActions, type BalancesActions } from '../../../balances.slice'
import { InternalBalanceType, FetchBalanceQuery, FetchBalanceQueryNames } from '../../../balances.const'
import type { FetchAllResultType, TokenBalanceData } from '../../../balances.types'
import { mapSubgraphDefaultBalanceQueryResultToTokenBalanceData } from '../../../balances.mappers'
import { walletSelectors } from '../../../../wallet/wallet.selectors'
import type { WalletType } from '../../../../../graphql/generated/graphql'

export async function fetchBalances(
  client: GraphQLClient,
  type: InternalBalanceType,
  addresses: WalletType['ethereumAddress'][]
): Promise<TokenBalanceData[]> {
  const result = await client.rawRequest<FetchAllResultType>(FetchBalanceQuery[type], { addresses })
  const resultKey = FetchBalanceQueryNames[type]
  return result.data[resultKey].map((data) => mapSubgraphDefaultBalanceQueryResultToTokenBalanceData(data, type))
}

export function* fetchAllInternalBalancesSaga(): SagaGenerator<void> {
  try {
    const userWallets = yield* select(walletSelectors.selectAll)

    const walletAddresses = userWallets.map((wallet) => wallet.ethereumAddress.toLowerCase())

    const fundraiseTokensBalacnes = yield* subgraphCall(fetchBalances, InternalBalanceType.FundraiseToken, walletAddresses)
    const stakeTokensBalances = yield* subgraphCall(fetchBalances, InternalBalanceType.StakeToken, walletAddresses)
    const rewardERC20TokensBalances = yield* subgraphCall(fetchBalances, InternalBalanceType.RewardTokenERC20, walletAddresses)
    const projectTokensBalances = yield* subgraphCall(fetchBalances, InternalBalanceType.ProjectToken, walletAddresses)

    const balances = [...fundraiseTokensBalacnes, ...stakeTokensBalances, ...rewardERC20TokensBalances, ...projectTokensBalances]

    yield* put(balancesActions.fetchInternalBalancesSuccess(balances))
  } catch (error) {
    if (error instanceof Error) yield* put(balancesActions.fetchInternalBalancesError(error.message))
  }
}

export function* fetchBalancesSaga(payload: BalancesActions['fetchInternalBalances']['payload']): SagaGenerator<void> {
  const { balanceType } = payload

  try {
    const userWallets = yield* select(walletSelectors.selectAll)

    const walletAddresses = userWallets.map((wallet) => wallet.ethereumAddress.toLowerCase())

    const balancesData = yield* subgraphCall(fetchBalances, balanceType, walletAddresses)

    yield* put(balancesActions.fetchInternalBalancesSuccess(balancesData))
  } catch (error) {
    if (error instanceof Error) yield* put(balancesActions.fetchInternalBalancesError(error.message))
  }
}

export function* fetchBalancesActionHandler({ payload }: BalancesActions['fetchInternalBalances']): SagaGenerator<void> {
  yield* call(fetchBalancesSaga, payload)
}

export function* fetchFundraiseTokensBalancesSaga({ payload }: BalancesActions['fetchInternalBalances']): SagaGenerator<void> {
  yield* fetchBalancesSaga({ ...payload, balanceType: InternalBalanceType.FundraiseToken })
}

export function* fetchStakeTokensBalancesSaga({ payload }: BalancesActions['fetchInternalBalances']): SagaGenerator<void> {
  yield* fetchBalancesSaga({ ...payload, balanceType: InternalBalanceType.StakeToken })
}

export function* fetchRewardTokensERC20BalancesSaga({ payload }: BalancesActions['fetchInternalBalances']): SagaGenerator<void> {
  yield* fetchBalancesSaga({ ...payload, balanceType: InternalBalanceType.RewardTokenERC20 })
}

export function* fetchProjectTokensBalancesSaga({ payload }: BalancesActions['fetchInternalBalances']): SagaGenerator<void> {
  yield* fetchBalancesSaga({ ...payload, balanceType: InternalBalanceType.ProjectToken })
}
