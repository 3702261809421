// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgPrinter = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M24 8V4a1.333 1.333 0 0 0-1.333-1.334H9.333A1.334 1.334 0 0 0 8 3.999v4m14.667 4H24M8 22.666H6.667A2.667 2.667 0 0 1 4 19.999v-9.333a2.667 2.667 0 0 1 2.667-2.667h18.666A2.667 2.667 0 0 1 28 10.666v9.333a2.667 2.667 0 0 1-2.667 2.667H24m-4-2.667h-8m8 4h-8m-2.667-8h13.334c.736 0 1.333.597 1.333 1.334v9.333c0 .736-.597 1.333-1.333 1.333H9.333A1.333 1.333 0 0 1 8 26.666v-9.333c0-.737.597-1.334 1.333-1.334Z"
    />
  </svg>
)
export default SvgPrinter
