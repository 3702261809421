import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { StoreKeys } from '../store.keys'
import type { ActionsType } from '../store.types'
import { RequestStatus } from '../types'

import type {
  ProjectBase,
  ProjectBaseData,
  ErrorPayload,
  FetchAllProjectsPayloadSuccess,
  FetchSingleProjectPayload,
  FetchSingleProjectPayloadSuccess,
} from './projectBase.types'
import { ProjectBaseState } from './projectBase.state'
import { isProjectBase } from './projectBase.const'

export const projectBaseAdapter = createEntityAdapter<ProjectBaseData>({
  selectId: (projectBase) => projectBase.id,
})

export const initialProjectBaseSliceState: ProjectBase = projectBaseAdapter.getInitialState({
  ...new ProjectBaseState(),
})

const hydrate = createAction<{ [StoreKeys.ProjectBase]: ProjectBase }>(HYDRATE)

export const projectBaseSlice = createSlice({
  reducers: {
    fetchSingleProjectSuccess: (state, action: PayloadAction<FetchSingleProjectPayloadSuccess>) => {
      state.fetchSingleProjectStatus = RequestStatus.Succeeded
      projectBaseAdapter.upsertOne(state, action.payload)
    },
    fetchSingleProjectError: (state, action: PayloadAction<ErrorPayload>) => {
      state.fetchSingleProjectStatus = RequestStatus.Failed
      state.error = action.payload
    },
    fetchSingleProject: (state, action: PayloadAction<FetchSingleProjectPayload>) => {
      state.fetchSingleProjectStatus = RequestStatus.Loading
    },
    fetchAllProjectsSuccess: (state, action: PayloadAction<FetchAllProjectsPayloadSuccess>) => {
      state.fetchAllProjectsStatus = RequestStatus.Succeeded
      projectBaseAdapter.upsertMany(state, action.payload)
    },
    fetchAllProjectsError: (state, action: PayloadAction<ErrorPayload>) => {
      state.fetchAllProjectsStatus = RequestStatus.Failed
      state.error = action.payload
    },
    fetchAllProjects: (state) => {
      state.fetchAllProjectsStatus = RequestStatus.Loading
    },
  },
  name: StoreKeys.ProjectBase,
  initialState: initialProjectBaseSliceState,
  extraReducers: (builder) =>
    builder.addCase(hydrate, (state, { payload }) => {
      const { entities, ids, ...statuses } = payload[StoreKeys.ProjectBase]
      const projects = Object.values(entities)
      const projectsToUpsert = projects.filter(isProjectBase)

      return projectBaseAdapter.upsertMany(state, projectsToUpsert)
    }),
})

export const projectBaseActions = projectBaseSlice.actions
export const projectBaseReducer = projectBaseSlice.reducer

export type ProjectBaseActions = ActionsType<typeof projectBaseActions>
