// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgCamera = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 12a1.5 1.5 0 0 1 1.5-1.5m2.586-5.914 1.121 1.121a1 1 0 0 0 .707.293H19a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2.586a1 1 0 0 0 .707-.293l1.121-1.121A2 2 0 0 1 10.828 4h2.344a2 2 0 0 1 1.414.586ZM16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm2.05-3a.05.05 0 1 1-.1 0 .05.05 0 0 1 .1 0Z"
    />
  </svg>
)
export default SvgCamera
