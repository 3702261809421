import type { SagaGenerator } from 'typed-redux-saga'
import { call, put, select, take } from 'typed-redux-saga'
import type { RampInstantSDK } from '@ramp-network/ramp-instant-sdk'
import { RampInstantEventTypes } from '@ramp-network/ramp-instant-sdk'
import type { IPurchaseCreatedEvent, TAllEvents } from '@ramp-network/ramp-instant-sdk/dist/types/types'
import type { EventChannel } from 'redux-saga'
import { END, eventChannel } from 'redux-saga'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'

import { investmentSelectors } from '../investment.selectors'
import type { InvestmentActions } from '../investment.slice'
import { investmentActions } from '../investment.slice'
import envConfig from '../../../../config/env'
import { createRampInstance } from '../investment.utils'
import { pushNotification } from '../../../utils/pushNotification'
import { cryptocurrenciesSelectors } from '../../cryptocurrencies/cryptocurrencies.selectors'

export function createRampPurchaseEventChannel(ramp: RampInstantSDK): EventChannel<IPurchaseCreatedEvent> {
  return eventChannel((emitter) => {
    const purchaseCreatedEventHandler = (event: TAllEvents) => {
      emitter(event as IPurchaseCreatedEvent)
    }
    const widgetCloseEventHandler = (_: TAllEvents) => {
      emitter(END)
    }

    ramp
      .on(RampInstantEventTypes.PURCHASE_CREATED, purchaseCreatedEventHandler)
      .on(RampInstantEventTypes.WIDGET_CLOSE, widgetCloseEventHandler)

    return () => {
      ramp
        .unsubscribe(RampInstantEventTypes.PURCHASE_CREATED, purchaseCreatedEventHandler)
        .unsubscribe(RampInstantEventTypes.WIDGET_CLOSE, widgetCloseEventHandler)
    }
  })
}

export function* rampInvestmentSaga(_: InvestmentActions['rampInvestmentRequest']): SagaGenerator<void> {
  try {
    const rampInvestmentInput = yield* select(investmentSelectors.selectRampInvestmentInput)
    const USDC = yield* select(cryptocurrenciesSelectors.selectUSDC)

    if (rampInvestmentInput && USDC) {
      let purchaseAsset = 'MATIC_USDC'
      let investmentInAmount = BigNumber.from(10).pow(USDC.decimals).mul(BigNumber.from(rampInvestmentInput.investmentInAmount))
      const investmentOutAmount = parseUnits(rampInvestmentInput.investmentOutAmount, 18).toString()

      if (envConfig.network === 'mumbai') {
        purchaseAsset = 'MATIC_TEST'
        const mumbaiPurchaseAssetDecimals = 18
        investmentInAmount = BigNumber.from(10).pow(mumbaiPurchaseAssetDecimals).mul(BigNumber.from(rampInvestmentInput.investmentInAmount))
      }

      const rampInstance = yield* call(
        createRampInstance,
        rampInvestmentInput.userId,
        rampInvestmentInput.userEmail,
        purchaseAsset,
        rampInvestmentInput.fundraiseAddress,
        investmentInAmount.toString(),
        investmentOutAmount.toString(),
        rampInvestmentInput.walletAddress
      )

      const readyRampInstance = yield* call([rampInstance, rampInstance.show])

      const rampPurchaseEventChannel = yield* call(createRampPurchaseEventChannel, readyRampInstance)

      try {
        while (true) {
          const {
            payload: { purchase },
          } = yield* take(rampPurchaseEventChannel)
          yield* put(investmentActions.rampInvestment({ rampPurchaseId: purchase.id }))
        }
      } finally {
        yield* put(investmentActions.rampInvestmentSuccess())
      }
    }
  } catch (error) {
    yield* put(investmentActions.rampInvestmentFailure())
    if (error instanceof Error) pushNotification(`Ramp Purchase Failure: ${error.message}`, 'error')
  }
}
