import type { SagaGenerator } from 'typed-redux-saga'
import { put, select, call } from 'typed-redux-saga'

import { web3Selectors } from '../../../../web3/web3.selectors'
import { walletSelectors } from '../../../../wallet/wallet.selectors'
import { LegacyBalanceType } from '../../../balances.const'
import { legacyTokensSelectors } from '../../../../legacyTokens/legacyTokens.selectors'
import { balancesActions } from '../../../balances.slice'
import { BigDecimal } from '../../../../../utils/bigDecimal'

import { fetchLegacyBalancesForWallets } from './fetchLegacyBalances.utils'

export function* fetchLegacyProjectTokensBalancesSaga(): SagaGenerator<void> {
  try {
    const legacyProjectTokens = yield* select(legacyTokensSelectors.selectProjectTokens)
    const readOnlyProvider = yield* select(web3Selectors.selectReadOnlyProvider)
    const userWallets = yield* select(walletSelectors.selectAll)

    const projectTokensBalances = yield* call(
      fetchLegacyBalancesForWallets,
      legacyProjectTokens.map((token) => token.address),
      userWallets.map((wallet) => wallet.ethereumAddress),
      readOnlyProvider,
      LegacyBalanceType.ProjectToken
    )

    const filteredProjectTokensBalances = projectTokensBalances.filter((balance) =>
      BigDecimal.fromString(balance.amount).gt(BigDecimal.from(0))
    )

    if (filteredProjectTokensBalances?.length) yield* put(balancesActions.fetchLegacyBalancesSuccess(filteredProjectTokensBalances))
  } catch (error) {
    if (error instanceof Error) yield* put(balancesActions.fetchLegacyBalancesError(error.message))
  }
}
