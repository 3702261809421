import type { SagaGenerator } from 'typed-redux-saga'
import { put, select } from 'typed-redux-saga'

import { apiCall } from '../../../../utils/apiCall'
import { VerificationStatus } from '../../../sumsub/sumsub.constants'
import { userSelectors } from '../../user.selectors'
import { userActions } from '../../user.slice'
import { fetchApplicantData } from '../loadUserData/loadUserData.saga'

export function* fetchApplicantDataSaga(): SagaGenerator<void> {
  const basicKycStatus = yield* select(userSelectors.selectBasicKycStatus)

  if (basicKycStatus !== VerificationStatus.UNVERIFIED)
    try {
      const sumsubApplicantData = yield* apiCall(fetchApplicantData)

      yield* put(userActions.fetchApplicantDataSuccess(sumsubApplicantData))
    } catch (error) {
      console.error(error)
      yield* put(userActions.fetchApplicantDataFailure())
    }
}
