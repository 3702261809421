// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgText = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13 11.5h4m-2 0v4m-8-7h6m-3 0v7M8 3h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8a5 5 0 0 1 5-5Z"
    />
  </svg>
)
export default SvgText
