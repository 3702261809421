// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgDownload = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.741 14.815 16 19.557m0 0-4.742-4.742M16 19.557V5.334m10.666 16.592a4.741 4.741 0 0 1-4.741 4.741h-11.85a4.741 4.741 0 0 1-4.742-4.741"
    />
  </svg>
)
export default SvgDownload
