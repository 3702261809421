import type { Components, Theme } from '@mui/material'

import type { CustomPalette } from '../palette'
import type { CustomTypographyVariants } from '../typography'

declare module '@mui/material/LinearProgress/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    white: true
  }
}

export const getProgressBar = (palette: CustomPalette, typography: CustomTypographyVariants): Components<Theme> => ({
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
      },
      determinate: {
        backgroundColor: palette._fill[400],
      },
      dashed: {
        backgroundImage: 'none',
        backgroundColor: palette._shade[600],
        animation: 'none',
      },
      bar2Buffer: { backgroundColor: palette._success[600] },
      bar1Determinate: {
        backgroundColor: palette._success[300],
      },
      bar1Buffer: { backgroundColor: palette._success[300] },
      bar: {
        borderRadius: '16px',
      },
    },
    defaultProps: {},
  },
})
