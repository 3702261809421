// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgCoins = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 13V9h8m0 0v4m0-4h1V5H7v4m-1 8v4h9m0-8H5v4h6m6.828-2.828a4 4 0 1 1-5.656 5.656 4 4 0 0 1 5.656-5.656Z"
    />
  </svg>
)
export default SvgCoins
