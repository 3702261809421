// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgPenEdit = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m13 6 5 5m-9 4 3-3m-3.477 8H4v-4.523c0-.464.184-.909.512-1.236L15.24 3.513a1.747 1.747 0 0 1 2.473 0l2.774 2.774a1.747 1.747 0 0 1 0 2.473L9.759 19.488A1.75 1.75 0 0 1 8.523 20Z"
    />
  </svg>
)
export default SvgPenEdit
