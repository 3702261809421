import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { rewardStablecoinActions } from './rewardStablecoin.slice'
import { fetchAllRewardStablecoinsSaga } from './saga/fetchAll/fetchAll.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([takeLatest(rewardStablecoinActions.fetchAll, fetchAllRewardStablecoinsSaga)])
}

export function* rewardStablecoinSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
