import { createSelector } from '@reduxjs/toolkit'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'

import type { Transaction } from './transaction.types'
import { transactionsAdapter } from './transaction.slice'

const selectTransactions = createSelector([selectReducer(StoreKeys.Transactions)], (state) =>
  transactionsAdapter.getSelectors().selectAll(state)
)
const selectTransactionById = createSelector(
  [selectTransactions, (_, transactionId: Transaction['id']) => transactionId],
  (transactions, transactionId) => transactions.find(({ id }) => id === transactionId)
)
const selectTransactionsBySource = createSelector(
  [selectTransactions, (_, transactionSource: Transaction['source']) => transactionSource],
  (transactions, transactionSource) => transactions.filter(({ source }) => source === transactionSource)
)
export const transactionsSelectors = {
  selectTransactionsBySource,
  selectTransactions,
  selectTransactionById,
}
