// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import type { SVGProps } from 'react'

const SvgTokenPrice = (
  props: SVGProps<SVGSVGElement> & {
    size?: string | number | undefined
  }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || '100%'}
    height={props.size || '100%'}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={props.color || '#25262D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 9v.808m0 0a1.839 1.839 0 0 0-2 1.616 1.435 1.435 0 0 0 1.154 1.407l1.692.338A1.435 1.435 0 0 1 14 14.576a1.839 1.839 0 0 1-2 1.616m0-6.384a1.95 1.95 0 0 1 1.95 1.263M12 17v-.808m0 0a1.95 1.95 0 0 1-1.95-1.263M21 17v-6.28a4 4 0 0 0-1.501-3.124l-4.375-3.5a5 5 0 0 0-6.248 0l-4.375 3.5A4 4 0 0 0 3 10.719V17a4 4 0 0 0 4 4h10a4 4 0 0 0 4-4Z"
    />
  </svg>
)
export default SvgTokenPrice
