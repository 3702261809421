import type { SagaGenerator } from 'typed-redux-saga'
import { all, call, takeLatest } from 'typed-redux-saga'

import { web3Actions } from '../web3/web3.slice'

import { sessionActions } from './session.slice'
import { loginHandler } from './sagas/loginSaga/login.saga'
import { logoutHandler } from './sagas/logoutSaga/logout.saga'
import { initializeSessionHandler } from './sagas/sessionInitSaga/sessionInit.saga'
import { redirectHandler } from './sagas/redirectSaga/redirect.saga'
import { saveLatestWalletAddressSaga, saveLatestWalletTypeSaga } from './sagas/saveLatestWalletType/saveLatestWalletType.saga'

function* actionHandlers(): SagaGenerator<void> {
  yield* all([
    takeLatest(sessionActions.redirectStart.type, redirectHandler),
    takeLatest(sessionActions.initializeSessionStart.type, initializeSessionHandler),
    takeLatest(sessionActions.loginStart.type, loginHandler),
    takeLatest(sessionActions.logoutStart.type, logoutHandler),
    takeLatest(web3Actions.connectWalletSuccess.type, saveLatestWalletTypeSaga),
    takeLatest(web3Actions.connectWalletSuccess.type, saveLatestWalletAddressSaga),
  ])
}

export function* sessionSaga(): SagaGenerator<void> {
  yield* all([call(actionHandlers)])
}
