import type { ExternalProvider, JsonRpcFetchFunc } from '@ethersproject/providers'
import { Web3Provider } from '@ethersproject/providers'

export const getWeb3Library = (provider: ExternalProvider | JsonRpcFetchFunc) => {
  if (!provider) return

  const library = new Web3Provider(provider)

  library.pollingInterval = 15_000
  return library
}
