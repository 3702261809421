import type { StackProps } from '@mui/material'

import type { WalletType, WalletTypeEnum } from '../../graphql/generated/graphql'
import type { TokenBalanceData } from '../../store/balances/balances.types'
import type { Chains } from '../../../config/chains'

import type { TransferFormFieldsKeys } from './TransferPopup.const'

export interface TransferFormFields {
  [TransferFormFieldsKeys.WalletAddress]: string
  [TransferFormFieldsKeys.WalletAddressInput]: string
  [TransferFormFieldsKeys.TokenAmount]: string
}

export type IncomingWalletType = WalletType & { balance: TokenBalanceData | undefined }

export enum TransferTokenType {
  ERC20 = 'ERC20',
  NATIVE = 'NATIVE',
}

export type TransferTokenData = {
  decimals: number
  name: string
  symbol: string
  address: string
  logo: string
  chain: Chains
  type: TransferTokenType
}

export type WalletData = {
  walletAddress: string
  walletBalance: string
  walletType: WalletTypeEnum
}

export interface TransferPopupContainerProps {
  tokenData: TransferTokenData
  onClose: () => void
}

export interface TransferPopupFormProps {
  senderWallet: WalletData
  wallets: WalletData[]
  tokenData: TransferTokenData
}

export interface TransferPopupComponentProps extends StackProps, TransferPopupFormProps {
  onClose: () => void
}
