import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk'

import envConfig from '../../../config/env'

export const createRampInstance = (
  userId: string,
  userEmail: string,
  purchaseAsset: string,
  fundraiseAddress: string,
  investmentInAmount: string,
  investmentOutAmount: string,
  walletAddress: string
) => {
  const ramp = new RampInstantSDK({
    webhookStatusUrl: `${envConfig.rampWebhookUrl}/${userId}?investorAddress=${walletAddress}&fundraiseAddress=${fundraiseAddress}&tokenOutAmount=${investmentOutAmount}`,
    userEmailAddress: userEmail,
    userAddress: envConfig.kmsAddress,
    url: envConfig.rampUrl,
    swapAsset: purchaseAsset,
    swapAmount: investmentInAmount,
    hostLogoUrl: 'https://mosaico.ai/assets/media/logos/mosaico_logo.png',
    hostAppName: envConfig.rampAppName,
    hostApiKey: envConfig.rampApiKey,
  })

  return ramp
}
