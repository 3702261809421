import type {
  CryptoInvestmentResultStep,
  CryptoInvestmentStep,
  InvestmentAmountStep,
  InvestmentPaymentMethodStep,
  InvestmentSetupStep,
  InvestmentState,
  RampInvestmentResultStep,
  RampInvestmentStep,
} from './investment.state'
import { InvestmentStep, PaymentMethod } from './investment.types'

export function canProceedToInvestmentSetupStep(state: InvestmentState): state is InvestmentSetupStep {
  return state.nextStep === InvestmentStep.Setup
}

export const isInvestmentSetupDataAvailable = (state: InvestmentState): state is InvestmentSetupStep => {
  return state.step >= InvestmentStep.Setup
}

export const isInvestmentAmountStep = (state: InvestmentState): state is InvestmentAmountStep => {
  return state.step === InvestmentStep.Amount
}

export const canProceedToInvestmentAmountStep = (state: InvestmentState): state is InvestmentAmountStep => {
  return state.nextStep === InvestmentStep.Amount
}

export const isInvestmentAmountDataAvailable = (state: InvestmentState): state is InvestmentAmountStep => {
  return state.step >= InvestmentStep.Setup
}

export const canProceedToStepInvestmentPaymentMethod = (state: InvestmentState): state is InvestmentPaymentMethodStep => {
  return state.nextStep === InvestmentStep.PaymentMethod
}

export const isInvestmentPaymentMethodDataAvailable = (state: InvestmentState): state is InvestmentPaymentMethodStep => {
  return state.step >= InvestmentStep.PaymentMethod
}

export const canProceedTorampInvestmentStep = (state: InvestmentState): state is RampInvestmentStep => {
  return (
    isInvestmentPaymentMethodDataAvailable(state) &&
    state.paymentMethod === PaymentMethod.Ramp &&
    state.nextStep === InvestmentStep.Transaction
  )
}

export const isrampInvestmentDataAvailable = (state: InvestmentState): state is RampInvestmentResultStep => {
  return (
    isInvestmentPaymentMethodDataAvailable(state) && state.paymentMethod === PaymentMethod.Ramp && state.step >= InvestmentStep.Transaction
  )
}

export const canProceedTorampInvestmentResultStep = (state: InvestmentState): state is RampInvestmentResultStep => {
  return isrampInvestmentDataAvailable(state)
}

export const isrampInvestmentResultDataAvailable = (state: InvestmentState): state is RampInvestmentResultStep => {
  return (
    isInvestmentPaymentMethodDataAvailable(state) &&
    state.paymentMethod === PaymentMethod.Ramp &&
    state.step >= InvestmentStep.TransactionResult
  )
}

export const canProceedToCryptoTransactionStep = (state: InvestmentState): state is CryptoInvestmentStep => {
  return (
    isInvestmentPaymentMethodDataAvailable(state) &&
    state.paymentMethod === PaymentMethod.CryptoWallet &&
    state.nextStep === InvestmentStep.Transaction
  )
}

export const isCryptoTransactionDataAvailable = (state: InvestmentState): state is CryptoInvestmentResultStep => {
  return (
    state.step >= InvestmentStep.Transaction &&
    isInvestmentPaymentMethodDataAvailable(state) &&
    state.paymentMethod === PaymentMethod.CryptoWallet
  )
}

export const canProceedToCryptoTransactionResultStep = (state: InvestmentState): state is CryptoInvestmentResultStep => {
  return isCryptoTransactionDataAvailable(state)
}

export const isCryptoTransactionResultDataAvailable = (state: InvestmentState): state is CryptoInvestmentResultStep => {
  return (
    state.step >= InvestmentStep.TransactionResult &&
    isInvestmentPaymentMethodDataAvailable(state) &&
    state.paymentMethod === PaymentMethod.CryptoWallet
  )
}
