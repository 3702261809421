import { call, put, select } from 'typed-redux-saga'

import { web3Actions } from '../web3.slice'
import { web3Selectors } from '../web3.selectors'
import { Web3NotDefined } from '../web3.constants'

export function* deactivateWeb3Saga() {
  const web3 = yield* select(web3Selectors.selectWeb3)

  if (!web3) throw Web3NotDefined

  try {
    yield* call(web3.deactivate)
    yield* put(web3Actions.web3DeactivationSuccess())
  } catch (error) {
    console.error(error)
  }
}
