import type { SagaGenerator } from 'typed-redux-saga'
import { call, put } from 'typed-redux-saga'

import { projectBaseActions } from '../../projectBase.slice'
import type { FetchAllProjectsResult } from '../../projectBase.types'
import { AllProjectBaseQueryResponse } from '../../../../../staticContent'

export function fetchAllProjectsBase(): FetchAllProjectsResult {
  return AllProjectBaseQueryResponse() as FetchAllProjectsResult
}
export function* fetchAllProjectsBaseSaga(): SagaGenerator<void> {
  try {
    const staticProjects = yield* call(fetchAllProjectsBase)

    yield* put(projectBaseActions.fetchAllProjectsSuccess(staticProjects))
  } catch (error) {
    if (error instanceof Error) yield* put(projectBaseActions.fetchAllProjectsError(error.message))
  }
}
