import { ethers } from 'ethers'

import { Chains } from '../../../config/chains'

import { CryptocurrencyTypeEnum, CryptocurrencySymbolEnum, type CryptocurrenciesData } from './cryptocurrencies.types'

// ============================ POLYGON ==========================================
export const POLYGON_TOKENS: Record<CryptocurrencySymbolEnum, CryptocurrenciesData> = {
  [CryptocurrencySymbolEnum.USDC]: {
    type: CryptocurrencyTypeEnum.STABLECOIN,
    symbol: CryptocurrencySymbolEnum.USDC,
    name: 'Polygon USDC',
    logo: '/svg/icons/custom/usdc.svg',
    decimals: 6,
    chain: Chains.POLYGON,
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  [CryptocurrencySymbolEnum.USDT]: {
    type: CryptocurrencyTypeEnum.STABLECOIN,
    symbol: CryptocurrencySymbolEnum.USDT,
    name: 'Polygon USDT',
    logo: '/svg/icons/custom/usdt.svg',
    decimals: 6,
    chain: Chains.POLYGON,
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
  [CryptocurrencySymbolEnum.MATIC]: {
    type: CryptocurrencyTypeEnum.NATIVE,
    symbol: CryptocurrencySymbolEnum.MATIC,
    name: 'Polygon Matic',
    logo: '/svg/icons/custom/matic.svg',
    decimals: 18,
    chain: Chains.POLYGON,
    address: ethers.constants.AddressZero,
  },
}
// ============================= MUMBAI =======================================
export const MUMBAI_TOKENS: Record<CryptocurrencySymbolEnum, CryptocurrenciesData> = {
  [CryptocurrencySymbolEnum.USDC]: {
    type: CryptocurrencyTypeEnum.STABLECOIN,
    symbol: CryptocurrencySymbolEnum.USDC,
    name: 'Mumbai USDC',
    logo: '/svg/icons/custom/usdc.svg',
    decimals: 6,
    chain: Chains.MUMBAI,
    address: '0x64480f579af4771754dA03824100fF1099893141',
  },
  [CryptocurrencySymbolEnum.MATIC]: {
    type: CryptocurrencyTypeEnum.NATIVE,
    symbol: CryptocurrencySymbolEnum.MATIC,
    name: 'Mumbai MATIC',
    logo: '/svg/icons/custom/matic.svg',
    decimals: 18,
    chain: Chains.MUMBAI,
    address: ethers.constants.AddressZero,
  },
  [CryptocurrencySymbolEnum.USDT]: {
    type: CryptocurrencyTypeEnum.STABLECOIN,
    symbol: CryptocurrencySymbolEnum.USDT,
    name: 'Polygon USDT',
    logo: '/svg/icons/custom/usdt.svg',
    decimals: 6,
    chain: Chains.MUMBAI,
    address: '0xA02f6adc7926efeBBd59Fd43A84f4E0c0c91e832',
  },
}
