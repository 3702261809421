import type { SubgraphProjectTokenQueryResult } from '../../types/subgraphQueryTypes'
import type { BackendProjectTokenQueryResult } from '../../types/backendQueryTypes'

import type { BackendProjectTokenData, SubgraphProjectTokenData } from './projectToken.types'

export const mapSubgraphProjectTokenQueryResultToSubgraphProjectTokenData = (
  subgraphData: SubgraphProjectTokenQueryResult
): SubgraphProjectTokenData => {
  const base = {
    tranchesCounter: Number(subgraphData.tranchesCounter),
    tranches: subgraphData.tranches.map(({ id }) => id),
    totalSupply: subgraphData.totalSupply,
    symbol: subgraphData.symbol,
    stakeTokenAddresses: subgraphData.stakeTokens ? subgraphData.stakeTokens.map(({ address }) => address) : [],
    rewardTokenERC20Addresses: subgraphData.rewardTokenERC20.map(({ address }) => address),
    name: subgraphData.name,
    id: subgraphData.id,
    decimals: Number(subgraphData.decimals),
    address: subgraphData.address,
  }

  if (subgraphData.rewardStablecoin) base.rewardTokenERC20Addresses.push(subgraphData.rewardStablecoin.address)

  return base
}

export const mapBackendProjectTokenQueryResultToBackendProjectTokenData = (
  backendData: BackendProjectTokenQueryResult
): BackendProjectTokenData => ({
  logo: backendData.logo,
  address: backendData.address,
})
