import type { SagaGenerator } from 'typed-redux-saga'
import { select, put, call } from 'typed-redux-saga'

import { getFundraise } from '../../../web3/web3.utils'
import type { FundraiseActions } from '../../fundraise.slice'
import { fundraiseActions } from '../../fundraise.slice'
import { selectSigner, selectWeb3Account } from '../../../web3/web3.selectors'
import { pushNotification } from '../../../../utils/pushNotification'

export function* swapFundTokensSaga({ payload }: FundraiseActions['swapFundTokens']): SagaGenerator<void> {
  const { fundraiseAddress, account: payloadAccount } = payload

  const web3Account = yield* select(selectWeb3Account)

  const account = payloadAccount ?? web3Account

  if (!account) throw new Error('No account provided or detected')

  try {
    const signer = yield* select(selectSigner)
    const fundraise = getFundraise(fundraiseAddress, signer)
    const tx = yield* call(fundraise.swapFundTokensToProjectTokensFrom, account)
    yield* call(tx.wait)

    yield* put(fundraiseActions.swapFundTokensSuccess())
    pushNotification(`You've successfully swapped funds`)
  } catch (error) {
    if (error instanceof Error) {
      pushNotification(`Swapping Funds Error: ${error.message}`, 'error')
      yield* put(fundraiseActions.swapFundTokensFailure(error.message))
    }
  }
}
