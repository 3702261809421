import { createSelector } from '@reduxjs/toolkit'

import { selectReducer } from '../store.utils'
import { StoreKeys } from '../store.keys'
import type { WalletTypeEnum } from '../../graphql/generated/graphql'
import { selectWeb3Account } from '../web3/web3.selectors'

import { walletAdapter } from './wallet.slice'

const selectState = createSelector([selectReducer(StoreKeys.Wallet)], (state) => state)

const selectAll = createSelector([selectState], (state) => walletAdapter.getSelectors().selectAll(state))

const selectByAddress = createSelector(
  [selectAll],
  (state) => (address: string) => state.find((wallet) => wallet.ethereumAddress === address)
)

const selectByWalletType = (walletType: WalletTypeEnum) => {
  return createSelector([selectAll], (state) => state.find((wallet) => wallet.walletType === walletType))
}

const selectActiveFirst = createSelector([selectAll, selectWeb3Account], (wallets, activeWalletAddress) =>
  activeWalletAddress ? [...wallets].sort((wallet) => (wallet.ethereumAddress === activeWalletAddress ? -1 : 0)) : wallets
)

const selectActiveWallet = createSelector([selectActiveFirst], (wallets) => wallets[0])

const selectNonActiveWallets = createSelector([selectAll, selectActiveWallet], (wallets, activeWallet) =>
  wallets.filter((wallet) => wallet.ethereumAddress !== activeWallet.ethereumAddress)
)

export const walletSelectors = {
  selectState,
  selectNonActiveWallets,
  selectByWalletType,
  selectByAddress,
  selectAll,
  selectActiveWallet,
  selectActiveFirst,
}
