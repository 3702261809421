import type { SagaGenerator } from 'typed-redux-saga'
import { all, apply, call, put, select } from 'typed-redux-saga'
import { ERC20__factory } from '@sapiency-io/mosaico-contracts/dist'

import { web3Selectors } from '../../../../web3/web3.selectors'
import { balancesActions } from '../../../balances.slice'
import { walletSelectors } from '../../../../wallet/wallet.selectors'
import type { TokenBalanceData } from '../../../balances.types'
import { ExternalBalanceType } from '../../../balances.const'
import { cryptocurrenciesSelectors } from '../../../../cryptocurrencies/cryptocurrencies.selectors'
import { BigDecimal } from '../../../../../utils/bigDecimal'

export function* getStablecoinsBalancesBlockchainSaga(walletAddress: string): SagaGenerator<TokenBalanceData[]> {
  const stablecoins = yield* select(cryptocurrenciesSelectors.selectStablecoins)
  const readOnlyProvider = yield* select(web3Selectors.selectReadOnlyProvider)

  return yield* all(
    stablecoins.map(function* (stablecoin) {
      const erc20Contract = ERC20__factory.connect(stablecoin.address, readOnlyProvider)

      const stablecoinBalanceBN = yield* call(erc20Contract.balanceOf, walletAddress)

      return {
        tokenAddress: stablecoin.address,
        balanceType: ExternalBalanceType.STABLECOIN,
        amount: BigDecimal.from(stablecoinBalanceBN, stablecoin.decimals).toFixed(stablecoin.decimals),
        account: walletAddress,
      }
    })
  )
}

export function* getNativeBalanceBlockchainSaga(walletAddress: string): SagaGenerator<TokenBalanceData> {
  const readOnlyProvider = yield* select(web3Selectors.selectReadOnlyProvider)

  const balance = yield* apply(readOnlyProvider, readOnlyProvider.getBalance, [walletAddress])
  const NATIVE_TOKEN = yield* select(cryptocurrenciesSelectors.selectNativeToken)

  if (!NATIVE_TOKEN) throw new Error('NATIVE_TOKEN not found')

  return {
    tokenAddress: NATIVE_TOKEN.address,
    balanceType: ExternalBalanceType.NATIVE,
    amount: BigDecimal.from(balance, NATIVE_TOKEN.decimals).toFixed(NATIVE_TOKEN.decimals),
    account: walletAddress,
  }
}

export function* getBalancesBlockchainSaga(): SagaGenerator<void> {
  try {
    const wallets = yield* select(walletSelectors.selectAll)

    const nativeBalances = yield* all(
      wallets.map(({ ethereumAddress: walletAddress }) => call(getNativeBalanceBlockchainSaga, walletAddress))
    )
    const stablecoinsBalances = yield* all(
      wallets.map(({ ethereumAddress: walletAddress }) => call(getStablecoinsBalancesBlockchainSaga, walletAddress))
    )

    yield* put(balancesActions.updateBalancesSuccess([...nativeBalances, ...stablecoinsBalances.flat()]))
  } catch (error) {
    if (error instanceof Error) yield* put(balancesActions.updateBalancesFailure(error.message))
  }
}
